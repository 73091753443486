import { useEffect, useState } from "react";

import useDebounce from "../../customHooks/useDebounce";

import DataAccordion from "../../components/UIs/DataAccordion/DataAccordion";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";

import { DateRange } from "react-date-range";
import dayjs from "dayjs";

import { toast } from "react-toastify";

import axios from "../../utils/axios";
import { useQuery } from "react-query";

import { useTranslation } from "react-i18next";

import cls from "./logs.module.scss";
import LargeSquare from "../../components/UIs/skeletons/LargeSquare/LargeSquare";
import Stripe from "../../components/UIs/skeletons/Stripe/Stripe";

const Logs = () => {
  // COMPONENT HOOKS
  const [date, setDate] = useState([
    {
      startDate: dayjs().startOf("month").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [search, setSearch] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateQuery, setDateQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [logsCount, setLogsCount] = useState(0);
  const debouncedSearchTerm = useDebounce(search, 1000);
  const { t: translate, i18n } = useTranslation("common");

  useEffect(() => {
    refetchLogs();
  }, [debouncedSearchTerm]);

  // COMPONENT HANDLERS
  const handleDateChange = (item) => {
    setDate([item.selection]);
    setDateQuery(
      `${new Date([item.selection][0].startDate).getTime()}~${new Date(
        [item.selection][0].endDate
      ).getTime()}`
    );
  };

  const handleDateFocusChange = (focused) => {
    if (!focused[0] && !focused[1]) setShowDatePicker(false);
  };

  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
  };

  const fetchAllLogs = async () => {
    const response = await axios
      .get(
        `/logs?limit=${limit}&skip=${
          (pageNumber - 1) * limit
        }&select=action,details,createdAt&createdAt=${
          dateQuery ? dateQuery : ""
        }&search=${search}`
      )
      .catch((err) => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    setLogsCount(response.data.success.LogsCount);

    return response.data.success.Logs;
  };

  const {
    data: allLogs,
    isFetching: logsLoading,
    refetch: refetchLogs,
  } = useQuery(["allLogs", pageNumber, dateQuery], fetchAllLogs, {
    refetchOnWindowFocus: false,
  });

  const resetDatefilter = () => {
    setDateQuery("");
  };

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box className={cls.logs}>
      <Box className={cls.logs__head}>
        <Typography variant="h6" component="h6">
          {translate("logs.title")}
        </Typography>

        <Typography>
          <span>{translate("logs.dashboard")}</span> &gt;{" "}
          {translate("logs.logs")}
        </Typography>
      </Box>

      <Box className={cls.logs__body} sx={{ bgcolor: "background.secondary" }}>
        {dateQuery && (
          <div className={cls.dateClearFilter}>
            <span onClick={resetDatefilter}>
              <i className="fa-light fa-arrow-rotate-left"></i>{" "}
              {translate("general.resetFilter")}
            </span>
          </div>
        )}

        <Box className={cls.logs__body__filters}>
          <Box>
            <label>{translate("support.search")}</label>

            <Box className={`${cls.search} ${cls[i18n.language]}`}>
              <i className="fa-light fa-magnifying-glass"></i>

              <input
                type="search"
                placeholder={translate("logs.search")}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>
          </Box>

          <Box className={cls.datePicker}>
            <label>{translate("support.date")}</label>

            <Box className={`${cls.datePicker__date} ${cls[i18n.language]}`}>
              <input
                type="text"
                readOnly
                value={
                  dayjs(date[0].startDate).format("YYYY-MM-DD") +
                  `  ${translate("analytics.to")}  ` +
                  dayjs(date[0].endDate || new Date()).format("YYYY-MM-DD")
                }
                onClick={() => setShowDatePicker((prev) => !prev)}
              />
              <i
                onClick={() => setShowDatePicker((prev) => !prev)}
                className="fa-light fa-calendar-day"
              ></i>

              {showDatePicker && (
                <DateRange
                  editableDateInputs={false}
                  onChange={handleDateChange}
                  onRangeFocusChange={handleDateFocusChange}
                  ranges={date}
                  retainEndDateOnFirstSelection={false}
                  showDateDisplay={false}
                />
              )}
            </Box>
          </Box>
        </Box>

        {!logsLoading ? (
          <Box>
            {allLogs.length ? (
              <>
                {allLogs.map((log, idx) => (
                  <DataAccordion key={idx} data={log} />
                ))}

                <Box className={cls.paginationWrapper}>
                  <Typography>
                    {translate("product.show")} {pageNumber}{" "}
                    {translate("product.of")} {Math.ceil(logsCount / limit)}{" "}
                    {translate("product.results")}
                  </Typography>

                  <Box className={`${cls.pagination} pagination`}>
                    <Pagination
                      count={Math.ceil(logsCount / limit)}
                      page={pageNumber}
                      onChange={changePage}
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <Box className="empty">
                <img src="/assets/imgs/logs/logs.png" alt="no logs" />
                <p>{translate("logs.empty")}</p>
              </Box>
            )}
          </Box>
        ) : (
          <>
            <Stripe />
            <Stripe />
            <Stripe />
            <Stripe />
            <Stripe />
            <Stripe />
            <Stripe />
            <Stripe />
            <Stripe />
            <Stripe />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Logs;
