import Skeleton from '@mui/material/Skeleton';

const SmallSquare = () => {
  return (
    <Skeleton variant="rectangular" width="100%" height="150px" style={{ borderRadius: '5px' }}>
      <div style={{ paddingTop: '57%' }} />
    </Skeleton>
  )
}

export default SmallSquare