import { useState } from 'react';

import { AllReturnsTable, CreateOrder } from '../../components';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useQuery } from 'react-query';
import axios from '../../utils/axios';

import { toast } from 'react-toastify';

import { format } from 'date-fns';

import { useTranslation } from 'react-i18next';

import cls from './returns.module.scss';

const Returns = () => {
  // COMPONENT HOOKS
  const [allReturns, setAllReturns] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(10);
  const [openCreateOrder, setOpenCreateOrder] = useState(false);
  const { t: translate } = useTranslation('common');

  // COMPONENT HANDLERS
  const fetchAllReturns = async () => {
    const response = await axios.get(`/return?po=1&pu=1&pi=1&p_items_select=name,images&limit=${limit}&skip=${(pageNumber - 1) * limit}`).catch(err => errorNotify(err.response.data.err));

    if(!response || !response.data) return;

    setTotalCount(response.data.success.totalCount)

    return response.data.success.returns;
  }

  const { data: allReturnsList, isFetching: returnsLoading, refetch: refetchReturns } = useQuery(['allReturnsList', pageNumber], fetchAllReturns, {
    refetchOnWindowFocus: false,
    onSuccess: (allReturnsList) => {
      const updatedReturns = allReturnsList.map(singleReturn => (
        {
          id: singleReturn._id,
          product:{
            img: singleReturn.items[0].item.item.images[0],
            name: `${singleReturn.items[0].item.item.name.substring(0, 50) + '...'}`
          },
          customer: {
            img: singleReturn.user.profile_picture || '/assets/imgs/user/user.jpg',
            name: singleReturn.user.username
          },
          date: `${format(new Date(singleReturn.order.createdAt).getTime(), 'dd/MM/yyyy')}`,
          method: singleReturn.order.payment.type,  
          status: singleReturn.status,
        }
      ))

      setAllReturns(updatedReturns)
    }
  })

  const successNotify = (message) => toast.success(message)
  const errorNotify = (message) => toast.error(message)

  return (
    <Box className={cls.returns}>

      <Box className={cls.returns__head}>

        <Typography variant='h6' component='h6'>{translate('returns.title')}</Typography>

        <Typography><span>{translate('returns.dashboard')}</span> &gt; {translate('returns.returns')}</Typography>

      </Box>

      <Box className={cls.returns__table} sx={{ bgcolor: 'background.secondary' }}>

        <AllReturnsTable 
          allReturns={allReturns} 
          returnsLoading={returnsLoading} 
          pageNumber={pageNumber} 
          setPageNumber={setPageNumber} 
          totalCount={totalCount} 
          limit={limit}
          refetchReturns={refetchReturns}
        />

      </Box>

      {openCreateOrder && <CreateOrder setOpenCreateOrder={setOpenCreateOrder} />}

    </Box>
  )
}

export default Returns;