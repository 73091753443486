import { useState } from 'react';

import Box from '@mui/material/Box'

import { useTranslation } from 'react-i18next';

import cls from './chooseList.module.scss';

const ChooseList = ({ list, text, icon, withArrow }) => {
  const [showFilter, setShowFilter] = useState(false);
  const { i18n } = useTranslation();

  return (
    <Box className={cls.wrapper}>
      {showFilter && <Box className={cls.dropLayer} onClick={() => setShowFilter(false)}></Box>}
      <button className="showPopup-btn">
        {icon}
        <span>{text}</span>
        {withArrow !== false && <i className="fa-regular fa-angle-down"></i>}
      </button>
      <Box className={`${cls.chooseList} ${cls[i18n.language]} popup`}>
        <ul>
          {
            list.map((item, idx) => (
              <li
                key={idx}
                className={`${item.icon ? '' : cls.iconNotFound} ${item.active ? cls.active : ''}`}
                onClick={() => item.method(item)}
              >
                {item.icon && item.icon}
                <span>{item.item}</span>
              </li>
            ))
          }
        </ul>
      </Box>
    </Box>
  )
}

export default ChooseList