import { configureStore } from "@reduxjs/toolkit";

// IMPORT SLICES
import userSliceReducer from "./slices/userSlice";
import websiteDataReducer from "./slices/websiteDataSlice";

const store = configureStore({
  reducer: {
    user: userSliceReducer,
    websiteData: websiteDataReducer,
  },
});

export default store;
