import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import NotificationsDropdown from "./NotificationsDropdown/NotificationsDropdown";
import Loader from "../UIs/Loading/Loading";

import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../store/slices/userSlice";

import MobSidebar from "./MobSidebar/MobSidebar";
import LangSwitch from "../Switches/LangSwitch/LangSwitch";
import ModeSwitch from "../Switches/ModeSwitch/ModeSwitch";

import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Swal from "sweetalert2";
import { toast } from "react-toastify";

import Cookies from "universal-cookie";

import axios from "../../utils/axios";

import socket from "../../utils/socket";

import cls from "./navbar.module.scss";

const cookies = new Cookies();

const Navbar = ({ smallBar, setSmallBar }) => {
  // COMPONENT HOOKS
  const [mobSidebar, setMobSidebar] = useState(false);
  const [mobileView, setMobileView] = useState(
    window.matchMedia("(max-width: 992px)").matches
  );
  const { t: translate, i18n } = useTranslation("nav");
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [userPopup, setUserPopup] = useState(false);
  const [showNotificationsDropdown, setShowNotificationsDropdown] =
    useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.onresize = () => {
      setMobileView(window.matchMedia("(max-width: 992px)").matches);
    };
  }, []);

  // NOTIFICATIONS WEBSOCKET
  useEffect(() => {
    socket.emit("user-connected", user._id);
    socket.on("new-notification", ({ notification, user }) => {
      dispatch(
        userActions.updateUser({
          notifications: [...user.notifications, notification],
        })
      );
    });
  }, []);

  const markAllAsRead = async () => {
    setLoading(true);
    const response = await axios
      .patch(`/notification/${user._id}`)
      .catch((err) => {
        errorNotify(err.response.data.err);
        setLoading(false);
      });
    if (!response || !response.data) return;
    setLoading(false);
    successNotify(t("notifications.allRead"));

    let userNotifications = JSON.parse(JSON.stringify(user.notifications));

    let updatedNotifications = userNotifications.map((one) => {
      if (one.read === false) {
        one.read = true;
        return one;
      } else {
        return one;
      }
    });

    dispatch(
      userActions.updateUser({ notifications: [...updatedNotifications] })
    );
  };

  const checkLogout = () => {
    Swal.fire({
      title: t("toast.sure"),
      text: t("toast.logoutWarn"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("toast.logout"),
      cancelButtonText: t("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  };

  const logout = async () => {
    localStorage.removeItem('markety-dashboard-session');
    cookies.remove("markety-dashboard-session");
    dispatch(userActions.setUser(null));

    await axios.post('/auth/logout').catch(err => errorNotify(err))

    location.reload()
    // navigate("/login");
  };

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box
      className={`${cls.navbar} ${smallBar ? cls.small : ""}`}
      sx={{ bgcolor: "background.secondary" }}
    >
      {loading && <Loader />}

      <Box className={cls.navbar__content}>
        <Box className={cls.navbar__content_icons}>
          <Box
            className={cls.smallBarIcon}
            onClick={() => setSmallBar(!smallBar)}
            sx={{ bgcolor: "background.default" }}
          >
            <i className="fa-regular fa-bars-sort"></i>
          </Box>

          <Box
            className={cls.mobBar}
            onClick={() => setMobSidebar(!mobSidebar)}
            sx={{ bgcolor: "background.default" }}
          >
            <i className="fa-thin fa-grid"></i>
          </Box>

          <Box sx={{ bgcolor: "background.default" }}>
            <input
              type="search"
              placeholder={translate("nav.search")}
              className={cls[i18n.language]}
            />
            <button>
              <i className="fa-light fa-magnifying-glass"></i>
            </button>
          </Box>
        </Box>

        <Box className={cls.navbar__content_actions}>
          <Box
            className={cls.notifications}
            onClick={() =>
              setShowNotificationsDropdown(!showNotificationsDropdown)
            }
          >
            <i className={`${cls.notificationIcon} fa-regular fa-bell-on`}></i>

            <Box className={cls.num}>
              {user &&
                user.notifications &&
                user.notifications.filter((not) => not.read === false).length}
            </Box>

            {showNotificationsDropdown && (
              <Box className={cls.notificationsDropdown}>
                <Box
                  className={cls.layedDrop}
                  onClick={() => setShowNotificationsDropdown(false)}
                ></Box>

                <Box className={`${cls.popup} ${cls[i18n.language]}`}>
                  <Box className={cls.notificationsHead}>
                    <Link to="/notifications">
                      <span>
                        <i className="fa-light fa-eye"></i>{" "}
                        {t("notifications.view")}
                      </span>
                    </Link>
                    <span onClick={markAllAsRead}>
                      <i className="fa-light fa-badge-check"></i>{" "}
                      {t("notifications.markAsRead")}
                    </span>
                  </Box>

                  <NotificationsDropdown
                    userId={user._id}
                    notifications={user.notifications}
                    setLoading={setLoading}
                  />
                </Box>
              </Box>
            )}
          </Box>

          <LangSwitch />

          <ModeSwitch />

          <Box className={`${cls.user} ${cls[i18n.language]}`}>
            <Box
              className={cls.user__thumb}
              sx={{ bgcolor: "background.default" }}
              onClick={() => setUserPopup(!userPopup)}
            >
              {user.profile_picture ? (
                <img
                  className={cls[i18n.language]}
                  src={user.profile_picture}
                  alt={user.username}
                />
              ) : (
                <Box className={`${cls.imageAlt} ${cls[i18n.language]}`}>
                  {user.username.substring(0, 1)}
                </Box>
              )}

              <Box>
                <Typography component="p">{user.username}</Typography>
                <Typography component="span">{user.role}</Typography>
              </Box>
            </Box>

            {userPopup && (
              <Box className={cls.user__popup}>
                <Box
                  className={cls.layedDrop}
                  onClick={() => setUserPopup(false)}
                ></Box>

                <Box
                  className={`${cls.popup} ${cls[i18n.language]}`}
                  sx={{ bgcolor: "background.secondary" }}
                >
                  <Box
                    onClick={() => {
                      navigate("/account");
                      setUserPopup(false);
                    }}
                  >
                    <i className="fa-light fa-gear"></i>
                    {translate("nav.accountSett")}
                  </Box>
                  <Box
                    className={cls.logout}
                    onClick={() => {
                      checkLogout();
                      setUserPopup(false);
                    }}
                  >
                    <i className="fa-light fa-arrow-right-from-bracket"></i>
                    {translate("nav.logout")}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {mobileView && (
        <MobSidebar mobSidebar={mobSidebar} setMobSidebar={setMobSidebar} />
      )}
    </Box>
  );
};

export default Navbar;
