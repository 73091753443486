import { useState, useMemo } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import ChooseList from "../UIs/ChooseList/ChooseList";
import InputFieldComponent from "../UIs/InputField/InputField";
import LargeSquare from "../UIs/skeletons/LargeSquare/LargeSquare";
import SmallSquare from "../UIs/skeletons/SmallSquare/SmallSquare";
import { useQuery } from "react-query";
import Loader from "../UIs/Loading/Loading";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";

import { useTranslation } from "react-i18next";

import axios from "../../utils/axios";

import { toast } from "react-toastify";
import Swal from "sweetalert2";

import cls from "./addCountry.module.scss";

const AddCountry = () => {
  // COMPONENT HOOKS
  const [pageNumber, setPageNumber] = useState(1);
  const [emptyFields, setEmptyFields] = useState(false);
  const [country, setCountry] = useState("");
  const [townFields, setTownFields] = useState({});
  const [choosedCities, setChoosedCities] = useState([]);
  const { t: translate, i18n } = useTranslation("common");
  const [searchParams, setSearchParams] = useSearchParams();
  const [countryId] = useState(searchParams.get("country"));
  const [updateCityMode, setUpdateCityMode] = useState(false);
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // COMPONENT HANDLERS
  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
    gotoPage(pageNum - 1);
  };

  // if (countryId) {
  const fetchCountryData = async () => {
    const response = await axios
      .get(`/logistics/${countryId}`)
      .catch((err) => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    return response.data.success;
  };

  const {
    data: countryData,
    isFetching: countryDataLoading,
    refetch: refetchCountryData,
  } = useQuery("countryData", countryId ? fetchCountryData : "", {
    refetchOnWindowFocus: false,
    onSuccess: (countryData) => {
      setCountry(countryData.country);
      setChoosedCities([...countryData.cities]);
    },
  });
  // }

  const actionList = (id, cityName) => [
    {
      item: `${translate("category.delete")}`,
      icon: <i className="fa-light fa-trash-can"></i>,
      method: () => checkDeleteCity(id, cityName),
    },
  ];

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        header: `${translate("logistics.town")}`,
        accessor: "name",
        minWidth: "15%",
        width: "15%",
      },
      {
        header: `${translate("logistics.delivery")}`,
        accessor: "estimated_delivery",
        minWidth: "5%",
        width: "10%",
      },
      {
        header: `${translate("logistics.charge")} (${websiteData.currency})`,
        id: "shipping",
        accessor: "shipping",
        Cell: ({ value }) => <span>{value.toFixed(2)}</span>,
        minWidth: "15%",
        width: "15%",
      },
      {
        header: `${translate("logistics.action")}`,
        id: "action",
        Cell: ({ row }) => (
          <ChooseList
            list={actionList(row.original._id, row.original.name)}
            text={<i className="fa-thin fa-ellipsis-stroke"></i>}
            withArrow={false}
          />
        ),
        disableSortBy: true,
        minWidth: "2%",
        width: "2%",
      },
    ],
    [i18n.language]
  );

  const tableInstance = useTable(
    { columns, data: choosedCities, initialState: { pageSize: 10 } },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    pageCount,
    pageOptions,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, selectedRowIds },
  } = tableInstance;

  // COMPONENTS HANDLERS
  const handleTownFields = (e) => {
    setTownFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const addLogistic = async () => {
    const data = {
      country: country,
      cities: [...choosedCities],
    };

    // const emptyFields = Object.values(data.country).filter(one => one === undefined || one === '');

    if (!country) return setEmptyFields(true);

    setLoading(true);

    const response = await axios
      .post("/logistics", data)
      .catch((err) => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    successNotify(translate("toast.logistic"));

    navigate("/logistics");

    setLoading(false);
  };

  const addNewTown = async () => {
    const data = {
      city: {
        name: townFields.town,
        shipping: townFields.charge,
        estimated_delivery: townFields.deliverTime,
      },
    };

    const emptyFields = Object.values(data.city).filter(
      (one) => one === undefined || one === ""
    );

    if (emptyFields.length || !country) return setEmptyFields(true);

    setChoosedCities((prev) => [...prev, data["city"]]);

    Object.keys(townFields).forEach((key) => {
      townFields[key] = "";
    });

    setTownFields({ ...townFields });
  };

  const addExtraTown = async () => {
    const data = {
      city: {
        name: townFields.town,
        shipping: townFields.charge,
        estimated_delivery: townFields.deliverTime,
      },
    };

    const emptyFields = Object.values(data.city).filter(
      (one) => one === undefined || one === ""
    );

    if (emptyFields.length || !country) return setEmptyFields(true);

    setLoading(true);

    const response = await axios
      .patch(`/logistics/${countryId}/add-city`, data)
      .catch((err) => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    setChoosedCities((prev) => [...prev, data["city"]]);

    successNotify(translate("toast.cityAdded"));

    setLoading(false);

    Object.keys(townFields).forEach((key) => {
      townFields[key] = "";
    });

    setTownFields({ ...townFields });

    refetchCountryData();
  };

  const checkDeleteLogistic = () => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteLogistic"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        removeLogistic();
      }
    });
  };

  const removeLogistic = async () => {
    const response = await axios
      .delete(`/logistics/${countryId}`)
      .catch((err) => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    successNotify(translate("toast.logisticDeleted"));

    navigate("/logistics");
  };

  const checkDeleteCity = (id, name) => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteCity"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCity(id, name);
      }
    });
  };

  const deleteCity = async (id, name) => {
    setLoading(true);
    setChoosedCities((prev) => [...prev.filter((city) => city.name !== name)]);

    if (id) {
      const response = await axios
        .delete(`/logistics/${countryId}/remove-city`, {
          data: { city_id: id },
        })
        .catch((err) => {
          errorNotify(err.response.data.err);
          setLoading(false);
        });

      if (!response || !response.data) return;
    }

    setLoading(false);

    setTownFields({});

    setUpdateCityMode(false);

    successNotify(translate("toast.cityDeleted"));
  };

  const checkDeleteMultipleCities = () => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteCities"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMultipleCities();
      }
    });
  };

  const deleteMultipleCities = async () => {
    const ids = selectedFlatRows.map((id) => id.original.id);
    const response = await axios
      .delete(`/logistics`, { data: { IDs: ids } })
      .catch((err) => {
        errorNotify(err.response.data.err);
      });

    if (!response || !response.data) return;

    successNotify(translate("toast.citiesDeleted"));
  };

  const setCityToUpdate = (data) => {
    setTownFields({
      _id: data._id,
      town: data.name,
      deliverTime: data.estimated_delivery,
      charge: data.shipping,
    });

    setUpdateCityMode(true);
  };

  const updateCity = async () => {
    setLoading(true);

    const updatedCity = {
      city_id: townFields._id,
      city: townFields.town,
      delivery: townFields.deliverTime,
      charge: townFields.shipping,
    };

    const response = await axios
      .patch(`/logistics/${countryId}`, updatedCity)
      .catch((err) => {
        setLoading(false);
        errorNotify(err.response.data.err);
      });

    if (!response || !response.data) return;

    let allCities = [...choosedCities];

    let cityFound = allCities.findIndex(
      (city) => city.name === updatedCity.city
    );

    allCities.splice(cityFound, 1, {
      name: townFields.town,
      estimated_delivery: townFields.deliverTime,
      shipping: townFields.charge,
    });

    setChoosedCities([...allCities]);

    Object.keys(townFields).forEach((key) => {
      townFields[key] = "";
    });

    setTownFields({ ...townFields });

    setLoading(false);

    refetchCountryData();

    successNotify(translate("toast.cityUpdated"));

    setUpdateCityMode(false);
  };

  const outUpdateMode = () => {
    setUpdateCityMode(false);

    Object.keys(townFields).forEach((key) => {
      townFields[key] = "";
    });

    setTownFields({ ...townFields });
  };

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  if (countryDataLoading && countryId) {
    return (
      <>
        <SmallSquare />
        <div className={cls.loadingPart}>
          <LargeSquare />
          <LargeSquare />
        </div>
      </>
    );
  }

  return (
    <Box className={cls.addCountry}>
      {loading && <Loader />}

      <Box
        className={cls.addCountry__country}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Typography variant="h6" component="h6">
          {translate("addCountry.name")}
        </Typography>

        <InputFieldComponent
          type="text"
          placeholder={translate("addCountry.country")}
          name="country"
          value={country}
          setFunc={(e) => setCountry(e.target.value)}
          error={emptyFields && !country}
        />
      </Box>

      <Typography variant="h5" component="h5">
        {translate("addCountry.manage")}
      </Typography>

      <Typography>{translate("addCountry.edit")}</Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} xl={5}>
          <Box
            className={cls.addCountry__create}
            sx={{ bgcolor: "background.secondary" }}
          >
            <label>{translate("addCountry.town")}</label>
            <InputFieldComponent
              type="text"
              placeholder={translate("addCountry.town")}
              name="town"
              value={townFields.town || ""}
              setFunc={(e) => handleTownFields(e)}
              error={emptyFields && !townFields.town}
            />

            <label>{translate("addCountry.deliver")}</label>
            <InputFieldComponent
              type="number"
              placeholder={translate("addCountry.deliver")}
              name="deliverTime"
              value={townFields.deliverTime || ""}
              setFunc={(e) => handleTownFields(e)}
              error={emptyFields && !townFields.deliverTime}
            />

            <label>
              {translate("addCountry.charge")} ({websiteData.currency})
            </label>
            <InputFieldComponent
              type="number"
              placeholder={translate("addCountry.charge")}
              name="charge"
              value={townFields.charge || ""}
              setFunc={(e) => handleTownFields(e)}
              error={emptyFields && !townFields.charge}
            />

            {countryId && !updateCityMode ? (
              <Box className={cls.btn}>
                <button onClick={addExtraTown}>
                  {translate("addCountry.addExtraTown")}
                </button>
              </Box>
            ) : (
              <>
                {!updateCityMode && (
                  <Box className={cls.btn}>
                    <button onClick={addNewTown}>
                      {translate("addCountry.addTown")}
                    </button>
                  </Box>
                )}
              </>
            )}

            {updateCityMode && (
              <Box className={cls.btn}>
                <button onClick={updateCity}>
                  {translate("addCountry.updateTown")}
                </button>
              </Box>
            )}

            {updateCityMode && (
              <p className={cls.addNew} onClick={outUpdateMode}>
                {translate("addCountry.addNew")}
              </p>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} xl={7}>
          {selectedFlatRows.length >= 1 && (
            <Box className={cls.deleteSelected}>
              {translate("createSubCat.select")} {selectedFlatRows.length}{" "}
              {translate("createSubCat.result")}{" "}
              <span onClick={checkDeleteMultipleCities}>
                {translate("addCountry.remove")}
              </span>
            </Box>
          )}

          <Box
            className={cls.addCountry__edit}
            sx={{ bgcolor: "background.secondary" }}
          >
            <Typography variant="h6" component="h6">
              {translate("addCountry.towns")}
            </Typography>

            {!choosedCities.length ? (
              <Box className="empty">
                <img src="/assets/imgs/logistics/global.png" alt="empty logistics" />
                <p>{translate("logistics.noCities")}</p>
              </Box>
            ) : (
              <>
                <Table {...getTableProps()}>
                  <TableHead>
                    {headerGroups.map((headerGroup, idx) => (
                      <TableRow
                        key={idx}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column, idx) => (
                          <TableCell
                            key={idx}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={{
                              minWidth: column.minWidth,
                              width: column.width,
                            }}
                          >
                            <span>
                              {column.render("header")}

                              {!column.disableSortBy && (
                                <>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <i className="fa-light fa-sort-down"></i>
                                    ) : (
                                      <i className="fa-light fa-sort-up"></i>
                                    )
                                  ) : (
                                    <i className="fa-light fa-sort"></i>
                                  )}
                                </>
                              )}
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>

                  <TableBody {...getTableBodyProps()}>
                    {page.map((row, idx) => {
                      prepareRow(row);

                      return (
                        <TableRow key={idx} {...row.getRowProps()}>
                          {row.cells.map((cell, idx) => (
                            <TableCell
                              key={idx}
                              {...cell.getCellProps()}
                              style={{
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                              }}
                              onClick={() => setCityToUpdate(cell.row.original)}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>

                <Box className={cls.footer}>
                  <Typography>
                    {translate("product.show")} {pageIndex + 1}{" "}
                    {translate("product.of")} {pageOptions.length}{" "}
                    {translate("product.results")}
                  </Typography>

                  <Box className={`${cls.pagination} pagination`}>
                    <Pagination
                      count={pageCount}
                      page={pageNumber}
                      onChange={changePage}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>

          {countryId ? (
            <div className={cls.actions}>
              <Box className={cls.removeLogistic}>
                <button onClick={checkDeleteLogistic}>
                  {translate("addCountry.removeLogistic")}
                </button>
              </Box>

              {/* <Box className={cls.addCountry}>
                <button onClick={UpdateLogistic}>{translate('addCountry.updateLogistic')}</button>
              </Box> */}
            </div>
          ) : (
            <Box className={cls.addCountry}>
              <button onClick={addLogistic}>
                {translate("addCountry.addLogistic")}
              </button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddCountry;
