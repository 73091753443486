import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';

import cls from './footer.module.scss'

const Footer = () => {
  const { t:translate, i18n } = useTranslation('nav')

  return (
    <Box className={cls.footer}>
      <Typography>
        {translate('footer.markety')}
      </Typography>
      <Typography>
        {translate('footer.name')}
      </Typography>
    </Box>
  )
}

export default Footer