import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import cls from "./itemForReturn.module.scss";

const ItemForReturn = ({ product, choosedItems, chooseItem }) => {
  // COMPONENT HOOKS
  const [itemData, setItemData] = useState({
    qty: product.qty || "",
    amount: product.price || "",
  });
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const { t: translate, i18n } = useTranslation("common");
  const navigate = useNavigate();

  const selectItem = () => {
    const item = {
      item: product._id,
      qty: itemData.qty,
      amount: itemData.amount,
    };

    chooseItem(item);
  };

  const handleItemData = (e) => {
    if (e.target.name === "qty" && e.target.value > product.qty) return false;
    if (e.target.name === "amount" && e.target.value > product.price)
      return false;

    setItemData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div
      className={`${cls.body__order} ${
        choosedItems.find((item) => item.item === product._id)
          ? cls.choosed
          : ""
      }`}
      key={product.item._id}
    >
      <div className={cls.body__order_img}>
        <img
          className={cls[i18n.language]}
          src={product.item.images[0]}
          alt="orderImage"
          loading="lazy"
        />

        <div>
          <h6 onClick={() => navigate(`/products/${product.item._id}`)}>
            {product.item.name.substring(0, 50) + "..."}
          </h6>

          <div className={cls.color}>
            <span>{translate("support.color")}: </span>
            <p style={{ backgroundColor: product.color }}></p>
          </div>

          <div className={cls.inputs}>
            <div>
              <label>{translate("support.number")}</label>
              <input
                type="number"
                max={product.qty}
                placeholder={translate("support.number")}
                name="qty"
                value={itemData.qty}
                onChange={handleItemData}
              />
            </div>
            <div>
              <label>
                {translate("support.amount")} ({websiteData.currency})
              </label>
              <input
                type="number"
                max={product.price}
                placeholder={translate("support.amount")}
                name="amount"
                value={itemData.amount.toFixed(2)}
                onChange={handleItemData}
              />
            </div>
          </div>
        </div>

        <div className={cls.checkdiv}>
          <input type="checkbox" onChange={() => selectItem(product)} />
        </div>
      </div>
    </div>
  );
};

export default ItemForReturn;
