import { useState, useMemo } from "react";
import { useNavigate } from "react-router";

import ChooseList from "../../UIs/ChooseList/ChooseList";
import TableCheckBox from "../../UIs/TableCheckBox/TableCheckBox";
import EditReturn from "./../../modals/EditReturn/EditReturn";
import LargeSquare from "../../UIs/skeletons/LargeSquare/LargeSquare";
import Loader from "../../UIs/Loading/Loading";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";
import Snackbar from "@mui/material/Snackbar";

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";

import axios from "../../../utils/axios";

import Swal from "sweetalert2";
import { toast } from "react-toastify";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { useTranslation } from "react-i18next";

import cls from "./allReturnsTable.module.scss";

const AllReturnsTable = ({
  allReturns,
  returnsLoading,
  pageNumber,
  setPageNumber,
  totalCount,
  limit,
  refetchReturns,
}) => {
  // COMPONENT HOOKS
  const [showReturnOrder, setShowReturnOrder] = useState(false);
  const [choosedReturn, setChoosedReturn] = useState({});
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const { t: translate, i18n } = useTranslation("common");
  const navigate = useNavigate();

  const actionList = (id, returned) => [
    {
      item: `${translate("category.view")}`,
      icon: <i className="fa-light fa-eye"></i>,
      method: () => navigate(`/returns/${id}`),
    },
    {
      item: `${translate("category.edit")}`,
      icon: <i className="fa-light fa-pen-nib"></i>,
      method: () => {
        setShowReturnOrder(true);
        setChoosedReturn(returned);
      },
    },
    {
      item: `${translate("category.delete")}`,
      icon: <i className="fa-light fa-trash-can"></i>,
      method: () => checkDeleteReturn(id),
    },
  ];

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        header: `${translate("orders.id")}`,
        accessor: "id",
        Cell: ({ value }) => (
          <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
            <span className="idString">{value.substring(0, 10) + "..."}</span>
          </CopyToClipboard>
        ),
        minWidth: "3%",
        width: "3%",
      },
      {
        header: `${translate("orders.product")}`,
        id: "product",
        accessor: "product.name",
        Cell: ({ value, row }) => (
          <Box className={cls.product}>
            <img
              src={row.original.product.img}
              alt="productImage"
              className="productImage"
              loading="lazy"
            />

            <Box className={cls.info}>
              <p>{value}</p>
            </Box>
          </Box>
        ),
        minWidth: "20%",
        width: "20%",
      },
      {
        header: `${translate("orders.customer")}`,
        id: "customer",
        accessor: "customer.name",
        Cell: ({ value, row }) => (
          <Box className={cls.customer}>
            <img
              src={row.original.customer.img}
              alt="productImage"
              loading="lazy"
            />

            <Box className={cls.info}>
              <p>{value}</p>
            </Box>
          </Box>
        ),
        minWidth: "15%",
        width: "15%",
      },
      {
        header: `${translate("orders.date")}`,
        accessor: "date",
        Cell: ({ value }) => <span>{value}</span>,
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("orders.method")}`,
        accessor: "method",
        minWidth: "15%",
        width: "15%",
      },
      {
        header: `${translate("returns.status")}`,
        id: "status",
        accessor: "status",
        Cell: ({ value }) => (
          <span
            className={`${cls.status} ${cls[value]} ${
              value === "Under Progress" && cls.Under_progress
            }`}
          >
            {value}
          </span>
        ),
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("orders.action")}`,
        id: "action",
        Cell: ({ row }) => (
          <ChooseList
            list={actionList(row.original.id, row.original)}
            text={<i className="fa-thin fa-ellipsis-stroke"></i>}
            withArrow={false}
          />
        ),
        disableSortBy: true,
        minWidth: "5%",
        width: "5%",
      },
    ],
    [i18n.language]
  );

  const tableInstatce = useTable(
    {
      columns,
      data: allReturns || [],
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <TableCheckBox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <TableCheckBox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableSortBy: true,
          minWidth: "2%",
          width: "2%",
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    pageCount,
    pageOptions,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, selectedRowIds },
  } = tableInstatce;

  // COMPONENT HANDLERS
  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
    gotoPage(pageNum - 1);
  };

  const checkDeleteReturn = (id) => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteReturn"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReturn(id);
      }
    });
  };

  const deleteReturn = async (id) => {
    setLoading(true);
    const response = await axios.delete(`/return/${id}`).catch((err) => {
      errorNotify(err.response.data.err);
      setLoading(false);
    });

    if (!response || !response.data) return;

    refetchReturns();

    setLoading(false);

    successNotify(translate("toast.returnDeleted"));
  };

  const checkDeleteMultipleReturns = () => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteReturns"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMultipleReturns();
      }
    });
  };

  const deleteMultipleReturns = async () => {
    setLoading(true);
    const ids = selectedFlatRows.map((id) => id.original.id);
    const response = await axios
      .delete(`/return`, { data: { IDs: ids } })
      .catch((err) => {
        errorNotify(err.response.data.err);
        setLoading(false);
      });

    if (!response || !response.data) return;

    refetchReturns();

    setLoading(false);

    successNotify(translate("toast.returnsDeleted"));
  };

  const gotToReturnDetails = (e, id) => {
    if (
      e.target.tagName !== "INPUT" &&
      e.target.tagName !== "BUTTON" &&
      e.target.tagName !== "LI" &&
      e.target.tagName !== "SPAN"
    ) {
      navigate(`/returns/${id}`);
    }
  };

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box className={cls.allReturnsTable}>
      {loading && <Loader />}

      <Box className={cls.returns__table_options}>
        <Box className={`${cls.search} ${cls[i18n.language]}`}>
          <i className="fa-light fa-magnifying-glass"></i>

          <input
            type="search"
            placeholder={translate("returns.search")}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </Box>
      </Box>

      <Box className={cls.allReturnsTable__table}>
        {selectedFlatRows.length >= 1 && (
          <Box className={cls.deleteSelected}>
            {translate("product.select")} {selectedFlatRows.length}{" "}
            {translate("product.result")}{" "}
            <span onClick={checkDeleteMultipleReturns}>
              {translate("product.remove")}
            </span>
          </Box>
        )}

        {!returnsLoading ? (
          <>
            {allReturns.length && rows.length >= 1 ? (
              <>
                <Table {...getTableProps()}>
                  <TableHead>
                    {headerGroups.map((headerGroup, idx) => (
                      <TableRow
                        key={idx}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column, idx) => (
                          <TableCell
                            key={idx}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={{
                              minWidth: column.minWidth,
                              width: column.width,
                            }}
                          >
                            <span>
                              {column.render("header")}

                              {!column.disableSortBy && (
                                <>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <i className="fa-light fa-sort-down"></i>
                                    ) : (
                                      <i className="fa-light fa-sort-up"></i>
                                    )
                                  ) : (
                                    <i className="fa-light fa-sort"></i>
                                  )}
                                </>
                              )}
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>

                  <TableBody {...getTableBodyProps()}>
                    {page.map((row, idx) => {
                      prepareRow(row);

                      return (
                        <TableRow
                          key={idx}
                          {...row.getRowProps()}
                          onClick={(e) =>
                            gotToReturnDetails(e, row.original.id)
                          }
                        >
                          {row.cells.map((cell, idx) => (
                            <TableCell
                              key={idx}
                              {...cell.getCellProps()}
                              style={{
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                              }}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>

                <Box className={cls.allReturnsTable__footer}>
                  <Typography>
                    {translate("product.show")} {pageNumber}{" "}
                    {translate("product.of")} {Math.ceil(totalCount / limit)}{" "}
                    {translate("product.results")}
                  </Typography>

                  <Box className={`${cls.pagination} pagination`}>
                    <Pagination
                      count={Math.ceil(totalCount / limit)}
                      page={pageNumber}
                      onChange={changePage}
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <Box className="empty">
                <img src="/assets/imgs/returns/returns.png" alt="no returns" />
                <p>{translate("returns.empty")}</p>
              </Box>
            )}
          </>
        ) : (
          <LargeSquare />
        )}
      </Box>

      {showReturnOrder && (
        <EditReturn
          setShowReturnOrder={setShowReturnOrder}
          returnDetails={choosedReturn}
          refetchReturns={refetchReturns}
        />
      )}
      {/* SNACKBAR COMPONENT */}
      <Snackbar
        open={copied}
        autoHideDuration={2000}
        onClose={() => setCopied(false)}
        message="Copied to Clipboard!"
      />
    </Box>
  );
};

export default AllReturnsTable;
