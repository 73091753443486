import { useState, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../../store/slices/userSlice";

import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";

import axios from "../../../utils/axios";

import cls from "./linkAccount.module.scss";

const LinkAccount = ({ setOpenLinkAccount, setLoading }) => {
  const userData = useSelector(({ user }) => user.user);
  const [email, setEmail] = useState(userData.email);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const overlay = useRef();
  const { t: translate, i18n } = useTranslation("common");

  const closeModal = (e) => {
    if (overlay.current === e.target) {
      setOpenLinkAccount(false);
    }
  };
  const close = () => {
    setOpenLinkAccount(false);
  };

  const linkAccount = async () => {
    const data = {
      link: {
        type: "email",
        email,
        password,
      },
    };

    setLoading(true);

    const res = await axios
      .patch(`/user/${userData._id}/link`, data)
      .catch((err) => errorNotify(err.response.data.err));

    if (!res || !res.data) return;

    close();

    dispatch(userActions.updateUser({ password_linked: true }));

    setLoading(false);

    successNotify(translate("linkSettings.linkedSuccess"));
  };

  // TOSTIFY ALERTS
  const successNotify = (msg) => toast.success(`${msg}`);
  const errorNotify = (msg) => toast.error(`${msg}`);

  return (
    <Box className={cls.overlay} ref={overlay} onClick={(e) => closeModal(e)}>
      <Box className={cls.area}>
        <Box className={cls.area__wrapper}>
          <Box className={`${cls.close} ${cls[i18n.language]}`} onClick={close}>
            <i className="fa-solid fa-xmark"></i>
          </Box>

          <Box container className={cls.area__content} spacing={3}>
            <h3>{translate("linkSettings.link")}</h3>

            <Box className={cls.area__content_field}>
              <label>{translate("linkSettings.emailAddress")}</label>
              <input
                type="text"
                value={email}
                placeholder={translate("linkSettings.emailAddress")}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>

            <Box className={cls.area__content_field}>
              <label>{translate("linkSettings.password")}</label>
              <input
                type="password"
                placeholder={translate("linkSettings.password")}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>

            <Box className={cls.actions}>
              <button onClick={close}>{translate("userProfile.close")}</button>

              <button onClick={linkAccount}>
                {translate("linkSettings.linkAcc")}
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LinkAccount;
