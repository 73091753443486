import Box from '@mui/material/Box';

import NavList from '../../NavList/NavList';

import { useTranslation } from 'react-i18next';

import cls from './MobSidebar.module.scss'

const MobSidebar = ({ mobSidebar, setMobSidebar }) => {
  // COMPONENT HOOKS
  const { i18n } = useTranslation()

  return (
    <Box className={`${cls.wrapper} ${mobSidebar ? cls.show : cls.hide}`}>

      <Box className={cls.dropLayer} onClick={() => setMobSidebar(false)}></Box>

      <Box className={`${cls.mobSidebar} ${cls[i18n.language]}`} sx={{ bgcolor: 'background.secondary' }}>

        <Box className={cls.mobSidebar__mobileSearch} sx={{bgcolor: 'background.default'}}>
          <input type="search" placeholder='search' className={cls[i18n.language]} />
          <button><i className="fa-light fa-magnifying-glass"></i></button>
        </Box>

        <NavList setMobSidebar={setMobSidebar} />

      </Box>

    </Box>
  )
}

export default MobSidebar;