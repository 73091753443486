import { AnalyticsHead } from '../../components';
import { RevenueChart } from '../../components';
import { BestSellingTable } from '../../components';
import { OrdersTable } from '../../components';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';

import cls from './analytics.module.scss'

const Analytics = () => {
  // REACT STATES
  const { t } = useTranslation('auth')

  return (
    <Box className={cls.analytics} sx={{bgcolor: 'background.default'}}>

      {/* ANALYTICS HEAD COMPONENT */}
      <AnalyticsHead />

      {/* REVENUE CHART COMPONENT */}
      <RevenueChart />

      {/* ANALYTICS TABLES */}
      <Grid container direction={"row"} spacing={3} alignItems="stretch">

        <Grid item xs={12} xl={7}>

          <OrdersTable />

        </Grid>

        <Grid item xs={12} xl={5}>

          <BestSellingTable />      

        </Grid>
      
      </Grid>

    </Box>
  )
}

export default Analytics