import Skeleton from '@mui/material/Skeleton';

const Stripe = () => {
  return (
    <Skeleton variant="rectangular" width="100%" height="50px" style={{ borderRadius: '5px', marginBottom: '10px' }}>
      <div style={{ paddingTop: '57%' }} />
    </Skeleton>
  )
}

export default Stripe