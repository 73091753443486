import { useState } from "react";

import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import cls from "./smallTopAnalytics.module.scss";
// DATE RANGE PICKER PACKAGE STYLES
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const SmallTopAnalytics = ({ analytics }) => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation("common");

  return (
    <Box>
      <Box className={cls.smallTopAnalytics}>
        {analytics.map((section) => (
          <Box
            key={section.id}
            className={`${cls.smallTopAnalytics__section} section`}
          >
            <Box className={cls.smallTopAnalytics__section_part}>
              <Typography>{section.label}</Typography>

              {section.percent && <span>{section.percent}</span>}
            </Box>

            <Box className={cls.smallTopAnalytics__section_part}>
              <Typography className={cls.price}>{section.var}</Typography>

              {section.item && (
                <Typography className={cls.item}>{section.item}</Typography>
              )}
              <Typography className={cls.icon}>{section.icon}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SmallTopAnalytics;
