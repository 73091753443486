import { useState } from 'react';

import { InputFieldComponent } from '../../../components';

import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

import Selectbox from '../../UIs/SelectBox/Selectbox';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next'

import cls from './createProductOptions.module.scss';

const statusList = [
  'Published',
  'Hidden'
]

const CreateProductsOptions = ({
  productFields,
  setProductFields,
  emptyFields,
  selectStatus,
  setSelectStatus,
  categoryValue,
  setCategoryValue,
  subCategoryValue,
  setSubCategoryValue,
  allCategories,
  choosedCategory,
  setChoosedCategory,
  setChoosedSubCategory
}) => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation('common');

  const handleChange = (tags) => {
    setProductFields(prev => (
      {...prev, tags: tags}
    ))
  }

  const chooseCategory = (e) => {
    setCategoryValue(e.target.value);
    setChoosedCategory({...allCategories.find(category => category.name === e.target.value)});
  }

  const changeSubcategory = (e) => {
    setSubCategoryValue(e.target.value);
    setChoosedSubCategory(choosedCategory.sub_categories.find(sub => sub.name === e.target.value))
    if(choosedCategory && !choosedCategory.sub_categories.filter(sub => sub.name === e.target.value).length) {
      setSubCategoryValue('')
    }
  }

  const handleBrand = (value) => {
    setProductFields(prev => (
      {...prev, brand: value}
    ))
  }

  const handleShortDesc = (value) => {
    setProductFields(prev => (
      {...prev, short_description: value}
    ))
  }

  return (
    <Box className={cls.createProductsOptions}>
      
      <Box className={cls.createProductsOptions__publish} sx={{ bgcolor: 'background.secondary' }}>

        <Typography variant='h6' component='h6'>
          {translate('createPro.publish')}
        </Typography>

        <label>
          {translate('createPro.status')}
        </label>

        <Selectbox list={statusList} value={selectStatus} placeholder='set product status' select={setSelectStatus} />

      </Box>
      
      <Box className={cls.createProductsOptions__category} sx={{ bgcolor: 'background.secondary' }}>

        <Typography variant='h6' component='h6'>
          {translate('createPro.proCat')}
        </Typography>

        <label>
          {translate('createPro.category')}
        </label>

        <InputFieldComponent type="text" id="category" list="categoriesList" placeholder={translate('createPro.category')} name="category" value={categoryValue} setFunc={(e) => chooseCategory(e)} error={emptyFields && !categoryValue ? true : false} />

        <datalist id="categoriesList">
          {allCategories && allCategories.map(category => (
            <option key={category._id} value={category.name}></option>
          ))}
        </datalist> 

        <label className={!categoryValue ? cls.disabled : ""}>
          {translate('createPro.subcategory')}
        </label>

        <InputFieldComponent type="text" id="sub-category" list="subCategories" name="sub-category" placeholder={translate('createPro.subcategory')} value={subCategoryValue} setFunc={(e) => changeSubcategory(e)} error={emptyFields && !subCategoryValue ? true : false} disabled={!categoryValue || !allCategories.find(category => category.name === categoryValue) ? true : false} />

        <datalist id="subCategories">
          {allCategories && allCategories.find(category => category.name === categoryValue)?.sub_categories.map(sub => (
            <option key={sub._id} value={sub.name}></option>
          ))}
        </datalist> 

      </Box>

      <Box className={cls.createProductDetails__brand} sx={{ bgcolor: 'background.secondary' }}>

        <Typography variant='h6' component='h6'>
          {translate('createPro.brand')}
        </Typography>

        <Box className={cls.field}>

          <InputFieldComponent type="text" id="brand" list="brands" name="brand" placeholder={translate('createPro.brand')} value={productFields.brand} setFunc={(e) => handleBrand(e.target.value)} error={emptyFields && !productFields.brand ? true : false} />

          <datalist id="brands">
            <option value="Samsung"></option>
            <option value="Dell"></option>
            <option value="Acer"></option>
            <option value="Asus"></option>
            <option value="Apple"></option>
          </datalist> 

        </Box>

      </Box>

      <Box className={cls.createProductDetails__tags} sx={{ bgcolor: 'background.secondary' }}>

        <Typography variant='h6' component='h6'>
          {translate('createPro.tags')}
        </Typography>

        <Box className={cls.field}>

          <TagsInput value={productFields.tags} onChange={handleChange} className={emptyFields && !productFields.tags.length ? 'tags-error' : ''} />

          {emptyFields && !productFields.tags.length && <p><i className="fa-light fa-circle-exclamation"></i> {translate('error.emptyFields')}</p>}

        </Box>

      </Box>
      
      <Box className={cls.createProductsOptions__desc} sx={{ bgcolor: 'background.secondary' }}>

        <Typography variant='h6' component='h6'>
          {translate('createPro.shortDesc')}
        </Typography>

        <textarea placeholder={translate('createPro.shortDesc')} value={productFields.short_description} name="short_description" onChange={(e) => handleShortDesc(e.target.value)} className={emptyFields && !productFields.short_description ? cls.error : ''}></textarea>

        {emptyFields && !productFields.short_description && <p><i className="fa-light fa-circle-exclamation"></i> {translate('error.emptyFields')}</p>}

      </Box>

    </Box>
  )
}

export default CreateProductsOptions