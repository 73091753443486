import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import cls from './itemsForReturn.module.scss';

const ReturnedItem = ({ retrn }) => {
  // COMMPONENT HOOKS 
  const [selectedItems, setSelectedItems] = useState([]);
  // const [quantity, setQuantity] = useState([...retrn.order.items]);
  const { t: translate, i18n } = useTranslation('common');

  // COMPONENT HANDLERS
  const handleSelectItem = (item) => {
    if(selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter(it => it !== item));
    } else {
      setSelectedItems([...selectedItems, item])
    }
  }

  // const changeQunatity = (number, item) => {
  //   let updatedItem = quantity.find(it => it === item).item.quantity = number;
  //   setQuantity([...quantity, updatedItem]);
  // }

  return (
    <Box className={cls.returnedItem}>
      <Box className={cls.head}>

      <Box className={cls.head__part}>

        <Box className={cls.side}>
          <Box>
            <Typography className={cls.top}>
              {translate('userProfile.requestedDate')}
            </Typography>
            <Typography>
              22/5/2022
            </Typography>
          </Box>
        </Box>

        {retrn.status === 'Unhandled' &&
        
          <Box className={cls.side}>
            <Box>
              <Typography className={cls.top}>
              {translate('userProfile.amount')}
              </Typography>
                
              $202

            </Box>
          </Box>
        
        }

      </Box>

      <Box className={`${cls.head__part} ${cls.includeView}`}>

        <Box className={cls.side}>
          <Box>
            <Typography className={cls.top}>
              {translate('userProfile.id')}
            </Typography>
            <Typography>
              {retrn._id}
            </Typography>
          </Box>
        </Box>

      </Box>

      </Box>

      <Box className={cls.body}>

        {retrn.order.items.map((product, idx) => (
          
          <Box className={`${cls.body__order} ${selectedItems.includes(product) ? cls.selected : ''}`} key={idx}>

            <Box className={cls.body__order_img}>

              <img className={cls[i18n.language]} src={product.item.images[0]} alt="orderImage" loading="lazy" />

              <Box>

                <h6>{product.item.name.substring(0, 100) + '...'}</h6>

                <Typography>
                  Quantity: {product.item.quantity}
                </Typography>

                <button className={cls.return} onClick={() => handleSelectItem(product)}>

                  {/* {translate('userProfile.details')} */}

                  {!selectedItems.includes(product) ? 

                    <span>Select Item</span>
                    :
                    <span>Deselect Item</span>
                
                  }
                  
                </button>

              </Box>

            </Box>

            {product.item.quantity > 1 && selectedItems.find(it => it === product) && 
            
              <input type="number" placeholder='Quantity' min='1' max={product.item.quantity} defaultValue="1" />
            
            }

          </Box>

        ))}

      </Box>
    </Box>
  )
}

export default ReturnedItem;