import { useState, useRef, useCallback } from "react";

import Box from "@mui/material/Box";

import ReactCrop from "react-image-crop";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";

import cls from "./cropImage.module.scss";

import "react-image-crop/src/ReactCrop.scss";

const QuickView = ({
  setOpenCropImage,
  imageToCrop,
  setCroppedImage,
  oldPhoto,
}) => {
  // CROPPER STATES
  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });

  const [image, setImage] = useState();

  const overlay = useRef();
  const { t, i18n } = useTranslation("common");
  const imageFromCrop = useRef();

  const closeModal = (e) => {
    if (overlay.current === e.target) {
      setOpenCropImage(false);
    }
  };
  const close = () => {
    setOpenCropImage(false);
    if (oldPhoto) {
      setCroppedImage(oldPhoto);
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const getCroppedImg = async () => {
    try {
      const image = imageFromCrop.current;

      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;

      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg", 1);

      setCroppedImage(dataURLtoFile(base64Image, "profilePic.jpeg"));
    } catch (err) {
      errorNotify(err);
    }
  };

  const cropImage = () => {
    getCroppedImg();
    setOpenCropImage(false);
  };

  const errorNotify = (message) => toast.error(message);

  return (
    <Box className={cls.overlay} ref={overlay} onClick={(e) => closeModal(e)}>
      <Box className={cls.area}>
        <Box className={cls.area__wrapper}>
          <Box className={`${cls.close} ${cls[i18n.language]}`} onClick={close}>
            <i className="fa-solid fa-xmark"></i>
          </Box>

          <Box container className={cls.area__content} spacing={3}>
            <h3>{t("userProfile.cropPicture")}</h3>

            <ReactCrop
              crop={crop}
              onChange={(c) => setCrop(c)}
              onImageLoaded={setImage}
              className={cls.cropperArea}
            >
              <img src={imageToCrop} ref={imageFromCrop} />
            </ReactCrop>

            <Box className={`${cls.actions} ${cls[i18n.language]}`}>
              <button className={cls.actions__cancel} onClick={close}>
                {t("userProfile.editAddressCancel")}
              </button>
              <button className={cls.actions__save} onClick={cropImage}>
                {t("userProfile.editAddressSave")}
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default QuickView;
