import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';

import cls from './mobSettingsNav.module.scss'

const MobSettingsNav = ({ openSidebar, setOpenSidebar, currentPage, changePage }) => {
  // COMPONENT HOOKS
  const { i18n } = useTranslation()

  // COMPONENT HANDLERS
  const clickChoice = (page) => {
    changePage(page)
    setOpenSidebar(false)
  }

  return (
    <Box className={`${cls.wrapper} ${openSidebar ? cls.show : cls.hide}`}>

      <Box className={cls.dropLayer} onClick={() => setOpenSidebar(false)}></Box>

      <Box className={`${cls.mobSidebar} ${cls[i18n.language]}`} sx={{ bgcolor: 'background.secondary' }}>

        <ul>

          <li className={ currentPage === 'information' ? cls.active : ''} onClick={() => clickChoice('information')}>
            <i className="fa-light fa-user"></i>
            <span>General Information</span>
          </li>

          <li className={ currentPage === 'security' ? cls.active : ''} onClick={() => clickChoice('security')}>
            <i className="fa-light fa-lock"></i>
            <span>Security</span>
          </li>

          <li className={ currentPage === 'link' ? cls.active : ''} onClick={() => clickChoice('link')}>
            <i className="fa-regular fa-link"></i>
            <span>Link Account</span>
          </li>

          <li className={ currentPage === 'deactivate' ? cls.active : ''} onClick={() => clickChoice('deactivate')}>
            <i className="fa-regular fa-wave-pulse"></i>
            <span>Deactivate Account</span>
          </li>

        </ul>

      </Box>

    </Box>
  )
}

export default MobSettingsNav;