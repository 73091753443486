import { useState } from "react";

import UserPersonalTab from "../../modals/UserPersonalTab/UserPersonalTab";
import LargeSquare from "../../UIs/skeletons/LargeSquare/LargeSquare";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";
import format from "date-fns/format";

import cls from "./personalInfoTab.module.scss";

const PersonalInfoTab = ({ userData, refetchUserData }) => {
  // COMPONENT HOOKS
  const [openPersonalEdit, setOpenPersonalEdit] = useState(false);
  const { t: translate, i18n } = useTranslation("common");

  return (
    <Box
      className={cls.personalInfoTab}
      sx={{ bgcolor: "background.secondary" }}
    >
      {userData && userData.username ? (
        <>
          <Box className={cls.personalInfoTab__head}>
            <Box className={cls.info}>
              <Typography variant="h6" component="h6">
                {translate("userProfile.personal")}
              </Typography>

              <Typography>
                {translate("userProfile.personalInfoText")}
              </Typography>
            </Box>

            <i
              className="fa-light fa-pen-to-square"
              onClick={() => setOpenPersonalEdit(true)}
            ></i>
          </Box>

          <Box className={cls.personalInfoTab__info}>
            <Box className={cls.personalInfoTab__info_field}>
              <Box>
                <span>{translate("userProfile.username")}</span>

                <Typography>{userData?.username}</Typography>
              </Box>

              <Box>
                <span>{translate("userProfile.country")}</span>

                <Typography>
                  {userData?.addresses[0]?.country || "---"}
                </Typography>
              </Box>
            </Box>

            <Box className={cls.personalInfoTab__info_field}>
              <Box>
                <span>{translate("userProfile.email")}</span>

                <Typography>{userData?.email}</Typography>
              </Box>

              <Box>
                <span>{translate("userProfile.phoneNum")}</span>

                <Typography>{userData?.phone || "---"}</Typography>
              </Box>
            </Box>

            <Box className={cls.personalInfoTab__info_field}>
              <Box>
                <span>{translate("userProfile.date")}</span>

                <Typography>
                  {userData
                    ? format(
                        new Date(userData?.createdAt).getTime(),
                        "dd/MM/yyyy"
                      )
                    : "---"}
                </Typography>
              </Box>

              <Box>
                <span>{translate("userProfile.status")}</span>

                <Typography>{userData?.accountStatus}</Typography>
              </Box>
            </Box>

            <Box className={cls.personalInfoTab__info_field}>
              <Box>
                <span>{translate("userProfile.auth")}</span>

                <Typography>
                  {userData?.externalAuth.google.linked && "Google, "}
                  {userData?.externalAuth.facebook.linked && "Facebook, "}
                  {userData?.password_linked && "Email"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <LargeSquare />
      )}

      {openPersonalEdit && (
        <UserPersonalTab
          setOpenPersonalEdit={setOpenPersonalEdit}
          userData={userData}
          refetchUserData={refetchUserData}
        />
      )}
    </Box>
  );
};

export default PersonalInfoTab;
