import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import ImagePreview from '../../modals/ImagePreview/ImagePreview';
import CreateReturn from './../../modals/CreateReturn/CreateReturn';
import Loader from '../../UIs/Loading/Loading';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import axios from '../../../utils/axios';

import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { useMutation } from 'react-query';

import { format } from 'date-fns';

import cls from './supportAccordion.module.scss';

const SupportAccordion = ({ data, insideProfile, checkDeleteTicket }) => {
  // COMPONENT HOOKS
  const [showSub, setShowSub] = useState('');
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [image, setImage] = useState(false);
  const { t: translate, i18n } = useTranslation('common');
  const [showCreateReturn, setShowCreateReturn] = useState(false)
  const navigate = useNavigate();

  // COMPONENT HANDLERS
  const showSubList = (e, list) => {
    if(showSub === list) {
      setShowSub('')
    }
    else {
      setShowSub(list)
    }

    let subMenu = e.target.nextElementSibling;

    if (subMenu) {
      let maxHeight = subMenu.scrollHeight;

      if (!subMenu.style.maxHeight) {
        subMenu.style.maxHeight = maxHeight + 'px';
      } else {
        subMenu.style.maxHeight = null;
      }
    }
  }

  const openImageViewer = (img) => {
    setOpenImagePreview(true);
    setImage(img)
  }

  const createReturn = () => {
    setShowCreateReturn(true);
  }

  const openChat = async () => {
    if (data.chat) return navigate(`/ticket/${data._id}/chat`);

    const chatData = {
      user: data.user._id,
      ticket: data._id
    }

    const response = await axios.post('/chat', chatData).catch(err => errorNotify(err.response.data.err))

    if (!response || !response.data) return;
    
    navigate(`/ticket/${data._id}/chat`);
  }

  const checkClosingTicket = () => {
    Swal.fire({
      title: translate('toast.sure'),
      text: translate('toast.closeTicket'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: translate('toast.closeUserTicket'),
      cancelButtonText: translate('toast.cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        closeUserTicket();
      }
    })
  }

  const closeTicket = async () => {
    const updatedData = {
      status: 'Closed',
      type: data.type,
      chat: data.chat
    }

    const response = await axios.patch(`/ticket/${data?._id}`, updatedData).catch(err => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    successNotify(translate('support.ticketClosed'));

    if (!insideProfile) {
      navigate('/customer-support')
    }

  }

  const { mutate: closeUserTicket, isLoading: closingTicket } = useMutation('closeTicket', closeTicket);

  const successNotify = (message) => toast.success(message)
  const errorNotify = (message) => toast.error(message)

  return (
    <Box className={`${cls.accordion_link} ${showSub === 'products' ? cls.showSub : ''} ${cls[i18n.language]}`}>

      {closingTicket && <Loader />}

      <Box className={`${cls.link} ${cls[i18n.language]}`} onClick={(e) => showSubList(e, 'products')}>

        <Box className={`${cls.label} ${cls[i18n.language]}`}>

          <span>{`${!insideProfile ? data.user.username + ',' : ''}`} {data.subject}</span>

        </Box>

        <Box className={cls.icon}>

          <span>{format(new Date(data.createdAt).getTime(), 'H:m a')}</span>

          <span className={cls[data.status]}>{data.status}</span>

          <i className={`${cls[i18n.language]} fa-regular fa-angle-down`}></i>

        </Box>

      </Box>

      <Box className={`${cls.subMenu} ${cls[i18n.language]} subMenu`}>

        {showSub === 'products' && <Box className={cls.dropLayer} onClick={(e) => showSubList(e, '')}></Box>}

        <Box className={`${cls.accordionBody} ${cls[i18n.language]}`}>

          <Box className={cls.description}>

            <Typography>

              <i className="fa-regular fa-circle-question"></i> {data.details}

            </Typography>

            {data.status !== 'Closed' && 

              <Box>

                <button onClick={openChat}><i className="fa-thin fa-message-dots"></i> {translate('support.chat')}</button>

              </Box>

            }

          </Box>

            {data.order && 

              <Box className={cls.product}>

                <Typography>
                  {translate('support.chatOrderId')}: {data.order._id}
                </Typography>

              </Box>
            
            }

          {data?.attachments?.length >= 1 && 
          
            <Box className={cls.attachments}>

              <Typography variant="h6" component="h6">
              {translate('support.ticketAttachments')}
              </Typography>

              {data.attachments.map((attachment, idx) => (

                <span key={idx} onClick={() => openImageViewer(attachment)}>
                  Attach {idx + 1}
                </span>

              ))}

            </Box>
          
          }

          <Typography className={cls.time}>
            <i className="fa-light fa-stopwatch"></i> {format(new Date(data.createdAt).getTime(), 'EEEE')} {format(new Date(data.createdAt).getTime(), 'dd/MM/yyyy')}
          </Typography>


          <Box className={cls.actions}>

            {(data.type === 'Return' || data.type === 'Complaint') && 
            
              <button onClick={() => navigate(`/orders/${data.order._id}`)}>{translate('support.details')}</button>
            
            }

            {data.type !== 'Contact' && data.status !== 'Closed' &&

              <button onClick={createReturn}>{translate('support.return')}</button>
            
            }

            {data.status !== 'Closed' && 
            
              <button onClick={checkClosingTicket}>{translate('support.close')}</button>
            
            }

            {insideProfile && 
            
              <button className={cls.deleteTicket} onClick={() => checkDeleteTicket(data._id)}>{translate('support.delete')}</button>
            
            }

          </Box>

        </Box>

      </Box>

      {/* IMAGE PREVIEW MODAL */}
      {openImagePreview && <ImagePreview setOpenImagePreview={setOpenImagePreview} imgSrc={image} />}

      {/* CREATE RETURN MODAL */}
      {showCreateReturn && <CreateReturn setShowCreateReturn={setShowCreateReturn} order={data.order} ticketId={data._id} />}

    </Box>
  )
}

export default SupportAccordion;