import * as yup from 'yup'

export const loginSchema = yup.object().shape({
  email: yup.string().required('email address is required').email('please enter valid email address'),
  password: yup.string().required('password cant be empty').min(6, 'password cant be less than 6 characters')
})

export const forgetSchema = yup.object().shape({
  email: yup.string().required('email address is required').email('please enter valid email address')
})

export const resetSchema = yup.object().shape({
  password: yup.string().required('password cant be empty').min(6, 'password cant be less than 6 characters'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'password must match').required('password cant be empty')
})