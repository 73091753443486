import { useState } from 'react';

import { useNavigate } from 'react-router';

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { InputField } from '../../components'
import ButtonLoader from '../../components/UIs/ButtonLoader/ButtonLoader'

import { Formik, Form } from 'formik';
import { forgetSchema } from '../../components/Auth/validation/validations'
import { useSelector } from "react-redux";

import { useTranslation } from 'react-i18next';

import axios from '../../utils/axios';

import { toast } from 'react-toastify';

import cls from '../login/auth.module.scss'

const Forget = () => {
  // COMPONENT HOOKS
  const websiteData = useSelector(({ websiteData }) => websiteData.websiteData);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation('auth')

  // COMPONENT HANDLERS
  const sendEmail = async (values) => {
    setLoading(true)

    const response = await axios.post('/auth/forget', {...values})
    .catch(err => {
      errorNotify(err.response.data.err)
      setLoading(false)
    }) 

    if(!response || !response.data) return;

    successNotify(t('toast.email'))
    setLoading(false)
  }

  const successNotify = (message) => toast.success(message)
  const errorNotify = (message) => toast.error(message)

  return (
    <Box className={cls.auth}>
      <Grid container className={cls.auth__authBox}>

        <Grid item xs={12} md={12} lg={6} className={cls.auth__authBox_fields}>

          <Box className={cls.fieldsWrapper}>

            <img src="assets/imgs/auth/forget.png" alt="forget" />
            <Typography component="h4" variant='h4'>{t('forget.forgetPassword')}</Typography>

            <Formik 
              initialValues={{email: ''}}
              validationSchema={forgetSchema}
              onSubmit={(values) => sendEmail(values)}
            >

              <Form>
                
                <InputField type="text" name="email" placeholder={t('auth.email')} />
              
                <Box className={cls.actions}>
                  <ButtonLoader loading={loading} type="submit">
                    {t('forget.sendEmail')}
                  </ButtonLoader>
                </Box>

              </Form>

            </Formik>

          </Box>

        </Grid>

        <Grid item md={6} className={cls.auth__authBox_info}>
          <Box className={cls.logo}>
            <img src={websiteData?.logo} alt={websiteData?.website} />
            <Typography component="h4" variant='h4'>{websiteData?.website}</Typography>
          </Box>
          <Box className={cls.info}>
            <Typography component="h5" variant='h5'>{t('forget.forgetPassword')}</Typography>
            <Typography>{t('forget.forgetText')}</Typography>
            <button onClick={() => navigate('/login')}>{t('forget.back')}</button>
          </Box>
          <Box className={cls.footer}>
            {t('auth.footerMade')} <i className="fa-solid fa-heart"></i> {t('auth.footerDevelopers')}
          </Box>
        </Grid>

      </Grid>
    </Box>
  )
}

export default Forget