import { useState, useRef } from 'react'
import { useParams } from 'react-router-dom';

import Selectbox from './../../UIs/SelectBox/Selectbox';
import Loader from '../../UIs/Loading/Loading';

import Box from '@mui/material/Box'

import { toast } from "react-toastify";

import axios from '../../../utils/axios';

import { useTranslation } from 'react-i18next';

import countries from '../../../data/countries.json';

import { useMutation } from 'react-query';

import cls from './editAddress.module.scss';

const AddAddress = ({ setOpenEditAddress, addressDataToEdit, refetchAddresses, order }) => {
  // COMPONENT HOOKS
  const [type, setType] = useState(addressDataToEdit.type);
  const [city, setCity] = useState(addressDataToEdit.city);
  const [street, setStreet] = useState(addressDataToEdit.street);
  const [country, setCountry] = useState(addressDataToEdit.country);
  const [zipCode, setZipCode] = useState(addressDataToEdit.zipCode);
  const {customerId} = useParams();

  const overlay = useRef()
  const { t: translate, i18n } = useTranslation('common')

  const closeModal = (e) => {
    if(overlay.current === e.target) {
      setOpenEditAddress(false)
    }
  }
  const close = () => {
    setOpenEditAddress(false)
  }

  const editAddress = async() => {
    const addressData = {
      id: addressDataToEdit._id,
      address: {
        type,
        country,
        city,
        street,
        zipCode,
        isDefault: addressDataToEdit.isDefault
      }
    }
    const updatedAddress = {
      delivery_address: {
        type,
        country,
        city,
        street,
        zipCode
      }
    }

    const response = await axios.patch(order ? `/order/${order._id}/address` : `/user/${customerId}/address`, order ? updatedAddress : addressData).catch(err => errorNotify(err?.response?.data?.err))

    if(!response || !response.data) return;

    successNotify(translate('userProfile.addressEditedSuccessfully'));

    refetchAddresses();
  
    setOpenEditAddress(false);

  }

  const { mutate: editCustomerAddress, isLoading: editingLoading } = useMutation('editCustomerAddress', editAddress)

  // TOSTIFY ALERTS
  const successNotify = (msg) => toast.success(`${msg}`);
  const errorNotify = (msg) => toast.error(`${msg}`);

  const typeList = [
    `${translate('userProfile.editAddressSaveHome')}`,
    `${translate('userProfile.editAddressSaveWork')}`,
    `${translate('userProfile.editAddressSaveOther')}`,
  ]

  return (
    <Box className={cls.overlay} ref={overlay} onClick={(e) => closeModal(e)}>

      {editingLoading && <Loader />}

      <Box className={cls.area}>

        <Box className={cls.area__wrapper}>
          <Box className={`${cls.close} ${cls[i18n.language]}`} onClick={close}>
            <i className="fa-solid fa-xmark"></i>
          </Box>

          <Box container className={cls.area__content}>

            <h3>{translate('userProfile.editAddress')}</h3>

            <Box className={cls.field}>
              <label htmlFor="">{translate('userProfile.addressType')}</label>

              <Selectbox list={typeList} select={setType} value={type} placeholder={translate('type')} />

            </Box>

            <Box className={cls.field}>
              <label htmlFor="">{translate('userProfile.editAddressCountry')}</label>
              <input list="country" placeholder={translate('userProfile.editAddressCountry')} value={country} onChange={(e) => setCountry(e.target.value)} />
              <datalist id="country">
                {
                  countries.map(country => (
                    <option key={country.code} value={country.text}>{country.text} | {country.value}</option>
                  ))
                }
              </datalist>
            </Box>

            <Box className={cls.field}>
              <label htmlFor="">{translate('userProfile.editAddressCity')}</label>
              <input list="cities" placeholder={translate('userProfile.editAddressCity')} value={city} onChange={(e) => setCity(e.target.value)} disabled={!country || !countries.find(c => c.text === country)} />
              {
                country && countries.find(c => c.text === country) &&
                <datalist id="cities">
                  {
                    countries.find(c => c.text === country).cities.map(city => (
                      <option key={city} value={city}>{city}</option>
                    ))
                  }
                </datalist>
              }
            </Box>

            <Box className={cls.field}>
              <label htmlFor="">{translate('userProfile.editAddressStreet')}</label>
              <input type="text" placeholder={translate('userProfile.editAddressStreet')}
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </Box>

            <Box className={cls.field}>
              <label htmlFor="">{translate('userProfile.editAddressZip')}</label>
              <input type="text" placeholder={translate('userProfile.editAddressZip')}
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </Box>

          </Box>

          <Box className={`${cls.actions} ${cls[i18n.language]}`}>

            <button className={cls.actions__cancel} onClick={close}>{translate('userProfile.editAddressCancel')}</button>

            <button className={cls.actions__save} onClick={editCustomerAddress}>{translate('userProfile.editAddressSave')}</button>
            
          </Box>

        </Box>
      </Box>
    </Box>
  )
}

export default AddAddress;