import { useState } from "react";
import { useSelector } from "react-redux";

import SmallSquare from "../../UIs/skeletons/SmallSquare/SmallSquare";

import dayjs from "dayjs";

import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { DateRange } from "react-date-range";

import { useQuery } from "react-query";

import axios from "../../../utils/axios";

import cls from "./analyticsHead.module.scss";
// DATE RANGE PICKER PACKAGE STYLES
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const AnalyticsHead = () => {
  // COMPONENT HOOKS
  const [date, setDate] = useState([
    {
      startDate: dayjs().startOf("month").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const user = useSelector((state) => state.user.user);
  const { websiteData } = useSelector(({ websiteData }) => websiteData);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const { t: translate, i18n } = useTranslation("common");

  const handleDateChange = (item) => {
    setDate([item.selection]);
  };

  const handleDateFocusChange = (focused) => {
    if (!focused[0] && !focused[1]) setShowDatePicker(false);
  };

  const fetchMainAnalytics = async () => {
    const from = date[0].startDate.getTime();
    const to = date[0].endDate.getTime();

    const mainAnalytics = await axios.get(`/analytics?from=${from}&to=${to}`);

    return mainAnalytics;
  };

  const { data, isLoading, error } = useQuery(
    ["analytics", date],
    fetchMainAnalytics,
    { refetchOnWindowFocus: true }
  );

  return (
    <Box>
      <Box className={cls.welcome}>
        <Box className={cls.welcome__text}>
          <Typography variant="h6">
            {translate("analytics.welcome")}, {user.username.split(" ")[0]}
          </Typography>

          <Typography>{translate("analytics.welcomeText")}</Typography>
        </Box>

        <Box className={`${cls.welcome__date} ${cls[i18n.language]}`}>
          <input
            type="text"
            readOnly
            value={
              dayjs(date[0].startDate).format("YYYY-MM-DD") +
              `  ${translate("analytics.to")}  ` +
              dayjs(date[0].endDate || new Date()).format("YYYY-MM-DD")
            }
            onClick={() => setShowDatePicker((prev) => !prev)}
          />
          <i
            onClick={() => setShowDatePicker((prev) => !prev)}
            className="fa-light fa-calendar-day"
          ></i>

          {showDatePicker && (
            <DateRange
              editableDateInputs={false}
              onChange={handleDateChange}
              onRangeFocusChange={handleDateFocusChange}
              ranges={date}
              retainEndDateOnFirstSelection={false}
              showDateDisplay={false}
            />
          )}
        </Box>
      </Box>

      {isLoading ? (
        <Grid container spacing={3} style={{ marginBottom: "20px" }}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <SmallSquare />
          </Grid>
        </Grid>
      ) : (
        <Box className={cls.analyticsHead}>
          <Box
            className={`${cls.analyticsHead__section} section`}
            sx={{ bgcolor: "background.secondary" }}
          >
            <Box className={cls.analyticsHead__section_part}>
              <Typography>
                {translate("analytics.earnings")} ({websiteData.currency})
              </Typography>
              <span>
                <i className="fa-light fa-up-right"></i>
              </span>
            </Box>

            <Box className={cls.analyticsHead__section_part}>
              <Typography className={cls.price}>
                {!isLoading && !error
                  ? data.data.success.TotalEarnings.toFixed(2)
                  : "-"}
              </Typography>
            </Box>

            <Box className={cls.analyticsHead__section_part}>
              <span className={cls.smallTitle}>
                {translate("analytics.viewNet")}
              </span>
              <Typography className={cls.icon}>
                <i className="fa-regular fa-dollar-sign"></i>
              </Typography>
            </Box>
          </Box>

          <Box
            className={`${cls.analyticsHead__section} section`}
            sx={{ bgcolor: "background.secondary" }}
          >
            <Box className={cls.analyticsHead__section_part}>
              <Typography>{translate("analytics.orders")}</Typography>
              <span>
                <i className="fa-light fa-up-right"></i>
              </span>
            </Box>

            <Box className={cls.analyticsHead__section_part}>
              <Typography className={cls.price}>
                {!isLoading && !error ? data.data.success.TotalOrders : "-"}
              </Typography>
            </Box>

            <Box className={cls.analyticsHead__section_part}>
              <span className={cls.smallTitle}>
                {translate("analytics.viewOrder")}
              </span>
              <Typography className={cls.icon}>
                <i className="fa-light fa-bag-shopping"></i>
              </Typography>
            </Box>
          </Box>

          <Box
            className={`${cls.analyticsHead__section} section`}
            sx={{ bgcolor: "background.secondary" }}
          >
            <Box className={cls.analyticsHead__section_part}>
              <Typography>{translate("analytics.customers")}</Typography>
              <span>
                <i className="fa-light fa-up-right"></i>
              </span>
            </Box>

            <Box className={cls.analyticsHead__section_part}>
              <Typography className={cls.price}>
                {!isLoading && !error ? data.data.success.TotalCustomers : "-"}
              </Typography>
            </Box>

            <Box className={cls.analyticsHead__section_part}>
              <span className={cls.smallTitle}>
                {translate("analytics.ourCustomers")}
              </span>
              <Typography className={cls.icon}>
                <i className="fa-light fa-user-group"></i>
              </Typography>
            </Box>
          </Box>

          <Box
            className={`${cls.analyticsHead__section} section`}
            sx={{ bgcolor: "background.secondary" }}
          >
            <Box className={cls.analyticsHead__section_part}>
              <Typography>
                {translate("analytics.myRefunds")} ({websiteData.currency})
              </Typography>
              <span>
                <i className="fa-light fa-up-right"></i>
              </span>
            </Box>

            <Box className={cls.analyticsHead__section_part}>
              <Typography className={cls.price}>
                {!isLoading && !error
                  ? data.data.success.TotalRefunds.toFixed(2)
                  : "-"}
              </Typography>
            </Box>

            <Box className={cls.analyticsHead__section_part}>
              <span className={cls.smallTitle}>
                {translate("analytics.refunded")}
              </span>
              <Typography className={cls.icon}>
                <i className="fa-light fa-wallet"></i>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AnalyticsHead;
