import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from './../../store/slices/userSlice';

import Loader from '../../components/UIs/Loading/Loading';

import Pagination from '@mui/material/Pagination';

import { useTranslation } from 'react-i18next';

import axios from '../../utils/axios';

import { toast } from 'react-toastify';

import Swal from 'sweetalert2';

import { useMutation } from 'react-query';

import cls from './notifications.module.scss';

const Notifications = () => {
  const user = useSelector(state => state.user.user);
  const [pageNumber, setPageNumber] = useState(1);
  const { t: translate, i18n } = useTranslation('common');
  const dispatch = useDispatch();

  // COMPONENT HANDLERS
  const changePage = (e, pageNum) => {
    setPageNumber(pageNum)
  }

  const markAsRead = async (e, notification) => {
    if (e.target.tagName !== 'I') {
      
      if (notification.read === false) {

        const response = await axios.patch(`/notification/${user._id}/${notification._id}`).catch(err => errorNotify(err.response.data.err));
  
        if (!response || !response.data) return;
  
        dispatch(userActions.updateUserNotificationStatus(notification._id))
  
        location.href = notification.action

      } else {
        location.href = notification.action
      }
    }
  }

  const { mutate: markNotAsRead, isLoading: updateNotStatusLoading } = useMutation(({e, notification}) => markAsRead(e, notification));

  const checkDeleteLogistic = (notificationId) => {
    Swal.fire({
      title: translate('toast.sure'),
      text: translate('toast.deleteNotification'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: translate('toast.delete'),
      cancelButtonText: translate('toast.cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        deleteNotification(notificationId);
      }
    })
  }

  const deleteNotification = async (notificationId) => {
    const response = await axios.delete(`/notification/${user._id}/${notificationId}`).catch(err => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    dispatch(userActions.deleteUserNotification(notificationId))

    successNotify(translate('toast.notificationDeleted'))
  }

  const { mutate: deleteNot, isLoading: deleteNotLoading } = useMutation((notificationId) => checkDeleteLogistic(notificationId));
  
  const markAllAsRead = async() => {
    const response = await axios.patch(`/notification/${user._id}`).catch(err => {
      errorNotify(err.response.data.err);
    });
    if (!response || !response.data) return;
    successNotify(translate('notifications.allRead'));

    let userNotifications = JSON.parse(JSON.stringify(user.notifications))

    let updatedNotifications = userNotifications.map(one => {
      if (one.read === false) {
        one.read = true
        return one
      } else {
        return one
      }
    })

    dispatch(userActions.updateUser({notifications: [...updatedNotifications]}))
  }

  const { mutate: markAllRead, isLoading: allAsReadLoading } = useMutation('markAllRead', markAllAsRead)

  const successNotify = (message) => toast.success(message)
  const errorNotify = (message) => toast.error(message)

  return (
    <>

      {(updateNotStatusLoading || deleteNotLoading || allAsReadLoading) && <Loader />}

      <div className={cls.notifications__head}>

        <h6>{translate('notifications.title')}</h6>

        <h6><span>{translate('notifications.dashboard')}</span> &gt; {translate('notifications.notifs')}</h6>

      </div>

      <div className={cls.notifications}>

        {user.notifications.length >= 1 ?
          <>
            <div className={cls.head}>
              <span onClick={markAllRead}><i className="fa-light fa-badge-check"></i> {translate('notifications.markAsRead')}</span>
            </div>
            
            {user.notifications.map(notification => (

              <div className={`${cls.notification} ${!notification.read && cls.unRead} ${cls[i18n.language]}`} key={notification._id} onClick={(e) => markNotAsRead({e: e, notification: notification})}>

                <div className={cls.notification__wrapper}>

                  <img src={notification.image} alt="notification image" />

                  <div className={cls.notification__wrapper_details}>

                    <h6>{notification.title}</h6>

                    <p>{notification.content}</p>

                  </div>

                </div>

                <i className="fa-solid fa-trash-can" onClick={() => deleteNot(notification._id)}></i>

              </div>

            ))}

            <div className={cls.footer}>
    
              <p>
    
                {translate('product.show')} {pageNumber} {translate('product.of')} {5} {translate('product.results')}
    
              </p>
    
              <div className={`${cls.pagination} pagination`}>
    
                <Pagination count={5} page={pageNumber} onChange={changePage}/>
    
              </div>
    
            </div>
          </>
          :
          <div className='empty'>
            <img src="/assets/imgs/notifications/notifications.png" alt="emptyNotification" />
            <p>{translate('notifications.empty')}</p>
          </div>
        }
        
      </div>
    
    </>
  )
}

export default Notifications