import { useState, useRef } from "react";

import { useSelector } from "react-redux";

import ChooseList from "./../../UIs/ChooseList/ChooseList";
import ShowVariant from "./../../modals/ShowVariant/ShowVariant";
import { InputFieldComponent } from "../../../components";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import colourNameToHex from "../../../data/hexColorConverter.js";

import { useTranslation } from "react-i18next";

import cls from "./createProductDetails.module.scss";

const CreateProductDetails = ({
  productFields,
  setProductFields,
  emptyFields,
  setDiscount,
  colorValue,
  setColorValue,
  colorQuantity,
  setColorQuantity,
  colorPriceDifference,
  setColorPriceDifference,
  haveSale,
  setHaveSale,
  spec,
  setSpec,
  specValue,
  setSpecValue,
}) => {
  // COMPONENT HOOKS
  const [openShowVariant, setOpenShowVariant] = useState(false);
  const [choosedColor, setChoosedColor] = useState({});
  const galleryImagesInputRef = useRef();
  const dragBox = useRef();
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const [invalidColor, setInvalidColor] = useState(false)
  const { t: translate, i18n } = useTranslation("common");

  // COMPONENT HANDLERS
  const handleProductName = (value) => {
    setProductFields((prev) => ({ ...prev, name: value }));
  };

  const handleFullDesc = (value) => {
    setProductFields((prev) => ({ ...prev, full_description: value }));
  };

  const clickProductGalleryImagesInput = () => {
    galleryImagesInputRef.current.click();
  };

  const selectProductGalleryImages = (e) => {
    dragBox.current.classList.remove(cls.entered);
    let images = [...productFields.images];
    let allImages = [...images, ...e.target.files];
    setProductFields((prev) => ({ ...prev, images: allImages }));
  };

  const dragEnter = (e) => {
    e.preventDefault();
    dragBox.current.classList.add(cls.entered);
  };

  const dragLeave = () => {
    dragBox.current.classList.remove(cls.entered);
  };

  const drop = (e) => {
    e.preventDefault();
    dragBox.current.classList.remove(cls.entered);
    galleryImagesInputRef.current.files = e.dataTransfer.files;
    let images = [...productFields.images];
    let allImages = [...images, ...e.dataTransfer.files];
    setProductFields((prev) => ({ ...prev, images: allImages }));
  };

  const deleteImage = (image) => {
    const images = [...productFields.images];
    const imageFound = images.findIndex((img) => img === image);
    images.splice(imageFound, 1);
    setProductFields((prev) => ({ ...prev, images: images }));
  };

  const addToColors = () => {
    var regex = /^#([0-9a-f]{3}){1,2}$/i;
    let typedColor;
    if (colorValue.startsWith('#')) {
      if (regex.test(colorValue)) {
        typedColor = colorValue
      } else {
        return setInvalidColor(true);
      }
    } else {
      if (colourNameToHex(colorValue)) {
        typedColor = colourNameToHex(colorValue)
      } else {
        return setInvalidColor(true);
      }
    }

    setInvalidColor(false)

    const color = {
      hex: typedColor,
      availability: colorQuantity ? colorQuantity : 0,
      priceDifference: colorPriceDifference ? colorPriceDifference : 0,
      variants: [],
    };

    if (colorValue) {
      let colors = [...productFields.colors];
      let allColors = [...colors, color];
      setProductFields((prev) => ({ ...prev, colors: allColors }));
      setColorValue("");
      setColorQuantity("");
      setColorPriceDifference("");
    }
  };

  const deleteChoice = (choice) => {
    const oldList = [...productFields.colors];
    oldList.splice(choice, 1);
    setProductFields((prev) => ({ ...prev, colors: oldList }));
  };

  const addToSpecs = () => {
    if (spec && specValue) {
      let specs = { ...productFields.specs };
      let allSpecs = { ...specs, [spec]: specValue };
      setProductFields((prev) => ({ ...prev, specs: allSpecs }));
    }
    setSpec("");
    setSpecValue("");
  };

  const deleteSpec = (key) => {
    let allSpecs = { ...productFields.specs };

    delete allSpecs[key];

    setProductFields((prev) => ({ ...prev, specs: allSpecs }));
  };

  const handlePrice = (value) => {
    let price = productFields.price;
    price.amount = +value;
    setProductFields((prev) => ({ ...prev, price: price }));
  };

  const toggleSale = (value) => {
    setHaveSale(value);
    let price = productFields.price;
    price.sale.onSale = value;
    setProductFields((prev) => ({ ...prev, price: price }));
  };

  const handleDiscountValue = (type, value) => {
    let price = productFields.price;
    if (type === "percent") {
      if (value <= 100 && value >= 0) {
        price.sale.percent = value;
        price.sale.amount = 0;
      }
    } else {
      if (value <= price.amount && value >= 0) {
        price.sale.amount = value;
        price.sale.percent = 0;
      }
    }
    setProductFields((prev) => ({ ...prev, price: price }));
  };

  const handleExpirationDate = (value) => {
    let price = productFields.price;
    price.sale.expiration = new Date(value).getTime();
    setProductFields((prev) => ({ ...prev, price: price }));
  };

  const handleDiscountType = (type) => {
    let price = productFields.price;
    price.sale.type = type;
    setProductFields((prev) => ({ ...prev, price: price }));
  };

  const openVariantsPopup = (color) => {
    setChoosedColor(color);
    setOpenShowVariant(true);
  };

  const discountList = [
    {
      item: `${translate("createPro.percentage")}`,
      icon: <i className="fa-light fa-percent"></i>,
      method: () => handleDiscountType("percent"),
    },
    {
      item: `${translate("createPro.amount")}`,
      icon: <i className="fa-light fa-circle-dollar"></i>,
      method: () => handleDiscountType("amount"),
    },
  ];

  return (
    <Box className={cls.createProductDetails}>
      <Box
        className={`${cls.createProductDetails__text} ${cls.section}`}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Typography variant="h6" component="h6">
          {translate("createPro.productTitle")}
        </Typography>

        <InputFieldComponent
          type="text"
          placeholder={translate("createPro.productTitle")}
          name="name"
          value={productFields.name}
          setFunc={(e) => handleProductName(e.target.value)}
          error={emptyFields && !productFields.name ? true : false}
        />

        <Typography variant="h6" component="h6">
          {translate("createPro.productDesc")}
        </Typography>

        <textarea
          type="text"
          placeholder={translate("createPro.productDesc")}
          name="full_description"
          value={productFields.full_description}
          onChange={(e) => handleFullDesc(e.target.value)}
          className={
            emptyFields && !productFields.full_description ? cls.error : ""
          }
        ></textarea>

        {emptyFields && !productFields.full_description && (
          <p>
            <i className="fa-light fa-circle-exclamation"></i>{" "}
            {translate("error.emptyFields")}
          </p>
        )}
      </Box>

      <Box
        className={`${cls.createProductDetails__gallery} ${cls.section}`}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Typography variant="h5" component="h5">
          {translate("createPro.proGallery")}
        </Typography>

        <Typography>{translate("createPro.proAddGallery")}</Typography>

        <Box
          className={`${cls.createProductDetails__gallery_Box} ${emptyFields && !productFields.images.length ? cls.error : ""
            }`}
          ref={dragBox}
          onDragOver={(e) => dragEnter(e)}
          onDragLeave={dragLeave}
          onDrop={(e) => drop(e)}
        >
          {productFields.images.length ? (
            <Box className={cls.images}>
              <Box className={cls.wrapper}>
                {productFields.images.map((image, idx) => (
                  <Box key={idx} className={cls.image}>
                    <img
                      src={
                        typeof image === "object"
                          ? URL.createObjectURL(image)
                          : image
                      }
                      alt="uploadedImage"
                    />

                    <i
                      className="fa-regular fa-xmark"
                      onClick={() => deleteImage(image)}
                    ></i>
                  </Box>
                ))}
              </Box>

              <Typography>
                {productFields.images.length} {translate("createPro.uploaded")}
              </Typography>
            </Box>
          ) : (
            <Box onClick={clickProductGalleryImagesInput}>
              <i className="fa-solid fa-cloud-arrow-up"></i>

              <Typography>{translate("createPro.upload")}</Typography>
            </Box>
          )}
        </Box>

        {productFields.images.length ? (
          <Box className={cls.btn}>
            <button onClick={clickProductGalleryImagesInput}>
              {translate("createPro.addMore")}
            </button>
          </Box>
        ) : (
          <span></span>
        )}

        {emptyFields && !productFields.images.length && (
          <span>
            <i className="fa-light fa-circle-exclamation"></i>{" "}
            {translate("error.emptyFields")}
          </span>
        )}

        <input
          type="file"
          style={{ display: "none" }}
          ref={galleryImagesInputRef}
          onChange={selectProductGalleryImages}
          multiple
        />
      </Box>

      <Box
        className={cls.createProductDetails__general}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Typography variant="h6" component="h6">
          {translate("createPro.colors")}
        </Typography>

        <Box className={cls.fields}>
          <InputFieldComponent
            type="text"
            placeholder={translate("createPro.color")}
            value={colorValue}
            setFunc={(e) => setColorValue(e.target.value)}
            customError={invalidColor}
            errorMessage={translate('createPro.invalidColor')}
          />

          <InputFieldComponent
            type="number"
            placeholder={translate("createPro.quantity")}
            value={colorQuantity}
            setFunc={(e) => setColorQuantity(e.target.value)}
          />

          <InputFieldComponent
            type="number"
            placeholder={`${translate("createPro.priceDifference")} (${websiteData.currency
              })`}
            value={colorPriceDifference}
            setFunc={(e) => setColorPriceDifference(e.target.value)}
          />
        </Box>

        {emptyFields && !productFields.colors.length && (
          <p>
            <i className="fa-light fa-circle-exclamation"></i>{" "}
            {translate("error.colorsEmpty")}
          </p>
        )}

        <Box className={cls.btn}>
          <button onClick={() => addToColors()}>
            {translate("createPro.add")}
          </button>
        </Box>

        {productFields.colors.length >= 1 && (
          <Box className={cls.list}>
            <ul>
              {productFields.colors.map((color, idx) => (
                <li key={idx}>
                  <span>
                    {translate("createPro.color")}: {color.hex} ({color.priceDifference > 0 ? `+${color.priceDifference}` : color.priceDifference})
                  </span>

                  <span>
                    {translate("createPro.quantity")}: {color.availability}{" "}
                    {translate("createPro.items")}
                  </span>

                  <span>
                    {translate("createPro.variants")}: {color.variants.length}{" "}
                    {translate("createPro.items")}
                  </span>

                  <span>
                    <button onClick={() => openVariantsPopup(color)}>
                      <i className="fa-regular fa-eye"></i>{" "}
                      {translate("createPro.showVariants")}
                    </button>
                    <i
                      className={`${cls.cancel} fa-light fa-xmark`}
                      onClick={() => deleteChoice(color)}
                    ></i>
                  </span>
                </li>
              ))}
            </ul>
          </Box>
        )}
      </Box>

      <Box
        className={cls.createProductDetails__specs}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Typography variant="h6" component="h6">
          {translate("createPro.specs")}
        </Typography>

        <Box className={cls.fields}>
          <InputFieldComponent
            type="text"
            placeholder={translate("createPro.spec")}
            value={spec}
            setFunc={(e) => setSpec(e.target.value)}
          />

          <InputFieldComponent
            type="text"
            placeholder={translate("createPro.value")}
            value={specValue}
            setFunc={(e) => setSpecValue(e.target.value)}
          />
        </Box>

        {emptyFields && !Object.keys(productFields.specs).length && (
          <p>
            <i className="fa-light fa-circle-exclamation"></i>{" "}
            {translate("error.specsEmpty")}
          </p>
        )}

        <Box className={cls.btn}>
          <button onClick={() => addToSpecs()}>
            {translate("createPro.add")}
          </button>
        </Box>

        {Object.keys(productFields.specs).length >= 1 && (
          <Box className={cls.list}>
            <ul>
              {Object.keys(productFields.specs).map((key, idx) => (
                <li key={idx}>
                  <span>
                    {key}: {productFields.specs[key]}
                  </span>

                  <i
                    className="fa-light fa-xmark"
                    onClick={() => deleteSpec(key)}
                  ></i>
                </li>
              ))}
            </ul>
          </Box>
        )}
      </Box>

      <Box
        className={`${cls.createProductDetails__price} ${cls.section}`}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Typography variant="h6" component="h6">
          {translate("createPro.price")} ({websiteData.currency})
        </Typography>

        <Box className={cls.createProductDetails__price_fields}>
          <Box className={cls.price}>
            <InputFieldComponent
              type="number"
              placeholder={translate("createPro.price")}
              name="price"
              value={productFields.price.amount}
              setFunc={(e) => handlePrice(e.target.value)}
              error={emptyFields && !productFields.price.amount ? true : false}
            />

            <Box className={cls.switch}>
              <i className="fa-light fa-fire"></i> {translate("createPro.sale")}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      direction="rtl"
                      onChange={() => toggleSale(!haveSale)}
                      checked={haveSale ? true : false}
                    />
                  }
                />
              </FormGroup>
            </Box>
          </Box>

          {haveSale && (
            <Box className={cls.discount}>
              <label htmlFor="">{translate("createPro.discount")}</label>

              <Box className={cls.choose}>
                <ChooseList
                  list={discountList}
                  text={
                    productFields.price.sale.type === "percent"
                      ? translate("createPro.percent")
                      : translate("createPro.amount")
                  }
                  icon={
                    productFields.price.sale.type === "percent" ? (
                      <i className="fa-light fa-percent"></i>
                    ) : (
                      <i className="fa-light fa-circle-dollar"></i>
                    )
                  }
                  withArrow={true}
                />

                <InputFieldComponent
                  type="number"
                  placeholder={translate("createPro.discount")}
                  value={
                    productFields.price.sale.type === "percent"
                      ? productFields.price.sale.percent
                      : productFields.price.sale.amount
                  }
                  setFunc={(e) =>
                    handleDiscountValue(
                      productFields.price.sale.type,
                      e.target.value
                    )
                  }
                  error={
                    emptyFields &&
                      (productFields.price.sale.type === "amount"
                        ? !productFields.price.sale.amount
                        : !productFields.price.sale.percent)
                      ? true
                      : false
                  }
                />
              </Box>

              <Box className={`${cls.date} ${cls[i18n.language]}`}>
                <label>{translate("createPro.saleEnd")}</label>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    mask="____/__/__"
                    inputFormat="dd/MM/yyyy"
                    minDate={new Date().getTime()}
                    value={productFields.price.sale.expiration}
                    onChange={(newValue) => handleExpirationDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {/* SHOW VARIANT MODAL */}
      {openShowVariant && (
        <ShowVariant
          setOpenShowVariant={setOpenShowVariant}
          choosedColor={choosedColor}
          colorsList={productFields.colors}
          setColorsList={setProductFields}
        />
      )}
    </Box>
  );
};

export default CreateProductDetails;
