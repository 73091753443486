import { CustomersList, TopAnalytics } from '../../components';
import SmallSquare from './../../components/UIs/skeletons/SmallSquare/SmallSquare';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import axios from '../../utils/axios';
import { useQuery } from 'react-query';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';

import cls from './customers.module.scss';

const Customers = () => {
  // COMPONENT HOOKS
  const { t: translate } = useTranslation('common');

  const analytics = [
    {
      id: 1,
      label: `${translate('topAnalytics.customers')}`,
      var: '-',
      link: `${translate('topAnalytics.allCustomers')}`,
      icon: <i className="fa-light fa-user-group"></i>
    },
    {
      id: 2,
      label: `${translate('topAnalytics.moderators')}`,
      var: '-',
      link: `${translate('topAnalytics.allModerators')}`,
      icon: <i className="fa-light fa-user-group-crown"></i>
    },
    {
      id: 3,
      label: `${translate('topAnalytics.admins')}`,
      var: '-',
      link: `${translate('topAnalytics.allAdmins')}`,
      icon: <i className="fa-light fa-id-card"></i>
    },
    {
      id: 4,
      label: `${translate('topAnalytics.deliveries')}`,
      var: '-',
      link: `${translate('topAnalytics.allDelivers')}`,
      icon: <i className="fa-light fa-truck"></i>
    },
    {
      id: 5,
      label: `${translate('topAnalytics.active')}`,
      var: '-',
      link: `${translate('topAnalytics.allActive')}`,
      icon: <i className="fa-light fa-bell"></i>
    },
    {
      id: 6,
      label: `${translate('topAnalytics.inActive')}`,
      var: '-',
      link: `${translate('topAnalytics.allInActive')}`,
      icon: <i className="fa-light fa-bell-slash"></i>
    },
    {
      id: 7,
      label: `${translate('topAnalytics.banned')}`,
      var: '-',
      link: `${translate('topAnalytics.allbanned')}`,
      icon: <i className="fa-light fa-ban"></i>
    },
    {
      id: 8,
      label: `${translate('topAnalytics.confirmed')}`,
      var: '-',
      link: `${translate('topAnalytics.allConfirmed')}`,
      icon: <i className="fa-light fa-badge-check"></i>
    }
  ]

  const fetchCustomersAnalytics = async () => {
    const response = await axios.get('/user/analytics').catch(err => errorNotify(err));

    if(!response || !response.data.success) return;

    return response.data.success;
  }

  const { data: customersAnalytics, isFetching } = useQuery('customersAnalytics', fetchCustomersAnalytics, {
    refetchOnWindowFocus: false,
    select: (customersAnalytics) => {
      analytics[0].var = customersAnalytics.totalCustomers
      analytics[1].var = customersAnalytics.totalModerators
      analytics[2].var = customersAnalytics.totalAdmins
      analytics[3].var = customersAnalytics.totalDeliveries
      analytics[4].var = customersAnalytics.totalInActiveUsers
      analytics[5].var = customersAnalytics.totalInActiveUsers
      analytics[6].var = customersAnalytics.totalBannedUsers
      analytics[7].var = customersAnalytics.totalConfirmedUsers

      return analytics
    }
  });

  const successNotify = (message) => toast.success(message)
  const errorNotify = (message) => toast.error(message)

  return (
    <Box className={cls.customers}>

      <Box className={cls.customers__head}>

        <Typography variant='h6' component='h6'>{translate('customers.title')}</Typography>

        <Typography><span>{translate('customers.customers')}</span> &gt; {translate('customers.all')}</Typography>

      </Box>

      {!isFetching ? 

        <TopAnalytics analytics={analytics} special={true} />

        :

        <Grid container spacing={2} style={{ marginBottom: '20px' }}>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

        </Grid>

      }

      <Box className={cls.customers__table} sx={{ bgcolor: 'background.secondary' }}>

        <Box className={cls.customers__table_head}>

          <Typography variant="h6" component="h6">
            {translate('customers.list')}
          </Typography>

        </Box>

        <CustomersList />

      </Box>

    </Box>
  )
}

export default Customers;