import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import cls from './404.module.scss';

const Error = () => {
  const navigate = useNavigate()
  const { t: translate } = useTranslation('common');

  return (
    <div className={cls.notFound}>
      <img src="/assets/imgs/404/404.png" alt="NotFount" />
      <h4>{translate('404.lost')}</h4>
      <button onClick={() => navigate('/')}>{translate('404.goHome')}</button>
    </div>
  )
}

export default Error