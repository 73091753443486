import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { AllProductsFilter, AllProductsTable, TopAnalytics } from '../../../components';
import SmallSquare from './../../../components/UIs/skeletons/SmallSquare/SmallSquare';
import LargeSquare from '../../../components/UIs/skeletons/LargeSquare/LargeSquare';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { useQuery } from 'react-query';

import axios from '../../../utils/axios';

import { useTranslation } from 'react-i18next';

import cls from './allProducts.module.scss';
import { useEffect } from 'react';

const Products = () => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation('common');
  const analytics = [
    {
      id: 1,
      label: `${translate('topAnalytics.allProducts')}`,
      var: '-',
      link: `${translate('topAnalytics.ourProds')}`,
      icon: <i className="fa-light fa-bag-shopping"></i>
    },
    {
      id: 2,
      label: `${translate('topAnalytics.publishedProds')}`,
      var: '-',
      link: `${translate('topAnalytics.allPublished')}`,
      icon: <i className="fa-light fa-bag-shopping"></i>
    },
    {
      id: 3,
      label: `${translate('topAnalytics.productsStock')}`,
      var: '-',
      link: `${translate('topAnalytics.inStock')}`,
      icon: <i className="fa-light fa-box-open"></i>
    },
    {
      id: 4,
      label: `${translate('topAnalytics.productsSale')}`,
      var: '-',
      link: `${translate('topAnalytics.onSale')}`,
      icon: <i className="fa-regular fa-fire"></i>
    }
  ]
  const [allProducts, setAllProducts] = useState([]);
  const [productsCount, setProductsCount] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [filterUrl, setFilterUrl] = useState({})
  const [choosedCategory, setChoosedCategory] = useState({});
  const [choosedCategoryData, setChoosedCategoryData] = useState(null);
  const [selectedColors, setSelectedColors] = useState([]);
  const [limit, setLimit] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation()

  const fetchProductsAnalytics = async () => {
    const analyticsResponse = await axios.get('/product/analytics');

    if (!analyticsResponse || !analyticsResponse.data.success) return;

    return analyticsResponse.data.success;
  }

  const { data: productsAnalytics, isFetching } = useQuery('fetchProductsAnalytics', fetchProductsAnalytics, {
    refetchOnWindowFocus: false,
    select: (productsAnalytics) => {
      analytics[0].var = productsAnalytics.totalProducts
      analytics[1].var = productsAnalytics.totalProductsPublished
      analytics[2].var = productsAnalytics.totalProductsInStock
      analytics[3].var = productsAnalytics.totalProductsOnSale
      return analytics
    }
  })

  const fetchProducts = async () => {

    let endPoint = ''

    if (location.search) {

      endPoint = `/product${location.search}&select=name,price,images,solds,rating,availability,category&skip=${(pageNumber - 1) * limit}&limit=${limit}&pc=1`;

    } else {

      endPoint = `/product?select=name,price,images,solds,rating,availability,category&skip=${(pageNumber - 1) * limit}&limit=${limit}&pc=1`;

    }

    const productsResponse = await axios.get(endPoint);

    if (!productsResponse) return;

    setProductsCount(productsResponse.data.success.totalCount)

    return productsResponse.data.success.products;
  }

  const { data: products, isFetching: productsLoading, refetch: refetchProducts } = useQuery([['fetchProducts', location.search], pageNumber], fetchProducts, {
    refetchOnWindowFocus: false,
    onSuccess: (products) => {
      let allProducts = products.map(product => (
        {
          id: product._id,
          product: {
            img: product.images[0],
            name: product.name.substring(0, 40) + '...',
            category: product.category.name,
          },
          stock: product.availability,
          price: product.price.amount.toFixed(2),
          orders: product.solds,
          rating: product.rating
        }
      ))
      setAllProducts(allProducts)
    }
  })

  const fetchAllCategories = async () => {
    const response = await axios.get(`/category?select=sub_categories,name,slug`);

    return response.data.success;
  }

  const { data: allCategories, isLoading: categoriesLoading, refetch: refetchCategories } = useQuery('fetchCategories', fetchAllCategories, {

    refetchOnWindowFocus: false,

    onSuccess: async (allCategories) => {

      setChoosedCategory(searchParams.get('category') ? allCategories.find(category => category._id === searchParams.get('category')) : allCategories[0])

      const response = await axios.get(`/category/${searchParams.get('category') ? searchParams.get('category') : allCategories[0]._id}/details`);

      if (!response || !response.data) return;

      setChoosedCategoryData({ ...response.data.success })

      if (searchParams.get('colors')) {
        setSelectedColors([...searchParams.get('colors').split(',')])
      }

    }
  });

  useEffect(() => {
    refetchCategories()
  }, [searchParams.get('category')])

  const productsFilterProps = {
    filterUrl,
    setFilterUrl,
    allCategories,
    choosedCategory,
    setChoosedCategory,
    choosedCategoryData,
    setChoosedCategoryData
  }

  return (
    <Box className={cls.allProducts}>

      <Box className={cls.allProducts__head}>

        <Typography variant='h6' component='h6'>{translate('products.title')}</Typography>

        <Typography><span>{translate('products.products')}</span> &gt; {translate('products.all')}</Typography>

      </Box>

      {!isFetching ?

        <TopAnalytics analytics={analytics} />

        :

        <Grid container spacing={2} style={{ marginBottom: '20px' }}>

          <Grid item xs={12} sm={6} lg={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} lg={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} lg={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} lg={6} xl={3}>
            <SmallSquare />
          </Grid>

        </Grid>

      }

      <Grid container spacing={3}>

        {!categoriesLoading &&

          <Grid item xs={12} md={3}>

            <AllProductsFilter
              {...productsFilterProps}
              selectedColors={selectedColors}
              setSelectedColors={setSelectedColors}
              categoriesLoading={categoriesLoading}
            />

          </Grid>
          // :

          // <Grid item xs={12} md={3}>
          //   <LargeSquare />
          // </Grid>

        }

        <Grid item xs={12} md={9} style={{ position: 'relative' }}>

          <AllProductsTable
            products={allProducts}
            productsLoading={productsLoading}
            productsCount={productsCount}
            pageNumber={pageNumber}
            limit={limit}
            setPageNumber={setPageNumber}
            refetchProducts={refetchProducts}
          />

        </Grid>

      </Grid>

    </Box>
  )
}

export default Products