import cls from './loading.module.scss';

const Loading = () => {
  return (
    <div className={cls.wrapper}>

      <div className={cls.push_pop}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className={cls.line}>
        <div className={cls.circle}></div>
        <div className={cls.circle}></div>
        <div className={cls.circle}></div>
      </div>

    </div>
  )
}

export default Loading