import { useState, useEffect } from "react";

import Box from "@mui/material/Box";

import Cookies from "universal-cookie";

// STYLES FILES
import cls from "./dropDown.module.scss";

const cookie = new Cookies();

const DropDown = ({ menu }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [lang, setLang] = useState(cookie.get('marketyLang'));

  useEffect(() => {
    setLang(cookie.get('marketyLang'))
  }, [cookie.get('marketyLang')])

  const handleClick = () => {
    setOpenMenu(!openMenu);
  };

  const handleClose = () => {
    setOpenMenu(false);
  };

  return (
    <div className={cls.dropdown}>
      {openMenu && <div className={cls.overlay} onClick={handleClose}></div>}
      
      {
        lang === "en" ? (
          <img src="/assets/imgs/navbar/america.png" alt="america" onClick={handleClick} />
        ) : (
          <img src="/assets/imgs/navbar/suadi.png" alt="suadi" onClick={handleClick} />
        )
      }

        {/* {side && <h6 onClick={handleClick}>{lang}</h6>}

        <i className="fa-solid fa-caret-down" onClick={handleClick}></i> */}

      <Box className={`${cls.dropdownMenu} ${openMenu ? cls.show : ""}`} sx={{ bgcolor: 'background.secondary' }}>
        <ul>
          {
            menu.map((item) => (
              <li
                key={item.option}
                onClick={() => {
                  item.method(item.param);
                  handleClose();
                }}
              >
                
                {item.img && <img src={item.img} alt="flag" className={cls.flag} />}

                <p>{item.option}</p>
              </li>
            ))
          }
        </ul>
      </Box>

    </div>
  );
};

export default DropDown;
