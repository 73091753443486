import Box from '@mui/material/Box'

import { useField, ErrorMessage } from "formik";

import { useTranslation } from 'react-i18next';

import cls from './inputField.module.scss'

const TextField = (props) => {
  // COMPONENT HOOKS
  const [field, meta] = useField(props)
  const { i18n } = useTranslation()

  const icons = {
    name: "pen-nib",
    email: "envelope",
    password: "lock-keyhole",
    confirmPassword: "lock-keyhole",
  };

  return (
    <Box className={cls.field}>

      <Box className={cls.field__input}>
        <input 
          {...props} 
          {...field} 
          className={meta.touched && meta.error ? cls.invalid : ""} 
        />

        {meta.touched && meta.error ? (
          <i
            className={`fa-regular fa-circle-exclamation ${cls.alert} ${
              cls[i18n.language]
            }`}
          ></i>
        ) : (
          <i
            className={`fa-light fa-${icons[props.name]} ${cls[i18n.language]}`}
          ></i>
        )}
      </Box>
      
      <Box className={cls.errorMessage}>

        <ErrorMessage name={field.name} />

      </Box>

    </Box>
  )
}

export default TextField;