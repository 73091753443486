import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';

import axios from '../../../utils/axios';

import cls from './filterAccordion.module.scss'

const FilterAccordion = ({ 
    category, 
    list, 
    size,
    addToFilters,
    removeFromFilters, 
    active, 
    choosedCategory,
    setChoosedCategory, 
    setChoosedCategoryData,
    filterUrl,
    setSearchParams,
    searchParams,
    setFilteredResults,
    setFilterUrl
  }) => {

  // COMPONENT HOOKS
  const [showSub, setShowSub] = useState('')
  const { i18n } = useTranslation()

  useEffect(() => {
    let subMenu = document.getElementById(searchParams.get('category'));
    if(subMenu) {
      setShowSub(choosedCategory._id)
      beOpen(subMenu)
    }
  }, [choosedCategory])

  // COMPONENT HANDLERS
  const beOpen = async (element) => {
    let openMenu = element.nextElementSibling;

    if (openMenu) {
      let maxHeight = openMenu.scrollHeight;

      if (!openMenu.style.maxHeight) {
        openMenu.style.maxHeight = maxHeight + 'px';
      } else {
        openMenu.style.maxHeight = null;
      }
    }
  }

  const showSubList = async (e, list) => {
    
    if(showSub === list) {
      setShowSub('')
    }
    else {
      setShowSub(list)

      var elements = document.getElementsByClassName('openMenu');

      for(var i = 0; i < elements.length; i++) {
        elements[i].style.maxHeight = null;
      }

    }

    let openMenu = e.target.nextElementSibling;

    if (openMenu) {
      let maxHeight = openMenu.scrollHeight;

      if (!openMenu.style.maxHeight) {
        openMenu.style.maxHeight = maxHeight + 'px';
      } else {
        openMenu.style.maxHeight = null;
      }
    }

    setFilteredResults([])

    setFilterUrl({category: [category._id]})

    setChoosedCategory({...category})

    setSearchParams({category: category._id})

    const response = await axios.get(`/category/${category._id}/details`);

    if(!response || !response.data) return;

    setChoosedCategoryData({...response.data.success})
  }

  const checkOption = (item) => {
    if(filterUrl.subcategory && filterUrl.subcategory.find(one => one === item._id)) {
      removeFromFilters('subcategory', item.name, item._id)
    } else {
      addToFilters('subcategory', item)
    }
  }

  return (
    <Box className={`${cls.accordion_link} ${showSub === category._id ? cls.showSub : ''} ${cls[i18n.language]} ${size === 'small' ? cls.smallAccordion : ''}`}>

      <Box id={category._id} className={`${cls.link}  ${active ? cls.active : ''}`} onClick={(e) => showSubList(e, category._id)}>

        <Box className={`${cls.label}`}>
          {category.name}
        </Box>

        <i className={`${cls[i18n.language]} fa-regular fa-angle-down`}></i>

      </Box>

      <Box className={`${cls.subMenu} ${cls[i18n.language]} openMenu`}>

        {showSub === category._id && <Box className={cls.dropLayer} onClick={(e) => showSubList(e, '')}></Box>}

        <ul>

          {list.map((item, index) => (
            <li key={index} className={`${filterUrl.subcategory && filterUrl.subcategory.find(one => one === item._id ) ? cls.choosed : ''}`}>
              <>
                <i className="fa-regular fa-circle-small"></i>

                <p onClick={() => checkOption(item)}>{item.name}</p>
              </>
            </li>
          ))}

        </ul>

      </Box>

    </Box>
  )
}

export default FilterAccordion;