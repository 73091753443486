import { useState, useRef } from 'react';

import { InputFieldComponent, UploadField } from '../../../../components';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';

import cls from './createCategory.module.scss'

const CreateCategory = ({ 
    categoryFields, 
    updateCategoryFields, 
    choosedCategoryImage, 
    setChoosedCategoryImage, 
    category, 
    updateCategory,
    emptyFields
  }) => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation('common');

  return (
    <Box className={cls.createCategory}>
      
      <Typography variant="h3" component="h3">
        {translate('category.create')}
      </Typography>
      
      <Box className={cls.createCategory__fields}>

        <InputFieldComponent type="text" name="name" placeholder={translate('createCat.name')} value={categoryFields.name} setFunc={(e) => updateCategoryFields(e)} error={!categoryFields.name && emptyFields ? true : false} />

        <InputFieldComponent type="text" name="description" placeholder={translate('createCat.desc')} value={categoryFields.description} setFunc={(e) => updateCategoryFields(e)} error={!categoryFields.description && emptyFields ? true : false}  />

        <label>{translate('category.image')}</label>

        <UploadField attach={choosedCategoryImage} updateAttachment={setChoosedCategoryImage} error={!choosedCategoryImage && emptyFields ? true : false} />

      </Box>

      <Box className={cls.btn}>
        {category && <button onClick={updateCategory}>{translate('category.updateCategory')}</button>}
      </Box>

    </Box>
  )
}

export default CreateCategory