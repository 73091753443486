import { createSlice } from "@reduxjs/toolkit";

var initialState = { user: null }

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    updateUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload
      }
    },
    updateUserLinking: (state, action) => {
      state.user = {
        ...state.user,
        externalAuth: {
          ...state.user.externalAuth,
          ...action.payload
        }
      }
    },
    updateUserNotificationStatus: (state, action) => {
      const updatedNotifications = state.user.notifications.map(not => {
        if (not._id === action.payload) {
          not.read = true
        }
        return not;
      })
      state.user.notifications = updatedNotifications;
    },
    deleteUserNotification: (state, action) => {
      const updatedNotifications = state.user.notifications.filter(not => not._id !== action.payload);
      state.user.notifications = updatedNotifications;
    }
  }
})

export const userActions = userSlice.actions

export default userSlice.reducer;