/* eslint-disable no-undef */
import axios from "axios";
import Cookies from 'universal-cookie';


axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use((config) => {
  const cookies = new Cookies(null, { path: '/' });
  const token = cookies.get("markety-dashboard-session") || localStorage.getItem("markety-dashboard-session");

  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
}, (error) => {
  return Promise.reject(error);
});


export default axios;