import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';

import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import cls from './productReviews.module.scss';

const ProductReviews = ({ rating, reviews }) => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation('common');

  return (
    <Box className={cls.productReviews}>

      <Typography variant="h6" component="h6">
        {translate('proDetails.rate')}
      </Typography>

      <Grid container spacing={3}>

        <Grid item xs={12} lg={4}>

          <Box className={cls.productReviews__ratingBox}>

            <Box className={cls.productReviews__ratingBox_rating} sx={{ bgcolor: 'background.default' }}>
              
              <Rating name="read-only" value={rating} readOnly />

              <Typography>
                {rating} {translate('proDetails.outOf')} 5
              </Typography>

            </Box>

            <Typography>
              {translate('proDetails.tot')} {reviews.length} {translate('proDetails.reviews')}
            </Typography>

            <Box className={cls.productReviews__ratingBox_rates}>

              <Box className={cls.rate}>

                <Typography>
                  5 {translate('proDetails.star')}
                </Typography>

                <Box className={cls.progress}><span style={{ width: `${(reviews.filter(review => review.rating === 5).length / reviews.length) * 100}%`}}></span></Box>

                <Typography>
                  {reviews.filter(review => review.rating === 5).length}
                </Typography>

              </Box>

              <Box className={cls.rate}>

                <Typography>
                  4 {translate('proDetails.star')}
                </Typography>

                <Box className={cls.progress}><span style={{ width: `${(reviews.filter(review => review.rating === 4).length / reviews.length) * 100}%`}}></span></Box>

                <Typography>
                  {reviews.filter(review => review.rating === 4).length}
                </Typography>

              </Box>

              <Box className={cls.rate}>

                <Typography>
                  3 {translate('proDetails.star')}
                </Typography>

                <Box className={cls.progress}><span style={{ width: `${(reviews.filter(review => review.rating === 3).length / reviews.length) * 100}%`}}></span></Box>

                <Typography>
                  {reviews.filter(review => review.rating === 3).length}
                </Typography>

              </Box>

              <Box className={cls.rate}>

                <Typography>
                  2 {translate('proDetails.star')}
                </Typography>

                <Box className={cls.progress}><span style={{ width: `${(reviews.filter(review => review.rating === 2).length / reviews.length) * 100}%`}}></span></Box>

                <Typography>
                  {reviews.filter(review => review.rating === 2).length}
                </Typography>

              </Box>

              <Box className={cls.rate}>

                <Typography>
                  1 {translate('proDetails.star')}
                </Typography>

                <Box className={cls.progress}><span style={{ width: `${(reviews.filter(review => review.rating === 1).length / reviews.length) * 100}%`}}></span></Box>

                <Typography>
                  {reviews.filter(review => review.rating === 1).length}
                </Typography>

              </Box>

            </Box>

          </Box>

        </Grid>

        <Grid item xs={12} lg={8}>

          <Box className={cls.productReviews__reviews}>

            <Typography variant="h6" component="h6">
              {translate('proDetails.reviews')}:
            </Typography>

            <Box className={cls.productReviews__reviewsBox}>

              {reviews.length ? 
              
                <>
                
                  {reviews.map((review, idx) => (

                    <Box key={idx} className={cls.productReviews__reviewsBox_review}>

                      <Box className={cls.user}>

                        <Box className={cls.userInfo}>

                          <Typography variant="h6" component="h6">
                            {review.user.username}
                          </Typography>

                          <Typography>
                            {review.content}
                          </Typography>

                        </Box>

                      </Box>

                      <Box className={cls.side}>
                        <Typography className={cls.stars}>
                        <i className="fa-solid fa-star"></i> {review.rating}
                        </Typography>
                        <Typography>

                          {format(new Date(review.createdAt).getTime(), 'dd MMM, yyyy')}
                        </Typography>
                      </Box>

                    </Box>

                  ))}
                
                </>
                :
                <p>{translate('product.noReviews')}</p>
              }

            </Box>

          </Box>

        </Grid>

      </Grid>

    </Box>
  )
}

export default ProductReviews