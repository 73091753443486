import { useNavigate, useParams } from "react-router";

import LargeSquare from "../../components/UIs/skeletons/LargeSquare/LargeSquare";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { useQuery } from "react-query";

import axios from "../../utils/axios";

import Swal from "sweetalert2";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

import cls from "./contactDetails.module.scss";

const ContactDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t: translate, i18n } = useTranslation("common");

  const fetchContactDetails = async () => {
    const response = await axios.get(`/contact/${id}`);

    if (response.data.success) {
      return response.data.success;
    }
  };

  const { data: contactData, isLoading } = useQuery(
    "fetchContactDetails",
    fetchContactDetails
  );

  const checkDeleteContact = () => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("contactDetails.sureDelete"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteContact();
      }
    });
  };

  const deleteContact = async () => {
    const { data } = await axios.delete(`/contact/${id}`);
    if (data.success) {
      successNotify(translate("contactDetails.contactDeleted"));
      navigate("/contact");
    }
  };

  const successNotify = (message) => toast.success(message);

  return (
    <>
      <Box className={cls.contactDetails__head}>
        <Typography variant="h6" component="h6">
          {translate("contactDetails.title")}
        </Typography>

        <Typography>
          <span>{translate("contactDetails.dashboard")} </span> &gt;
          {translate("contactDetails.contact")}
        </Typography>
      </Box>
      {isLoading ? (
        <LargeSquare />
      ) : (
        <Box
          className={cls.detailsTable}
          sx={{ bgcolor: "background.secondary" }}
        >
          <h3>{translate("contactDetails.titlePage")}</h3>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <div className={cls.field}>
                <label>{translate("contactDetails.name")}</label>
                <span>{contactData.name}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={cls.field}>
                <label>{translate("contactDetails.phone")}</label>
                <span>
                  {contactData.phone_number
                    ? contactData.phone_number
                    : "No Phone Number Found"}
                </span>
              </div>
            </Grid>
            <Grid item xs={12}>
              
              <div className={cls.field}>
                <label>{translate("contactDetails.email")}</label>
                <span>{contactData.email_address}</span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={cls.field}>
                <label>{translate("contactDetails.message")}</label>
                <span>{contactData.message}</span>
              </div>
            </Grid>
          </Grid>
          <div className={cls.btn}>
            <button onClick={checkDeleteContact}>
              {translate("contactDetails.delete")}
            </button>
          </div>
        </Box>
      )}
    </>
  );
};

export default ContactDetails;
