import { useState, useMemo } from "react";

import ChooseList from "../../UIs/ChooseList/ChooseList";
import SupportAccordion from './../../customer-support/SupportAccordion/SupportAccordion';
import TableCheckBox from "../../UIs/TableCheckBox/TableCheckBox";
import LargeSquare from "./../../UIs/skeletons/LargeSquare/LargeSquare";
import Loader from '../../UIs/Loading/Loading';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";

import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";

import { format } from "date-fns";

import { DateRange } from "react-date-range";

import { useTranslation } from "react-i18next";

import { useQuery, useMutation } from "react-query";
import axios from "../../../utils/axios";

import { toast } from "react-toastify";
import Swal from 'sweetalert2';

import dayjs from "dayjs";

import cls from "./chatTickets.module.scss";

const ChatTickets = ({ customerId }) => {
  // COMPONENT HOOKS
  const [pageNumber, setPageNumber] = useState(1);
  const [ticketsCount, setTicketsCount] = useState(0);
  const [limit] = useState(10);
  const [ticketForUpdate, setTicketForUpdate] = useState({})
  const [date, setDate] = useState([
    {
      startDate: dayjs().startOf("year").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateQuery, setDateQuery] = useState('')
  const { t: translate, i18n } = useTranslation("common");

  const handleDateChange = (item) => {
    setDate([item.selection]);
    setDateQuery(`${new Date([item.selection][0].startDate).getTime()}~${new Date([item.selection][0].endDate).getTime()}`);
  };

  const handleDateFocusChange = (focused) => {
    if (!focused[0] && !focused[1]) setShowDatePicker(false);
  };

  const fetchTickets = async () => {
    const response = await axios
      .get(`/ticket?pu=1&po=1&poi=1&limit=${limit}&skip=${(pageNumber - 1) * limit}&user=${customerId}&createdAt=${dateQuery ? dateQuery : ''}`)
      .catch((err) => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    setTicketsCount(response.data.success.totalCount)

    return response.data.success.tickets;
  };

  const { data: tickets, isFetching: ticketsLoading, refetch: refetchingTickets } = useQuery(
    ["fetchTickets", dateQuery],
    fetchTickets,
    {
      refetchOnWindowFocus: false
    }
  );

  // COMPONENT HANDLERS
  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
  };

  const checkDeleteTicket = (id) => {
    Swal.fire({
      title: translate('toast.sure'),
      text: translate('toast.deleteTicket'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: translate('toast.deleteAddress'),
      cancelButtonText: translate('toast.cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUserTicket(id);
      }
    })
  }

  const deleteTicket = async (id) => {
    const response = await axios.delete(`/ticket/${id}`).catch(err => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    successNotify('toast.ticketDeleted');

    refetchingTickets()
  }

  const { mutate: deleteUserTicket, isLoading: deletingTicket } = useMutation('deleteTicket', deleteTicket);

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box className={cls.chatTickets}>

      {deletingTicket && <Loader />}

      <Box className={cls.welcome}>
        <Box className={`${cls.welcome__date} ${cls[i18n.language]}`}>
          <input
            type="text"
            readOnly
            value={
              dayjs(date[0].startDate).format("YYYY-MM-DD") +
              `  ${translate("analytics.to")}  ` +
              dayjs(date[0].endDate || new Date()).format("YYYY-MM-DD")
            }
            onClick={() => setShowDatePicker((prev) => !prev)}
          />
          <i
            onClick={() => setShowDatePicker((prev) => !prev)}
            className="fa-light fa-calendar-day"
          ></i>

          {showDatePicker && (
            <DateRange
              editableDateInputs={false}
              onChange={handleDateChange}
              onRangeFocusChange={handleDateFocusChange}
              ranges={date}
              retainEndDateOnFirstSelection={false}
              showDateDisplay={false}
            />
          )}
        </Box>
      </Box>

      <Box className={cls.chatTickets__tickets}>
        {ticketsLoading ? (
          <LargeSquare />
        ) : (
          <>
            {tickets?.length >= 1 ? (
              <>
                {tickets.map((ticket, idx) => (
                  <SupportAccordion key={idx} data={ticket} insideProfile={true} checkDeleteTicket={checkDeleteTicket} />
                ))}

                <Box className={cls.chatTickets__footer}>
                  <Typography>
                    {translate("product.show")} {pageNumber}{" "}
                    {translate("product.of")} {Math.ceil(ticketsCount / limit)}{" "}
                    {translate("product.results")}
                  </Typography>

                  <Box className={`${cls.pagination} pagination`}>
                    <Pagination
                      count={Math.ceil(ticketsCount / limit)}
                      page={pageNumber}
                      onChange={changePage}
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <Box className="empty">
                <img src="/assets/imgs/support/ticket.png" alt="emptyTickets" />
                <p>{translate("userProfile.emptyTickets")}</p>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ChatTickets;
