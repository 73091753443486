import { useDispatch } from "react-redux";
import { userActions } from "../../../store/slices/userSlice";

import { useTranslation } from "react-i18next";

import axios from "../../../utils/axios";

import { toast } from "react-toastify";

import Swal from "sweetalert2";

import { useMutation } from "react-query";

import cls from "./notificationsDropdown.module.scss";

const NotificationsDropdown = ({ userId, notifications, setLoading }) => {
  const { t: translate, i18n } = useTranslation("common");
  const dispatch = useDispatch();

  // COMPONENT HANDLERS
  const markAsRead = async (e, notification) => {
    if (e.target.tagName !== "I") {
      if (notification.read === false) {
        setLoading(true);
        const response = await axios
          .patch(`/notification/${userId}/${notification._id}`)
          .catch((err) => {
            errorNotify(err.response.data.err);
            setLoading(false);
          });

        if (!response || !response.data) return;

        setLoading(false);

        dispatch(userActions.updateUserNotificationStatus(notification._id));

        location.href = notification.action;
      } else {
        location.href = notification.action;
      }
    }
  };

  const { mutate: markNotAsRead } = useMutation(({ e, notification }) =>
    markAsRead(e, notification)
  );

  const checkDeleteNotification = (notificationId) => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteNotification"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteNotification(notificationId);
      }
    });
  };

  const deleteNotification = async (notificationId) => {
    setLoading(true);
    const response = await axios
      .delete(`/notification/${userId}/${notificationId}`)
      .catch((err) => {
        setLoading(false);
        errorNotify(err.response.data.err);
      });

    setLoading(false);

    if (!response || !response.data) return;

    dispatch(userActions.deleteUserNotification(notificationId));

    successNotify(translate("toast.notificationDeleted"));
  };

  const { mutate: deleteNot } = useMutation((notificationId) =>
    checkDeleteNotification(notificationId)
  );

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <>
      {notifications && notifications.length >= 1 ? (
        <>
          {notifications.map((notification) => (
            <div
              className={`${cls.notification} ${
                !notification.read && cls.unRead
              } ${cls[i18n.language]}`}
              key={notification._id}
              onClick={(e) =>
                markNotAsRead({ e: e, notification: notification })
              }
            >
              <div className={cls.notification__wrapper}>
                <img src={notification.image} alt="notification image" />

                <div className={cls.notification__wrapper_details}>
                  <h6>
                    {notification.title.substring(0, 25)}{" "}
                    {notification.title.length >= 25 && "..."}
                  </h6>
                  <p>
                    {notification.content.substring(0, 50)}{" "}
                    {notification.content.length >= 50 && "..."}
                  </p>
                </div>
              </div>

              <i
                className="fa-solid fa-trash-can"
                onClick={() => deleteNot(notification._id)}
              ></i>
            </div>
          ))}
        </>
      ) : (
        <div className="empty">
          <img
            src="/assets/imgs/notifications/notifications.png"
            alt="emptyNotification"
          />
          <p>{translate("notifications.empty")}</p>
        </div>
      )}
    </>
  );
};

export default NotificationsDropdown;
