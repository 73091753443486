import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { Selectbox, InputFieldComponent, UploadField, Loading } from '../../../components';

import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';

import axios from '../../../utils/axios';

import { toast } from 'react-toastify';

import cls from './createAd.module.scss';

const CreateAd = () => {
  // COMPONENT HOOKS
  const [position, setPosition] = useState('');
  const [adImage, setAdImage] = useState();
  const [emptyFields, setEmptyFields] = useState(false)
  const inputRef = useRef();
  const { t: translate, i18n } = useTranslation('common');
  const { adId } = useParams();
  const navigate = useNavigate();

  const [fields, setFields] = useState({});

  // COMPONENT HANDLERS
  const inputClick = () => {
    inputRef.current.click()
  }
  const chooseCategoryImage = (e) => {
    // setChoosedImage(URL.createObjectURL(e.target.files[0]))
    setAdImage(e.target.files[0])
  }

  const updateAdImage = (image) => {
    setAdImage(image)
  }
  
  const positionsList = [
    `${translate('general.Top-Large')}`,
    `${translate('general.Top-Small-Top')}`,
    `${translate('general.Top-Small-Bottom')}`,
    `${translate('general.Middle-Left')}`,
    `${translate('general.Middle-Right')}`,
    `${translate('general.Bottom')}`
  ]

  const handleFields = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value
    })
  }

  // COMPONENT HANDLERS
  const fetchAd = async () => {
    const response = await axios.get(`/ads/${adId}`);

    if(!response || !response.data.success) {
      navigate('/ads/all');
      return;
    }

    setFields(response.data.success);

    setAdImage(response.data.success.image);

    setPosition(response.data.success.position);
  }

  useEffect(() => {
    if (adId) {
      fetchAd()
    }
  }, [])

  const updateAd = async () => {
    const adData = {
      ...fields,
      position,
      ad_image: adImage,
      changeImage: 1
    }

    const emptyFields = Object.values(adData).filter(field => field === undefined || field === '')

    if(emptyFields.length) {

      setEmptyFields(true)

    } else {

      let data = new FormData()

      Object.keys(adData).forEach(key => {

        data.append(key, adData[key])

      })

      setEmptyFields(false)

      const response = await axios.patch(`/ads/${adId}`, data);
  
      if(!response || !response.data.success) return;
  
      successNotify(translate('toast.updateAd'))
  
      navigate('/ads/all');
    }
  }

  const { mutate: updateAdInfo, isLoading: updateLoading } = useMutation('updateAdInfo', updateAd)

  const successNotify = (message) => toast.success(message)

  return (

    <>

      {updateLoading && <Loading />}

      <Box className={cls.createAd__head}>

        <Typography variant='h6' component='h6'>{translate('general.createAd')}</Typography>

        <Typography><span>{translate('general.create')}</span> &gt; {translate('general.applicationAds')}</Typography>

      </Box>

      <Box className={cls.createAd} sx={{ bgcolor: 'background.secondary' }}>

        <Typography variant="h6" component="h6">
          {translate('general.marketyAds')}
        </Typography>

        <Box className={cls.createAd__fields}>

          <Box className={cls.createAd__fields_field}>

            <Box>

              <label>{translate('general.adTitle')}</label>

              <InputFieldComponent type="text" placeholder={translate('general.adTitle')} name="title" value={fields.title || ''} setFunc={(e) => handleFields(e)} error={emptyFields && !fields.title ? true : false}  />

            </Box>

            <Box>

              <label>{translate('general.adSubtitle')}</label>

              <InputFieldComponent type="text" placeholder={translate('general.adSubtitle')} name="sub_title" value={fields.sub_title || ''} setFunc={(e) => handleFields(e)} error={emptyFields && !fields.sub_title ? true : false}  />

            </Box>

          </Box>

          <Box className={cls.createAd__fields_field}>

            <Box>

              <label>{translate('general.badge')}</label>

              <InputFieldComponent type="text" placeholder={translate('general.badge')} name="badge_content" value={fields.badge_content || ''} setFunc={(e) => handleFields(e)} error={emptyFields && !fields.badge_content ? true : false}  />

            </Box>

            <Box>

              <label>{translate('general.btnLink')}</label>

              <InputFieldComponent type="text" placeholder={translate('general.btnLink')} name="btn_link" value={fields.btn_link || ''} setFunc={(e) => handleFields(e)} error={emptyFields && !fields.btn_link ? true : false}  />

            </Box>

          </Box>

          <Box className={cls.createAd__fields_field}>

            <Box className={cls.single}>

              <label>{translate('general.position')}</label>

              <Selectbox placeholder={translate('general.position')} list={positionsList} value={position} select={setPosition} error={emptyFields && !position ? true : false} />

            </Box>

          </Box>

          <label>{translate('general.uploadAd')}</label>

          <UploadField attach={adImage} updateAttachment={updateAdImage} error={emptyFields && !adImage ? true : false} />

        </Box>

      </Box>

      <Box className={cls.btn}>

        <button onClick={updateAdInfo}>
          {translate(('general.save'))}
        </button>

      </Box>

    </>
  )
}

export default CreateAd;