import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { InputFieldComponent } from "../../../components";
import { Loading } from "../../../components";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";

import { useQuery } from "react-query";

import { useTranslation } from "react-i18next";

import axios from "../../../utils/axios";

import { toast } from "react-toastify";

import cls from "./createCoupon.module.scss";

const CreateCoupon = () => {
  // COMPONENT HOOKS
  const initialValues = {
    discountType: "percent",
    discountAmount: "",
    usage: "",
    status: "enabled",
    haveDate: false,
    emptyFields: false,
  };
  const [fields, setFields] = useState({ ...initialValues });
  const [searchParams, setSearchParams] = useSearchParams();
  const couponId = searchParams.get("coupon");
  const [endDate, setEndDate] = useState(Date.now() + 24 * 3600 * 1000);
  const [haveDate, setHaveDate] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  const [loading, setLoading] = useState(false);
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const { t: translate, i18n } = useTranslation("common");
  const navigate = useNavigate();

  const fetchCouponData = async () => {
    const couponResponse = await axios.get(`/coupon/${couponId}`);

    if (!couponResponse || !couponResponse.data.success) return;

    return couponResponse.data.success;
  };

  if (couponId) {
    const { data: couponData, isFetching } = useQuery(
      "coupondata",
      fetchCouponData,
      {
        onSuccess: (couponData) => {
          if (couponId) {
            if (couponData.expiration.type === "date") {
              setHaveDate(true);
            }
            setFields((prev) => ({
              ...prev,
              discountType: couponData.discount.type,
              discountAmount: couponData.discount.amount,
              usage:
                couponData.expiration.type === "date"
                  ? 0
                  : couponData.expiration.usage,
            }));
            setEndDate(couponData.expiration.date);
          }
        },
      }
    );
  }

  const handleFields = (e) => {
    if (e.target.name === "discountAmount") {
      if (e.target.value > 100 || e.target.value < 0) return;
    }
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  // COMPONENT HANDLERS
  const addCoupon = async () => {
    const couponData = {
      discount: {
        type: fields.discountType,
        amount: fields.discountAmount,
      },
      expiration: {
        type: !haveDate ? "usage" : "date",
        ...(haveDate ? { date: endDate } : { usage: fields.usage }),
      },
    };

    let checkEmpty = "";

    if (!haveDate)
      checkEmpty = Object.values(fields).filter(
        (one) => one === undefined || one === ""
      );
    else {
      // COUPON BY DATE ENDING NOT BY USING TIMES NUMBER
      delete fields.usage;
      checkEmpty = Object.values(fields).filter(
        (one) => one === undefined || one === ""
      );
    }

    if (checkEmpty.length) {
      setEmptyFields(true);
    } else {
      setLoading(true);

      const couponResponse = await axios
        .post("/coupon", couponData)
        .catch((err) => {
          errorNotify(err);
          setLoading(false);
        });

      if (!couponResponse || !couponResponse.data.success) return;

      setLoading(false);

      navigate("/coupons/all");

      successNotify(translate("toast.newCouponAdded"));
    }
  };

  const updateCoupon = async () => {
    const couponData = {
      discount: {
        type: fields.discountType,
        amount: fields.discountAmount,
      },
      expiration: {
        type: !haveDate ? "usage" : "date",
        ...(haveDate ? { date: endDate } : { usage: fields.usage }),
      },
    };

    const checkEmpty = Object.values(fields).filter(
      (one) => one === undefined || one === ""
    );

    if (checkEmpty.length) {
      setEmptyFields(true);
    } else {
      setLoading(true);

      const couponResponse = await axios
        .patch(`/coupon/${couponId}`, couponData)
        .catch((err) => {
          errorNotify(err.response.data.err);
          setLoading(false);
        });

      if (!couponResponse || !couponResponse.data.success) return;

      setLoading(false);

      navigate("/coupons/all");

      successNotify(translate("toast.couponUpdated"));
    }
  };

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box className={cls.createCoupon}>
      {loading && <Loading />}

      <Box className={cls.createCoupon__head}>
        <Typography variant="h6" component="h6">
          {translate("createCoupon.title")}
        </Typography>

        <Typography>
          <span>{translate("createCoupon.coupons")}</span> &gt;{" "}
          {translate("createCoupon.create")}
        </Typography>
      </Box>

      <Grid container spacing={3} className={cls.gridsWarpper}>
        <Grid item xs={12} md={8}>
          <Box
            className={cls.createCoupon__information}
            sx={{ bgcolor: "background.secondary" }}
          >
            <Typography variant="h6" component="h6">
              {translate("createCoupon.info")}
            </Typography>

            <Box
              className={`${cls.createCoupon__information_field} ${cls.radioArea}`}
            >
              <label>{translate("createCoupon.type")}</label>

              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={fields.discountType}
                  name="discountType"
                  onChange={(e) => handleFields(e)}
                >
                  <FormControlLabel
                    value="percent"
                    control={<Radio />}
                    label={translate("createCoupon.percentage")}
                  />
                  <FormControlLabel
                    value="amount"
                    control={<Radio />}
                    label={`${translate("createCoupon.fixed")} (${
                      websiteData.currency
                    })`}
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box className={cls.createCoupon__information_field}>
              <label>{translate("createCoupon.discount")}</label>

              <InputFieldComponent
                type="number"
                placeholder={translate("createCoupon.discount")}
                name="discountAmount"
                min={1}
                max={100}
                value={fields.discountAmount}
                setFunc={(e) => handleFields(e)}
                error={emptyFields && !fields.discountAmount ? true : false}
              />
            </Box>

            <Box className={cls.createCoupon__information_field}>
              <label className={haveDate ? cls.disabled : ""}>
                {translate("createCoupon.usage")}
              </label>

              <Box className={cls.switch}>
                <InputFieldComponent
                  type="number"
                  placeholder={translate("createCoupon.usage")}
                  name="usage"
                  value={fields.usage}
                  setFunc={(e) => handleFields(e)}
                  disabled={haveDate}
                  error={
                    emptyFields && !fields.usage && !haveDate ? true : false
                  }
                />

                <FormGroup>
                  <i className="fa-light fa-calendar-days"></i>{" "}
                  <label htmlFor="">{translate("createCoupon.date")}</label>
                  <FormControlLabel
                    control={
                      <Switch
                        direction="rtl"
                        onChange={() => setHaveDate(!haveDate)}
                        checked={haveDate ? true : false}
                      />
                    }
                  />
                </FormGroup>
              </Box>
            </Box>
          </Box>

          <Box className={cls.btn}>
            {couponId ? (
              <button onClick={updateCoupon}>
                {translate("createCoupon.update")}
              </button>
            ) : (
              <button onClick={addCoupon}>
                {translate("createCoupon.save")}
              </button>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            className={cls.createCoupon__status}
            sx={{ bgcolor: "background.secondary" }}
          >
            <Typography variant="h6" component="h6">
              {translate("createCoupon.status")}
            </Typography>

            <Box className={cls.createCoupon__status_field}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={fields.status}
                  name="status"
                  onChange={(e) => handleFields(e)}
                >
                  <FormControlLabel
                    value="enabled"
                    control={<Radio />}
                    label={translate("createCoupon.enabled")}
                  />
                  <FormControlLabel
                    value="disabled"
                    control={<Radio />}
                    label={translate("createCoupon.disabled")}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>

          {haveDate && (
            <Box
              className={cls.createCoupon__date}
              sx={{ bgcolor: "background.secondary" }}
            >
              <Typography variant="h6" component="h6">
                {translate("createCoupon.usage")}
              </Typography>

              <Typography>{translate("createCoupon.dateText")}</Typography>

              <Box
                className={`${cls.createCoupon__date_field} ${
                  cls[i18n.language]
                }`}
              >
                <label>{translate("createCoupon.endDate")}</label>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    mask="____/__/__"
                    minDate={new Date().getTime() + 24 * 3600 * 1000}
                    inputFormat="dd/MM/yyyy"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateCoupon;
