import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import { AddCountryComponent } from "./../../../components";

import cls from "./editCountry.module.scss";

const EditCountry = () => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation("common");

  return (
    <Box className={cls.editCountry}>
      <Box className={cls.editCountry__head}>
        <Typography variant="h6" component="h6">
          {translate("editCountry.title")}
        </Typography>

        <Typography>
          <span>{translate("addCountry.logistics")}</span> &gt;{" "}
          {translate("editCountry.addCountry")}
        </Typography>
      </Box>

      <AddCountryComponent />
    </Box>
  );
};

export default EditCountry;
