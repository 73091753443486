import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import axios from "../../../utils/axios";

import { toast } from "react-toastify";
import Swal from "sweetalert2";

import cls from "./deactivateSettings.module.scss";

const DeactivateSettings = ({ user, setLoading }) => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation("common");

  // COMPONENT HANDLERS
  const checkDeactivateAccount = () => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deactivate"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.deactivateAccount"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deactivateAccount();
      }
    });
  };

  const deactivateAccount = async () => {
    setLoading(true);

    const response = await axios
      .patch(`/user/${user._id}/deactivate`)
      .catch((err) => {
        errorNotify(err.response.data.err);
      });

    if (!response || !response.data) return;

    successNotify(translate("toast.accountDeactivated"));

    setLoading(false);
  };

  const errorNotify = (message) => toast.error(message);
  const successNotify = (message) => toast.success(message);

  return (
    <Box
      className={cls.deactivateSettings}
      sx={{ bgcolor: "background.secondary" }}
    >
      <Typography variant="h6" component="h6">
        {translate("deactivateAccount.deactivate")}
      </Typography>

      <Typography>{translate("deactivateAccount.text")}</Typography>

      <button onClick={checkDeactivateAccount}>
        {translate("deactivateAccount.btn")}
      </button>
    </Box>
  );
};

export default DeactivateSettings;
