import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { InputField } from '../../components'
import ButtonLoader from '../../components/UIs/ButtonLoader/ButtonLoader';

import { Formik, Form } from 'formik'

import { useTranslation } from 'react-i18next'
import { resetSchema } from '../../components/Auth/validation/validations';

import axios from '../../utils/axios';

import { toast } from 'react-toastify';

import cls from '../login/auth.module.scss'
import { useEffect } from 'react';

const Reset = () => {
  // COMPONENT HOOKS
  const websiteData = useSelector(({ websiteData }) => websiteData.websiteData);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const { t } = useTranslation('auth')
  const [token, setToken] = useState('')
  const search = useLocation().search

  useEffect(() => {

    const resetToken = new URLSearchParams(search).get('t');

    setToken(resetToken)

  }, [])

  // COMPONENT HANDLERS
  const resetPassword = async (values) => {
    setLoading(true)

    const data = {
      token,
      newPassword: values.password,
    }

    const response = await axios.patch('/auth/reset', {...data}).catch(err => {
      errorNotify(err.response.data.err)
      setLoading(false)
    });

    if(!response || !response.data) return;

    // HERE WE SHOULD NAVIGATE TO LOGIN PAGE
    setLoading(false)
  }
  
  const errorNotify = (message) => toast.error(message)

  return (
    <Box className={cls.auth}>
      <Grid container className={cls.auth__authBox}>

        <Grid item xs={12} md={12} lg={6} className={cls.auth__authBox_fields}>

          <Box className={cls.fieldsWrapper}>
            <img src="assets/imgs/auth/reset.png" alt="reset password" />
            <Typography component="h4" variant='h4'>{t('reset.resetPassword')}</Typography>

            <Formik
              initialValues={{
                password: '',
                confirmPassword: ''
              }}
              validationSchema={resetSchema}
              onSubmit={(values) => resetPassword(values)}
            >

              <Form>

                <InputField type="password" name='confirmPassword' placeholder={t('auth.password')} />
                <InputField type="password" name='password' placeholder={t('auth.confirmPassword')} />
            
                <Box className={cls.actions}>
                  <ButtonLoader laoding={loading} type="submit">
                    {t('reset.resetBtn')}
                  </ButtonLoader>
                </Box>

              </Form>

            </Formik>

          </Box>

        </Grid>

        <Grid item md={6} className={cls.auth__authBox_info}>
          <Box className={cls.logo}>
            <img src={websiteData?.logo} alt={websiteData?.website} />
            <Typography component="h4" variant='h4'>{websiteData?.website}</Typography>
          </Box>
          <Box className={cls.info}>
            <Typography component="h5" variant='h5'>{t('reset.resetCurrent')}</Typography>
            <Typography>{t('reset.resetText')}</Typography>
            <button onClick={() => navigate('/login')}>{t('forget.back')}</button>
          </Box>
          <Box className={cls.footer}>
            {t('auth.footerMade')} <i className="fa-solid fa-heart"></i> {t('auth.footerDevelopers')}
          </Box>
        </Grid>

      </Grid>
    </Box>
  )
}

export default Reset