import { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

import { useSelector } from "react-redux";

import { ProductReviews } from "../../../components";
import SmallSquare from "../../../components/UIs/skeletons/SmallSquare/SmallSquare";
import LargeSquare from "../../../components/UIs/skeletons/LargeSquare/LargeSquare";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Rating from "@mui/material/Rating";
import Tooltip from "@mui/material/Tooltip";

import { useTranslation } from "react-i18next";

import { useQuery } from "react-query";
import axios from "../../../utils/axios";

import CurrencyFormat from "react-currency-format";

import { format } from "date-fns";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import cls from "./productDetails.module.scss";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 8,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1500 },
    items: 6,
  },
  desktop2: {
    breakpoint: { max: 1500, min: 1300 },
    items: 5,
  },
  desktop3: {
    breakpoint: { max: 1300, min: 1100 },
    items: 6,
  },
  desktop4: {
    breakpoint: { max: 1100, min: 1000 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1000, min: 800 },
    items: 6,
  },
  small: {
    breakpoint: { max: 800, min: 600 },
    items: 6,
  },
  mobile: {
    breakpoint: { max: 600, min: 480 },
    items: 5,
  },
  mobile2: {
    breakpoint: { max: 480, min: 0 },
    items: 4,
  },
};

const ProductDetails = () => {
  // COMPONENT HOOKS
  const [imageSelected, setImageSelected] = useState();
  const [productState, setProductState] = useState("specs");
  const [variants, setVariants] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState("");
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const { t: translate, i18n } = useTranslation("common");
  const { id } = useParams();

  const fetchProductData = async () => {
    const response = await axios.get(`/product/${id}?pr=1&pru=1&revenue=1`);

    if (!response || !response.data.success) return;

    setTotalRevenue(response.data.success.totalRevenue);

    return response.data.success.Product;
  };

  const {
    data: productData,
    isFetching,
    error,
  } = useQuery(["productData", id, variants], fetchProductData, {
    refetchOnWindowFocus: false,
    onSuccess: (productData) => {
      setImageSelected(productData.images[0]);

      let allVariants = [];

      productData.colors.forEach((color) => {
        color.variants.forEach((variant) => {
          if (allVariants.find((v) => v.name === variant.name)) return;

          allVariants.push(variant);
        });
      });

      setVariants(allVariants);
    },
  });

  // COMPONENT HANDLERS
  const selectImage = (e) => {
    setImageSelected(e.target.src);
  };

  if (isFetching) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} xl={4}>
          <SmallSquare />
        </Grid>

        <Grid item xs={12} xl={8}>
          <LargeSquare />
        </Grid>
      </Grid>
    );
  }

  return (
    <Box className={cls.productDetails}>
      <Box className={cls.productDetails__head}>
        <Typography variant="h6" component="h6">
          {translate("proDetails.title")}
        </Typography>

        <Typography>
          <span>{translate("proDetails.products")}</span> &gt;{" "}
          {translate("proDetails.details")}
        </Typography>
      </Box>

      <Grid
        container
        className={cls.wrapper}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Grid item xs={12} xl={4}>
          <Box className={cls.productDetails__imageArea}>
            <Box className={cls.bigImage}>
              <img src={imageSelected} alt="productImage" />
            </Box>

            <Carousel
              responsive={responsive}
              className={`${cls.thumbnails} thumbnails`}
              swipeable={true}
              draggable={true}
              infinite={true}
            >
              {productData.images.map((image, index) => (
                <img
                  src={image}
                  onClick={(e) => selectImage(e)}
                  alt="product"
                  key={index}
                />
              ))}
            </Carousel>
          </Box>
        </Grid>

        <Grid item xs={12} xl={8}>
          <Box className={cls.productDetails__details}>
            <Box className={cls.name}>
              <Typography variant="h5" component="h5">
                {productData.name}
              </Typography>

              <Tooltip title={translate("proDetails.edit")} placement="top">
                <Link to={`/products/create-product?product=${id}`}>
                  <i className="fa-light fa-pen-to-square"></i>
                </Link>
              </Tooltip>
            </Box>

            <Typography>
              {translate("proDetails.publish")}:{" "}
              {format(
                new Date(
                  productData.createdAt ? productData.createdAt : Date.now()
                ),
                "MMMM dd, yyyy"
              )}
            </Typography>

            <Box className={cls.productDetails__details_rate}>
              <Rating name="read-only" value={productData.rating} readOnly />
              <Typography>
                ( {productData.reviews.length}{" "}
                {translate("proDetails.customer")} )
              </Typography>
            </Box>

            <Box className={cls.productDetails__details_features}>
              <Box className={`${cls.feature} ${cls[i18n.language]}`}>
                <span className={cls.one}>
                  <i className="fa-regular fa-dollar-sign"></i>
                </span>

                <Box>
                  <Typography>
                    {translate("proDetails.price")} ({websiteData.currency}):
                  </Typography>
                  <span className={cls.number}>
                    <CurrencyFormat
                      value={productData.price.amount.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </span>
                </Box>
              </Box>

              <Box className={`${cls.feature} ${cls[i18n.language]}`}>
                <span className={cls.two}>
                  <i className="fa-regular fa-dollar-sign"></i>
                </span>

                <Box>
                  <Typography>{translate("proDetails.ordersNum")}:</Typography>
                  <span className={cls.number}>{productData.solds}</span>
                </Box>
              </Box>

              <Box className={`${cls.feature} ${cls[i18n.language]}`}>
                <span className={cls.one}>
                  <i className="fa-regular fa-dollar-sign"></i>
                </span>

                <Box>
                  <Typography>{translate("proDetails.available")}:</Typography>
                  <span className={cls.number}>{productData.availability}</span>
                </Box>
              </Box>

              <Box className={`${cls.feature} ${cls[i18n.language]}`}>
                <span className={cls.two}>
                  <i className="fa-regular fa-dollar-sign"></i>
                </span>
                <Box>
                  <Typography>
                    {translate("proDetails.total")} ({websiteData.currency}):
                  </Typography>
                  <span className={cls.number}>
                    <CurrencyFormat
                      value={totalRevenue.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value) => <div>{value}</div>}
                    />
                  </span>
                </Box>
              </Box>
            </Box>

            <Box className={cls.productDetails__details_variants}>
              <Box className={cls.variants}>
                <Typography variant="h6" component="h6">
                  {translate("proDetails.varianst")}:
                </Typography>

                {variants.length ? (
                  <>
                    {variants.map((variant, idx) => (
                      <Tooltip
                        key={idx}
                        className={`${cls.variant} ${
                          variant.availability <= 0 && cls.disabled
                        }`}
                        title={
                          variant.availability > 0
                            ? `${variant.availability} ${translate(
                                "proDetails.itemsAvail"
                              )}`
                            : `${translate("proDetails.outStock")}`
                        }
                        placement="top"
                      >
                        <Box style={{ backgroundColor: variant.variant }}>
                          {variant.name}
                        </Box>
                      </Tooltip>
                    ))}
                  </>
                ) : (
                  <p>{translate("product.noVariants")}</p>
                )}
              </Box>

              <Box className={cls.colors}>
                <Typography variant="h6" component="h6">
                  {translate("proDetails.colors")}:
                </Typography>

                {productData.colors.length ? (
                  <Box className={cls.colorsWrapper}>
                    {productData.colors.map((color, idx) => (
                      <Box key={idx} className={cls.wrapper}>
                        <Tooltip
                          title={
                            color.availability > 0
                              ? `${color.availability} ${translate(
                                  "proDetails.itemsAvail"
                                )}`
                              : `${translate("proDetails.outStock")}`
                          }
                          placement="top"
                        >
                          <Box
                            className={`${cls.color} ${
                              color.stock <= 0 && cls.disabled
                            }`}
                            style={{ backgroundColor: color.hex }}
                          ></Box>
                        </Tooltip>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <p>{translate("product.noColors")}</p>
                )}
              </Box>
            </Box>

            <Box className={cls.productDetails__details_description}>
              <Typography variant="h6" component="h6">
                {translate("proDetails.description")}:
              </Typography>

              <Typography>{productData.full_description}</Typography>
            </Box>

            <Box className={cls.productDetails__details_specifications}>
              <Box className={cls.links}>
                <button
                  className={productState === "specs" ? cls.selected : ""}
                  onClick={() => setProductState("specs")}
                >
                  {translate("proDetails.specs")}
                </button>

                <button
                  className={productState === "tags" ? cls.selected : ""}
                  onClick={() => setProductState("tags")}
                >
                  {translate("proDetails.tags")}
                </button>
              </Box>

              {productState === "specs" ? (
                <Box className={cls.specsBox}>
                  {Object.values(productData.specs).length ? (
                    <table className={cls.mainTable}>
                      <tbody>
                        <tr>
                          <td>
                            <table className={cls.head}>
                              <tbody>
                                {Object.keys(productData.specs).map(
                                  (key, idx) => (
                                    <tr key={idx}>
                                      <td>{key}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <table className={cls.result}>
                              <tbody>
                                {Object.values(productData.specs).map(
                                  (value, idx) => (
                                    <tr key={idx}>
                                      <td>{value}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <p>{translate("product.noSpecs")}</p>
                  )}
                </Box>
              ) : (
                <Box className={cls.detailBox}>
                  {productData.tags.length ? (
                    <ul>
                      {productData.tags.map((tag, idx) => (
                        <li key={idx}>{tag}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>{translate("product.noTags")}</p>
                  )}
                </Box>
              )}
            </Box>

            {/* PRODUCT REVIEWS PAGE */}
            <ProductReviews
              rating={productData.rating}
              reviews={productData.reviews}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductDetails;
