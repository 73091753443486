import { useRef, useState, useEffect } from "react";

import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";

import cls from "./uploadField.module.scss";

const UploadField = ({ attach, updateAttachment, error }) => {
  // COMPONENT HOOKS
  const inputRef = useRef();
  const { t: translate, i18n } = useTranslation("common");

  // COMPONENT HANDLERS
  const inputClick = () => {
    inputRef.current.click();
  };

  const chooseImage = (e) => {
    updateAttachment(e.target.files[0]);
    inputRef.current.value = "";
  };

  const deletedImage = () => {
    updateAttachment("");
    inputRef.current.value = "";
  };

  return (
    <Box className={cls.field}>
      <Box className={`${cls.imageArea} ${error ? cls.error : ""}`}>
        {attach ? (
          <>
            <Box className={cls.images}>
              <Box className={cls.image}>
                {/* <img src={previewImage ? previewImage : attach} alt="uploadedImage" /> */}
                <img
                  src={
                    typeof attach === "object"
                      ? URL.createObjectURL(attach)
                      : attach
                  }
                  alt="uploadedImage"
                />

                <i className="fa-regular fa-xmark" onClick={deletedImage}></i>
              </Box>
            </Box>
          </>
        ) : (
          <Box onClick={inputClick}>
            <i className="fa-light fa-cloud-arrow-up"></i>

            <p>{translate("general.uploadAdImg")}</p>
          </Box>
        )}

        <input
          type="file"
          style={{ display: "none" }}
          ref={inputRef}
          onChange={chooseImage}
        />
      </Box>

      {error && (
        <p className={cls.error}>
          <i className="fa-light fa-circle-exclamation"></i>{" "}
          {translate("general.uploadError")}
        </p>
      )}
    </Box>
  );
};

export default UploadField;
