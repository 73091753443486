import { useState, useRef } from 'react';

import Selectbox from './../../UIs/SelectBox/Selectbox';

import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { useTranslation } from 'react-i18next';

import cls from './createOrder.module.scss';

const CreateOrder = ({ setOpenCreateOrder }) => {
  // COMPONENT HOOKS
  const overlay = useRef();
  const [orderStatus, setOrderStatus] = useState('');
  const [orderPayment, setOrderPayment] = useState('');
  const [date, setDate] = useState(new Date());
  const { t: translate, i18n } = useTranslation('common');

  const statusList = [
    `${translate('orders.cancelled')}`,
    `${translate('orders.delivered')}`,
    `${translate('orders.pending')}`,
    `${translate('orders.returns')}`
  ]

  const paymentList = [
    `${translate('orders.master')}`,
    `${translate('orders.deivery')}`,
    `${translate('orders.paypal')}`,
    `${translate('orders.visa')}`
  ]

  // COMPONENT HANDLERS
  const closeModal = (e) => {

    if(overlay.current === e.target) setOpenCreateOrder(false)

  }

  const close = () => {

    setOpenCreateOrder(false)

  }

  return (
    <Box className={cls.overlay} ref={overlay} onClick={(e) => closeModal(e)}>
      <Box className={cls.area}>

        <Box className={cls.area__wrapper}>
          <Box className={`${cls.close} ${cls[i18n.language]}`} onClick={close}>
            <i className="fa-solid fa-xmark"></i>
          </Box>

          <Box container className={cls.area__content} spacing={3}>

            <h3>{translate('orders.createNew')}</h3>

            <Box className={cls.fields}>

              <Box className={cls.field}>

                <label htmlFor="">{translate('orders.customerName')}</label>

                <input type="text" placeholder={translate('orders.customerName')} />

              </Box>

              <Box className={cls.field}>

                <label htmlFor="">{translate('orders.product')}</label>

                <input type="text" placeholder={translate('orders.product')} />

              </Box>

              <Box className={cls.field}>

                <label htmlFor="">{translate('orders.date')}</label>

                <Box className={`${cls.date} ${cls[i18n.language]}`}>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      mask="____/__/__"
                      value={date}
                      onChange={(newValue) => setDate(newValue)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>

                </Box>

              </Box>

              <Box className={cls.duoField}>

                <Box>

                  <label htmlFor="">{translate('orders.amount')}</label>

                  <input type="text" placeholder={translate('orders.amount')} />

                </Box>

                <Box>

                  <label htmlFor="">{translate('orders.payMethod')}</label>

                  <Selectbox placeholder={translate('orders.payMethod')} list={paymentList} value={orderPayment} select={setOrderPayment} />

                </Box>

              </Box>

              <Box className={cls.field}>

                <label htmlFor="">{translate(('orders.delivery'))}</label>

                <Selectbox placeholder={translate(('orders.delivery'))} list={statusList} value={orderStatus} select={setOrderStatus} />

              </Box>

            </Box>

            <Box className={cls.actions}>
              
              <button onClick={close}>{translate('orders.close')}</button>

              <button>{translate('orders.add')}</button>
            
            </Box>

          </Box>

        </Box>
      </Box>
    </Box>
  )
}

export default CreateOrder;