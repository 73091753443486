import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import ChooseList from "../../UIs/ChooseList/ChooseList";
import TableCheckBox from "../../UIs/TableCheckBox/TableCheckBox";
import Selectbox from "./../../UIs/SelectBox/Selectbox";
import LargeSquare from "../../UIs/skeletons/LargeSquare/LargeSquare";
import Loader from "../../UIs/Loading/Loading";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";

import axios from "../../../utils/axios";
import { useQuery } from "react-query";

import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { format } from "date-fns";

import { useTranslation } from "react-i18next";

import cls from "./logisticsTable.module.scss";

const LogisticsTable = () => {
  // COMPONENT HOOKS
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [allLogistics, setAllLogistics] = useState([]);
  const [choosedCountry, setChoosedCountry] = useState({});
  const [countriesList, setCountriesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const { t: translate, i18n } = useTranslation("common");

  const actionList = (id, countryId) => [
    {
      item: `${translate("category.edit")}`,
      icon: <i className="fa-light fa-pen-to-square"></i>,
      method: () => navigate(`/logistics/edit-country?country=${countryId}`),
    },
    {
      item: `${translate("category.delete")}`,
      icon: <i className="fa-light fa-trash-can"></i>,
      method: () => checkDeleteCity(id, countryId),
    },
  ];

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        header: `${translate("logistics.town")}`,
        accessor: "town",
        minWidth: "15%",
        width: "15%",
      },
      {
        header: `${translate("logistics.delivery")}`,
        accessor: "deliverTime",
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("logistics.charge")} (${websiteData.currency})`,
        id: "chargeCost",
        accessor: "chargeCost",
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("logistics.action")}`,
        id: "action",
        Cell: ({ row }) => (
          <ChooseList
            list={actionList(row.original.id, row.original.countryId)}
            text={<i className="fa-thin fa-ellipsis-stroke"></i>}
            withArrow={false}
          />
        ),
        disableSortBy: true,
        minWidth: "2%",
        width: "2%",
      },
    ],
    [i18n.language]
  );

  const tableInstatce = useTable(
    {
      columns,
      data: allLogistics || [],
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <TableCheckBox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <TableCheckBox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableSortBy: true,
          minWidth: "2%",
          width: "2%",
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    pageCount,
    pageOptions,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, selectedRowIds },
  } = tableInstatce;

  // COMPONENT HANDLERS
  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
    gotoPage(pageNum - 1);
  };

  const fetchAllLogistics = async () => {
    const response = await axios.get(`/logistics`);

    if (!response || !response.data) return;

    return response.data.success.logistics;
  };

  const {
    data: allLogisticsList,
    isFetching: logisticsLoading,
    refetch: refetchLogistics,
  } = useQuery(["allLogisticsList"], fetchAllLogistics, {
    refetchOnWindowFocus: false,
    onSuccess: (allLogisticsList) => {
      setChoosedCountry(allLogisticsList[0]);

      const allCountries = allLogisticsList.map((country) => country);

      setCountriesList(allCountries);

      const updatedLogistics = allLogisticsList[0]
        ? allLogisticsList[0].cities.map((logistic) => ({
            countryId: allLogisticsList[0]?._id,
            id: logistic?._id,
            town: logistic?.name,
            deliverTime: `${logistic?.estimated_delivery} Days`,
            chargeCost: logistic?.shipping.toFixed(2),
          }))
        : [];

      setAllLogistics(updatedLogistics);
    },
  });

  const checkDeleteCity = (id, countryId) => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteCity"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCity(id, countryId);
      }
    });
  };

  const deleteCity = async (id, countryId) => {
    const response = await axios
      .delete(`/logistics/${countryId}/remove-city`, { data: { city_id: id } })
      .catch((err) => {
        errorNotify(err.response.data.err);
      });

    if (!response || !response.data) return;

    refetchLogistics();

    successNotify(translate("toast.cityDeleted"));
  };

  const checkDeleteMultipleCities = () => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteCities"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMultipleCities();
      }
    });
  };

  const deleteMultipleCities = async () => {
    setLoading(true);
    const ids = selectedFlatRows.map((id) => id.original.id);
    const response = await axios
      .delete(`/logistics/${choosedCountry?._id}/remove-cities`, {
        data: { cities: ids },
      })
      .catch((err) => {
        errorNotify(err.response.data.err);

        setLoading(false);
      });

    if (!response || !response.data) return;

    refetchLogistics();

    successNotify(translate("toast.citiesDeleted"));

    setLoading(false);
  };

  const selectCountry = (country) => {
    setChoosedCountry(country);
    const newCities = country.cities.map((city) => ({
      countryId: country?._id,
      id: city?._id,
      town: city?.name,
      deliverTime: `${city?.estimated_delivery} Days`,
      chargeCost: "$" + city?.shipping,
    }));
    setAllLogistics(newCities);
  };

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box className={cls.logisticsTable}>
      {loading && <Loader />}

      <Box className={cls.logistics__table_options}>
        <Box className={cls.logistics__table_countries}>
          <Link to={`/logistics/edit-country?country=${choosedCountry?._id}`}>
            <i className="fa-light fa-pen-to-square"></i>
          </Link>

          <Selectbox
            placeholder="Country"
            list={countriesList}
            displayProp="country"
            value={choosedCountry?.country || ""}
            select={selectCountry}
          />
        </Box>

        <Box className={`${cls.search} ${cls[i18n.language]}`}>
          <i className="fa-light fa-magnifying-glass"></i>

          <input
            type="search"
            placeholder={translate("logistics.search")}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </Box>
      </Box>

      {selectedFlatRows.length >= 1 && (
        <Box className={cls.deleteSelected}>
          {translate("product.select")} {selectedFlatRows.length}{" "}
          {translate("product.result")}{" "}
          <span onClick={checkDeleteMultipleCities}>
            {translate("product.remove")}
          </span>
        </Box>
      )}

      <Box className={cls.logisticsTable__table}>
        {!logisticsLoading ? (
          <>
            {allLogistics.length && !logisticsLoading && rows.length >= 1 ? (
              <Table {...getTableProps()}>
                <TableHead>
                  {headerGroups.map((headerGroup, idx) => (
                    <TableRow key={idx} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, idx) => (
                        <TableCell
                          key={idx}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                          }}
                        >
                          <span>
                            {column.render("header")}

                            {!column.disableSortBy && (
                              <>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <i className="fa-light fa-sort-down"></i>
                                  ) : (
                                    <i className="fa-light fa-sort-up"></i>
                                  )
                                ) : (
                                  <i className="fa-light fa-sort"></i>
                                )}
                              </>
                            )}
                          </span>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>

                <TableBody {...getTableBodyProps()}>
                  {page.map((row, idx) => {
                    prepareRow(row);

                    return (
                      <TableRow key={idx} {...row.getRowProps()}>
                        {row.cells.map((cell, idx) => (
                          <TableCell
                            key={idx}
                            {...cell.getCellProps()}
                            style={{
                              minWidth: cell.column.minWidth,
                              width: cell.column.width,
                            }}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Box className="empty">
                <img src="/assets/imgs/logistics/global.png" alt="no logistics" />
                <p>{translate("logistics.noLogistics")}</p>
              </Box>
            )}
          </>
        ) : (
          <LargeSquare />
        )}
      </Box>

      <Box className={cls.logisticsTable__footer}>
        <Typography>
          {translate("product.show")} {pageIndex + 1} {translate("product.of")}{" "}
          {pageOptions.length} {translate("product.results")}
        </Typography>

        <Box className={`${cls.pagination} pagination`}>
          <Pagination
            count={pageCount}
            page={pageNumber}
            onChange={changePage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LogisticsTable;
