import { useNavigate, useParams } from "react-router-dom";

import { useSelector } from "react-redux";

import LargeSquare from "./../UIs/skeletons/LargeSquare/LargeSquare";
import SmallSquare from "./../UIs/skeletons/SmallSquare/SmallSquare";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import ReturnedItem from "./ReturnedItem/ReturnedItem";

import { useTranslation } from "react-i18next";

import { format } from "date-fns";
import CurrencyFormat from "react-currency-format";

import { useQuery } from "react-query";
import axios from "../../utils/axios";

import cls from "./returnDetails.module.scss";

const ReturnDetails = () => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation("common");
  const navigate = useNavigate();
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const { returnId } = useParams();

  // COMPONENT HANDLERS
  const fetchReturnDetails = async () => {
    const response = await axios.get(`/return/${returnId}?pu=1&po=1&pi=1`);

    if (!response || !response.data) return;

    return response.data.success;
  };

  const { data: returnData, isFetching: returnLoading } = useQuery(
    "fetchReturn",
    fetchReturnDetails,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (returnLoading) {
    return (
      <>
        <Box className={cls.returnDetails__user__loading}>
          <div>
            <SmallSquare />
          </div>
          <div>
            <SmallSquare />
          </div>
          <div>
            <SmallSquare />
          </div>
          <div>
            <SmallSquare />
          </div>
          <div>
            <SmallSquare />
          </div>
        </Box>
        <LargeSquare />
      </>
    );
  }

  return (
    <Box className={cls.returnDetails}>
      <Box className={cls.returnDetails__user}>
        <Box className="section">
          <img
            src={
              returnData.user.profile_picture || "/assets/imgs/user/user.jpg"
            }
            alt="userImage"
          />

          <span className={cls.name}>{returnData.user.username}</span>
        </Box>

        <Box className="section">
          <span>
            <p>{translate("returnDetails.placed")}: </p>
            {format(
              new Date(returnData.order.createdAt).getTime(),
              "EE MMMM, yyyy"
            )}
          </span>
        </Box>

        <Box className="section">
          <span>
            <p>{translate("returnDetails.returnDate")}: </p>
            {format(new Date(returnData.createdAt).getTime(), "EE MMMM, yyyy")}
          </span>
        </Box>

        <Box className="section">
          <span>
            <p>{translate("returnDetails.status")}: </p>
            {returnData.status}
          </span>
        </Box>

        <Box className="section">
          <span>
            <p>
              {translate("returnDetails.total")} ({websiteData.currency}):{" "}
            </p>
            <CurrencyFormat
              value={returnData.items
                .reduce((total, item) => (total += item.amount * item.qty), 0)
                .toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              renderText={(value) => <span>{value}</span>}
            />
          </span>
        </Box>
      </Box>

      <ReturnedItem returnData={returnData} />
    </Box>
  );
};

export default ReturnDetails;
