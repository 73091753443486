import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import { format } from "date-fns";
import CurrencyFormat from "react-currency-format";

import cls from "./orderedItem.module.scss";

const OrderedItem = (order) => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation("common");
  const navigate = useNavigate();

  return (
    <Box className={cls.orderedItem}>
      <Box className={cls.head}>
        <Box className={cls.head__part}>
          <Box className={cls.side}>
            <Box>
              <Typography className={cls.top}>
                {translate("userProfile.track")}
              </Typography>
              <Typography>{order.order.tracking_number}</Typography>
            </Box>
          </Box>

          <Box className={cls.side}>
            <Box>
              <Typography className={cls.top}>
                {translate("userProfile.placed")}
              </Typography>
              <Typography>
                {format(
                  new Date(order.order.createdAt).getTime(),
                  "dd/mm/yyyy"
                )}
              </Typography>
            </Box>
          </Box>

          <Box className={cls.side}>
            <Box>
              <Typography className={cls.top}>
                {translate("userProfile.totalPrice")}
              </Typography>

              <CurrencyFormat
                value={
                  order &&
                  order.order.items &&
                  order.order.items.reduce(
                    (total, item) => (total += item.price),
                    0
                  ).toFixed(2)
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value) => <div>{value}</div>}
              />
            </Box>
          </Box>

          <Box className={cls.side}>
            <Box>
              <Typography className={cls.top}>
                {translate("userProfile.orderStatus")}
              </Typography>
              <Typography>{order.order.status}</Typography>
            </Box>
          </Box>
        </Box>

        <Box className={`${cls.head__part} ${cls.includeView}`}>
          <Box className={cls.side}>
            <Box>
              <Typography className={cls.top}>
                {translate("userProfile.id")}
              </Typography>
              <Typography>
                {order.order._id.substring(0, 10) + "..."}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={cls.body}>
        {order &&
          order.order.items &&
          order.order.items.map((item) => (
            <Box className={cls.body__order} key={item._id}>
              <Box className={cls.body__order_img}>
                <img
                  className={cls[i18n.language]}
                  src={item?.item?.images[0]}
                  alt="orderImage"
                  loading="lazy"
                />
                <Box className={cls.body__order_info}>
                  <h6 onClick={() => navigate(`/products/${item.item._id}`)}>
                    {item?.item?.name?.length > 80
                      ? item?.item?.name.substring(0, 80) + "..."
                      : item?.item?.name}
                  </h6>

                  <Typography>
                    <span>
                      <b>{translate("userProfile.quantity")}: </b> {item.qty}
                    </span>
                    -
                    <span>
                      <b>{translate("userProfile.price")}: </b>
                      <CurrencyFormat
                        value={item.price.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </span>
                    -
                    <span>
                      <b>{translate("userProfile.totalPri")}: </b>
                      <CurrencyFormat
                        value={(item.price * item.qty).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value) => <span>{value}</span>}
                      />
                    </span>
                  </Typography>
                  <Typography>
                    {item.variant && (
                      <Typography
                        component="span"
                        className={`${cls.item_variant} ${cls[i18n.language]}`}
                      >
                        {translate("userProfile.variant")}: {item.variant}
                      </Typography>
                    )}
                    {item.color && (
                      <>
                        {item.variant ? " - " : null}
                        <Box component="span">
                          {translate("userProfile.color")}:{" "}
                          <span
                            style={{
                              backgroundColor: item.color,
                              width: "40px",
                              height: "13px",
                              borderRadius: "3px",
                              display: "inline-block",
                            }}
                            className={`${cls.item_color}`}
                          ></span>
                        </Box>
                      </>
                    )}
                  </Typography>

                  <button
                    onClick={() => navigate(`/products/${item.item._id}`)}
                  >
                    {translate("userProfile.details")}
                  </button>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default OrderedItem;
