import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CreateReturn from '../../modals/CreateReturn/CreateReturn';
import Loader from '../../UIs/Loading/Loading';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import axios from '../../../utils/axios';

import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import cls from './chatHead.module.scss';

const ChatHead = ({ user, ticketDetails }) => {
  // COMPONENT HOOKS
  const [showCreateReturn, setShowCreateReturn] = useState(false)
  const { t: translate, i18n } = useTranslation('common');
  const navigate = useNavigate()

  // COMPONENT HANDLERS
  const closeTicket = async () => {
    const updatedData = {
      status: 'Closed',
      type: ticketDetails.type,
      chat: ticketDetails.chat
    }

    const response = await axios.patch(`/ticket/${ticketDetails?._id}`, updatedData).catch(err => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    successNotify(translate('support.ticketClosed'));

    navigate('/customer-support')
  }

  const { mutate: closeUserTicket, isFetching: closingTicket } = useMutation('closeTicket', closeTicket);

  const successNotify = (message) => toast.success(message)
  const errorNotify = (message) => toast.error(message)

  return (
    <Box className={cls.chatHead}>

      {closingTicket && <Loader />}
      
      <Box className={cls.chatHead__user}>

        <img src={user?.profile_picture || '/assets/imgs/user/user.jpg'} alt="userImage" />

        <Box>
          <Typography>
            {user?.username}
          </Typography>
          <span>
            {user?.type}
          </span>
        </Box>

      </Box>

      <div className={cls.btns}>

        <button onClick={() => setShowCreateReturn(true)}><i className="fa-light fa-rotate-left"></i> {translate('createReturn.create')}</button>
        
        <button onClick={closeUserTicket}><i className="fa-light fa-octagon-xmark"></i> {translate('chat.close')}</button>

      </div>

      {/* CREATE RETURN MODAL */}
      {showCreateReturn && <CreateReturn setShowCreateReturn={setShowCreateReturn} order={ticketDetails.order} ticketId={ticketDetails._id} />}

    </Box>
  )
}

export default ChatHead