import { useState } from 'react';

import { useNavigate } from 'react-router';
import Cookies from 'universal-cookie';

import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../store/slices/userSlice';

import { InputField } from '../../components';
import ButtonLoader from './../../components/UIs/ButtonLoader/ButtonLoader';

import axios from "../../utils/axios";

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { Formik, Form } from 'formik';
import { loginSchema } from '../../components/Auth/validation/validations';

import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';

import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import cls from './auth.module.scss';

const Login = () => {
  // COMPONENT HOOKS 
  const websiteData = useSelector(({ websiteData }) => websiteData.websiteData);
  const navigate = useNavigate()
  const { t } = useTranslation('auth')
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const cookies = new Cookies(null, { path: '/' });

  // COMPONENT HANDLERS
  const login = async (values) => {
    setLoading(true)
    const user = await axios.post('/auth/login', { ...values, client: 'dashboard' }).catch(err => {
      errorNotify(err.response.data.err)
      setLoading(false)
    });

    if (!user || !user.data) return;

    window.localStorage.setItem('markety-dashboard-session', user.data.success?.token);
    cookies.set('markety-dashboard-session', user.data.success?.token, { path: '/', expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000) });

    setTimeout(() => {
      location.href = '/';
    }, 100);

    // dispatch(userActions.setUser(user.data.success));

    setLoading(false);
  }

  const authProvider = async (response, type) => {
    setLoading(true)

    const data = {
      externalId: response.googleId,
      externalType: type,
      externalToken: response.tokenId
    }

    const user = await axios.post('/auth/provider', { ...data, client: 'dashboard' }).catch(err => errorNotify(err.response.data.err));

    if (!user || !user.data) return;

    window.localStorage.setItem('markety-dashboard-session', user.data.success?.token);
    cookies.set('markety-dashboard-session', user.data.success?.token, { path: '/', expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000) });

    setTimeout(() => {
      location.href = '/';
    }, 100);

    dispatch(userActions.setUser(user.data.success));
    setLoading(false)
  }

  const errorNotify = (message) => toast.error(message)

  return (
    <Box className={cls.auth}>
      <Grid container className={cls.auth__authBox}>

        <Grid item xs={12} md={12} lg={6} className={cls.auth__authBox_fields}>

          <Box className={cls.fieldsWrapper}>
            <Typography component="h4" variant='h4'>{t('auth.signin')}</Typography>
            <Typography>{t('auth.signToContinue')}</Typography>

            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              validationSchema={loginSchema}
              onSubmit={(values) => login(values)}
            >

              <Form>

                <label>Email: (admin@admin.com)</label>
                <InputField type="email" name="email" placeholder={t('auth.email')} />
                <label>Password: (123465)</label>
                <InputField type="password" name="password" placeholder={t('auth.password')} />

                <Box className={cls.actions}>
                  <Typography>{t('auth.cantAccess')} <span onClick={() => navigate('/forget')}>{t('auth.forgetHint')}</span></Typography>

                  <ButtonLoader loading={loading} type="submit">
                    {t('auth.signin')}
                  </ButtonLoader>
                </Box>

              </Form>

            </Formik>

            <GoogleLogin
              // eslint-disable-next-line no-undef
              clientId={process.env.REACT_APP_G_CLIENT_ID}
              render={renderProps => (
                // <button>This is my custom Google button</button>
                <Box onClick={renderProps.onClick} disabled={renderProps.disabled} className={cls.externalAuth}>
                  <img src="assets/imgs/auth/G_logo.svg" alt="google" />
                  <Typography>{t('auth.googleSign')}</Typography>
                </Box>
              )}
              buttonText="Login"
              onSuccess={(response) => authProvider(response, 'google')}
              // onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
            />

            <FacebookLogin
              // eslint-disable-next-line no-undef
              appId={process.env.REACT_APP_F_APP_ID}
              autoLoad={false}
              // callback={authProvider}
              render={renderProps => (
                <Box onClick={renderProps.onClick} className={cls.externalAuth}>
                  <img src="assets/imgs/auth/F_logo.svg" alt="google" />
                  <Typography>{t('auth.facebookSign')}</Typography>
                </Box>
              )}
            />

          </Box>

        </Grid>

        <Grid item md={6} className={cls.auth__authBox_info}>
          <Box className={cls.logo}>
            <img src={websiteData?.logo} alt={websiteData?.website} />
            <Typography component="h4" variant='h4'>{websiteData?.website}</Typography>
          </Box>
          <Box className={cls.info}>
            <Typography component="h5" variant='h5'>{t('auth.welcomeTitle')}</Typography>
            <Typography>{t('auth.welcomeText')}</Typography>
            <button>{t('auth.goMarkety')}</button>
          </Box>
          <Box className={cls.footer}>
            {t('auth.footerMade')} <i className="fa-solid fa-heart"></i> {t('auth.footerDevelopers')}
          </Box>
        </Grid>

      </Grid>
    </Box>
  )
}

export default Login;