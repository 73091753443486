import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../../store/slices/userSlice";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";

import axios from "../../../utils/axios";
import { useMutation } from "react-query";

import { toast } from "react-toastify";

import cls from "./profileSettings.module.scss";

const ProfileSettings = ({
  user,
  croppedImage,
  setCroppedImage,
  setOpenImageCropper,
  setLoading,
}) => {
  // COMPONENT HOOKS
  const [fields, setFields] = useState({
    username: user.username,
    email: user.email,
  });
  const { t: translate, i18n } = useTranslation("common");
  const profilePicRef = useRef();
  const dispatch = useDispatch();

  // COMPONENT HANDLERS
  const handleFields = (e) => {
    setFields((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const openChoosePhoto = () => {
    profilePicRef.current.click();
  };

  const chooseProfileImage = (e) => {
    setOpenImageCropper(true);
    setCroppedImage(URL.createObjectURL(e.target.files[0]));
    profilePicRef.current.value = "";
  };

  const saveUserSettings = async () => {
    setLoading(true);
    const response = await axios
      .patch(`/user/${user._id}`, fields)
      .catch((err) => {
        setLoading(false);
        errorNotify(err.response.data.err);
      });

    if (typeof croppedImage === "object") {
      let image = new FormData();
      image.append("profile-picture", croppedImage);

      const imgResponse = await axios
        .patch(`/user/${user._id}/profile-picture`, image)
        .catch((err) => {
          setLoading(false);
          errorNotify(err.response.data.err);
        });

      if (!imgResponse || !imgResponse.data) return;
    }

    if (!response || !response.data) return;

    dispatch(userActions.setUser({ ...user, ...response.data.success }));

    setLoading(false);

    successNotify(translate("toast.userUpdated"));
  };

  const { mutate: updateUserData, isLoading: updateDataLoading } = useMutation(
    "updateUserData",
    saveUserSettings
  );

  const errorNotify = (message) => toast.error(message);
  const successNotify = (message) => toast.success(message);

  return (
    <Box className={cls.profileSettings}>
      <Box
        className={cls.profileSettings__information}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Box className={cls.profileSettings__user}>
          <img
            src={
              typeof croppedImage === "object"
                ? URL.createObjectURL(croppedImage)
                : croppedImage
            }
            alt="userImage"
          />

          <Box className={cls.profileSettings__user_info}>
            <Typography variant="h6" component="h6">
              {user.username}
            </Typography>

            <Typography>
              {translate("profileSettings.role")}: {user.role}
            </Typography>

            <button onClick={openChoosePhoto}>
              {translate("profileSettings.avatar")}
            </button>

            <input
              type="file"
              ref={profilePicRef}
              hidden
              onChange={chooseProfileImage}
            />
          </Box>
        </Box>

        <Typography variant="h6" component="h6">
          {translate("profileSettings.general")}
        </Typography>

        <Box className={cls.profileSettings__information_fields}>
          <Box className={cls.field}>
            <Box>
              <label>{translate("profileSettings.username")}</label>

              <input
                type="text"
                placeholder={translate("profileSettings.username")}
                name="username"
                value={fields.username}
                onChange={(e) => handleFields(e)}
              />
            </Box>
          </Box>

          <Box className={cls.field}>
            <Box>
              <label>{translate("profileSettings.email")}</label>

              <input
                type="text"
                placeholder={translate("profileSettings.email")}
                name="email"
                value={fields.email}
                onChange={(e) => handleFields(e)}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={cls.btn}>
        <button onClick={updateUserData}>
          {translate("profileSettings.save")}
        </button>
      </Box>
    </Box>
  );
};

export default ProfileSettings;
