import { useTranslation } from "react-i18next";

import cls from "./inputField.module.scss";

const InputField = ({
  type,
  placeholder,
  name,
  id,
  list,
  value,
  setFunc,
  disabled,
  error,
  customError,
  errorMessage,
  min,
  max,
}) => {
  // COMPONENT HOOKS
  const { t: translate } = useTranslation("common");

  return (
    <div className={cls.inputField}>
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        id={id}
        list={list}
        value={value}
        onChange={setFunc}
        className={error ? cls.error : ""}
        disabled={disabled}
        min={min}
        max={max}
      />

      {error && (
        <p>
          <i className="fa-light fa-circle-exclamation"></i>{" "}
          {translate("error.emptyFields")}
        </p>
      )}

      {customError && (
        <p>
          <i className="fa-light fa-circle-exclamation"></i>{" "}
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default InputField;
