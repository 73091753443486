import { useState, useMemo } from "react";
import { useNavigate } from "react-router";

import { useSelector } from "react-redux";

import { ChooseList } from "../../../components";
import LargeSquare from "./../../UIs/skeletons/LargeSquare/LargeSquare";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";

import { useTable, usePagination } from "react-table";

import { useTranslation } from "react-i18next";

import axios from "../../../utils/axios";
import { useQuery } from "react-query";

import CurrencyFormat from "react-currency-format";

import cls from "./ordersTable.module.scss";

const OrdersTable = () => {
  // COMPONENT HOOKS
  const [recentOrdersData, setRecentOrdersData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [dateFrom, setDateFrom] = useState(Date.now() - 24 * 60 * 60 * 1000);
  const [dateTo, setDateTo] = useState(Date.now());
  const [filter, setFilter] = useState("today");
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const { t: translate, i18n } = useTranslation("common");
  const navigate = useNavigate();

  const fetchRecentOrders = async () => {
    const response = await axios.get(
      `/order?pu=1&sort=-createdAt&limit=4&skip=${
        (pageNumber - 1) * 4
      }&createdAt=${dateFrom}~${dateTo}`
    );

    if (!response || !response.data.success) return;

    const recentOrders = response.data.success.map((order) => ({
      orderId: order._id,
      customer: [
        {
          img: order.user?.profile_picture,
          name: order.user?.username,
        },
      ],
      shippingStatus: order.shipping.status,
      status: order.status,
      payment: order.payment.type,
      amount: order.items.reduce(
        (total, item) => (total += item.price * item.qty),
        0
      ),
    }));

    setTotalCount(response.data.count);

    return recentOrders;
  };

  const { data, isFetching, error } = useQuery(
    ["recentOrders", recentOrdersData, dateFrom, dateTo, pageNumber],
    fetchRecentOrders,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setRecentOrdersData(data);
      },
    }
  );

  const changeOrdersFilter = (from, to, filterWord) => {
    setDateFrom(from);
    setDateTo(to);
    setFilter(filterWord);
  };

  const filterOptions = [
    {
      item: `${translate("analytics.today")}`,
      method: () =>
        changeOrdersFilter(
          Date.now() - 24 * 60 * 60 * 1000,
          Date.now(),
          "today"
        ),
      active: filter === "today",
    },
    {
      item: `${translate("analytics.yesterday")}`,
      method: () =>
        changeOrdersFilter(
          Date.now() - 2 * 24 * 60 * 60 * 1000,
          Date.now() - 24 * 60 * 60 * 1000,
          "yesterday"
        ),
      active: filter === "yesterday",
    },
    {
      item: `${translate("analytics.lastWeek")}`,
      method: () =>
        changeOrdersFilter(
          Date.now() - 7 * 24 * 60 * 60 * 1000,
          Date.now(),
          "lastWeek"
        ),
      active: filter === "lastWeek",
    },
    {
      item: `${translate("analytics.lastMonth")}`,
      method: () =>
        changeOrdersFilter(
          Date.now() - 30 * 24 * 60 * 60 * 1000,
          Date.now(),
          "lastMonth"
        ),
      active: filter === "lastMonth",
    },
  ];

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      // {
      //   header: `${translate('analytics.orderId')}`,
      //   accessor: 'orderId',
      //   Cell: ({ value }) => (
      //     <span>{value.substring(0, 10) + '...'}</span>
      //   )
      // },
      {
        header: `${translate("analytics.customer")}`,
        id: "customer",
        accessor: (data) =>
          data.customer.map((item) => (
            <div key={item}>
              {item.img ? (
                <img src={item.img} alt="customerImg" loading="lazy" />
              ) : (
                <img
                  src="/assets/imgs/user/user.jpg"
                  alt="customerImg"
                  loading="lazy"
                />
              )}
              <span>{item.name}</span>
            </div>
          )),
        minWidth: "25%",
        width: "25%",
      },
      {
        header: `${translate("analytics.shipping")}`,
        id: "shippingStatus",
        accessor: (data) => (
          <span className={cls[data.shippingStatus]}>
            {data.shippingStatus}
          </span>
        ),
        minWidth: "25%",
        width: "25%",
      },
      {
        header: `${translate("analytics.status")}`,
        id: "status",
        accessor: (data) => (
          <span
            className={`${cls[data.status]} ${
              data.status === "Ready To Shipping" ? cls.Ready : ""
            } ${data.status === "On The Way" ? cls.onWay : ""}`}
          >
            {data.status}
          </span>
        ),
      },
      {
        header: `${translate("analytics.payment")}`,
        accessor: "payment",
      },
      {
        header: `${translate("analytics.amount")} (${websiteData.currency})`,
        id: "amount",
        accessor: (data) => (
          <span>
            <CurrencyFormat
              value={data.amount.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              renderText={(value) => <div>{(+value).toFixed(2)}</div>}
            />
          </span>
        ),
      },
    ],
    [i18n.language]
  );

  const tableInstance = useTable(
    { columns, data: recentOrdersData, initialState: { pageSize: 5 } },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    state,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = tableInstance;

  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
    gotoPage(pageNum - 1);
  };

  return (
    <>
      {isFetching ? (
        <Box className={cls.ordersTable2}>
          <LargeSquare />
        </Box>
      ) : (
        <Box
          className={`${cls.ordersTable} section`}
          sx={{ bgcolor: "background.secondary" }}
        >
          <Box className={cls.ordersTable_head}>
            <Typography>{translate("analytics.recent")}</Typography>
            <ChooseList
              list={filterOptions}
              text={translate("analytics.filter")}
            />
          </Box>

          {recentOrdersData.length ? (
            <>
              <Table {...getTableProps()}>
                <TableHead>
                  {headerGroups.map((headerGroup, idx) => (
                    <TableRow key={idx} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, idx) => (
                        <TableCell
                          key={idx}
                          {...column.getHeaderProps}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                          }}
                        >
                          {column.render("header")}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>

                <TableBody {...getTableBodyProps}>
                  {page.map((row, idx) => {
                    prepareRow(row);
                    return (
                      <TableRow
                        key={idx}
                        {...row.getRowProps}
                        onClick={() =>
                          navigate(`/orders/${row.original.orderId}`)
                        }
                      >
                        {row.cells.map((cell, idx) => (
                          <TableCell
                            key={idx}
                            {...cell.getCellProps}
                            style={{
                              minWidth: cell.minWidth,
                              width: cell.width,
                            }}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

              <Box className={cls.ordersTable__footer}>
                <Typography>
                  {translate("analytics.show")} {pageNumber}{" "}
                  {translate("analytics.of")} {Math.ceil(totalCount / 4)}{" "}
                  {translate("analytics.results")}
                </Typography>
                <Box className={`${cls.pagination} pagination`}>
                  <Pagination
                    count={Math.ceil(totalCount / 4)}
                    page={pageNumber}
                    onChange={changePage}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Box className="empty">
              <img src="/assets/imgs/common/empty.png" alt="empty orders" />
              <p>{translate("analytics.noOrders")}</p>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default OrdersTable;
