import { useState, useMemo } from "react";
import { useNavigate } from "react-router";

import ChooseList from "../UIs/ChooseList/ChooseList";
import TableCheckBox from "../UIs/TableCheckBox/TableCheckBox";
import LargeSquare from "../UIs/skeletons/LargeSquare/LargeSquare";
import Loader from "../UIs/Loading/Loading";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";

import { format } from "date-fns";

import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { useQuery } from "react-query";
import axios from "../../utils/axios";

import { useTranslation } from "react-i18next";

import cls from "./customersList.module.scss";

const CustomersList = () => {
  // COMPONENT HOOKS
  const [pageNumber, setPageNumber] = useState(1);
  const [customersList, setCustomersList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(10);
  const [loading, setLoading] = useState(false);
  const { t: translate, i18n } = useTranslation("common");
  const navigate = useNavigate();

  const actionList = (id, status) => [
    {
      item: `${translate("category.view")}`,
      icon: <i className="fa-light fa-eye"></i>,
      method: () => navigate(`/customers/${id}`),
    },
    {
      item: `${
        status === "alive"
          ? translate("customers.block")
          : translate("customers.alive")
      }`,
      icon:
        status === "alive" ? (
          <i className="fa-light fa-ban"></i>
        ) : (
          <i className="fa-light fa-bolt-lightning"></i>
        ),
      method: () => checkChangeUserStatus(id, status),
    },
    {
      item: `${translate("category.delete")}`,
      icon: <i className="fa-light fa-trash-can"></i>,
      method: () => checkDeleteCustomer(id),
    },
  ];

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        header: `${translate("customers.customer")}`,
        id: "customer",
        accessor: "customer.name",
        Cell: ({ value, row }) => (
          <Box className={cls.customer}>
            {row.original.customer.img ? (
              <img
                src={row.original.customer.img}
                alt="productImage"
                loading="lazy"
              />
            ) : (
              <img
                src="/assets/imgs/user/user.jpg"
                alt="productImage"
                loading="lazy"
              />
            )}

            <Box className={cls.info}>
              <p>{value}</p>
            </Box>
          </Box>
        ),
        minWidth: "15%",
        width: "15%",
      },
      {
        header: `${translate("customers.email")}`,
        accessor: "email",
        minWidth: "15%",
        width: "15%",
      },
      {
        header: `${translate("customers.phone")}`,
        accessor: "phone",
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("customers.role")}`,
        accessor: "role",
        Cell: ({ value }) => <span>{value}</span>,
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("customers.auth")}`,
        accessor: "auth_method",
        Cell: ({ value }) => <span>{value}</span>,
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("customers.date")}`,
        accessor: "date",
        Cell: ({ value }) => <span>{value}</span>,
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("customers.status")}`,
        id: "status",
        accessor: "status",
        Cell: ({ value }) => (
          <span className={`${cls.status} ${cls[value]}`}>{value}</span>
        ),
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("customers.action")}`,
        id: "action",
        Cell: ({ row }) => (
          <ChooseList
            list={actionList(row.original.id, row.original.status)}
            text={<i className="fa-thin fa-ellipsis-stroke"></i>}
            withArrow={false}
          />
        ),
        disableSortBy: true,
        minWidth: "5%",
        width: "5%",
      },
    ],
    [i18n.language]
  );

  const tableInstatce = useTable(
    {
      columns,
      data: customersList || [],
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <TableCheckBox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <TableCheckBox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableSortBy: true,
          minWidth: "2%",
          width: "2%",
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    pageCount,
    pageOptions,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, selectedRowIds },
  } = tableInstatce;

  // COMPONENT HANDLERS
  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
    gotoPage(pageNum - 1);
  };

  const fetchCustomersList = async () => {
    const response = await axios
      .get(`/user?limit=${limit}&skip=${(pageNumber - 1) * limit}`)
      .catch((err) => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    setTotalCount(response.data.success.totalCount);

    return response.data.success.users;
  };

  const {
    data: allCustomers,
    isFetching: customersListLoading,
    refetch: refetchCustomers,
  } = useQuery(["customersList", pageNumber], fetchCustomersList, {
    refetchOnWindowFocus: false,
    onSuccess: (allCustomers) => {
      const updatedCustomers = allCustomers.map((customer) => {
        let authMethod = [];
        for (const [key, value] of Object.entries(customer.externalAuth)) {
          if (value.linked) {
            authMethod.push(key);
          }
        }
        return {
          id: customer._id,
          customer: {
            img: customer.profile_picture,
            name: customer.username,
          },
          email: customer.email,
          phone: customer.phone ? customer.phone : "-",
          role: customer.role,
          auth_method:
            Array.isArray(authMethod) && authMethod.length
              ? authMethod.toString().split(",")
              : "email",
          date: `${format(
            new Date(customer.createdAt).getTime(),
            "dd/MM/yyyy"
          )}`,
          status: customer.accountStatus,
        };
      });

      setCustomersList([...updatedCustomers]);
    },
  });

  const checkDeleteCustomer = (id) => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteCustomer"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCustomer(id);
      }
    });
  };

  const deleteCustomer = async (id) => {
    const response = await axios.delete(`/user/${id}`).catch((err) => {
      errorNotify(err.response.data.err);
    });

    if (!response || !response.data) return;

    refetchCustomers();

    successNotify(translate("toast.customerDeleted"));
  };

  const checkDeleteMultipleCustomers = () => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteCustomers"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMultipleCustomers();
      }
    });
  };

  const deleteMultipleCustomers = async () => {
    const ids = selectedFlatRows.map((id) => id.original.id);
    const response = await axios
      .delete(`/user`, { data: { IDs: ids } })
      .catch((err) => {
        errorNotify(err.response.data.err);
      });

    if (!response || !response.data) return;

    refetchCustomers();

    successNotify(translate("toast.customersDeleted"));
  };

  const checkChangeUserStatus = (id, status) => {
    Swal.fire({
      title: translate("toast.sure"),
      text:
        status === "alive"
          ? translate("toast.blockUser")
          : translate("toast.setAlive"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        status === "alive"
          ? translate("toast.block")
          : translate("toast.alive"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        changeUserStatus(id, status);
      }
    });
  };

  const changeUserStatus = async (userId, status) => {
    let realStatus = "";
    if (status === "alive") realStatus = "banned";
    else realStatus = "alive";

    setLoading(true);

    const response = await axios
      .patch(`/user/${userId}/status`, { status: realStatus })
      .catch((err) => {
        errorNotify(err.response.data.err);

        setLoading(false);
      });

    if (!response || !response.data) return;

    refetchCustomers();

    successNotify(translate("toast.status"));

    setLoading(false);
  };

  const gotToCustomerDetails = (e, id) => {
    if (
      e.target.tagName !== "INPUT" &&
      e.target.tagName !== "BUTTON" &&
      e.target.tagName !== "LI" &&
      e.target.tagName !== "SPAN"
    ) {
      navigate(`/customers/${id}`);
    }
  };

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box className={cls.customersTable}>
      {loading && <Loader />}
      <Box className={`${cls.search} ${cls[i18n.language]}`}>
        <i className="fa-light fa-magnifying-glass"></i>

        <input
          type="search"
          placeholder={translate("customers.search")}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
      </Box>

      <Box className={cls.customersTable__table}>
        {customersListLoading ? (
          <LargeSquare />
        ) : (
          <>
            {customersList.length && rows.length >= 1 ? (
              <>
                <Box className={cls.customers__table_options}>
                </Box>

                {selectedFlatRows.length >= 1 && (
                  <Box className={cls.deleteSelected}>
                    {translate("product.select")} {selectedFlatRows.length}{" "}
                    {translate("product.result")}{" "}
                    <span onClick={checkDeleteMultipleCustomers}>
                      {translate("product.remove")}
                    </span>
                  </Box>
                )}

                <Table {...getTableProps()}>
                  <TableHead>
                    {headerGroups.map((headerGroup, idx) => (
                      <TableRow
                        key={idx}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column, idx) => (
                          <TableCell
                            key={idx}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={{
                              minWidth: column.minWidth,
                              width: column.width,
                            }}
                          >
                            <span>
                              {column.render("header")}

                              {!column.disableSortBy && (
                                <>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <i className="fa-light fa-sort-down"></i>
                                    ) : (
                                      <i className="fa-light fa-sort-up"></i>
                                    )
                                  ) : (
                                    <i className="fa-light fa-sort"></i>
                                  )}
                                </>
                              )}
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>

                  <TableBody {...getTableBodyProps()}>
                    {page.map((row, idx) => {
                      prepareRow(row);

                      return (
                        <TableRow
                          key={idx}
                          {...row.getRowProps()}
                          onClick={(e) =>
                            gotToCustomerDetails(e, row.original.id)
                          }
                        >
                          {row.cells.map((cell, idx) => (
                            <TableCell
                              key={idx}
                              {...cell.getCellProps()}
                              style={{
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                              }}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </>
            ) : (
              <Box className="empty">
                <img src="/assets/imgs/user/customers.png" alt="no customers" />
                <p>{translate("customers.empty")}</p>
              </Box>
            )}
          </>
        )}
      </Box>

      <Box className={cls.customersTable__footer}>
        <Typography>
          {translate("product.show")} {pageNumber} {translate("product.of")}{" "}
          {Math.ceil(totalCount / limit)} {translate("product.results")}
        </Typography>

        <Box className={`${cls.pagination} pagination`}>
          <Pagination
            count={Math.ceil(totalCount / limit)}
            page={pageNumber}
            onChange={changePage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CustomersList;
