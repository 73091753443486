import { useState } from "react";

import ReturnedItem from "./ReturnedItem/ReturnedItem";
import SmallTopAnalytics from "./../../TopAnalytics/SmallTopAnalytics/SmallTopAnalytics";
import SmallSquare from "../../UIs/skeletons/SmallSquare/SmallSquare";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";

import axios from "../../../utils/axios";
import { useQuery } from "react-query";

import { DateRange } from "react-date-range";

import dayjs from "dayjs";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import cls from "./returnsTab.module.scss";

const RetirnsTab = ({ customerId }) => {
  // COMPONENT HOOKS
  const [pageNumber, setPageNumber] = useState(1);
  const [limit] = useState(10);
  const [returnsCount, setReturnsCount] = useState(0);
  const { t: translate, i18n } = useTranslation("common");
  const [date, setDate] = useState([
    {
      startDate: dayjs().startOf("year").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
  };

  const handleDateChange = (item) => {
    setDate([item.selection]);
  };

  const handleDateFocusChange = (focused) => {
    if (!focused[0] && !focused[1]) setShowDatePicker(false);
  };

  const analytics = [
    {
      id: 1,
      label: `${translate("topAnalytics.allOrders")}`,
      var: "-",
      link: `${translate("topAnalytics.createOrder")}`,
      icon: <i className="fa-light fa-bag-shopping"></i>,
    },
    {
      id: 2,
      label: `${translate("topAnalytics.totalEarnings")}`,
      var: "-",
      link: `${translate("topAnalytics.viewEarnings")}`,
      icon: <i className="fa-regular fa-dollar-sign"></i>,
    },
    {
      id: 3,
      label: `${translate("topAnalytics.paypal")}`,
      var: "-",
      link: `${translate("topAnalytics.viewDetails")}`,
      icon: <i className="fa-brands fa-paypal"></i>,
    },
    {
      id: 4,
      label: `${translate("topAnalytics.delivery")}`,
      var: "-",
      link: `${translate("topAnalytics.viewDetails")}`,
      icon: <i className="fa-light fa-truck"></i>,
    },
  ];

  const fetchReturnsAnalytics = async () => {
    const response = await axios
      .get(`/order/analytics?user=${customerId}`);

    if (!response || !response.data) return;

    return response.data.success;
  };

  const { data: returnsAnalytics, isFetching: returnsAnalyticsLoading } =
    useQuery("returnsAnalytics", fetchReturnsAnalytics, {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      select: (returnsAnalytics) => {
        analytics[0].var = returnsAnalytics.totalOrders;
        analytics[1].var = returnsAnalytics.totalEarnings.toFixed(2);
        analytics[2].var = returnsAnalytics.totalOrdersByPayPal;
        analytics[3].var = returnsAnalytics.totalOrdersByDelivery;
      },
    });

  const fetchReturns = async () => {
    const response = await axios
      .get(
        `/return?po=1&pi=1&p_items_select=name,images&user=${customerId}&limit=${limit}&skip=${
          (pageNumber - 1) * limit
        }`
      )
      .catch((err) => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    setReturnsCount(response.data.success.totalCount);

    return response.data.success.returns;
  };

  const { data: allReturns, isFetching: returnsLoading } = useQuery(
    ["fetchReturns", pageNumber],
    fetchReturns,
    {
      refetchOnWindowFocus: false
    }
  );

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box className={cls.returnsTab} sx={{ bgcolor: "background.secondary" }}>
      <Box className={cls.welcome}>
        <Box className={`${cls.welcome__date} ${cls[i18n.language]}`}>
          <input
            type="text"
            readOnly
            value={
              dayjs(date[0].startDate).format("YYYY-MM-DD") +
              `  ${translate("analytics.to")}  ` +
              dayjs(date[0].endDate || new Date()).format("YYYY-MM-DD")
            }
            onClick={() => setShowDatePicker((prev) => !prev)}
          />
          <i
            onClick={() => setShowDatePicker((prev) => !prev)}
            className="fa-light fa-calendar-day"
          ></i>

          {showDatePicker && (
            <DateRange
              editableDateInputs={false}
              onChange={handleDateChange}
              onRangeFocusChange={handleDateFocusChange}
              ranges={date}
              retainEndDateOnFirstSelection={false}
              showDateDisplay={false}
            />
          )}
        </Box>
      </Box>

      {returnsAnalyticsLoading ? (
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item xs={12} md={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} md={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} md={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} md={6} xl={3}>
            <SmallSquare />
          </Grid>
        </Grid>
      ) : (
        <>
        
          {allReturns.length >= 1 && 
          
            <SmallTopAnalytics analytics={analytics} />

          }
        
        </>
      )}

      {(returnsLoading || returnsAnalyticsLoading) ? (
        <>
          <div style={{ marginBottom: "15px" }}>
            <SmallSquare />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <SmallSquare />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <SmallSquare />
          </div>
        </>
      ) : (
        <>
          {allReturns?.length >= 1 ? (
            <>
              {allReturns?.map((singleReturn) => (
                <ReturnedItem
                  key={singleReturn._id}
                  singleReturn={singleReturn}
                />
              ))}

              <Box className={cls.paginationWrapper}>
                <Typography>
                  {translate("product.show")} {pageNumber}{" "}
                  {translate("product.of")}{" "}
                  {Math.ceil(returnsCount / limit)}{" "}
                  {translate("product.results")}
                </Typography>

                <Box className={`${cls.pagination} pagination`}>
                  <Pagination
                    count={Math.ceil(returnsCount / limit)}
                    page={pageNumber}
                    onChange={changePage}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Box className="empty">
              <img src="/assets/imgs/returns/returns.png" alt="Image" />
              <p>{translate("userProfile.emptyReturns")}</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default RetirnsTab;
