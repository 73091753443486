import { useState } from 'react';

import { AllCategoriesTable, TopAnalytics } from '../../../components';
import SmallSquare from '../../../components/UIs/skeletons/SmallSquare/SmallSquare';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { useQuery } from 'react-query'

import axios from '../../../utils/axios';

import { useTranslation } from 'react-i18next';

import cls from './allCategories.module.scss';

const Categories = () => {
  // COMPONENTN HOOKS
  const { t: translate, i18n } = useTranslation('common');
  const allAnalytics = [
    {
      id: 1,
      label: `${translate('topAnalytics.categories')}`,
      var: '-',
      link: `${translate('topAnalytics.create')}`,
      icon: <i className="fa-light fa-server"></i>
    },
    {
      id: 2,
      label: `${translate('topAnalytics.subCategories')}`,
      var: '-',
      link: `${translate('topAnalytics.ourSub')}`,
      icon: <i className="fa-light fa-server"></i>
    },
    {
      id: 3,
      label: `${translate('topAnalytics.mostCategories')}`,
      var: '-',
      link: `${translate('topAnalytics.mostCategory')}`,
      icon: <i className="fa-light fa-bag-shopping"></i>
    },
    {
      id: 4,
      label: `${translate('topAnalytics.popularCats')}`,
      var: '-',
      link: `${translate('topAnalytics.mostPopular')}`,
      icon: <i className="fa-light fa-server"></i>
    }
  ]

  const fetchCateogriesAnalytics = async () => {
    const response = await axios.get('/category/analytics');

    if(!response && !response.data.success) return;

    return response.data.success;
  }

  const { data: categoriesAnalytics, isFetching, isError } = useQuery(['categoriesAnalytics'], fetchCateogriesAnalytics, {
    refetchOnWindowFocus: false,
    select: (categoriesAnalytics) => {
        allAnalytics[0].var = categoriesAnalytics.totalCategroies;
        allAnalytics[1].var = categoriesAnalytics.totalSubCatgories;
        allAnalytics[2].var = categoriesAnalytics.mostPopularCatgory.name || '-';
        allAnalytics[3].var = categoriesAnalytics.mostProductsCatgory.name || '-';
        return allAnalytics;
    }
  });

  return (
    <Box className={cls.allCategories}>

      <Box className={cls.allCategories__head}>

        <Typography variant='h6' component='h6'>{translate('categories')}</Typography>

        <Typography><span>{translate('categories.sm')}</span> &gt; {translate('categories.all')}</Typography>

      </Box>

      {/* TOP ANALYTICS SECTION */}
      {!isFetching ? 

        <TopAnalytics analytics={allAnalytics} />

        :

        <Grid container spacing={2} style={{ marginBottom: '20px' }}>

          <Grid item xs={12} md={6} lg={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} md={6} lg={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} md={6} lg={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} md={6} lg={6} xl={3}>
            <SmallSquare />
          </Grid>

        </Grid>
    
      }

      <Grid container spacing={3}>

        <Grid item xs={12}>

          <AllCategoriesTable />

        </Grid>

      </Grid>

    </Box>
  )
}

export default Categories;