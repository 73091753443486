import { useSelector } from "react-redux";

import { useTranslation } from 'react-i18next';

import cls from './Loader.module.scss';

const Loader = () => {
  const websiteData = useSelector(({ websiteData }) => websiteData.websiteData);
  const { i18n } = useTranslation('common')

  return (
    <div className={cls.wrapper}>
    <img src="/assets/imgs/navbar/logo2.png" alt="logo" />
    {i18n.language === 'en' ? 
      <h4>Mar <span>K</span> ety</h4>
      :
      <h4>سو <span className={cls.arabicLang}>قـــ</span>ــى </h4>
    }
      <div className={cls.lds_ellipsis}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loader