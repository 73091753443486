import { useRef } from 'react';

import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { ChatHead, ChatBody, ChatBottom } from '../../components';

import Box from '@mui/material/Box';

import { useQuery } from 'react-query';

import axios from '../../utils/axios';

import socket from '../../utils/socket';

import cls from './chat.module.scss';

const Chat = () => {
  const { user } = useSelector(({user}) => user)
  const { ticketId } = useParams()
  const chatContainer = useRef();

  const scrollToBottom = () => {
    chatContainer.current.scrollTo({
      top: chatContainer.current.scrollHeight,
      behavior: 'smooth',
    })
  }

  const fetchChatDetails = async () => {
    const response = await axios.get(`/chat?ticket=${ticketId}&pu=1`);

    if (!response || !response.data) return;

    const chat = response.data.success.Chats[0];

    socket.emit('user-connected', user._id);

    socket.emit('join-user-chat', chat._id);

    return chat;
  }

  const { data: chatDetails, isFetching: chatLoading } = useQuery('chatDetails', fetchChatDetails, {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setTimeout(() => {
        scrollToBottom()
      }, 1000)
    }
  })

  const fetchTicketData = async () => {
    const response = await axios.get(`/ticket/${ticketId}?po=1&poi=1`);

    if (!response || !response.data) return;

    return response.data.success;
  }

  const { data: ticketDetails } = useQuery('fetchTicketData', fetchTicketData);

  return (
    <Box className={cls.chat}>

      <Box className={cls.chat__chatHeader} sx={{ bgcolor: 'background.secondary' }}>
        <ChatHead user={chatDetails?.user} ticketDetails={ticketDetails} />
      </Box>

      <Box className={cls.chat__chatBody} ref={chatContainer}>
        <ChatBody messages={chatDetails?.messages} chatId={chatDetails?._id} user={user} chatLoading={chatLoading} />
      </Box>

      <Box className={cls.chat__chatBottom} sx={{ bgcolor: 'background.secondary' }}>
        <ChatBottom user={user} chatId={chatDetails?._id} scrollToBottom={scrollToBottom} />
      </Box>

    </Box>
  )
}

export default Chat;