import Stripe from "../../UIs/skeletons/Stripe/Stripe";
import Circular from "../../UIs/skeletons/Circular/Circular";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import cls from "./userPreview.module.scss";

const UserPreview = ({ currentTab, setCurrentTab, userData }) => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation("common");

  const list = [
    {
      link: "Personal Info",
      item: `${translate("userProfile.personal")}`,
    },
    {
      link: "Orders",
      item: `${translate("userProfile.orders")}`,
    },
    {
      link: "Returns",
      item: `${translate("userProfile.returns")}`,
    },
    {
      link: "Addresses",
      item: `${translate("userProfile.addresses")}`,
    },
    {
      link: "Tickets",
      item: `${translate("userProfile.tickets")}`,
    },
    {
      link: "Logs",
      item: `${translate("userProfile.logs")}`,
    },
  ];

  return (
    <Box className={cls.userPreview} sx={{ bgcolor: "background.secondary" }}>
      {userData && userData.username ? (
        <>
          <Box className={cls.userPreview__userPart}>
            {userData?.profile_picture ? (
              <img src={userData?.profile_picture} alt="userImage" />
            ) : (
              <img src="/assets/imgs/user/user.jpg" alt="userImage" />
            )}

            <Box className={cls.userPreview__userPart_userInfo}>
              <Typography variant="h6" component="h6">
                {userData?.username}
              </Typography>

              <Typography>{userData?.email}</Typography>
            </Box>
          </Box>

          <Box className={cls.userPreview__transactions}>
            <Typography>{translate("userProfile.transactions")}</Typography>

            <span>
              {userData?.orders.length === 0 ? (
                <>{translate("userProfile.noOrders")}</>
              ) : (
                <>
                  {userData?.orders.length >= 2 ? (
                    <>
                      {userData?.orders.length}{" "}
                      {translate("userProfile.totalOrders")}
                    </>
                  ) : (
                    <>
                      {userData?.orders.length}{" "}
                      {translate("userProfile.totalOrder")}
                    </>
                  )}
                </>
              )}
            </span>
          </Box>

          <Box className={cls.userPreview__links}>
            <ul>
              {list.map((item, idx) => (
                <li
                  key={idx}
                  onClick={() => setCurrentTab(item.link)}
                  className={currentTab === item.link ? cls.selected : ""}
                >
                  <span>{item.item}</span>

                  <i
                    className={`${
                      cls[i18n.language]
                    } fa-regular fa-chevron-right`}
                  ></i>
                </li>
              ))}
            </ul>
          </Box>
        </>
      ) : (
        <>
          <div className={cls.topSkeleton}>
            <Circular />
            <div className={cls.stripe}>
              <Stripe />
            </div>
          </div>
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
        </>
      )}
    </Box>
  );
};

export default UserPreview;
