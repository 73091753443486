import { useState } from 'react';

import { CustomerReturnsTab, ContactTab, ComplaintTab } from '../../components';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import cls from './customerSupport.module.scss';

const CustomerSupport = () => {
  // COMPONENT HOOKS
  const [currentTab, setCurrentTab] = useState('Returns');
  const { t: translate, i18n } = useTranslation('common');

  const tabs = [
    {
      label: translate('support.returns'),
      link: 'Returns',
    },
    {
      label: translate('support.contact'),
      link: 'Contact',
    },
    {
      label: translate('support.complaint'),
      link: 'Complaint',
    }
  ]

  const renderView= () => {

    switch(currentTab) {

      case 'Returns': {
        return <CustomerReturnsTab />
      }

      case 'Contact': {
        return <ContactTab />
      }

      case 'Complaint': {
        return <ComplaintTab />
      }

    }

  }

  return (
    <Box className={cls.customerSupport}>

      <Box className={cls.customerSupport__head}>

        <Typography variant='h6' component='h6'>{translate('support.title')}</Typography>

        <Typography><span>{translate('support.dashboard')}</span> &gt; {translate('support.support')}</Typography>

      </Box>

      <Box className={cls.customerSupport__tabs}>

        {tabs.map((tab, idx) => (

          <Typography key={idx} className={currentTab === tab.link ? cls.selected : ''} onClick={() => setCurrentTab(tab.link)}>
            {tab.label}
          </Typography>

        ))}

      </Box>

      <Box className={cls.customerSupport__currentTab} sx={{ bgcolor: 'background.secondary' }}>

        {renderView()}

      </Box>

    </Box>
  )
}

export default CustomerSupport