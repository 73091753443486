import { useState, useMemo } from "react";

import ChooseList from "../../UIs/ChooseList/ChooseList";
import EditAddress from '../../modals/EditAddress/EditAddress';
import TableCheckBox from "../../UIs/TableCheckBox/TableCheckBox";
import LargeSquare from "./../../UIs/skeletons/LargeSquare/LargeSquare";
import Loader from '../../UIs/Loading/Loading';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";

import { useQuery, useMutation } from "react-query";
import axios from "../../../utils/axios";

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";

import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { useTranslation } from "react-i18next";

import cls from "./addressesTab.module.scss";

const AddressesTab = ({ customerId }) => {
  // COMPONENT HOOKS
  const [pageNumber, setPageNumber] = useState(1);
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState({})
  const { t: translate, i18n } = useTranslation("common");

  const actionList = (address) => [
    {
      item: `${translate("category.edit")}`,
      icon: <i className="fa-light fa-pen-to-square"></i>,
      method: () => {
        setAddressToEdit(address);
        setOpenEditAddress(true);
      },
    },
    {
      item: `${translate("category.delete")}`,
      icon: <i className="fa-light fa-trash-can"></i>,
      method: () => { 
        setAddressToEdit(address);
        checkDeleteAddress();
       },
    },
  ];

  const fetchAddressesList = async () => {
    const response = await axios.get(`/user/${customerId}?select=addresses`);

    if (!response || !response.data) return;

    return response.data.success.addresses;
  };

  const { data: addressesList, isFetching: addressesLoading, refetch: refetchAddresses } = useQuery(
    "addressesList",
    fetchAddressesList,
    {
      refetchOnWindowFocus: false
    }
  );

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        header: `${translate("userProfile.addressTitle")}`,
        accessor: "street",
        minWidth: "15%",
        width: "15%",
      },
      {
        header: `${translate("userProfile.type")}`,
        id: "type",
        accessor: "type",
        minWidth: "5%",
        width: "5%",
      },
      {
        header: `${translate("userProfile.addressCountry")}`,
        id: "country",
        accessor: "country",
        minWidth: "5%",
        width: "5%",
      },
      {
        header: `${translate("userProfile.city")}`,
        id: "city",
        accessor: "city",
        minWidth: "5%",
        width: "5%",
      },
      {
        header: `${translate("userProfile.postal")}`,
        id: "zipCode",
        accessor: "zipCode",
        Cell: ({ value }) => <span>{value}</span>,
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("userProfile.action")}`,
        id: "action",
        Cell: ({row}) => (
          <ChooseList
            list={actionList(row.original)}
            text={<i className="fa-thin fa-ellipsis-stroke"></i>}
            withArrow={false}
          />
        ),
        disableSortBy: true,
        minWidth: "2%",
        width: "2%",
      },
    ],
    [i18n.language]
  );

  const tableInstatce = useTable(
    {
      columns,
      data: addressesList || [],
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <TableCheckBox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <TableCheckBox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableSortBy: true,
          minWidth: "2%",
          width: "2%",
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    pageCount,
    pageOptions,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, selectedRowIds },
  } = tableInstatce;

  // COMPONENT HANDLERS
  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
    gotoPage(pageNum - 1);
  };

  const checkDeleteAddress = () => {
    Swal.fire({
      title: translate('toast.sure'),
      text: translate('toast.sureDeleteAddress'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: translate('toast.deleteAddress'),
      cancelButtonText: translate('toast.cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUserAddress();
      }
    })
  }

  const deleteAddress = async () => {
    const response = await axios.delete(`/user/${customerId}/address`, { data: { id: addressToEdit._id } }).catch(err => {
      errorNotify(err.response.data.err);
    });

    if (!response || !response.data) return;

    refetchAddresses()

    successNotify(translate('toast.addressDeleted'));
  }

  const { mutate: deleteUserAddress, isLoading } = useMutation('deleteAddress', deleteAddress);

  const checkDeleteMultiple = () => {
    Swal.fire({
      title: translate('toast.sure'),
      text: translate('toast.sureDeleteAddresses'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: translate('toast.delete'),
      cancelButtonText: translate('toast.cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMultipleCustomerAddresses();
      }
    })
  }

  const deleteMultipleAddresses = async () => {
    const ids = selectedFlatRows.map(id => id.original._id);

    const response = await axios.delete(`/user/${customerId}/addresses`, {data: {addresses: ids}}).catch(err => errorNotify(err.response.data.err));

    if(!response && !response.data.success) return;

    successNotify(translate('toast.addressesDeleted'));

    refetchAddresses();
  }

  const { mutate: deleteMultipleCustomerAddresses, isLoading: deletingMultipleAddressesLoading } = useMutation('deleteMultipleTickets', deleteMultipleAddresses);

  const successNotify = (msg) => toast.success(`${msg}`);
  const errorNotify = (msg) => toast.error(`${msg}`);

  return (
    <Box className={cls.userAddresses}>

      {(isLoading || deletingMultipleAddressesLoading) && <Loader />}

      {selectedFlatRows.length >= 1 && 

        <Box className={cls.deleteSelected}>
          {translate('product.select')} {selectedFlatRows.length} {translate('product.result')} <span onClick={checkDeleteMultiple}>{translate('product.remove')}</span>
        </Box>

      }

      {addressesLoading ? (
        <>
          <LargeSquare />
        </>
      ) : (
        <>
          {addressesList.length >= 1 ? (
            <>
              <Box className={cls.logistics__table_options}>
                <Box className={`${cls.search} ${cls[i18n.language]}`}>
                  <i className="fa-light fa-magnifying-glass"></i>

                  <input
                    type="search"
                    placeholder={translate("userProfile.search")}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                  />
                </Box>
              </Box>

              <Box className={cls.userAddresses__table}>
                <Table {...getTableProps()}>
                  <TableHead>
                    {headerGroups.map((headerGroup, idx) => (
                      <TableRow
                        key={idx}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column, idx) => (
                          <TableCell
                            key={idx}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={{
                              minWidth: column.minWidth,
                              width: column.width,
                            }}
                          >
                            <span>
                              {column.render("header")}

                              {!column.disableSortBy && (
                                <>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <i className="fa-light fa-sort-down"></i>
                                    ) : (
                                      <i className="fa-light fa-sort-up"></i>
                                    )
                                  ) : (
                                    <i className="fa-light fa-sort"></i>
                                  )}
                                </>
                              )}
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>

                  <TableBody {...getTableBodyProps()}>
                    {page.map((row, idx) => {
                      prepareRow(row);

                      return (
                        <TableRow key={idx} {...row.getRowProps()}>
                          {row.cells.map((cell, idx) => (
                            <TableCell
                              key={idx}
                              {...cell.getCellProps()}
                              style={{
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                              }}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>

              <Box className={cls.userAddresses__footer}>
                <Typography>
                  {translate("product.show")} {pageIndex + 1}{" "}
                  {translate("product.of")} {pageOptions.length}{" "}
                  {translate("product.results")}
                </Typography>

                <Box className={`${cls.pagination} pagination`}>
                  <Pagination
                    count={pageCount}
                    page={pageNumber}
                    onChange={changePage}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Box className="empty">
              <img src="/assets/imgs/addresses/address.png" alt="no addresses" />
              <p>{translate("userProfile.emptyAddresses")}</p>
            </Box>
          )}
        </>
      )}

      {openEditAddress && 
        <EditAddress 
          setOpenEditAddress={setOpenEditAddress} 
          addressDataToEdit={addressToEdit} 
          refetchAddresses={refetchAddresses}
        />}
    </Box>
  );
};

export default AddressesTab;
