import { useState } from "react";

import OrderedItem from "./OrderedItem/OrderedItem";
import SmallTopAnalytics from "../../TopAnalytics/SmallTopAnalytics/SmallTopAnalytics";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";

import axios from "../../../utils/axios";
import { useQuery } from "react-query";

import { DateRange } from "react-date-range";

import dayjs from "dayjs";

import { format } from "date-fns";

import { useTranslation } from "react-i18next";

import cls from "./ordersTab.module.scss";
import SmallSquare from "./../../UIs/skeletons/SmallSquare/SmallSquare";

const OrdersTab = ({ customerId }) => {
  // COMPONENT HOOKS
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(3);
  const [ordersCount, setOrdersCount] = useState(0);
  const { t: translate, i18n } = useTranslation("common");
  const [date, setDate] = useState([
    {
      startDate: dayjs().startOf("year").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
  };

  const handleDateChange = (item) => {
    setDate([item.selection]);
  };

  const handleDateFocusChange = (focused) => {
    if (!focused[0] && !focused[1]) setShowDatePicker(false);
  };

  const analytics = [
    {
      id: 1,
      label: `${translate("topAnalytics.allOrders")}`,
      var: "-",
      link: `${translate("topAnalytics.createOrder")}`,
      icon: <i className="fa-light fa-bag-shopping"></i>,
    },
    {
      id: 2,
      label: `${translate("topAnalytics.totalEarnings")}`,
      var: "-",
      link: `${translate("topAnalytics.viewEarnings")}`,
      icon: <i className="fa-regular fa-dollar-sign"></i>,
    },
    {
      id: 3,
      label: `${translate("topAnalytics.paypal")}`,
      var: "-",
      link: `${translate("topAnalytics.viewDetails")}`,
      icon: <i className="fa-brands fa-paypal"></i>,
    },
    {
      id: 4,
      label: `${translate("topAnalytics.delivery")}`,
      var: "-",
      link: `${translate("topAnalytics.viewDetails")}`,
      icon: <i className="fa-light fa-truck"></i>,
    },
    {
      id: 5,
      label: `${translate("topAnalytics.processing")}`,
      var: "-",
      link: `${translate("topAnalytics.viewDetails")}`,
      icon: <i className="fa-light fa-stopwatch"></i>,
    },
    {
      id: 6,
      label: `${translate("topAnalytics.completed")}`,
      var: "-",
      link: `${translate("topAnalytics.viewDetails")}`,
      icon: <i className="fa-light fa-badge-check"></i>,
    },
    {
      id: 7,
      label: `${translate("topAnalytics.refuneded")}`,
      var: "-",
      link: `${translate("topAnalytics.viewDetails")}`,
      icon: <i className="fa-regular fa-dollar-sign"></i>,
    },
    {
      id: 8,
      label: `${translate("topAnalytics.cancelled")}`,
      var: "-",
      link: `${translate("topAnalytics.createOrder")}`,
      icon: <i className="fa-light fa-xmark"></i>,
    },
  ];

  const fetchOrdersAnalytics = async () => {
    const response = await axios
      .get(`/order/analytics?user=${customerId}`);

    if (!response || !response.data) return;

    return response.data.success;
  };

  const { data: ordersAnalytics, isFetching: ordersAnalyticsLoading } = useQuery(
    "ordersAnalytics",
    fetchOrdersAnalytics,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      select: (ordersAnalytics) => {
        analytics[0].var = ordersAnalytics.totalOrders;
        analytics[1].var = ordersAnalytics.totalEarnings.toFixed(2);
        analytics[2].var = ordersAnalytics.totalOrdersByPayPal;
        analytics[3].var = ordersAnalytics.totalOrdersByDelivery;
        analytics[4].var = ordersAnalytics.totalOrdersProcessing;
        analytics[5].var = ordersAnalytics.totalOrdersCompleted;
        analytics[6].var = ordersAnalytics.totalOrdersRefunded;
        analytics[7].var = ordersAnalytics.totalOrdersCancelled;
      },
    }
  );

  const fetchOrders = async () => {
    const response = await axios.get(
      `/order?limit=${limit}&skip=${
        (pageNumber - 1) * limit
      }&user=${customerId}&pi=1&&createdAt=${new Date(
        date[0].startDate
      ).getTime()}~${new Date(date[0].endDate).getTime()}`
    );

    if (!response || !response.data) return;

    setOrdersCount(response.data.count);

    return response.data.success;
  };

  const { data: allOrders, isFetching: ordersLoading } = useQuery(
    ["fetchOrders", pageNumber, date],
    fetchOrders,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  return (
    <Box className={cls.ordersTab} sx={{ bgcolor: "background.secondary" }}>
      <Box className={cls.welcome}>
        <Box className={`${cls.welcome__date} ${cls[i18n.language]}`}>
          <input
            type="text"
            readOnly
            value={
              dayjs(date[0].startDate).format("YYYY-MM-DD") +
              `  ${translate("analytics.to")}  ` +
              dayjs(date[0].endDate || new Date()).format("YYYY-MM-DD")
            }
            onClick={() => setShowDatePicker((prev) => !prev)}
          />
          <i
            onClick={() => setShowDatePicker((prev) => !prev)}
            className="fa-light fa-calendar-day"
          ></i>

          {showDatePicker && (
            <DateRange
              editableDateInputs={false}
              onChange={handleDateChange}
              onRangeFocusChange={handleDateFocusChange}
              ranges={date}
              retainEndDateOnFirstSelection={false}
              showDateDisplay={false}
            />
          )}
        </Box>
      </Box>

      {ordersLoading || ordersAnalyticsLoading ? (
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item xs={12} md={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} md={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} md={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} md={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} md={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} md={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} md={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} md={6} xl={3}>
            <SmallSquare />
          </Grid>
        </Grid>
      ) : (
        <>
          {allOrders.length >= 1 && (
            <Box className={cls.ordersTab__analyticsPart}>
              <SmallTopAnalytics analytics={analytics} />
            </Box>
          )}
        </>
      )}

      {ordersLoading ? (
        <>
          <div style={{ marginBottom: "15px" }}>
            <SmallSquare />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <SmallSquare />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <SmallSquare />
          </div>
        </>
      ) : (
        <>
          {allOrders.length >= 1 ? (
            <>
              {allOrders &&
                allOrders.map((order) => (
                  <OrderedItem key={order._id} order={order} />
                ))}

              <Box className={cls.paginationWrapper}>
                <Typography>
                  {translate("product.show")} {pageNumber}{" "}
                  {translate("product.of")} {Math.ceil(ordersCount / limit)}{" "}
                  {translate("product.results")}
                </Typography>

                <Box className={`${cls.pagination} pagination`}>
                  <Pagination
                    count={Math.ceil(ordersCount / limit)}
                    page={pageNumber}
                    onChange={changePage}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Box className="empty">
              <img src="/assets/imgs/orders/orders.png" alt="Image" />
              <p>{translate("userProfile.emptyOrders")}</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default OrdersTab;
