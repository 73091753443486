import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { AddCountryComponent } from './../../../components';

import cls from './addCountry.module.scss';

const AddCountry = () => {
  // COMPONENT HOOKS 
  const { t: translate, i18n } = useTranslation('common')

  return (
    <Box className={cls.addCountry}>

      <Box className={cls.addCountry__head}>

        <Typography variant='h6' component='h6'>{translate('addCountry.title')}</Typography>

        <Typography><span>{translate('addCountry.logistics')}</span> &gt; {translate('addCountry.addCountry')}</Typography>

      </Box>

      <AddCountryComponent />

    </Box>
  )
}

export default AddCountry