import { useState } from "react";
import { useParams } from "react-router";

import {
  PersonalInfoTab,
  UserPreview,
  OrdersTab,
  ReturnsTab,
  AddressesTab,
  ChatTickets,
  LogsTab,
} from "../../components";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import axios from "../../utils/axios";

import { useQuery } from "react-query";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

import cls from "./userProfile.module.scss";

const UserProfile = () => {
  // COMPONENT HOOKS
  const [currentTab, setCurrentTab] = useState("Personal Info");
  const { t: translate, i18n } = useTranslation("common");
  const { customerId } = useParams();

  const fethcUserData = async () => {
    const response = await axios
      .get(`/user/${customerId}`)
      .catch((err) => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    return response.data.success;
  };

  const {
    data: userData,
    isFetching: userDataLoading,
    refetch: refetchUserData,
  } = useQuery("fetchUserData", fethcUserData, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const renderView = (userData) => {
    switch (currentTab) {
      case "Personal Info": {
        return (
          <PersonalInfoTab
            userData={userData}
            refetchUserData={refetchUserData}
          />
        );
      }

      case "Orders": {
        return <OrdersTab customerId={customerId} />;
      }

      case "Returns": {
        return <ReturnsTab customerId={customerId} />;
      }

      case "Addresses": {
        return <AddressesTab customerId={customerId} />;
      }

      case "Tickets": {
        return <ChatTickets customerId={customerId} />;
      }

      case "Logs": {
        return <LogsTab customerId={customerId} />;
      }
    }
  };

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box className={cls.userProfile}>
      <Box className={cls.userProfile__head}>
        <Typography variant="h6" component="h6">
          {translate("userProfile.title")}
        </Typography>

        <Typography>
          <span>{translate("userProfile.dashboard")}</span> &gt;{" "}
          {translate("userProfile.profile")}
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <UserPreview
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            userData={userData}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          {renderView(userData)}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserProfile;
