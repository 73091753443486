import { useState, useEffect, useRef, useMemo } from "react";

import UploadField from "./../../../UIs/UploadField/UploadField";
import InputFieldComponent from "../../../UIs/InputField/InputField";

import TableCheckBox from "../../../UIs/TableCheckBox/TableCheckBox";
import ChooseList from "../../../UIs/ChooseList/ChooseList";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";

import { useTranslation } from "react-i18next";

import axios from "../../../../utils/axios";

import { toast } from "react-toastify";

import Swal from "sweetalert2";

import cls from "./createSubCategory.module.scss";

const CreateSubCategory = ({
  category,
  categoryData,
  choosedSubCategoryImage,
  setChoosedSubCategoryImage,
  choosedSubCategory,
  subCategoryName,
  setSubCategoryName,
  setChoosedSubCategory,
  addNewSubCategory,
  updateSubCategory,
  subCategoriesData,
  setSubCategoriesData,
  refetchCategory,
  subEmptyFields,
  setSubEmptyFields,
}) => {
  // COMPONENT HOOKS
  // const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [choosedCategory, setChoosedCategory] = useState(null);
  const { t: translate, i18n } = useTranslation("common");

  // COMPONENT HANDLERS
  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
    gotoPage(pageNum - 1);
  };

  const selectSubCategory = (subCat) => {
    setChoosedSubCategory(subCat);
    setSubCategoryName(subCat.name);
    setChoosedSubCategoryImage(subCat.imgPreview);
  };

  const actionList = (category) => {
    return [
      {
        item: `${translate("category.edit")}`,
        icon: <i className="fa-light fa-pen-nib"></i>,
        method: () => {
          setChoosedSubCategory(category);
          setSubCategoryName(category.category.name);
          setChoosedSubCategoryImage(category.category.thumb);
        },
      },
      {
        item: `${translate("category.delete")}`,
        icon: <i className="fa-light fa-trash-can"></i>,
        method: () =>
          checkDeleteSubCategory(category.id, category.category.name),
      },
    ];
  };

  useEffect(() => {
    if (categoryData) {
      const data = categoryData?.sub_categories.map((category) => {
        return {
          id: category._id,
          category: {
            name: category.name,
            thumb: category.thumb,
          },
          productsCount: category.products.length,
        };
      });

      setSubCategoriesData(data);
    }
  }, [categoryData]);

  const handleAddNewSubCategory = () => {
    if (!category) {
      if (!subCategoryName || !setChoosedSubCategory) {
        setSubEmptyFields(true);
      } else {
        setSubEmptyFields(false);
        const subCategory = {
          id: "",
          category: {
            name: subCategoryName,
            img: choosedSubCategoryImage,
            imgPreview: URL.createObjectURL(choosedSubCategoryImage),
          },
          productsCount: 0,
        };

        setSubCategoriesData((p) => [subCategory, ...p]);
        setSubCategoryName("");
        setChoosedSubCategoryImage("");
      }
    } else {
      addNewSubCategory();
    }
  };

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        header: `${translate("createSubCat.id")}`,
        accessor: "id",
        Cell: ({ value }) => <span>{value.substring(0, 10) + "..."}</span>,
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("createSubCat.subCat")}`,
        accessor: "category.name",
        Cell: ({ value, row }) => (
          <div className={cls.category}>
            {!row.original.category.imgPreview &&
            !row.original.category.thumb ? (
              <img
                className={cls.defaultSubCat}
                src="/assets/imgs/category/default.png"
                alt="defaultCategory"
              />
            ) : (
              <img
                src={
                  row.original.category.imgPreview
                    ? row.original.category.imgPreview
                    : row.original.category.thumb
                }
                alt="catImage"
              />
            )}

            <span>{row.original.category.name}</span>
          </div>
        ),
        minWidth: "30%",
        width: "30%",
      },
      {
        header: `${translate("createSubCat.products")}`,
        accessor: "productsCount",
        minWidth: "20%",
        width: "20%",
      },
      {
        header: `${translate("createSubCat.action")}`,
        id: "action",
        Cell: ({ row }) => (
          <ChooseList
            list={actionList({
              id: row.original.id,
              category: row.original.category,
            })}
            text={<i className="fa-thin fa-ellipsis-stroke"></i>}
            withArrow={false}
          />
        ),
        disableSortBy: true,
        minWidth: "2%",
        width: "2%",
      },
    ],
    [i18n.language]
  );

  const tableInstance = useTable(
    { columns, data: subCategoriesData },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
    // (hooks) => {
    //   hooks.visibleColumns.push(columns => [
    //     {
    //       header: ({ getToggleAllRowsSelectedProps }) => (
    //         <div>
    //           <TableCheckBox {...getToggleAllRowsSelectedProps()} />
    //         </div>
    //       ),
    //       id: 'selection',
    //       Cell: ({ row }) => (
    //         <div>
    //           <TableCheckBox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //       disableSortBy: true,
    //       minWidth: '2%',
    //       width: '2%'
    //     },
    //     ...columns,
    //   ])
    // }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    pageCount,
    pageOptions,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, selectedRowIds },
  } = tableInstance;

  const switchToAdd = () => {
    setChoosedSubCategory("");
    setSubCategoryName("");
    setChoosedSubCategoryImage("");
  };

  const checkDeleteSubCategory = (id, subName) => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteSubCategory"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSubCategory(id, subName);
      }
    });
  };

  const deleteSubCategory = async (subId, subName) => {
    if (!category) {
      switchToAdd();
      return setSubCategoriesData((prev) => [
        ...prev.filter((one) => one.category.name !== subName),
      ]);
    }
    const response = await axios
      .delete(`/category/${category}/sub-category`, { data: { id: subId } })
      .catch((err) => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    successNotify(translate("toast.subCatDeleted"));

    refetchCategory();
  };

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box className={cls.createSubCategory}>
      <Typography variant="h5" component="h5">
        {translate("createSubCat.manage")}
      </Typography>

      <Typography>{translate("createSubCat.edit")}</Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} xl={5}>
          <Box
            className={cls.createSubCategory__create}
            sx={{ bgcolor: "background.secondary" }}
          >
            <InputFieldComponent
              type="text"
              name="subCategoryName"
              placeholder={translate("createSubCat.name")}
              value={subCategoryName}
              setFunc={(e) => setSubCategoryName(e.target.value)}
              error={!subCategoryName && subEmptyFields ? true : false}
            />

            <label>{translate("createSubCat.image")}</label>

            <UploadField
              attach={choosedSubCategoryImage}
              updateAttachment={setChoosedSubCategoryImage}
              error={!choosedSubCategoryImage && subEmptyFields ? true : false}
            />

            <Box className={cls.btn}>
              {choosedSubCategory ? (
                <button onClick={updateSubCategory}>
                  {translate("createSubCat.updateSub")}
                </button>
              ) : (
                <button onClick={handleAddNewSubCategory}>
                  {translate("createSubCat.add")}
                </button>
              )}
            </Box>

            {choosedSubCategory && (
              <p className={cls.addNewOne} onClick={switchToAdd}>
                {translate("createSubCat.addNewOne")}
              </p>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} xl={7}>
          {selectedFlatRows.length >= 1 && (
            <Box className={cls.deleteSelected}>
              {translate("createSubCat.select")} {selectedFlatRows.length}{" "}
              {translate("createSubCat.result")}{" "}
              <span>{translate("createSubCat.remove")}</span>
            </Box>
          )}

          <Box
            className={cls.createSubCategory__edit}
            sx={{ bgcolor: "background.secondary" }}
          >
            <Typography variant="h6" component="h6">
              {translate("createSubCat.subCat")}
            </Typography>

            {subCategoriesData.length ? (
              <>
                <Table {...getTableProps()}>
                  <TableHead>
                    {headerGroups.map((headerGroup, idx) => (
                      <TableRow
                        key={idx}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column, idx) => (
                          <TableCell
                            key={idx}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={{
                              minWidth: column.minWidth,
                              width: column.width,
                            }}
                          >
                            <span>
                              {column.render("header")}

                              {!column.disableSortBy && (
                                <>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <i className="fa-light fa-sort-down"></i>
                                    ) : (
                                      <i className="fa-light fa-sort-up"></i>
                                    )
                                  ) : (
                                    <i className="fa-light fa-sort"></i>
                                  )}
                                </>
                              )}
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>

                  <TableBody {...getTableBodyProps()}>
                    {page.map((row, idx) => {
                      prepareRow(row);

                      return (
                        <TableRow key={idx} {...row.getRowProps()}>
                          {row.cells.map((cell, idx) => (
                            <TableCell
                              key={idx}
                              {...cell.getCellProps()}
                              style={{
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                              }}
                              onClick={() =>
                                selectSubCategory(cell.row.original.category)
                              }
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>

                <Box className={cls.footer}>
                  <Typography>
                    {translate("product.show")} {pageIndex + 1}{" "}
                    {translate("product.of")} {pageOptions.length}{" "}
                    {translate("product.results")}
                  </Typography>

                  <Box className={`${cls.pagination} pagination`}>
                    <Pagination
                      count={pageCount}
                      page={pageNumber}
                      onChange={changePage}
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <Box className="empty">
                <img src="/assets/imgs/common/noProducts.png" alt="empty" />

                <p>{translate("createSubCat.noSubCats")}</p>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateSubCategory;
