import { useState } from 'react';

import SupportAccordion from '../SupportAccordion/SupportAccordion';
import Selectbox from './../../UIs/SelectBox/Selectbox';
import Stripe from './../../UIs/skeletons/Stripe/Stripe';

import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';

import { DateRange } from 'react-date-range';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import axios from '../../../utils/axios';

import cls from './complaintTab.module.scss';

const ComplaintTab = () => {
  // COMPONENT HOOKS
  const [status, setStatus] = useState('')
  const [date, setDate] = useState([{
    startDate: dayjs().startOf('month').toDate(),
    endDate: new Date(),
    key: 'selection'
  }]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0)
  const [filterString, setFilterString] = useState({})
  const { t: translate, i18n } = useTranslation('common')
  
  // COMPONENT HANDLERS
  const handleDateChange = (item) => {
    setDate([item.selection]);
    
    setFilterString(prev => (
      {
        ...prev, 
        createdAt: `${new Date(item.selection.startDate).getTime()}~${new Date(item.selection.endDate).getTime()}`
      }
    ))
  };

  const handleDateFocusChange = (focused) => {
    if (!focused[0] && !focused[1]) setShowDatePicker(false);
  };

  const changePage = (e, pageNum) => {
    setPageNumber(pageNum)
  }

  const statusList = [
    {
      name: `${translate('support.unhandled')}`,
      query: 'Unhandled'
    },
    {
      name: `${translate('support.opened')}`,
      query: 'Opened'
    },
    {
      name: `${translate('support.closed')}`,
      query: 'Closed'
    },
  ]

  const fetchComplaintTickets = async () => {

    let filtering = ''

    Object.keys(filterString).forEach(key => {
      filtering += `&${key}=${filterString[key]}`
    })

    const response = await axios.get(`/ticket?pu=1&po=1&poi=1&limit=${limit}&skip=${(pageNumber - 1) * limit}&type=Complaint${filtering}`)

    if (!response || !response.data) return;

    setTotalCount(response.data.success.totalCount)

    return response.data.success.tickets;
  }

  const { data: complaintTickets, isFetching : ticketsLoading } = useQuery(['fetchTickets', pageNumber, filterString], fetchComplaintTickets, {
    cacheTime: 0,
    refetchOnWindowFocus: false
  });

  const changeStatus = (st) => {
    setFilterString(prev => (
      {
        ...prev, 
        status: st.query
      }
    ))
    setStatus(st)
  }

  const resetFilter = () => {
    setFilterString({})
    setStatus('')
  }

  return (
    <Box className={cls.contactTab} sx={{ bgcolor: 'background.secondary' }}>

      {Object.keys(filterString).length >= 1 && 
        <div className={cls.resetFilter}>
          <span onClick={resetFilter}><i className="fa-light fa-arrow-rotate-left"></i> {translate('general.resetFilter')}</span>
        </div>
      }

      <Box className={cls.contactTab__filters}>

        <Box>

          <label>{translate('support.search')}</label>

          <input type="text" placeholder={translate('support.search')} />
        </Box>

        <Box>

          <label>{translate('support.status')}</label>

          <Selectbox placeholder={translate('support.status')} list={statusList} value={status.name || ''} select={changeStatus} displayProp="name" />

        </Box>

        <Box className={cls.datePicker}>

          <label>{translate('support.date')}</label>

          <Box className={`${cls.datePicker__date} ${cls[i18n.language]}`}>

            <input
              type="text"
              readOnly
              value={dayjs(date[0].startDate).format('YYYY-MM-DD') + `  ${translate('analytics.to')}  ` + dayjs(date[0].endDate || new Date()).format('YYYY-MM-DD')} 
              onClick={() => setShowDatePicker(prev => !prev)}
            />
            <i onClick={() => setShowDatePicker(prev => !prev)} className="fa-light fa-calendar-day"></i>

            {
              showDatePicker &&
                <DateRange
                  editableDateInputs={false}
                  onChange={handleDateChange}
                  onRangeFocusChange={handleDateFocusChange}
                  ranges={date}
                  retainEndDateOnFirstSelection={false}
                  showDateDisplay={false}
                />
            }
          </Box>

        </Box>

      </Box>

      {ticketsLoading ? 

        <>
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
        </>

        :

        <>

          {complaintTickets?.length >= 1 && !ticketsLoading ?
          
            <>
                
              {complaintTickets && complaintTickets.map((ticket, idx) => (

                <SupportAccordion key={idx} data={ticket} />
 
              ))}

              <Box className={`${cls.pagination} pagination`}>

                <p>

                  {translate('product.show')} {pageNumber} {translate('product.of')} {Math.ceil(totalCount / limit)} {translate('product.results')}

                </p>

                <Pagination count={Math.ceil(totalCount / limit)} page={pageNumber} onChange={changePage}/>
                
              </Box>
            
            </>
            :
            <Box className="empty">
              <img src="/assets/imgs/support/ticket.png" alt="np tickets" />
              <p>{translate("userProfile.emptyTickets")}</p>
            </Box>
          }
        
        </>
    
      }

    </Box>
  )
}

export default ComplaintTab;