import { useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import TableCheckBox from "../../UIs/TableCheckBox/TableCheckBox";
import ChooseList from "./../../UIs/ChooseList/ChooseList";
import LargeSquare from "../../UIs/skeletons/LargeSquare/LargeSquare";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Snackbar from "@mui/material/Snackbar";

import { useQuery } from "react-query";
import axios from "../../../utils/axios";

import { toast } from "react-toastify";

import Swal from "sweetalert2";

import {
  useTable,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
} from "react-table";

import { useTranslation } from "react-i18next";

import { CopyToClipboard } from "react-copy-to-clipboard";

import cls from "./allCategoriesTable.module.scss";

const AllCategories = () => {
  // COMPONENT HOOKS
  const [allCategories, setAllCategories] = useState([]);
  const [copied, setCopied] = useState(false);
  const { t: translate, i18n } = useTranslation("common");
  const navigate = useNavigate();

  // COMPONENT HANDLERS
  const checkDelete = (id) => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteCategory"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCategory(id);
      }
    });
  };
  // DELETE CATEGORY
  const deleteCategory = async (id) => {
    const response = await axios.delete(`/category/${id}`).catch((err) => {
      errorNotify(err.response.data);
    });

    if (!response || !response.data.success) return;

    refetch();

    successNotify(translate("categories.cateDeleted"));
  };
  // CHECK DELETE MULTIPLE CATEGORIES
  const checkDeleteMultiple = (ids) => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteCategories"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMultipeCategory(ids);
      }
    });
  };
  // DELETE MULTIPLE CATEGORIES
  const deleteMultipeCategory = async (ids) => {
    const allIds = ids.map((id) => id.original.id);

    const response = await axios
      .delete("/category", { data: { IDs: allIds } })
      .catch((err) => {
        errorNotify(err.response.data.err);
      });

    if (!response || !response.data.success) return;

    refetch();

    successNotify(translate("categories.catesDeleted"));
  };

  const actionList = (id) => [
    {
      item: `${translate("category.edit")}`,
      icon: <i className="fa-light fa-pen-nib"></i>,
      method: () => navigate(`/categories/create-category?category=${id}`),
    },
    {
      item: `${translate("category.delete")}`,
      icon: <i className="fa-light fa-trash-can"></i>,
      method: () => checkDelete(id),
    },
  ];

  const fetchCategories = async () => {
    const response = await axios.get("/category");

    if (!response && !response.data.success) return;

    const allCats = response.data.success.map((cat) => ({
      id: cat._id,
      category: {
        name: cat.name,
        thumb: cat.thumb,
      },
      productsCount: 0,
      subCatsCount: cat.sub_categories.length,
    }));

    setAllCategories(allCats);

    return response.data.success;
  };

  const {
    data: categories,
    isFetching,
    isError,
    refetch,
  } = useQuery(
    "categories",
    fetchCategories,
    {
      refetchOnWindowFocus: false,
      onSuccess: (categories) => {
        const allCats = categories.map((cat) => ({
          id: cat._id,
          category: {
            name: cat.name,
            thumb: cat.thumb,
          },
          productsCount: 0,
          subCatsCount: cat.sub_categories.length,
        }));

        return allCats;
      },
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        header: `${translate("category.id")}`,
        accessor: "id",
        Cell: ({ value }) => (
          <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
            <span className="idString">{value.substring(0, 10) + "..."}</span>
          </CopyToClipboard>
        ),
        minWidth: "12%",
        width: "12%",
      },
      {
        header: `${translate("category.category")}`,
        accessor: "category.name",
        Cell: ({ row }) => (
          <div className={cls.category}>
            <img src={row.original.category.thumb} alt="category image" />
            <span>{row.original.category.name}</span>
          </div>
        ),
        minWidth: "30%",
        width: "30%",
      },
      {
        header: `${translate("category.products")}`,
        accessor: "productsCount",
        minWidth: "25%",
        width: "25%",
      },
      {
        header: `${translate("category.sub-categories")}`,
        accessor: "subCatsCount",
        minWidth: "20%",
        width: "20%",
      },
      {
        header: `${translate("category.action")}`,
        id: "action",
        Cell: ({ row }) => (
          <ChooseList
            list={actionList(row.original.id)}
            text={<i className="fa-thin fa-ellipsis-stroke"></i>}
            withArrow={false}
          />
        ),
        disableSortBy: true,
        minWidth: "2%",
        width: "2%",
      },
    ],
    [i18n.language]
  );

  const tableInstance = useTable(
    { columns, data: allCategories },
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <TableCheckBox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          id: "selection",
          Cell: ({ row }) => (
            <div>
              <TableCheckBox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableSortBy: true,
          minWidth: "2%",
          width: "2%",
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    setGlobalFilter,
    state: { selectedRowIds },
  } = tableInstance;

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <>
      {selectedFlatRows.length >= 1 && (
        <Box className={cls.deleteSelected}>
          {translate("category.select")} {selectedFlatRows.length}{" "}
          {translate("category.result")}{" "}
          <span onClick={() => checkDeleteMultiple(selectedFlatRows)}>
            {translate("category.remove")}
          </span>
        </Box>
      )}

      {!isFetching ? (
        <Grid container spacing={3}>
          <Grid item xs={12} className={cls.allCategoriesTable}>
            <Box
              className={cls.allCategoriesTable__table}
              sx={{ bgcolor: "background.secondary" }}
            >
              <Box className={cls.allCategoriesTable__table_head}>
                <Box className={cls.btn}>
                  <Link to="/categories/create-category">
                    <button>
                      <i className="fa-regular fa-plus"></i>{" "}
                      {translate("category.create")}
                    </button>
                  </Link>
                </Box>

                <Box className={cls.allProductsTable__head_search}>
                  <input
                    type="search"
                    placeholder={translate("category.search")}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                  />

                  <i className="fa-light fa-magnifying-glass"></i>
                </Box>
              </Box>

              {categories.length >= 1 && rows.length >= 1 ? (
                <Box className={cls.table}>
                  <Table {...getTableProps()}>
                    <TableHead>
                      {headerGroups.map((headerGroup, idx) => (
                        <TableRow
                          key={idx}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map((column, idx) => (
                            <TableCell
                              key={idx}
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              style={{
                                minWidth: column.minWidth,
                                width: column.width,
                              }}
                            >
                              <span>
                                {column.render("header")}

                                {!column.disableSortBy && (
                                  <>
                                    {column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <i className="fa-light fa-sort-down"></i>
                                      ) : (
                                        <i className="fa-light fa-sort-up"></i>
                                      )
                                    ) : (
                                      <i className="fa-light fa-sort"></i>
                                    )}
                                  </>
                                )}
                              </span>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableHead>

                    <TableBody {...getTableBodyProps()}>
                      {rows.map((row, idx) => {
                        prepareRow(row);

                        return (
                          <TableRow key={idx} {...row.getRowProps()}>
                            {row.cells.map((cell, idx) => (
                              <TableCell
                                key={idx}
                                {...cell.getCellProps()}
                                style={{
                                  minWidth: cell.column.minWidth,
                                  width: cell.column.width,
                                }}
                              >
                                {cell.render("Cell")}
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              ) : (
                <Box className="empty">
                  <img src="/assets/imgs/category/categories.png" alt="categoriesImage" />
                  <p>{translate("categories.empty")}</p>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <LargeSquare />
      )}
      {/* SNACKBAR COMPONENT */}
      <Snackbar
        open={copied}
        autoHideDuration={2000}
        onClose={() => setCopied(false)}
        message="Copied to Clipboard!"
      />
    </>
  );
};

export default AllCategories;
