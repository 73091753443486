import { useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import axios from "../../../utils/axios";

import { toast } from "react-toastify";

import cls from "./securitySettings.module.scss";

const SecuritySettings = ({ user, setLoading }) => {
  // COMPONENT HOOKS
  const [fields, setfields] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [confirmPass, setConfirmPass] = useState("");
  const { t: translate, i18n } = useTranslation("common");

  // COMPONENT HANDLER
  const handleFields = (e) => {
    setfields((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const updateUserSecurity = async () => {
    setLoading(true);

    if (fields.newPassword === confirmPass) {
      const response = await axios
        .patch(`/user/${user._id}/password`, fields)
        .catch((err) => {
          errorNotify(err.response.data.err);
          setLoading(false);
        });

      if (!response || !response.data) return;

      setLoading(false);

      successNotify(translate("toast.userUpdatedSecurity"));
    } else {
      errorNotify(translate("toast.userUpdatedSecurityMatch"));
      setLoading(false);
    }
  };

  const errorNotify = (message) => toast.error(message);
  const successNotify = (message) => toast.success(message);

  return (
    <Box className={cls.wrapper}>
      <Box
        className={cls.securitySettings}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Typography variant="h6" component="h6">
          {translate("securitySettings.security")}
        </Typography>

        <Box className={cls.securitySettings__fields}>
          <Box className={cls.securitySettings__fields_field}>
            <label>{translate("securitySettings.password")}</label>

            <input
              type="password"
              placeholder={translate("securitySettings.password")}
              name="currentPassword"
              value={fields.currentPassword}
              onChange={(e) => handleFields(e)}
            />
          </Box>

          <Box className={cls.securitySettings__fields_field}>
            <label>{translate("securitySettings.newPassword")}</label>

            <input
              type="password"
              placeholder={translate("securitySettings.newPassword")}
              name="newPassword"
              value={fields.newPassword}
              onChange={(e) => handleFields(e)}
            />
          </Box>

          <Box className={cls.securitySettings__fields_field}>
            <label>{translate("securitySettings.confirmPass")}</label>

            <input
              type="password"
              placeholder={translate("securitySettings.confirmPass")}
              value={confirmPass}
              onChange={(e) => setConfirmPass(e.target.value)}
            />
          </Box>
        </Box>
      </Box>

      <Box className={cls.btn}>
        <button onClick={updateUserSecurity}>
          {translate("securitySettings.save")}
        </button>
      </Box>
    </Box>
  );
};

export default SecuritySettings;
