import { Translate } from '@mui/icons-material';
import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';

import cls from './settingsNav.module.scss';

const SettingsNav = ({ currentPage, changePage }) => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation('common')

  return (
    <Box className={cls.settingsNav} sx={{ bgcolor: 'background.secondary' }}>

      <ul>

        <li className={ currentPage === 'information' ? cls.active : ''} onClick={() => changePage('information')}>
          <i className="fa-light fa-user"></i>
          <span>{translate('settingsNav.general')}</span>
        </li>

        <li className={ currentPage === 'security' ? cls.active : ''} onClick={() => changePage('security')}>
          <i className="fa-light fa-lock"></i>
          <span>{translate('settingsNav.security')}</span>
        </li>

        <li className={ currentPage === 'link' ? cls.active : ''} onClick={() => changePage('link')}>
          <i className="fa-regular fa-link"></i>
          <span>{translate('settingsNav.link')}</span>
        </li>

        <li className={ currentPage === 'deactivate' ? cls.active : ''} onClick={() => changePage('deactivate')}>
          <i className="fa-regular fa-wave-pulse"></i>
          <span>{translate('settingsNav.deactivate')}</span>
        </li>

      </ul>

    </Box>
  )
}

export default SettingsNav