import { useState, useEffect, createContext } from "react";

import lightVars from "./lightVars/lightVars";
import darkVars from "./darkVars/darkVars";

import Cookies from "universal-cookie";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const cookie = new Cookies();

export const ThemeContext = createContext(null);

const ThemeContextProvide = ({ children }) => {
  const [currentMode, setCurrentMode] = useState(
    cookie.get("marketyMode") || "light"
  );
  const [lang, setLang] = useState(cookie.get("marketyLang") || "en");

  useEffect(() => {
    if (cookie.get("marketyMode") === "light") lightVars();
    else darkVars();
  }, [currentMode]);

  let theme = createTheme({
    palette: {
      currentMode,
      ...(currentMode === "light"
        ? {
            // palette values for light mode
            primary: {
              main: "#40739e",
            },
            divider: "#40739e",
            background: {
              default: "#F8F9FA",
              secondary: "#fff",
            },
            text: {
              primary: "#40739e",
              secondary: "#40739e",
            },
          }
        : {
            // palette values for dark mode
            primary: {
              main: "#40739e",
            },
            divider: "#40739e",
            background: {
              default: "#222736",
              secondary: "#2f3446",
            },
            text: {
              primary: "#fff",
              secondary: "#40739e",
            },
          }),
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1480,
      },
    },
    typography: {
      // fontFamily: 'IBMPlexSansArabic',
      fontFamily: "IBM Plex Sans Arabic",
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      h1: {
        fontWeight: 500,
      },
      h2: {
        fontWeight: 500,
      },
      h3: {
        fontWeight: 500,
      },
      h4: {
        fontWeight: 500,
      },
      h5: {
        fontWeight: 500,
      },
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        fontWeight: 400,
      },
      subtitle2: {
        fontWeight: 400,
      },
      body1: {
        fontWeight: 400,
      },
      body2: {
        fontWeight: 400,
      },
      caption: {
        fontWeight: 400,
      },
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "0.75rem",
            padding: "7px 15px",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
          },
        },
      },
      MuiSkeleton: {
        defaultProps: {
          animation: "wave",
        },
        styleOverrides: {
          root: {
            backgroundColor:
              currentMode === "dark"
                ? "#2c3041 !important"
                : "#ededed !important", // not apply
          },
        },
      },
    },
    direction: `${lang === "en" ? "ltr" : "rtl"}`,
  });

  const switchMode = () => {
    if (cookie.get("marketyMode") === "light") {
      cookie.set("marketyMode", "dark");
      setCurrentMode("dark");
    } else {
      cookie.set("marketyMode", "light");
      setCurrentMode("light");
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, switchMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvide;
