import cls from "./ButtonLoader.module.scss";

const ButtonLoader = (props) => {
  return (
    <button
      className={`${props.loading ? cls.loading : cls.btn}`}
      onClick={props.onClick}
      type={props.type || "button"}
      disabled={props.loading}
    >
      {
        props.loading 
        ? 
        (<div className={cls.loader}></div>)
        : 
        props.children
      }
    </button>
  );
}

export default ButtonLoader;