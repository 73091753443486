import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

import { useTranslation } from 'react-i18next';

import cls from './accordion.module.scss';

const Accordion = ({ 
    type, 
    label, 
    list, 
    size, 
    withCheckbox, 
    removeFromFilters, 
    addToFilters, 
    filterUrl, 
    categoriesLoading 
}) => {
  // COMPONENT HOOKS
  const [showSub, setShowSub] = useState(filterUrl[type] ? type : '')
  const { i18n } = useTranslation()

  useEffect(() => {
    let openedLink = document.getElementById(type);
    if (!categoriesLoading) {
      setTimeout(() => {
        if (openedLink && filterUrl[type] && !openedLink.nextElementSibling.style.maxHeight) openedLink.click();
      }, 100)
    }
  }, [filterUrl[type]])

  // COMPONENT HANDLERS
  const showSubList = (e, list) => {
    if(showSub === list) {
      setShowSub('')
    }
    else {
      setShowSub(list)
    }

    let subMenu = e.target.nextElementSibling;

    if (subMenu) {
      let maxHeight = subMenu.scrollHeight;

      if (!subMenu.style.maxHeight) {
        subMenu.style.maxHeight = maxHeight + 'px';
      } else {
        subMenu.style.maxHeight = null;
      }
    }
  }

  const checkOption = (item) => {
    if(document.getElementById(item).checked) {
      addToFilters(type, item)
    } else {
      removeFromFilters(type, item)
    }
  }

  return (
    <Box className={`${cls.accordion_link} ${showSub === type ? cls.showSub : ''} ${cls[i18n.language]} ${size === 'small' ? cls.smallAccordion : ''}`}>

      <Box id={type} className={cls.link} onClick={(e) => showSubList(e, type)}>

        <Box className={cls.label}>
          {label}
        </Box>

        <i className={`${cls[i18n.language]} fa-regular fa-angle-down`}></i>

      </Box>

      <Box className={`${cls.subMenu} ${cls[i18n.language]} subMenu`}>

        {showSub === type && <Box className={cls.dropLayer} onClick={(e) => showSubList(e, '')}></Box>}

        {list.length ? 
        
          <ul>

            {list.map((item, index) => (
              <li key={item}>

                {withCheckbox ? 

                  <>
                    <Checkbox id={item} onClick={() => checkOption(item)} checked={filterUrl[type] && filterUrl[type].find(one => one === item) ? true : false} />

                    <label htmlFor={item}>{item}</label>
                  </>

                  :
                
                  <>
                    <i className="fa-regular fa-circle-small"></i>

                    <p onClick={() => addToFilters(type, item)}>{item}</p>
                  </>
                
                }

              </li>
            ))}

          </ul>

          :

          <p className={cls.emptyData}><i className="fa-light fa-circle-question"></i> No data</p>
      
        }

      </Box>

    </Box>
  )
}

export default Accordion