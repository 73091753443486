import { useState } from 'react';

import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import cls from './topAnalytics.module.scss';
// DATE RANGE PICKER PACKAGE STYLES
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const TopAnalytics = ({ analytics, special }) => {
  const { i18n } = useTranslation('common')

  return (
    <Box>
      <Box className={cls.welcome}>

      </Box>

      <Box className={`${cls.topAnalytics} ${special ? cls.special : ''}`}>

        {analytics.map(section => (

          <Box key={section.id} className={`${cls.topAnalytics__section} section`} sx={{ bgcolor: 'background.secondary' }}>
            
            <Box className={cls.topAnalytics__section_part}>

              <Typography>

                {section.label}

              </Typography>

              {section.percent &&

                <span>{section.percent}</span>
                          
              }

            </Box>

            <Box className={cls.topAnalytics__section_part}>

              <Typography className={cls.price}>
                {section.var}
              </Typography>

            </Box>

            <Box className={cls.topAnalytics__section_part}>
              <a className={cls.smallTitle}>{section.link}</a>
              <Typography className={cls.icon}>
                {section.icon}
              </Typography>
            </Box>

          </Box>

        ))}

      </Box>

    </Box>
  )
}

export default TopAnalytics;