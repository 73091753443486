import { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import LargeSquare from "../../UIs/skeletons/LargeSquare/LargeSquare";

import { useQuery } from "react-query";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import axios from "../../../utils/axios";

import CurrencyFormat from "react-currency-format";

import ProgressBar from "@ramonak/react-progress-bar";

import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { useTranslation } from "react-i18next";

import { VectorMap } from "@south-paw/react-vector-maps";
import worldMap from "./worldMap.json";

import cls from "./revenueChart.module.scss";

const RevenueChart = () => {
  // REACT STATES
  const [revenue, setRevenue] = useState("daily");
  const [dailyChartData, setDailyChartData] = useState([]);
  const [monthsChartData, setMonthsChartData] = useState([]);
  const [chartData, setChartData] = useState(null);
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const { t: translate, i18n } = useTranslation("common");

  const fetchMainChartData = async () => {
    const response = await axios.get(`/analytics/chart?type=${revenue}`);

    if (!response || !response.data.success) return;

    return response.data.success;
  };

  const handleChartData = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const days = ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"];

    const dailyChartData = days.map((day) => {
      const order = chartData.Orders.find((order) => order.date === day);
      const earning = chartData.Earnings.find((ern) => ern.date === day);
      const refunds = chartData.Refunds.find((ref) => ref.date === day);

      return {
        name: day,
        Orders: order ? order.total : 0,
        Earnings: earning ? earning.total : 0,
        Refunds: refunds ? refunds.total : 0,
      };
    });

    const monthsChartData = months.map((month) => {
      const order = chartData.Orders.find((order) => order.date === month);
      const earning = chartData.Earnings.find((ern) => ern.date === month);
      const refunds = chartData.Refunds.find((ref) => ref.date === month);

      return {
        name: month,
        Orders: order ? order.total : 0,
        Earnings: earning ? earning.total : 0,
        Refunds: refunds ? refunds.total : 0,
      };
    });

    setDailyChartData(dailyChartData);

    setMonthsChartData(monthsChartData);
  };

  const { data, isLoading, error } = useQuery(
    ["revenuChart", revenue, dailyChartData],
    fetchMainChartData,
    {
      onSuccess: (data) => {
        setChartData(data);
      },
    }
  ); // WITH CALLBACK FUNCTION

  useEffect(() => {
    if (chartData) {
      handleChartData();
    }
  }, [chartData]);

  const fetchMapLocationData = async () => {
    const response = await axios.get("/analytics/sales-locations");

    if (!response || !response.data) return;

    return response.data.success;
  };

  const { data: mapLocationdata } = useQuery(
    "mapLocationData",
    fetchMapLocationData,
    { refetchOnWindowFocus: false }
  );

  return (
    <Grid container spacing={3} className={cls.mainAnalytics}>
      <Grid item xs={12} lg={12} xl={8}>
        {isLoading ? (
          <LargeSquare />
        ) : (
          <Box
            className={`${cls.analytics__yearChart} section`}
            sx={{ bgcolor: "background.secondary" }}
          >
            <Box className={cls.revenue}>
              <Typography>{translate("analytics.revenue")}</Typography>
              <Box className={cls.revenueFilter}>
                <Box
                  className={revenue === "daily" ? cls.selected : ""}
                  onClick={() => setRevenue("daily")}
                >
                  {translate("analytics.daily")}
                </Box>
                <Box
                  className={revenue === "monthly" ? cls.selected : ""}
                  onClick={() => setRevenue("monthly")}
                >
                  {translate("analytics.monthly")}
                </Box>
              </Box>
            </Box>

            <Box className={cls.analytics__yearChart_data}>
              <Box>
                <Typography className={cls.price}>
                  {chartData && chartData.Orders.length}
                </Typography>
                <Typography>{translate("analytics.Orders")}</Typography>
              </Box>

              <Box>
                <Typography className={cls.price}>
                  {chartData && (
                    <CurrencyFormat
                      value={chartData.Earnings.reduce(
                        (total, item) => (total += item.total),
                        0
                      ).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  )}
                </Typography>
                <Typography>
                  {translate("analytics.Earning")} ({websiteData.currency})
                </Typography>
              </Box>

              <Box>
                <Typography className={cls.price}>
                  {chartData && (
                    <CurrencyFormat
                      value={chartData.Refunds.reduce(
                        (total, item) => (total += item.total),
                        0
                      ).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  )}
                </Typography>
                <Typography>
                  {translate("analytics.refunds")} ({websiteData.currency})
                </Typography>
              </Box>
            </Box>

            <ResponsiveContainer
              width="99%"
              height={350}
              className={cls.yearChart}
            >
              <ComposedChart
                width="100%"
                data={revenue === "daily" ? dailyChartData : monthsChartData}
              >
                <CartesianGrid stroke="var(--border-color)" />
                <XAxis dataKey="name" scale="band" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey={translate("general.orders")}
                  barSize={20}
                  fill="var(--main-color)"
                />
                <Line
                  type="monotone"
                  dataKey={translate("general.earnings")}
                  stroke="var(--success"
                />
                <Line
                  type="monotone"
                  dataKey={translate("general.refunds")}
                  stroke="var(--secondary-color"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        )}
      </Grid>

      <Grid item xs={12} lg={12} xl={4}>
        <Box
          className={`${cls.map} section`}
          sx={{ bgcolor: "background.secondary" }}
        >
          <VectorMap {...worldMap} />

          <Box className={cls.info}>
            {mapLocationdata?.length >= 1 ? (
              <>
                {mapLocationdata?.map((location, idx) => (
                  <Box key={idx}>
                    <Typography>{location.location}</Typography>
                    <ProgressBar
                      completed={
                        (location.orders /
                          mapLocationdata.reduce(
                            (total, item) => (total += item.orders),
                            0
                          )) *
                        100
                      }
                      bgColor="var(--main-color)"
                      baseBgColor="var(--default-background)"
                      height="15px"
                      labelSize="13px"
                    />
                  </Box>
                ))}
              </>
            ) : (
              <p className={cls.noData}>{translate("general.noData")}</p>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RevenueChart;
