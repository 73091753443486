import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";

import { useSelector } from "react-redux";

import Loader from "../../../UIs/Loading/Loading";
import EditOrder from "./../../../modals/EditOrder/EditOrder";
import EditDeliveryStatus from '../../../modals/EditDeliveryStatus/EditDeliveryStatus';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import CurrencyFormat from "react-currency-format";

import axios from "../../../../utils/axios";

import { format } from "date-fns";

import { useTable } from "react-table";

import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import { toast } from "react-toastify";

import { useMutation } from "react-query";

import cls from "./order.module.scss";

const Order = ({ order, refetchOrder }) => {
  // COMPONENT HOOKS
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [openEditOrder, setOpenEditOrder] = useState(false);
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const { t: translate, i18n } = useTranslation("common");
  const navigate = useNavigate();

  useEffect(() => {
    switch (order.shipping.status) {
      case "Processing": {
        setOrderStatus(1);
        break;
      }
      case "Ready To Shipping": {
        setOrderStatus(2);
        break;
      }
      case "Shipped": {
        setOrderStatus(3);
        break;
      }
      case "On The Way": {
        setOrderStatus(4);
        break;
      }
      case "Delivered": {
        setOrderStatus(5);
        break;
      }
    }
  }, [order]);

  const orderProducts = order.items.map((item) => ({
    _id: item._id,
    product: {
      _id: item.item._id,
      name: item.item.name,
      thumb: item.item.images[0],
      color: item.color,
      size: item.variant ? item.variant : "no variant",
    },
    price: item.price,
    quantity: item.qty,
    rating: item.item.rating,
    totalAmount: item.price * item.qty,
  }));

  // TABLE DATA
  const data = useMemo(() => orderProducts, []);

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        header: `${translate("orderDetails.proDetails")}`,
        accessor: "product.name",
        Cell: ({ row }) => (
          <div className={cls.product}>
            <img src={row.original.product.thumb} alt="productImage" />
            <div>
              <p
                onClick={() =>
                  navigate(`/products/${row.original.product._id}`)
                }
              >
                {row.original.product.name.substring(0, 50) + "..."}
              </p>
              <span>
                <span
                  className={cls.colorBall}
                  style={{ backgroundColor: `${row.original.product.color}` }}
                ></span>
              </span>
              <span>
                {translate("createPro.var")}: {row.original.product.size}
              </span>
            </div>
          </div>
        ),
        minWidth: "40%",
        width: "40%",
      },
      {
        header: `${translate("orderDetails.itemPrice")} (${
          websiteData.currency
        })`,
        accessor: "price",
        Cell: ({ value }) => (
          <CurrencyFormat
            value={value.toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
            renderText={(value) => <div>{value}</div>}
          />
        ),
        minWidth: "20%",
        width: "20%",
      },
      {
        header: `${translate("orderDetails.quantity")}`,
        accessor: "quantity",
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("orderDetails.rating")}`,
        accessor: "rating",
        Cell: ({ value }) => (
          <span>
            <i className="fa-solid fa-star"></i> {value}
          </span>
        ),
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("orderDetails.totalAmount")} (${
          websiteData.currency
        })`,
        accessor: "totalAmount",
        Cell: ({ value }) => (
          <CurrencyFormat
            value={value.toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
            renderText={(value) => <div>{value}</div>}
          />
        ),
        minWidth: "30%",
        width: "30%",
      },
    ],
    [i18n.language]
  );

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const checkCancelOrder = () => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("orderDetails.sureCancel"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("orderDetails.cancel"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        cancelUserOrder();
      }
    });
  };

  const cancelOrder = async () => {
    const data = { status: "Cancelled" };

    const response = await axios
      .patch(`/order/${order._id}/status`, data)
      .catch((err) => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    refetchOrder();

    successNotify(translate("orderDetails.canceled"));
  };

  const { mutate: cancelUserOrder, isLoading: cancelLoading } = useMutation(
    "cancelOrder",
    cancelOrder
  );

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <>
      <Box className={cls.order} sx={{ bgcolor: "background.secondary" }}>
        {cancelLoading && <Loader />}

        <Box className={cls.order__head}>
          <Typography>
            {translate("orderDetails.order")}: {order._id}
          </Typography>

          <Box>
            <button onClick={() => setOpenEditOrder(true)}>
              <i className="fa-light fa-pen-to-square"></i>{" "}
              {translate("orderDetails.edit")}
            </button>

            {order.status !== "Cancelled" && (
              <button className={cls.cancel} onClick={checkCancelOrder}>
                {translate("orderDetails.cancel")}
              </button>
            )}
          </Box>
        </Box>

        <Box className={cls.order__table}>
          <Table {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup, idx) => (
                <TableRow key={idx} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, idx) => (
                    <TableCell
                      key={idx}
                      {...column.getHeaderProps()}
                      style={{ minWidth: column.minWidth, width: column.width }}
                    >
                      <span>{column.render("header")}</span>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {rows.map((row, idx) => {
                prepareRow(row);

                return (
                  <TableRow key={idx} {...row.getRowProps()}>
                    {row.cells.map((cell, idx) => (
                      <TableCell
                        key={idx}
                        {...cell.getCellProps()}
                        style={{
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                        }}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>

        <Box className={cls.order__calcs}>
          <Box>
            <Box className={`${cls.calc} ${cls[i18n.language]}`}>
              <Typography>{translate("orderDetails.sub")}:</Typography>
              <Typography>
                <CurrencyFormat
                  value={order.items
                    .reduce(
                      (total, item) => (total += item.price * item.qty),
                      0
                    )
                    .toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => <span>{value}</span>}
                />
              </Typography>
            </Box>

            <Box className={`${cls.calc} ${cls[i18n.language]}`}>
              <Typography>{translate("orderDetails.discount")}:</Typography>
              <Typography>0</Typography>
            </Box>

            <Box className={`${cls.calc} ${cls.last} ${cls[i18n.language]}`}>
              <Typography>{translate("orderDetails.charge")}:</Typography>
              <Typography>
                <CurrencyFormat
                  value={order.shipping.price.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => <span>{value}</span>}
                />
              </Typography>
            </Box>

            <Box className={`${cls.calc} ${cls[i18n.language]}`}>
              <Typography>
                {translate("orderDetails.total")} ({websiteData.currency}):{" "}
              </Typography>
              <Typography>
                <CurrencyFormat
                  value={(
                    order.items.reduce(
                      (total, item) => (total += item.price * item.qty),
                      0
                    ) + order.shipping.price
                  ).toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => <span>{value}</span>}
                />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={cls.orderStatus} sx={{ bgcolor: "background.secondary" }}>
        <Box className={cls.orderStatus__header}>
          <Typography variant="h6" component="h6">
            {translate("orderDetails.status")}
          </Typography>

          <Box className={cls.orderStatus__btns}>
            <button onClick={() => setOpenChangeStatus(true)}>
              {translate("orderDetails.change")}
            </button>
          </Box>
        </Box>

        <Box className={cls.stepper}>
          <Box
            className={`${cls.step} ${orderStatus >= 1 ? cls.completed : ""} ${
              cls[i18n.language]
            }`}
          >
            <Box className={cls.label}>
              <i className="fa-light fa-bag-shopping"></i>

              <Typography>
                {translate("orderDetails.processing")} -{" "}
                {format(new Date(order.createdAt).getTime(), "MMMM dd, yyyy")}
              </Typography>
            </Box>

            <Box className={cls.content}>
              <Box>
                <Typography>
                  <i className="fa-regular fa-circle-dot"></i>{" "}
                  {translate("orderDetails.place")}
                </Typography>

                <span>
                  {format(new Date(order.createdAt).getTime(), "MMMM dd, yyyy")}
                </span>
              </Box>
            </Box>
          </Box>

          <Box
            className={`${cls.step} ${orderStatus >= 2 ? cls.completed : ""} ${
              cls[i18n.language]
            }`}
          >
            <Box className={cls.label}>
              <i className="fa-light fa-box-check"></i>

              <Typography>{translate("orderDetails.ready")}</Typography>
            </Box>

            <Box className={cls.content}>
              <Box>
                <Typography>
                  <i className="fa-regular fa-circle-dot"></i>{" "}
                  {translate("orderDetails.picked")}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            className={`${cls.step} ${orderStatus >= 3 ? cls.completed : ""} ${
              cls[i18n.language]
            }`}
          >
            <Box className={cls.label}>
              <i className="fa-light fa-truck"></i>

              <Typography>{translate("orderDetails.shipped")}</Typography>
            </Box>

            <Box className={cls.content}>
              <Typography>
                <i className="fa-regular fa-circle-dot"></i>{" "}
                {translate("orderDetails.orderShipped")}
              </Typography>
            </Box>
          </Box>

          <Box
            className={`${cls.step} ${orderStatus >= 4 ? cls.completed : ""} ${
              cls[i18n.language]
            }`}
          >
            <Box className={cls.label}>
              <i className="fa-light fa-truck-ramp-box"></i>

              <Typography>{translate("orderDetails.onWay")}</Typography>
            </Box>

            <Box className={cls.content}></Box>
          </Box>

          <Box
            className={`${cls.step} ${orderStatus >= 5 ? cls.completed : ""} ${
              cls[i18n.language]
            }`}
          >
            <Box className={cls.label}>
              <i className="fa-regular fa-box-open"></i>

              <Typography>{translate("orderDetails.delivered")}</Typography>
            </Box>

            <Box className={cls.content}></Box>
          </Box>
        </Box>

        {openEditOrder && (
          <EditOrder
            setShowEditOrder={setOpenEditOrder}
            order={order}
            orderIdUpdate={order._id}
            refetchOrders={refetchOrder}
          />
        )}
        {openChangeStatus && (
          <EditDeliveryStatus
            setShowEditOrder={setOpenChangeStatus}
            order={order}
            orderIdUpdate={order.id}
            refetchOrders={() => {}}
          />
        )}
      </Box>
    </>
  );
};

export default Order;
