import { useState, useEffect } from "react";

import Stripe from "./../../UIs/skeletons/Stripe/Stripe";
import ImagePreview from "../../modals/ImagePreview/ImagePreview";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";

import socket from "../../../utils/socket";
import { formatDistance } from "date-fns";

// import { css } from 'emotion';
import ScrollToBottom from "react-scroll-to-bottom";

import cls from "./chatBody.module.scss";

const ChatBody = ({ messages, chatId, user, chatLoading }) => {
  // COMPONENT HOOKS
  const [allMessages, setAllMessages] = useState(messages || []);
  const [imageToPreview, setImageToPreview] = useState("");
  const { t: translate, i18n } = useTranslation("common");

  useEffect(() => {
    if (messages) {
      setAllMessages(messages);
    }
  }, [messages]);

  useEffect(() => {
    socket.on("new-message", (message) => {
      setAllMessages((prev) => [...prev, message]);
    });

    return () => {
      // CLEANUP FUNCTION
      socket.removeAllListeners("return-current-user");
      socket.removeAllListeners("receive-message");
      socket.removeAllListeners("Error-Message");

      socket.emit("user-disconnected", user._id);
      if (chatId) socket.emit("leave-user-chat", chatId._id);
    };
  }, []);

  const openImagePreview = (image) => {
    setImageToPreview(image);
  };

  return (
    <Box className={cls.chatBody}>
      {chatLoading ? (
        <>
          <Box
            className={`${cls.chatBody__message_loader} ${cls.me} ${
              cls[i18n.language]
            }`}
          >
            <Stripe />
          </Box>
          <Box
            className={`${cls.chatBody__message_loader} ${cls[i18n.language]}`}
          >
            <Stripe />
          </Box>
          <Box
            className={`${cls.chatBody__message_loader} ${cls.me} ${
              cls[i18n.language]
            }`}
          >
            <Stripe />
          </Box>

          <Box
            className={`${cls.chatBody__message_loader} ${cls[i18n.language]}`}
          >
            <Stripe />
          </Box>
          <Box
            className={`${cls.chatBody__message_loader} ${cls.me} ${
              cls[i18n.language]
            }`}
          >
            <Stripe />
          </Box>
          <Box
            className={`${cls.chatBody__message_loader} ${cls[i18n.language]}`}
          >
            <Stripe />
          </Box>
        </>
      ) : (
        <>
          {allMessages.length >= 1 ? (
            <>
              {allMessages.map((message, idx) => (
                <Box
                  className={`${cls.chatBody__message} ${
                    message.user !== user._id ? cls.me : ""
                  } ${cls[i18n.language]}`}
                  key={idx}
                >
                  {message.type === "text" ? (
                    <Typography>{message.content}</Typography>
                  ) : (
                    <img
                      src={message.content}
                      alt="chatImage"
                      onClick={() => openImagePreview(message.content)}
                    />
                  )}

                  <span>
                    {formatDistance(
                      new Date(message.sentAt).getTime(),
                      Date.now(),
                      { addSuffix: true }
                    )}
                  </span>
                </Box>
              ))}
            </>
          ) : (
            <Box className="empty">
              <img src="/assets/imgs/chat/chat.png" alt="no messages" />
              <p>{translate("support.emptyChat")}</p>
            </Box>
          )}
        </>
      )}

      {imageToPreview && (
        <ImagePreview
          setOpenImagePreview={setImageToPreview}
          imgSrc={imageToPreview}
        />
      )}
    </Box>
  );
};

export default ChatBody;
