import { useState, useMemo } from "react";
import { useNavigate } from "react-router";

import { useSelector } from "react-redux";

import ChooseList from "../../UIs/ChooseList/ChooseList";
import TableCheckBox from "../../UIs/TableCheckBox/TableCheckBox";
import LargeSquare from "../../UIs/skeletons/LargeSquare/LargeSquare";
import Loader from "../../UIs/Loading/Loading";

import { useQuery } from "react-query";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";
import Snackbar from "@mui/material/Snackbar";

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";

import { format } from "date-fns";

import axios from "../../../utils/axios";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";

import Swal from "sweetalert2";

import { CopyToClipboard } from "react-copy-to-clipboard";

import cls from "./couponsList.module.scss";

const CouponsList = () => {
  // COMPONENT HOOKS
  const [allCoupons, setAllCoupons] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const { t: translate, i18n } = useTranslation("common");
  const navigate = useNavigate();

  const fetchAllCoupons = async () => {
    const couponsRes = await axios.get(
      `/coupon?limit=${limit}&skip=${(pageNumber - 1) * limit}`
    );

    if (!couponsRes) return;

    setPagesCount(couponsRes.data.success.totalCount);

    return couponsRes.data.success.coupons;
  };

  const {
    data: coupons,
    isFetching,
    refetch,
  } = useQuery(["couponsList", pageNumber], fetchAllCoupons, {
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ["data", "error"],
    onSuccess: (coupons) => {
      const allCoupons = coupons.map((coupon) => ({
        id: coupon._id,
        coupon: coupon.code,
        type: coupon.discount.type,
        discount:
          coupon.discount.amount.toFixed(2) +
          `${
            coupon.discount.type === "amount"
              ? ` (${websiteData.currency})`
              : "%"
          }`,
        ...(coupon.expiration.type === "date"
          ? {
              status:
                new Date(coupon.expiration.date).getTime() > Date.now()
                  ? translate("general.alive")
                  : translate("general.ended"),
            }
          : {
              status:
                coupon.expiration.usage >= 1
                  ? translate("general.alive")
                  : translate("general.ended"),
            }),
        startDate: coupon.createdAt,
        ...(coupon.expiration.type === "date"
          ? { end: coupon.expiration.date }
          : { end: coupon.expiration.usage }),
      }));
      setAllCoupons(allCoupons);
    },
  });

  const actionList = (id) => [
    {
      item: `${translate("category.edit")}`,
      icon: <i className="fa-light fa-pen-to-square"></i>,
      method: () => navigate(`/coupons/create-coupon?coupon=${id}`),
    },
    {
      item: `${translate("category.delete")}`,
      icon: <i className="fa-light fa-trash-can"></i>,
      method: () => checkDelete(id),
    },
  ];

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        header: `${translate("allCoupons.coupon")}`,
        accessor: "coupon",
        Cell: ({ value }) => (
          <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
            <span className="idString">{value}</span>
          </CopyToClipboard>
        ),
        minWidth: "15%",
        width: "15%",
      },
      {
        header: `${translate("allCoupons.type")}`,
        id: "type",
        accessor: "type",
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("allCoupons.discount")}`,
        id: "discount",
        accessor: "discount",
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("allCoupons.status")}`,
        id: "status",
        accessor: "status",
        Cell: ({ value }) => (
          <span
            className={
              value === "Alive" || value === "مفعل" ? cls.alive : cls.ended
            }
          >
            {value}
          </span>
        ),
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("allCoupons.startDate")}`,
        id: "startDate",
        accessor: "startDate",
        Cell: ({ value }) => (
          <span>{format(new Date(value).getTime(), "dd/MM/yyyy")}</span>
        ),
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("allCoupons.endDate")}`,
        id: "end",
        accessor: "end",
        Cell: ({ value }) => (
          <>
            {isNaN(value) ? (
              <span>{format(new Date(value).getTime(), "dd/MM/yyyy")}</span>
            ) : (
              <span>{value + " Uses"}</span>
            )}
          </>
        ),
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("allCoupons.action")}`,
        id: "action",
        Cell: ({ row }) => (
          <ChooseList
            list={actionList(row.original.id)}
            text={<i className="fa-thin fa-ellipsis-stroke"></i>}
            withArrow={false}
          />
        ),
        disableSortBy: true,
        minWidth: "2%",
        width: "2%",
      },
    ],
    [i18n.language]
  );

  const tableInstatce = useTable(
    {
      columns,
      data: allCoupons || [],
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <TableCheckBox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <TableCheckBox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableSortBy: true,
          minWidth: "2%",
          width: "2%",
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    pageCount,
    pageOptions,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, selectedRowIds },
  } = tableInstatce;

  // COMPONENT HANDLERS
  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
    gotoPage(pageNum - 1);
  };
  // COMPONENT HANDLERS
  const checkDelete = (id) => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteCoupon"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCoupon(id);
      }
    });
  };
  // DELETE CATEGORY
  const deleteCoupon = async (id) => {
    setLoading(true);

    const response = await axios.delete(`/coupon/${id}`).catch((err) => {
      errorNotify(err.response.data);
      setLoading(false);
    });

    if (!response || !response.data.success) return;

    refetch();

    setLoading(false);

    successNotify(translate("toast.couponDeleted"));
  };
  // CHECK DELETE MULTIPLE CATEGORIES
  const checkDeleteMultiple = (ids) => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteCoupons"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMultipeCoupons(ids);
      }
    });
  };
  // DELETE MULTIPLE CATEGORIES
  const deleteMultipeCoupons = async (ids) => {
    setLoading(true);

    const allIds = ids.map((id) => id.original.id);

    const response = await axios
      .delete("/coupon", { data: { IDs: allIds } })
      .catch((err) => {
        errorNotify(err.response.data.err);
        setLoading(false);
      });

    if (!response || !response.data.success) return;

    refetch();

    setLoading(false);

    successNotify(translate("toast.couponsDeleted"));
  };

  const gotToCopounDetails = (e, id) => {
    if (
      e.target.tagName !== "INPUT" &&
      e.target.tagName !== "BUTTON" &&
      e.target.tagName !== "LI" &&
      e.target.tagName !== "SPAN"
    ) {
      navigate(`/coupons/create-coupon?coupon=${id}`);
    }
  };

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box className={cls.couponsList}>
      {loading && <Loader />}
      <Box className={`${cls.search} ${cls[i18n.language]}`}>
        <i className="fa-light fa-magnifying-glass"></i>

        <input
          type="search"
          placeholder={translate("allCoupons.search")}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
      </Box>

      <Box className={cls.couponsList__table}>
        {isFetching ? (
          <LargeSquare />
        ) : (
          <>
            {allCoupons.length >= 1 && rows.length >= 1 ? (
              <>
                <Box className={cls.logistics__table_options}>

                  {selectedFlatRows.length >= 1 && (
                    <Box className={cls.deleteSelected}>
                      {translate("category.select")} {selectedFlatRows.length}{" "}
                      {translate("category.result")}{" "}
                      <span
                        onClick={() => checkDeleteMultiple(selectedFlatRows)}
                      >
                        {translate("category.remove")}
                      </span>
                    </Box>
                  )}
                </Box>

                <Table {...getTableProps()}>
                  <TableHead>
                    {headerGroups.map((headerGroup, idx) => (
                      <TableRow
                        key={idx}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column, idx) => (
                          <TableCell
                            key={idx}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={{
                              minWidth: column.minWidth,
                              width: column.width,
                            }}
                          >
                            <span>
                              {column.render("header")}

                              {!column.disableSortBy && (
                                <>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <i className="fa-light fa-sort-down"></i>
                                    ) : (
                                      <i className="fa-light fa-sort-up"></i>
                                    )
                                  ) : (
                                    <i className="fa-light fa-sort"></i>
                                  )}
                                </>
                              )}
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>

                  <TableBody {...getTableBodyProps()}>
                    {page.map((row, idx) => {
                      prepareRow(row);

                      return (
                        <TableRow
                          key={idx}
                          {...row.getRowProps()}
                          onClick={(e) =>
                            gotToCopounDetails(e, row.original.id)
                          }
                        >
                          {row.cells.map((cell, idx) => (
                            <TableCell
                              key={idx}
                              {...cell.getCellProps()}
                              style={{
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                              }}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>

                <Box className={cls.couponsList__footer}>
                  <Typography>
                    {translate("product.show")} {pageNumber}{" "}
                    {translate("product.of")} {Math.ceil(pagesCount / limit)}{" "}
                    {translate("product.results")}
                  </Typography>

                  <Box className={`${cls.pagination} pagination`}>
                    <Pagination
                      count={Math.ceil(pagesCount / limit)}
                      page={pageNumber}
                      onChange={changePage}
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <Box className="empty">
                <img src="/assets/imgs/coupon/coupon.png" alt="no coupons" />
                <p>{translate("allCoupons.empty")}</p>
              </Box>
            )}
          </>
        )}
      </Box>
      {/* SNACKBAR COMPONENT */}
      <Snackbar
        open={copied}
        autoHideDuration={2000}
        onClose={() => setCopied(false)}
        message="Copied to Clipboard!"
      />
    </Box>
  );
};

export default CouponsList;
