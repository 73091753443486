import { useState } from "react";

import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// STYLES FILES
import cls from "./selectbox.module.scss";

const Selectbox = ({ placeholder, list, displayProp, value, select, disabled, error }) => {
  const { t: translate, i18n } = useTranslation('common')
  const listItems = list;
  const [open, setOpen] = useState(false);

  // SELECT CHOOSE OPTION HANDLER
  const selectChoose = (result) => {
    select(result);
    setOpen(false);
  };

  return (
    <>
      {open && (
        <div className={cls.overlay} onClick={() => setOpen(false)}></div>
      )}

      <Box className={cls.field}>
        <Box className={cls.field__selectArea}>
          <input
            readOnly
            type="text"
            placeholder={placeholder}
            value={value}
            onFocus={() => setOpen(true)}
            disabled={disabled}
            className={error ? cls.error : ''}
          />
          <i className={`fa-solid fa-angle-down ${cls[i18n.language]}`}></i>
        </Box>

        <Box className={`${cls.field__results} ${open ? cls.active : ""}`}>
          <Box className={cls.field__results_result}>
            {listItems.map((item, idx) => (
              <Box
                className={cls.resultInside}
                onClick={() => selectChoose(item)}
                key={idx}
              >
                <Typography key={item}>{typeof item === 'object' ? item[displayProp] : item}</Typography>
              </Box>
            ))}
          </Box>
        </Box>

        {error && <p className={cls.error}><i className="fa-light fa-circle-exclamation"></i> {translate('error.emptyFields')}</p>}
      </Box>
    </>
  );
};

export default Selectbox;
