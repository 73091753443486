import { useParams } from 'react-router';

import { Order, OrderSpecs } from '../../../components';
import LargeSquare from './../../../components/UIs/skeletons/LargeSquare/LargeSquare';
import SmallSquare from '../../../components/UIs/skeletons/SmallSquare/SmallSquare';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { useQuery } from 'react-query';
import axios from '../../../utils/axios';

import { useTranslation } from 'react-i18next';

import cls from './orderDetails.module.scss';

const OrderDetails = () => {
  // COMPONENTN HOOKS
  const { t: translate, i18n } = useTranslation('common');
  const { orderId } = useParams();

  const fetchOrderDetails = async () => {
    const response = await axios.get(`/order/${orderId}?pi=1&pu=1`);

    if(!response || !response.data.success) return;

    return response.data.success;
  }

  const { data: orderData, isFetching, error, refetch: refetchOrder } = useQuery('orderData', fetchOrderDetails, {
    refetchOnWindowFocus: false
  })

  if(isFetching) {
    return (

      <Grid container spacing={3}>

        <Grid item xs={12} xl={8} xxl={9}>

          <LargeSquare />

        </Grid>

        <Grid item xs={12} xl={4} xxl={3}>

          <SmallSquare />

          <SmallSquare />

        </Grid>

      </Grid>

    )
  }

  return (
    <Box className={cls.orderDetails}>

      <Box className={cls.orderDetails__head}>

        <Typography variant='h6' component='h6'>{translate('orderDetails.title')}</Typography>

        <Typography><span>{translate('orderDetails.orders')}</span> &gt; {translate('orderDetails.details')}</Typography>

      </Box>

      <Grid container spacing={3}>

        <Grid item xs={12} xl={8} xxl={9}>

          <Order order={orderData} refetchOrder={refetchOrder} />

        </Grid>

        <Grid item xs={12} xl={4} xxl={3}>

          <OrderSpecs order={orderData} refetchOrder={refetchOrder} />

        </Grid>

      </Grid>

    </Box>
  )
}

export default OrderDetails;