import { useState, useEffect } from "react";
import { useLocation } from "react-router";

import AppRoutes from "./routes";

import { useSelector, useDispatch } from "react-redux";
import { userActions } from "./store/slices/userSlice";
import { websiteDataActions } from "./store/slices/websiteDataSlice";

import ThemeContextProvider from "./theme/theme";

import Sidebar from "./components/Sidebar/Sidebar";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { Loader } from "./components";

import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";

import Cookies from "universal-cookie";

import axios from "./utils/axios";

import { QueryClientProvider, QueryClient } from "react-query";

import { ToastContainer } from "react-toastify";

import "./styles/globals.scss";
import "./styles/variables.scss";
import "./assets/css/all.min.css";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();

const cookies = new Cookies();

function App() {
  // COMPONENT STATES
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [smallBar, setSmallBar] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const location = useLocation();

  const getCurrentUser = async () => {
    const response = await axios.get("/user/current-user").catch((err) => {
      setLoading(false);
    });

    if (response && response.data) {
      dispatch(userActions.setUser(response.data.success));
      setLoading(false);
    }
  };

  const fetchWebsiteData = async () => {
    const response = await axios.get(`/info`);

    if (!response || !response.data) return;

    dispatch(websiteDataActions.setWebsiteData(response.data.success));
  };

  useEffect(() => {
    fetchWebsiteData();
  }, []);

  useEffect(() => {
    // YOU MUST DO THIS SO SCROLL TO TOP COMPONENT WORK PROPERLY...
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (cookies.get("markety-dashboard-session")) {
      getCurrentUser();
    } else {
      setLoading(false);
    }
  }, [cookies.get("markety-dashboard-session")]);

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (!cookies.get("marketyLang")) {
      cookies.set("marketyLang", i18n.language, { path: "/" });
    }

    if (!cookies.get("marketyMode")) {
      cookies.set("marketyMode", "light", { path: "/" });
    }
  }, [cookies.get("marketyLang"), cookies.get("marketyMode")]);

  // COLLAPSE POPUPS
  const hideAllPopups = (e) => {
    var popups = document.querySelectorAll(".popup");

    if (e.target.classList.contains("showPopup-btn")) {
      Object.values(popups).forEach((popup) => {
        popup.classList.remove("active");
      });

      e.target.nextElementSibling.classList.add("active");
    } else {
      Object.values(popups).forEach((popup) => {
        popup.classList.remove("active");
      });
    }
  };

  return (
    <Box className="App" id={lang} onClick={hideAllPopups}>
      {cookies.get("marketyMode") && cookies.get("marketyLang") && (
        <QueryClientProvider client={queryClient}>
          <ThemeContextProvider>
            {!loading ? (
              <Box
                sx={{ bgcolor: "background.default" }}
                style={{ minHeight: "100vh" }}
              >
                <ToastContainer
                  position={lang === "en" ? "top-right" : "top-left"}
                  autoClose={4000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  pauseOnHover
                />

                {user && <Sidebar smallBar={smallBar} />}

                <Box
                  className={`${smallBar && "small"} page ${i18n.language} ${!user && "noAuth"
                    }`}
                >
                  {user && (
                    <Navbar smallBar={smallBar} setSmallBar={setSmallBar} />
                  )}

                  <Box className="pages">
                    <AppRoutes />

                    {user && <Footer />}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Loader />
            )}
          </ThemeContextProvider>
        </QueryClientProvider>
      )}
    </Box>
  );
}

export default App;
