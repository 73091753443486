import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
  CreateSubCategory,
  CreateCategory as CreateCategoryComponent,
  Loading,
} from "../../../components";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useQuery } from "react-query";

import axios from "../../../utils/axios";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";

import cls from "./createCategory.module.scss";

const CreateCategory = () => {
  // COMPONENT HOOKS
  let [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get("category");
  const [categoryFields, setCategoryFields] = useState({
    name: "",
    description: "",
  });
  const [subCategoryName, setSubCategoryName] = useState("");
  const [choosedCategoryImage, setChoosedCategoryImage] = useState();
  const [choosedSubCategory, setChoosedSubCategory] = useState();
  const [choosedSubCategoryImage, setChoosedSubCategoryImage] = useState();
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [emptyFields, setEmptyFields] = useState(false);
  const [subEmptyFields, setSubEmptyFields] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t: translate, i18n } = useTranslation("common");

  // UPDATE FIELDS ON INPUT CHANGE
  const updateCategoryFields = (e) => {
    setCategoryFields({
      ...categoryFields,
      [e.target.name]: e.target.value,
    });
  };

  const updateSubCategoryFields = (name, id) => {
    setSubCategoryName(name);
  };

  // FETCH CATEGORY DATA IF YOU NEED TO UPDATE IT
  const fetchCategory = async () => {
    if (!category) return;
    const categoryResponse = await axios.get(`/category/${category}`);

    if (!categoryResponse || !categoryResponse.data.success) return;

    return categoryResponse.data.success;
  };

  const {
    data: categoryData,
    isFetching,
    isError,
    refetch: refetchCategory,
  } = useQuery("fetchCategory", fetchCategory, {
    refetchOnWindowFocus: false,
    onSuccess: (categoryData) => {
      if (category) {
        setCategoryFields({
          ...categoryFields,
          name: categoryData.name,
          description: categoryData.description,
        });
        setChoosedCategoryImage(categoryData.thumb);
      } else {
        setSubCategoriesData([]);
      }
    },
  });

  // ADD NEW SUB CATEGORY
  const addNewSubCategory = async () => {
    let data = new FormData();
    data.append("id", category);
    data.append("name", subCategoryName);
    data.append("sub_category_thumb", choosedSubCategoryImage);

    if (!subCategoryName || !choosedSubCategoryImage) {
      setSubEmptyFields(true);
    } else {
      setLoading(true);

      const response = await axios
        .post("/category/sub-category", data)
        .catch((err) => {
          errorNotify(err.response.data.err);
          setLoading(false);
        });

      if (!response || !response.data) return;

      refetchCategory();

      setLoading(false);

      successNotify(translate("toast.subCatAdded"));

      setSubCategoryName("");
      setChoosedSubCategoryImage("");
    }
  };

  // UPDATE EXISTING SUB-CATEGORY
  const updateSubCategory = async () => {
    let data = new FormData();

    data.append("name", subCategoryName);

    if (choosedSubCategory.thumb !== choosedSubCategoryImage) {
      data.append("sub_category_thumb", choosedSubCategoryImage);
      data.append("deleteOldThumb", 1);
    }

    if (!subCategoryName || !choosedSubCategoryImage) {
      setSubEmptyFields(true);
    } else {
      setLoading(true);

      const response = await axios
        .patch(`/category/${category}/${choosedSubCategory.id}`, data)
        .catch((err) => {
          errorNotify(err.response.data.err);

          setLoading(false);
        });

      if (!response || !response.data) return;

      successNotify(translate("toast.subCatUpdated"));

      refetchCategory();

      setLoading(false);
    }
  };

  // ADD NEW CATEGORY
  const addNewCategory = async () => {
    let categoryData = new FormData();

    categoryData.append("name", categoryFields.name);
    categoryData.append("description", categoryFields.description);
    categoryData.append("category_thumb", choosedCategoryImage);

    let subs = JSON.stringify(
      subCategoriesData.map((sub) => ({
        name: sub.category.name,
        img: sub.category.img.name,
      }))
    );
    categoryData.append("sub_categories", subs);

    for (let sub of subCategoriesData) {
      categoryData.append("sub_category_thumbs", sub.category.img);
    }

    if (
      !categoryFields.name ||
      !categoryFields.description ||
      !choosedCategoryImage
    ) {
      setEmptyFields(true);
    } else {
      setLoading(true);

      const categoryResponse = await axios
        .post("/category", categoryData)
        .catch((err) => {
          errorNotify(err.response.data.err);

          setLoading(false);
        });

      if (!categoryResponse || !categoryResponse.data.success) return;

      setLoading(false);

      successNotify(translate("toast.catAdded"));

      navigate("/categories/all");
    }
  };

  // UPDATE EXISISTING CATEGORY
  const updateCategory = async () => {
    let data = new FormData();
    data.append("name", categoryFields.name);
    data.append("description", categoryFields.description);

    if (categoryData.thumb !== choosedCategoryImage) {
      data.append("category_thumb", choosedCategoryImage);
      data.append("deleteOldThumb", 1);
    }

    if (
      !categoryFields.name ||
      !categoryFields.description ||
      !choosedCategoryImage
    ) {
      setEmptyFields(true);
    } else {
      setEmptyFields(false);

      setLoading(true);

      const response = await axios
        .patch(`/category/${category}`, data)
        .catch((err) => {
          errorNotify(err.response.data.err);
          setLoading(false);
        });

      if (!response || !response.data) return;

      setLoading(false);

      navigate("/categories/all");

      successNotify(translate("toast.catUpdated"));
    }
  };

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);
  return (
    <Box className={cls.createCategory}>
      {loading && <Loading />}

      <Box className={cls.createCategory__head}>
        <Typography variant="h6" component="h6">
          {translate("categories.create")}
        </Typography>

        <Typography>
          <span>{translate("categories")}</span> &gt;{" "}
          {translate("category.create")}
        </Typography>
      </Box>

      {!isFetching && (
        <>
          <Box
            className={cls.createSubCategory__category}
            sx={{ bgcolor: "background.secondary" }}
          >
            <CreateCategoryComponent
              category={category}
              categoryFields={categoryFields}
              updateCategoryFields={updateCategoryFields}
              choosedCategoryImage={choosedCategoryImage}
              setChoosedCategoryImage={setChoosedCategoryImage}
              updateCategory={updateCategory}
              emptyFields={emptyFields}
            />
          </Box>

          <Box className={cls.createCategory_subCategory}>
            <CreateSubCategory
              category={category}
              categoryData={categoryData}
              choosedSubCategoryImage={choosedSubCategoryImage}
              setChoosedSubCategoryImage={setChoosedSubCategoryImage}
              updateSubCategoryFields={updateSubCategoryFields}
              subCategoryName={subCategoryName}
              setSubCategoryName={setSubCategoryName}
              choosedSubCategory={choosedSubCategory}
              setChoosedSubCategory={setChoosedSubCategory}
              addNewSubCategory={addNewSubCategory}
              updateSubCategory={updateSubCategory}
              subCategoriesData={subCategoriesData}
              setSubCategoriesData={setSubCategoriesData}
              refetchCategory={refetchCategory}
              subEmptyFields={subEmptyFields}
              setSubEmptyFields={setSubEmptyFields}
            />
          </Box>

          <Box className={cls.addCat}>
            {!category && (
              <button onClick={addNewCategory}>
                {translate("createSubCat.create")}
              </button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default CreateCategory;
