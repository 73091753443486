import { useState } from 'react';

import { AdsTab, ApplicationTab } from './../../components';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import cls from './general.module.scss';

const General = () => {
  //  COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation('common');

  return (
    <Box className={cls.general}>

      <Box className={cls.general__head}>

        <Typography variant='h6' component='h6'>{translate('general.title')}</Typography>

        <Typography><span>{translate('general.general')}</span> &gt; {translate('general.dashboard')}</Typography>

      </Box>

      <Box className={cls.general__welcome}>

        <Typography variant="h6" component="h6">
          {translate('general.application')}
        </Typography>

        <Typography>
          {translate('general.appText')}
        </Typography>

      </Box>

      <ApplicationTab />

    </Box>
  )
}

export default General