import { useState, useRef } from 'react';

import Selectbox from './../../UIs/SelectBox/Selectbox';

import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';

import cls from './imagePreview.module.scss';

const ImagePreview = ({ setOpenImagePreview, imgSrc }) => {
  // COMPONENT HOOKS
  const overlay = useRef();
  const { t: translate, i18n } = useTranslation('common');

  // COMPONENT HANDLERS
  const closeModal = (e) => {

    if(overlay.current === e.target) setOpenImagePreview(false)

  }

  const close = () => {
    setOpenImagePreview(false)
  }

  return (
    <Box className={cls.overlay} ref={overlay} onClick={(e) => closeModal(e)}>
      <Box className={cls.area}>

        <Box className={cls.area__wrapper}>
          <Box className={`${cls.close} ${cls[i18n.language]}`} onClick={close}>
            <i className="fa-solid fa-xmark"></i>
          </Box>

          <Box container className={cls.area__content} spacing={3}>

            <img src={imgSrc} alt="Image" />

          </Box>

        </Box>
      </Box>
    </Box>
  )
}

export default ImagePreview;