import Box from "@mui/system/Box";

import DropDown from '../../UIs/DropDown/DropDown';

import { useTranslation } from "react-i18next";

import Cookies from "universal-cookie";

import cls from "./langSwitch.module.scss";

const cookie = new Cookies();

const LangSwitch = () => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation('nav')

  const switchLang = (lang) => {

    cookie.set('marketyLang', lang)
    
    i18n.changeLanguage(lang)
    
  };

  // LANG DROPDOWN DATA
  const menu = [
    {
      option: `${translate('nav.english')}`,
      img: "/assets/imgs/navbar/america.png",
      method: switchLang,
      param: 'en'
    },
    {
      option: `${translate('nav.arabic')}`,
      img: "/assets/imgs/navbar/suadi.png",
      method: switchLang,
      param: 'ar'
    },
  ];

  return (
    <Box className={cls.lang}>
      <DropDown menu={menu} />
    </Box>
  );
};

export default LangSwitch;
