import { useState, useRef } from 'react';

import Loader from '../../UIs/Loading/Loading';

import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';

import socket from '../../../utils/socket';

import cls from './chatBottom.module.scss';

import axios from '../../../utils/axios';

const ChatBottom = ({ user, chatId, scrollToBottom }) => {
  // COMPONENT HOOKS
  const [choosedImage, setChoosedImage] = useState('');
  const [message, setMessage] = useState('')
  const imageInputRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const { t: translate, i18n } = useTranslation('common');

  // COMPONENT HANDLERS
  const clicking = () => {
    imageInputRef.current.click();
  }

  const chooseImage = (e) => {
    setChoosedImage(e.target.files[0]);
    setMessage('');
  }

  const cancelImage = () => {
    setChoosedImage('');
    imageInputRef.current.value = '';
  }

  const sendNewMessage = async () => {
    setLoading(true)
    if (choosedImage) {
      let chatImage = new FormData()

      chatImage.append('attachment', choosedImage)

      const response = await axios.post(`/chat/${chatId}/attachment`, chatImage).catch(err => errorNotify(err.response.data.err));

      if (!response || !response.data) return;

      socket.emit('send-message', { 
        message: response.data.success,
        type: 'file',
        user: {
          _id: user._id,
          username: user.username,
          email: user.email,
          profile_picture: user.profile_picture,
          type: user.type,
          role: user.role
        } 
      });
      setMessage('')
      setChoosedImage('')
      setLoading(false)
      setTimeout(() => {
        scrollToBottom()
      }, 5000)
    }

    if(message) {
      socket.emit('send-message', { 
        message,
        type: 'text',
        user: {
          _id: user._id,
          username: user.username,
          email: user.email,
          profile_picture: user.profile_picture,
          type: user.type,
          role: user.role
        } 
      });
      setMessage('')
      setLoading(false)
      setTimeout(() => {
        scrollToBottom()
      }, 500)
    }

  }

  const enterSendMessage = (e) => {
    if (e.keyCode === 13) {
      sendNewMessage()
    }
  }

  const successNotify = (message) => toast.success(message)
  const errorNotify = (message) => toast.error(message)

  return (
    <Box className={cls.chatBottom}>

      {loading && <Loader />}

      <Box>
      
        <input type="text" placeholder={translate('chat.type')} value={message} onChange={(e) => setMessage(e.target.value)} disabled={choosedImage} onKeyUp={enterSendMessage} />

        <i className="fa-light fa-message-image" onClick={clicking}></i>

        <input type="file" style={{ display: 'none' }} ref={imageInputRef} onChange={(e) => chooseImage(e)} />

      </Box>

      <button className={cls[i18n.language]} onClick={sendNewMessage}><i className="fa-light fa-paper-plane-top"></i></button>
      
      {choosedImage && 
      
        <Box className={cls.chatBottom__imagePreview}>

          <Box>

            <img src={URL.createObjectURL(choosedImage)} alt="imagePreview"/>

            <i className="fa-light fa-xmark" onClick={cancelImage}></i>

          </Box>

        </Box>
      
      }

    </Box>
  )
}

export default ChatBottom