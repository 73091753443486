import { useState } from "react";

import DataAccordion from "../../UIs/DataAccordion/DataAccordion";
import Stripe from "../../UIs/skeletons/Stripe/Stripe";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";

import { DateRange } from "react-date-range";
import dayjs from "dayjs";

import { useTranslation } from "react-i18next";

import { useQuery } from "react-query";
import axios from "../../../utils/axios";

import cls from "./logsTab.module.scss";

const LogsTab = ({ customerId }) => {
  // COMPONENT HOOKS
  const [date, setDate] = useState([
    {
      startDate: dayjs().startOf("month").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [logsCount, setLogsCount] = useState(0);
  const { t: translate, i18n } = useTranslation("common");

  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
  };

  // COMPONENT HANDLERS
  const handleDateChange = (item) => {
    setDate([item.selection]);
  };

  const handleDateFocusChange = (focused) => {
    if (!focused[0] && !focused[1]) setShowDatePicker(false);
  };

  const fetchAllLogs = async () => {
    const response = await axios.get(
      `/logs?select=action,details,createdAt&createdAt=${new Date(
        date[0].startDate
      ).getTime()}~${new Date(
        date[0].endDate
      ).getTime()}&user=${customerId}&limit=${limit}&skip=${
        (pageNumber - 1) * limit
      }`
    );

    if (!response || !response.data) return;

    setLogsCount(response.data.success.LogsCount);

    return response.data.success.Logs;
  };

  const { data: allLogs, isFetching: logsLoading } = useQuery(
    ["allLogs", date, pageNumber],
    fetchAllLogs
  );

  return (
    <Box className={cls.logsTab} sx={{ bgcolor: "background.secondary" }}>
      <Box className={cls.datePicker}>
        <Typography variant="h6" component="h6">
          {translate("userProfile.recentLogs")}
        </Typography>

        <Box className={`${cls.datePicker__date} ${cls[i18n.language]}`}>
          <input
            type="text"
            readOnly
            value={
              dayjs(date[0].startDate).format("YYYY-MM-DD") +
              `  ${translate("analytics.to")}  ` +
              dayjs(date[0].endDate || new Date()).format("YYYY-MM-DD")
            }
            onClick={() => setShowDatePicker((prev) => !prev)}
          />
          <i
            onClick={() => setShowDatePicker((prev) => !prev)}
            className="fa-light fa-calendar-day"
          ></i>

          {showDatePicker && (
            <DateRange
              editableDateInputs={false}
              onChange={handleDateChange}
              onRangeFocusChange={handleDateFocusChange}
              ranges={date}
              retainEndDateOnFirstSelection={false}
              showDateDisplay={false}
            />
          )}
        </Box>
      </Box>

      {logsLoading ? (
        <>
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
          <Stripe />
        </>
      ) : (
        <>
          {allLogs.length >= 1 ? (
            <>
              {allLogs &&
                allLogs.map((log, idx) => (
                  <DataAccordion key={idx} data={log} />
                ))}
            </>
          ) : (
            <Box className="empty">
              <img src="/assets/imgs/logs/logs.png" alt="no logs" />
              <p>{translate("logs.empty")}</p>
            </Box>
          )}
        </>
      )}

      {allLogs && allLogs.length >= 1 && !logsLoading && (
        <Box className={cls.paginationWrapper}>
          <Typography>
            {translate("product.show")} {pageNumber} {translate("product.of")}{" "}
            {Math.ceil(logsCount / limit)} {translate("product.results")}
          </Typography>

          <Box className={`${cls.pagination} pagination`}>
            <Pagination
              count={Math.ceil(logsCount / limit)}
              page={pageNumber}
              onChange={changePage}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LogsTab;
