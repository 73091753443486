import { useState, useRef } from 'react';

import ItemForReturn from './ItemForReturn/ItemForReturn';
import Loader from '../../UIs/Loading/Loading';

import Box from '@mui/material/Box';

import axios from '../../../utils/axios';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';

import cls from './createReturn.module.scss';

const EditOrder = ({ setShowCreateReturn, order, ticketId }) => {
  // COMPONENT HOOKS
  const overlay = useRef();
  const [orderStatus, setOrderStatus] = useState('');
  const [choosedItems, setChoosedItems] = useState([])
  const { t: translate, i18n } = useTranslation('common');

  // COMPONENT HANDLERS
  const closeModal = (e) => {
    if(overlay.current === e.target) setShowCreateReturn(false);
  }

  const close = () => {
    setShowCreateReturn(false)
  }

  const chooseItem = (item) => {
    let allChoosedItems = [...choosedItems]
    const itemFound = allChoosedItems.findIndex(it => it.item === item.item);

    if (itemFound >= 0) {
      allChoosedItems.splice(itemFound, 1);
      setChoosedItems([...allChoosedItems])
    } else {
      setChoosedItems(prev => [...prev, item])
    }
  }

  const createReturn = async () => {
    const returnData = {
      user: order.user,
      ticket: ticketId,
      order: order._id,
      items: [
        ...choosedItems
      ]
    }

    if (choosedItems.length <= 0) return errorNotify(translate('support.noItemsChoosed'));

    const response = await axios.post('/return', returnData).catch(err => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    successNotify(translate('support.returnSuccess'));
    
    setShowCreateReturn(false)
  }

  const { mutate: createReturnProduct, isLoading: createReturnLoading } = useMutation('createReturn', createReturn)

  const successNotify = (message) => toast.success(message)
  const errorNotify = (message) => toast.error(message)

  return (
    <Box className={cls.overlay} ref={overlay} onClick={(e) => closeModal(e)}>
      {createReturnLoading && <Loader />}

      <Box className={cls.area}>

        <Box className={cls.area__wrapper}>
          <Box className={`${cls.close} ${cls[i18n.language]}`} onClick={close}>
            <i className="fa-solid fa-xmark"></i>
          </Box>

          <Box container className={cls.area__content} spacing={3}>

            <h3>{translate('editOrder.createReturn')}</h3>
 
            <div className={cls.returnedItem}>
              <div className={cls.head}>

              <div className={cls.head__part}>

                <div className={cls.side}>
                  <div>
                    <p className={cls.top}>
                      {translate('userProfile.return')}
                    </p>
                    <p>
                      {order.status}
                    </p>
                  </div>
                </div>

              </div>

              <div className={`${cls.head__part} ${cls.includeView}`}>

                <div className={cls.side}>
                  <div>
                    <p className={cls.top}>
                      {translate('userProfile.id')}
                    </p>
                    <p>
                      {order._id}
                    </p>
                  </div>
                </div>

              </div>

              </div>

              <div className={`${cls.body}`}>

                {order.items.map(product => (

                  <ItemForReturn key={product._id} product={product} choosedItems={choosedItems} chooseItem={chooseItem} /> 

                ))}

              </div>
            </div>

            <Box className={cls.actions}>
              
              <button onClick={close}>{translate('orders.close')}</button>

              <button onClick={createReturnProduct}>{translate('editOrder.createReturn')}</button>
            
            </Box>

          </Box>

        </Box>
      </Box>
    </Box>
  )
}

export default EditOrder;