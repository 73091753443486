import { useState, useMemo, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useSelector } from "react-redux";

import ChooseList from "./../../UIs/ChooseList/ChooseList";
import TableCheckBox from "../../UIs/TableCheckBox/TableCheckBox";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";

import { useTranslation } from "react-i18next";

import axios from "../../../utils/axios";

import { format } from "date-fns";

import { toast } from "react-toastify";

import Swal from "sweetalert2";

import cls from "./allProductsTable.module.scss";
import LargeSquare from "../../UIs/skeletons/LargeSquare/LargeSquare";

const AllProductsTable = ({
  products,
  productsCount,
  pageNumber,
  limit,
  setPageNumber,
  productsLoading,
  refetchProducts,
}) => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation("common");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { websiteData } = useSelector(({ websiteData }) => websiteData);

  const tabs = [
    { name: `${translate("product.All")}`, query: null },
    { name: `${translate("product.publish")}`, query: 1 },
    { name: `${translate("product.hide")}`, query: 0 },
  ];

  const [tab, setTab] = useState(
    searchParams.get("published")
      ? tabs.find((tab) => tab.query === +searchParams.get("published")).name
      : translate("product.All")
  );

  const actionList = (id) => [
    {
      item: `${translate("category.view")}`,
      icon: <i className="fa-light fa-eye"></i>,
      method: () => navigate(`/products/${id}`),
    },
    {
      item: `${translate("category.edit")}`,
      icon: <i className="fa-light fa-pen-nib"></i>,
      method: () => navigate(`/products/create-product?product=${id}`),
    },
    {
      item: `${translate("category.delete")}`,
      icon: <i className="fa-light fa-trash-can"></i>,
      method: () => checkDelete(id),
    },
  ];

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        header: `${translate("product.product")}`,
        id: "product",
        accessor: "product.name",
        Cell: ({ value, row }) => (
          <Box className={cls.product}>
            <img
              src={row.original.product.img}
              alt="productImage"
              loading="lazy"
            />

            <Box className={cls.info}>
              <p>{value}</p>
              <p className={cls.category}>
                {translate("product.category")} :{" "}
                {row.original.product.category}
              </p>
            </Box>
          </Box>
        ),
        minWidth: "28%",
        width: "28%",
      },
      {
        header: `${translate("product.stock")}`,
        id: "stock",
        accessor: "stock",
        Cell: ({ value }) => (
          <div>
            <div>
              {value ? (
                <span key={value}>{value}</span>
              ) : (
                <span className={cls.outStock} key={value}>
                  {translate("product.outStock")}
                </span>
              )}
            </div>
          </div>
        ),
        minWidth: "8%",
        width: "8%",
      },
      {
        header: `${translate("product.price")} (${websiteData.currency})`,
        accessor: `price`,
        minWidth: "13%",
        width: "13%",
      },
      {
        header: `${translate("product.orders")}`,
        accessor: "orders",
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("product.rating")}`,
        id: "rating",
        accessor: "rating",
        Cell: ({ value }) => (
          <span className={cls.rate}>
            <i className="fa-solid fa-star"></i> {value}
          </span>
        ),
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("product.action")}`,
        id: "action",
        Cell: ({ row }) => (
          <ChooseList
            list={actionList(row.original.id)}
            text={<i className="fa-thin fa-ellipsis-stroke"></i>}
            withArrow={false}
          />
        ),
        disableSortBy: true,
        minWidth: "5%",
        width: "5%",
      },
    ],
    [i18n.language]
  );

  const tableInstatce = useTable(
    {
      columns,
      data: products || [],
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <TableCheckBox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <TableCheckBox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableSortBy: true,
          minWidth: "2%",
          width: "2%",
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    setGlobalFilter,
    selectedFlatRows,
  } = tableInstatce;

  // COMPONENT HANDLERS
  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
    gotoPage(pageNum - 1);
  };

  const checkDelete = (id) => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteProduct"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProduct(id);
      }
    });
  };

  const deleteProduct = async (id) => {
    const response = await axios
      .delete(`/product/${id}`)
      .catch((err) => errorNotify(err.response.data.err));

    if (!response && !response.data.success) return;

    successNotify(translate("toast.productDeleted"));

    refetchProducts();
  };

  const checkDeleteMultiple = () => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteProducts"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMultipleProducts();
      }
    });
  };

  const deleteMultipleProducts = async () => {
    const ids = selectedFlatRows.map((id) => id.original.id);

    const response = await axios
      .delete(`/product`, { data: { IDs: ids } })
      .catch((err) => errorNotify(err.response.data.err));

    if (!response && !response.data.success) return;

    successNotify(translate("toast.productsDeleted"));

    refetchProducts();
  };

  const selectTab = (tab) => {
    setTab(tab.name);
    if (tab.query !== null) {
      searchParams.set("published", tab.query);
      setSearchParams(searchParams);
    } else {
      searchParams.delete("published");
      setSearchParams(searchParams);
    }
  };

  const gotToProductDetails = (e, id) => {
    if (
      e.target.tagName !== "INPUT" &&
      e.target.tagName !== "BUTTON" &&
      e.target.tagName !== "LI" &&
      e.target.tagName !== "SPAN"
    ) {
      navigate(`/products/${id}`);
    }
  };

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box
      className={cls.allProductsTable}
      sx={{ bgcolor: "background.secondary" }}
    >
      <Box className={cls.allProductsTable__head}>
        <button onClick={() => navigate("/products/create-product")}>
          <i className="fa-solid fa-plus"></i> {translate("product.add")}
        </button>

        <Box className={cls.allProductsTable__head_search}>
          <input
            type="search"
            placeholder={translate("product.search")}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />

          <i className="fa-light fa-magnifying-glass"></i>
        </Box>
      </Box>

      {selectedFlatRows.length >= 1 && (
        <Box className={cls.deleteSelected}>
          {translate("product.select")} {selectedFlatRows.length}{" "}
          {translate("product.result")}{" "}
          <span onClick={checkDeleteMultiple}>
            {translate("product.remove")}
          </span>
        </Box>
      )}

      <Box className={cls.allProductsTable__tabs}>
        {tabs.map((singleTab) => (
          <Typography
            key={singleTab.name}
            className={tab === singleTab.name ? cls.active : ""}
            onClick={() => selectTab(singleTab)}
          >
            {singleTab.name}

            {/* <span className={cls[i18n.language]}>{products.length}</span> */}
          </Typography>
        ))}
      </Box>

      <Box className={cls.allProductsTable__table}>
        {productsLoading ? (
          <LargeSquare />
        ) : (
          <>
            {products.length && rows.length >= 1 ? (
              <Table {...getTableProps()}>
                <TableHead>
                  {headerGroups.map((headerGroup, idx) => (
                    <TableRow key={idx} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, idx) => (
                        <TableCell
                          key={idx}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                          }}
                        >
                          <span>
                            {column.render("header")}

                            {!column.disableSortBy && (
                              <>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <i className="fa-light fa-sort-down"></i>
                                  ) : (
                                    <i className="fa-light fa-sort-up"></i>
                                  )
                                ) : (
                                  <i className="fa-light fa-sort"></i>
                                )}
                              </>
                            )}
                          </span>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>

                <TableBody {...getTableBodyProps()}>
                  {page.map((row, idx) => {
                    prepareRow(row);

                    return (
                      <TableRow
                        key={idx}
                        {...row.getRowProps()}
                        onClick={(e) => gotToProductDetails(e, row.original.id)}
                      >
                        {row.cells.map((cell, idx) => (
                          <TableCell
                            key={idx}
                            {...cell.getCellProps()}
                            style={{
                              minWidth: cell.column.minWidth,
                              width: cell.column.width,
                            }}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Box className="empty">
                <img src="/assets/imgs/common/noProducts.png" alt="empty" />
                <p>{translate("analytics.emptyProducts")}</p>
              </Box>
            )}
          </>
        )}
      </Box>

      <Box className={cls.allProductsTable__footer}>
        <Typography>
          {translate("product.show")} {pageNumber} {translate("product.of")}{" "}
          {productsCount ? Math.ceil(productsCount / limit) : 1}{" "}
          {translate("product.results")}
        </Typography>
        <Box className={`${cls.pagination} pagination`}>
          <Pagination
            count={productsCount ? Math.ceil(productsCount / limit) : 1}
            page={pageNumber}
            onChange={changePage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AllProductsTable;
