import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { CreateProductDetails, Loading } from "../../../components";
import { CreateProductOptions } from "../../../components";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import axios from "../../../utils/axios";

import { useQuery } from "react-query";

import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

import cls from "./createProduct.module.scss";

const CreateProduct = () => {
  // COMPONENT HOOKS
  // PRODUCT DETAILS STATES
  const [productFields, setProductFields] = useState({
    name: "",
    full_description: "",
    short_description: "",
    images: [],
    colors: [],
    specs: {},
    price: {
      amount: "",
      sale: {
        onSale: false,
        type: "percent",
        percent: '',
        amount: '',
        expiration: Date.now(),
      },
    },
    published: true,
    category: "",
    sub_category: "",
    brand: "",
    tags: [],
  });
  const [discount, setDiscount] = useState({ item: "Percentage" });
  const [colorValue, setColorValue] = useState("");
  const [colorQuantity, setColorQuantity] = useState("");
  const [colorPriceDifference, setColorPriceDifference] = useState("");
  const [spec, setSpec] = useState("");
  const [specValue, setSpecValue] = useState("");
  const [haveSale, setHaveSale] = useState(false);

  // PRODUCT OPTIONS STATES
  const [selectStatus, setSelectStatus] = useState("Published");
  const [choosedCategory, setChoosedCategory] = useState();
  const [choosedSubCategory, setChoosedSubCategory] = useState();
  const [categoryValue, setCategoryValue] = useState("");
  const [subCategoryValue, setSubCategoryValue] = useState("");

  const [emptyFields, setEmptyFields] = useState(false);
  const { t: translate, i18n } = useTranslation("common");
  const [searchParams, setSearchParams] = useSearchParams();
  const productId = searchParams.get("product");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // COMPONENT HANDLERS
  const fetchProductData = async () => {
    if (productId) {
      const response = await axios.get(`/product/${productId}?pc=1&pru=1`);

      if (!response || !response.data) return;

      return response.data.success;
    }
  };

  const { data: productData, isLoading } = useQuery(
    "productData",
    fetchProductData,
    {
      refetchOnWindowFocus: false,
      onSuccess: (productData) => {
        if (productId) {
          setProductFields({
            name: productData.name,
            full_description: productData.full_description,
            price: {
              amount: productData.price.amount,
              sale: productData.price.sale,
            },
            short_description: productData.short_description,
            images: productData.images,
            colors: productData.colors,
            category: productData.category.name,
            brand: productData.brand,
            tags: productData.tags[0].split(","),
            specs: productData.specs,
          });

          setHaveSale(productData.price.sale.onSale);
          setSelectStatus(productData.published ? "Published" : "Hidden");
          setCategoryValue(productData.category.name);
          setChoosedCategory(productData.category);
          setChoosedSubCategory(
            productData.category.sub_categories.find(
              (sub) => sub._id === productData.sub_category
            )
          );
          setSubCategoryValue(
            productData.category.sub_categories.find(
              (sub) => sub._id === productData.sub_category
            ).name
          );
        }
      },
    }
  );

  const fetchAllCategories = async () => {
    const { data } = await axios
      .get("/category?select=sub_categories,name")
      .catch((err) => {
        errorNotify(err.response.data.err);
      });

    if (!data) return;

    return data.success;
  };

  const { data: allCategories } = useQuery(
    "fetchCategories",
    fetchAllCategories
  );

  const addNewProduct = async () => {
    const data = {
      name: productFields.name,
      full_description: productFields.full_description,
      short_description: productFields.short_description,
      price: productFields.price.amount,
      published: selectStatus === "Published" ? true : false,
      brand: productFields.brand,
      tags: productFields.tags,
      category: categoryValue && choosedCategory._id,
      sub_category: categoryValue && choosedSubCategory._id,
    };

    const checkEmpty = Object.values(data).filter(
      (one) => one === undefined || one === ""
    );

    if (
      checkEmpty.length >= 1 ||
      !productFields.images.length ||
      !productFields.colors.length ||
      !productFields.tags.length
    ) {
      return setEmptyFields(true);
    }

    setEmptyFields(false);
    setLoading(true);

    let newProductData = new FormData();

    for (var val in data) {
      newProductData.append(val, data[val]);
    }

    productFields.images.forEach((image) => {
      newProductData.append("product_images", image);
    });

    newProductData.append("colors", JSON.stringify(productFields.colors));

    const productSale = {
      onSale: productFields.price.sale.onSale,
      type: productFields.price.sale.type,
      percent: productFields.price.sale.percent ? productFields.price.sale.percent : 0,
      amount: productFields.price.sale.amount ? productFields.price.sale.amount : 0,
      expiration: productFields.price.sale.expiration,
    }

    newProductData.append("sale", JSON.stringify(productSale));

    newProductData.append("specs", JSON.stringify(productFields.specs));

    const response = await axios
      .post("/product", newProductData)
      .catch((err) => {
        errorNotify(err.response.data.err);
        setLoading(false);
      });

    if (!response || !response.data) return;

    setLoading(false);

    navigate("/products/all");

    successNotify(translate("toast.productAdded"));
  };

  const updateProduct = async () => {
    const oldData = {
      name: productData.name,
      full_description: productData.full_description,
      price: productData.price.amount,
      short_description: productData.short_description,
      colors: productData.colors,
      brand: productData.brand,
      tags: productData.tags,
      category: productData.category,
      sub_category: productData.sub_category,
      sale: {
        onSale: productData.price.sale.onSale,
        type: productData.price.sale.type,
        percent: productData.price.sale.percent,
        amount: productData.price.sale.amount,
        expiration: productData.price.sale.expiration,
      },
      specs: productData.specs,
    };

    const newData = {
      name: productFields.name,
      full_description: productFields.full_description,
      short_description: productFields.short_description,
      price: productFields.price.amount,
      published: selectStatus === "Published" ? true : false,
      brand: productFields.brand,
      tags: productFields.tags,
      colors: productFields.colors,
      category: categoryValue && choosedCategory._id,
      sub_category: categoryValue && choosedSubCategory._id,
      sale: productFields.price.sale,
      specs: productFields.specs,
    };

    /** IMAGES **/
    let deletedImages = [];
    let new_product_images = [];
    productData.images.forEach((img) => {
      let imageFound = productFields.images.find((image) => image === img);
      if (!imageFound) deletedImages.push(img);
    });
    productFields.images.forEach((img) => {
      if (typeof img === "object") new_product_images.push(img);
    });

    let updatedData = {};

    Object.keys(newData).forEach((key) => {
      if (newData[key] !== oldData[key]) {
        updatedData[key] = newData[key];
      }
    });

    const checkEmpty = Object.values(newData).filter(
      (one) => one === undefined || one === ""
    );

    if (
      checkEmpty.length >= 1 ||
      !productFields.images.length ||
      !productFields.colors.length ||
      !productFields.tags.length
    ) {
      return setEmptyFields(true);
    }

    setLoading(true);

    const response = await axios
      .patch(`/product/${productId}`, updatedData)
      .catch((err) => {
        errorNotify(err.response.data.err);

        setLoading(false);
      });

    if (!response || !response.data) return;

    const updatedProductData = new FormData();

    if (deletedImages.length) {
      updatedProductData.append("deletedImages", JSON.stringify(deletedImages));
    }

    if (new_product_images.length) {
      new_product_images.forEach((image) =>
        updatedProductData.append("product_images", image)
      );
    }

    let imagesError = false;
    if (deletedImages.length || new_product_images.length) {
      const imagesResponse = await axios
        .patch(`/product/${productId}/images`, updatedProductData)
        .catch((err) => {
          errorNotify(err.response.data.err);
          setLoading(false);
          imagesError = true;
        });
    }

    setLoading(false);

    if (!imagesError) {
      successNotify(translate("toast.productUpdated"));
      navigate("/products/all");
    }
  };

  const productDetailsProps = {
    productFields,
    setProductFields,
    emptyFields,
    setDiscount,
    colorValue,
    setColorValue,
    colorQuantity,
    setColorQuantity,
    colorPriceDifference,
    setColorPriceDifference,
    haveSale,
    setHaveSale,
    spec,
    setSpec,
    specValue,
    setSpecValue,
  };

  const productsOptionsProps = {
    productFields,
    setProductFields,
    emptyFields,
    selectStatus,
    setSelectStatus,
    categoryValue,
    setCategoryValue,
    subCategoryValue,
    setSubCategoryValue,
    allCategories,
    choosedCategory,
    setChoosedCategory,
    setChoosedSubCategory,
  };

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box className={cls.createProduct}>
      {loading && <Loading />}

      <Box className={cls.createProducts__head}>
        <Typography variant="h6" component="h6">
          {translate("createPro.title")}
        </Typography>

        <Typography>
          <span>{translate("createPro.products")}</span> &gt;{" "}
          {translate("createPro.create")}
        </Typography>
      </Box>

      <Grid container spacing={3} className={cls.createProduct__wrapper}>
        <Grid item xs={12} lg={8} className={cls.createProduct__details}>
          <CreateProductDetails {...productDetailsProps} />
        </Grid>

        <Grid item xs={12} lg={4} className={cls.createProduct__options}>
          <CreateProductOptions {...productsOptionsProps} />
        </Grid>
      </Grid>

      <Box className={cls.submit}>
        {productId ? (
          <button onClick={updateProduct}>
            {translate("createPro.updateProduct")}
          </button>
        ) : (
          <button onClick={addNewProduct}>
            {translate("createPro.addNewProduct")}
          </button>
        )}
      </Box>
    </Box>
  );
};

export default CreateProduct;
