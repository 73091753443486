import { useMemo, useState } from "react";
import { useNavigate } from "react-router";

import { useSelector } from "react-redux";

import { ChooseList } from "../../../components";

import { useTable, usePagination } from "react-table";

import { format } from "date-fns";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";

import { useQuery } from "react-query";

import { useTranslation } from "react-i18next";

import axios from "../../../utils/axios";

import CurrencyFormat from "react-currency-format";

import cls from "./bestSellingTable.module.scss";
import { Typography } from "@mui/material";
import LargeSquare from "./../../UIs/skeletons/LargeSquare/LargeSquare";

const BestSellingTable = () => {
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [dateFrom, setDateFrom] = useState(Date.now() - 24 * 60 * 60 * 1000);
  const [dateTo, setDateTo] = useState(Date.now());
  const [filter, setFilter] = useState("today");
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const { t: translate, i18n } = useTranslation("common");
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const navigate = useNavigate();

  // COMPONENT HANDLERS
  const fetchBestSelling = async () => {
    const response = await axios.get(
      `/product?sort=-solds&limit=${limit}&skip=${
        (pageNumber - 1) * limit
      }&createdAt=${dateFrom}~${dateTo}`
    );

    if (!response || !response.data.success) return;

    const bestSellingData = response.data.success.products.map((product) => ({
      id: product._id,
      product: [
        {
          img: product.images[0],
          name: product.name,
          date: product.createdAt,
        },
      ],
      price: product.price.amount,
      orders: product.solds,
      stock: [
        {
          inStock: true,
          available: product.availability,
        },
      ],
      rating: product.rating,
    }));

    setBestSellingProducts(bestSellingData);

    setTotalCount(response.data.success.totalCount);

    return bestSellingData;
  };

  const { data, isFetching, isError } = useQuery(
    ["bestSellingProducts", pageNumber, dateFrom, dateTo],
    fetchBestSelling,
    {
      refetchOnWindowFocus: false,
    }
  );

  const changeOrdersFilter = (from, to, filterWord) => {
    setDateFrom(from);
    setDateTo(to);
    setFilter(filterWord);
  };

  const filterOptions = [
    {
      item: `${translate("analytics.today")}`,
      method: () =>
        changeOrdersFilter(
          Date.now() - 24 * 60 * 60 * 1000,
          Date.now(),
          "today"
        ),
      active: filter === "today" ? true : false,
    },
    {
      item: `${translate("analytics.yesterday")}`,
      method: () =>
        changeOrdersFilter(
          Date.now() - 2 * 24 * 60 * 60 * 1000,
          Date.now() - 24 * 60 * 60 * 1000,
          "yesterday"
        ),
      active: filter === "yesterday" ? true : false,
    },
    {
      item: `${translate("analytics.lastWeek")}`,
      method: () =>
        changeOrdersFilter(
          Date.now() - 7 * 24 * 60 * 60 * 1000,
          Date.now(),
          "lastWeek"
        ),
      active: filter === "lastWeek" ? true : false,
    },
    {
      item: `${translate("analytics.lastMonth")}`,
      method: () =>
        changeOrdersFilter(
          Date.now() - 30 * 24 * 60 * 60 * 1000,
          Date.now(),
          "lastMonth"
        ),
      active: filter === "lastMonth" ? true : false,
    },
  ];

  // TABLE COLUMNS STRUCTURE
  const columns = useMemo(
    () => [
      {
        id: "product",
        accessor: (data) =>
          data.product.map((item, idx) => (
            <div key={idx} className={cls.product}>
              <img src={item.img} />
              <div className={cls.product_info}>
                <span>{item.name.substring(0, 20) + "..."}</span>
                <p>{format(new Date(item.date).getTime(), "dd/MM/yyyy")}</p>
              </div>
            </div>
          )),
      },
      {
        id: "price",
        accessor: (data) => (
          <div>
            <span>
              <CurrencyFormat
                value={data.price}
                displayType={"text"}
                thousandSeparator={true}
                renderText={(value) => <div>{(+value).toFixed(2)}</div>}
              />
            </span>
            <p>
              {translate("analytics.price")} ({websiteData.currency})
            </p>
          </div>
        ),
      },
      {
        id: "orders",
        accessor: (data) => (
          <div>
            <span>{data.orders}</span>
            <p>{translate("analytics.order")}</p>
          </div>
        ),
      },
      {
        id: "stock",
        accessor: (data) =>
          data.stock.map((item, idx) => (
            <div key={idx}>
              {item.inStock ? (
                <span key={item}>{item.available}</span>
              ) : (
                <span className={cls.outStock} key={item}>
                  {translate("analytics.outStock")}
                </span>
              )}
              <p>{translate("analytics.stock")}</p>
            </div>
          )),
      },
      {
        id: "rating",
        accessor: (data) => (
          <div>
            <span>{data.rating}</span>
            <p>{translate("analytics.rating")}</p>
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  const tableInsatance = useTable(
    { columns, data: bestSellingProducts, initialState: { pageSize: 5 } },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    page,
    prepareRow,
    pageOptions,
    state,
    gotoPage,
    pageCount,
  } = tableInsatance;

  const { pageIndex } = state;

  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
    gotoPage(pageNum - 1);
  };

  return (
    <>
      {isFetching ? (
        <Box className={cls.bestSellingTable2}>
          <LargeSquare />
        </Box>
      ) : (
        <Box
          className={`${cls.bestSellingTable} section`}
          sx={{ bgcolor: "background.secondary" }}
        >
          <Box className={cls.bestSellingTable__head}>
            <Typography>{translate("analtics.bestSelling")}</Typography>
            <ChooseList
              list={filterOptions}
              text={translate("analytics.filter")}
            />
          </Box>

          {bestSellingProducts.length ? (
            <>
              <Table {...getTableProps}>
                <TableBody {...getTableBodyProps}>
                  {page.map((row, idx) => {
                    prepareRow(row);
                    return (
                      <TableRow
                        key={idx}
                        {...row.getRowProps()}
                        onClick={() => navigate(`/products/${row.original.id}`)}
                      >
                        {row.cells.map((cell, idx) => (
                          <TableCell key={idx} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

              <Box className={cls.bestSellingTable__footer}>
                <Typography>
                  {translate("analytics.show")} {pageNumber}{" "}
                  {translate("analytics.of")} {Math.ceil(totalCount / limit)}{" "}
                  {translate("analytics.results")}
                </Typography>
                <Box className={`${cls.pagination} pagination`}>
                  <Pagination
                    count={Math.ceil(totalCount / limit)}
                    page={pageNumber}
                    onChange={changePage}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Box className="empty">
              <img src="/assets/imgs/common/noProducts.png" alt="empty" />
              <p>{translate("analytics.noProducts")}</p>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default BestSellingTable;
