import { useState, useRef } from "react";

import { useSelector } from "react-redux";

import InputFieldComponent from "../../UIs/InputField/InputField";

import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";

import cls from "./showVariant.module.scss";

const ShowVariant = ({
  setOpenShowVariant,
  choosedColor,
  colorsList,
  setColorsList,
}) => {
  // COMPONENT HOOKS
  const [varaintName, setVariantName] = useState("");
  const [variantQuantity, setVariantQuantity] = useState("");
  const [variantPriceDiffer, setVariantPriceDiffer] = useState("");
  const [choosedColorHere, setChoosedColorHere] = useState(choosedColor);
  const [activeIndex, setActiveIndex] = useState(null)
  const [emptyFields, setEmptyFields] = useState(false);
  const overlay = useRef();
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const { t: translate, i18n } = useTranslation("common");

  // COMPONENT HANDLERS
  const closeModal = (e) => {
    if (overlay.current === e.target) setOpenShowVariant(false);
  };

  const close = () => {
    setOpenShowVariant(false);
  };

  const addAndUpdateVariant = (updating) => {
    const variant = {
      name: varaintName,
      availability: +variantQuantity,
      priceDifference: variantPriceDiffer,
    };

    if (!varaintName || !variantQuantity || !variantPriceDiffer) {
      return setEmptyFields(true);
    }

    setEmptyFields(false);

    if ( !updating )choosedColor.variants.push(variant);

    if ( updating ) choosedColor.variants[activeIndex] = variant

    choosedColor.availability = choosedColor.variants.reduce((total, one) => total += one.availability, 0);

    setChoosedColorHere({ ...choosedColor });

    const colorFound = colorsList.findIndex(
      (color) => color.hex === choosedColor.hex
    );

    colorsList.splice(colorFound, 1, choosedColor);

    setColorsList((prev) => ({ ...prev, colors: colorsList }));

    setVariantName("");
    setVariantQuantity("");
    setVariantPriceDiffer("");

    if ( updating ) setActiveIndex(null)
  };

  const switchToEditMode = (variant, idx) => {
    setActiveIndex(idx)
    setVariantName(variant.name)
    setVariantQuantity(variant.availability)
    setVariantPriceDiffer(variant.priceDifference)
  }

  const deleteVariant = (variant, idx) => {
    const variantFound = choosedColorHere.variants.findIndex(
      (vari) => vari.name === variant.name
    );
    choosedColorHere.variants.splice(variantFound, 1);
    setChoosedColorHere({ ...choosedColorHere });

    if (idx === activeIndex) {
      setVariantName("");
      setVariantQuantity("");
      setVariantPriceDiffer("");
      setActiveIndex(null)
    }
  };

  return (
    <Box className={cls.overlay} ref={overlay} onClick={(e) => closeModal(e)}>
      <Box className={cls.area}>
        <Box className={cls.area__wrapper}>
          <Box className={`${cls.close} ${cls[i18n.language]}`} onClick={close}>
            <i className="fa-solid fa-xmark"></i>
          </Box>

          <Box container className={cls.area__content} spacing={3}>
            <h3>{translate("createPro.proVariant")}</h3>

            <Box className={cls.variants}>
              {choosedColorHere.variants.length ? (
                <>
                  {choosedColor.variants.map((variant, idx) => (
                    <span key={idx}>
                      <span onClick={() => switchToEditMode(variant, idx)}>{variant.name}{" "}</span>
                      <i
                        className="fa-light fa-xmark"
                        onClick={() => deleteVariant(variant, idx)}
                      ></i>
                    </span>
                  ))}
                </>
              ) : (
                <p>{translate("createPro.noVariants")}</p>
              )}
            </Box>

            <h6>{translate('createPro.addNew')}</h6>

            <Box className={cls.fields}>
              <Box className={cls.field}>
                <label htmlFor="">{translate("createPro.variant")}</label>

                <InputFieldComponent
                  type="text"
                  placeholder={translate("createPro.variant")}
                  value={varaintName}
                  setFunc={(e) => setVariantName(e.target.value)}
                  error={emptyFields && !varaintName ? true : false}
                />
              </Box>

              <Box className={cls.field}>
                <label htmlFor="">{translate("createPro.quantity")}</label>

                <InputFieldComponent
                  type="number"
                  placeholder={translate("createPro.quantity")}
                  value={variantQuantity}
                  setFunc={(e) => setVariantQuantity(e.target.value)}
                  error={emptyFields && !variantQuantity ? true : false}
                />
              </Box>

              <Box className={cls.field}>
                <label htmlFor="">
                  {translate("createPro.priceDifference")} (
                  {websiteData.currency})
                </label>

                <InputFieldComponent
                  type="number"
                  placeholder={translate("createPro.priceDifference")}
                  value={variantPriceDiffer}
                  setFunc={(e) => setVariantPriceDiffer(e.target.value)}
                  error={emptyFields && !variantPriceDiffer ? true : false}
                />
              </Box>

              <Box className={cls.btn}>
                {activeIndex === null && 
                  <button onClick={() => addAndUpdateVariant(false)}>
                    {translate("createPro.addVariant")}
                  </button>
                }
                {activeIndex !== null &&
                  <button onClick={() => addAndUpdateVariant(true)}>
                    {translate("createPro.editVariant")}
                  </button>
                }
              </Box>
            </Box>

            <Box className={cls.actions}>
              <button onClick={close}>{translate("orders.close")}</button>

              <button onClick={close}>{translate("createPro.submit")}</button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ShowVariant;
