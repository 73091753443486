import { useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import TableCheckBox from './../../../components/UIs/TableCheckBox/TableCheckBox';
import ChooseList from './../../../components/UIs/ChooseList/ChooseList';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { useTable, useSortBy, useRowSelect, useGlobalFilter } from 'react-table';

import { useQuery } from 'react-query';
import axios from '../../../utils/axios';

import { useTranslation } from 'react-i18next';

import cls from './allAds.module.scss';
import LargeSquare from './../../../components/UIs/skeletons/LargeSquare/LargeSquare';

const AllAds = () => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation('common');
  const navigate = useNavigate();

  const fetchAllAdvertisemnets = async () => {
    const response = await axios.get('/ads');

    if(!response || !response.data.success) return;

    return response.data.success;
  }

  // SELECT CALLBACK FUNCTION RETURNS DATA THAT OVERWRITES THE DEFAULT DESTRUCTURED DATA FROM USE QUERY...
  const { data: advers, isLoading, isFetching, error } = useQuery(['advers'], fetchAllAdvertisemnets, {
    refetchOnWindowFocus: false,
    select: (advers) => (
      advers.map(adver => (
        {
          _id: adver._id,
          poster: {
            title: adver.title, 
            thumb: adver.image
          },
          subTitle: adver.sub_title,
          position: adver.position
        }
      ))
    )
  });

  const editAd = (adId) => {
    navigate(`/ads/edit-ad/${adId}`);
  }

  const actionList = (adId) => (
    [
      {
        item: `${translate('category.edit')}`,
        icon: <i className="fa-light fa-pen-nib"></i>,
        method: () => editAd(adId)
      },
    ]
  )

  // TABLE DATA
  const data = useMemo(() => (advers || []), [advers]);

  // TABLE COLUMNS
  const columns = useMemo(() => [
    {
      header: `${translate('allAds.poster')}`,
      accessor: 'poster.title',
      Cell: ({ row }) => (
        <div className={cls.category}>
          <img src={row.original.poster.thumb} alt={row.original.poster.title} />
          <span>{row.original.poster.title}</span>
        </div>
      ),
      minWidth: '30%',
      width: '30%'
    },
    {
      header: `${translate('allAds.subTitle')}`,
      accessor: 'subTitle',
      minWidth: '25%',
      width: '25%'
    },
    {
      header: `${translate('allAds.position')}`,
      accessor: 'position',
      minWidth: '20%',
      width: '20%'
    },
    {
      header: `${translate('allAds.action')}`,
      id: 'action',
      Cell: ({ row }) => (
        <ChooseList list={actionList(row.original._id)} id={row.original._id} text={<i className="fa-thin fa-ellipsis-stroke"></i>} withArrow={false} />
      ),
      disableSortBy: true,
      minWidth: '2%',
      width: '2%'
    }
  ], [i18n.language])

  const tableInstance = useTable(
    { columns, data }, 
    useGlobalFilter,
    useSortBy, 
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push(columns => [
        {
          header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <TableCheckBox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          id: 'selection',
          Cell: ({ row }) => (
            <div>
              <TableCheckBox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableSortBy: true,
          minWidth: '2%',
          width: '2%'
        },
        ...columns,
      ])
    }
  );

  const { 
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    rows, 
    prepareRow,
    selectedFlatRows,
    setGlobalFilter,
    state: {selectedRowIds}
  } = tableInstance;

  return (

    <>

      <Box className={cls.allAds__head}>

        <Typography variant='h6' component='h6'>{translate('allAds.title')}</Typography>

        <Typography><span>{translate('allAds.ads')}</span> &gt; {translate('allAds.all')}</Typography>

      </Box>

      {/* {selectedFlatRows.length >= 1 && 

        <Box className={cls.deleteSelected}>
          {translate('category.select')} {selectedFlatRows.length} {translate('category.result')} <span>{translate('category.remove')}</span>
        </Box>
      
      } */}

      {isLoading || isFetching ? 

        <LargeSquare />
      
        :

        <Grid container spacing={3} className={cls.allAds}>
  
          <Grid item xs={12}>
  
            <Box className={cls.allAds__table} sx={{ bgcolor: 'background.secondary' }}>
  
              {/* <Box className={cls.allAds__table_head}>
  
                <Box className={cls.allProductsTable__head_search}>
  
                  <input type="search" placeholder={translate('allAds.search')} onChange={(e) => setGlobalFilter(e.target.value)} />
  
                  <i className="fa-light fa-magnifying-glass"></i>
  
                </Box>
  
              </Box> */}

              {advers.length >= 1 ? 
                <Box className={cls.table}> 
    
                  <Table {...getTableProps()}>
    
                    <TableHead>
    
                      {headerGroups.map((headerGroup, idx) => (
    
                        <TableRow key={idx} {...headerGroup.getHeaderGroupProps()}>
    
                          {headerGroup.headers.map((column, idx) => (
    
                            <TableCell key={idx} {...column.getHeaderProps(column.getSortByToggleProps())} style={{minWidth: column.minWidth, width: column.width}}>
    
                              <span>
    
                              {column.render('header')} 
    
                              {!column.disableSortBy && 
    
                              <>{column.isSorted ? column.isSortedDesc ? <i className="fa-light fa-sort-down"></i> : <i className="fa-light fa-sort-up"></i> : <i className="fa-light fa-sort"></i>}</> 
    
                              }
    
                              </span>
    
                            </TableCell>
    
                          ))}
    
                        </TableRow>
                        
                      ))}
    
                    </TableHead>
    
                    <TableBody {...getTableBodyProps()}>
    
                      {rows.map((row, idx) => {
    
                        prepareRow(row)
    
                        return (
    
                          <TableRow key={idx} {...row.getRowProps()}>
    
                            {row.cells.map((cell, idx) => (
    
                              <TableCell key={idx} {...cell.getCellProps()} style={{minWidth: cell.column.minWidth, width: cell.column.width}}>
                                
                                {cell.render('Cell')}
    
                              </TableCell>
    
                            ))}
    
                          </TableRow>
    
                        )
    
                      })}
    
                    </TableBody>
    
                  </Table>
    
                </Box>
                :
                <Box className="empty">
                  <img src="/assets/imgs/promos/promo.png" alt="no ads" />
                  <p>{translate("allAds.empty")}</p>
                </Box>
              }
  
  
            </Box>
  
          </Grid>
  
        </Grid>
    
      }

    </>
  )
}

export default AllAds;