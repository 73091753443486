import { ContactsTable } from './../../components';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import cls from './contact.module.scss';

const Contact = () => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation('common');

  return (
    <Box className={cls.contact}>

      <Box className={cls.contact__head}>

        <Typography variant='h6' component='h6'>{translate('contact.title')}</Typography>

        <Typography><span>{translate('contact.dashboard')}</span> &gt; {translate('contact.contact')}</Typography>

      </Box>

      <ContactsTable />

    </Box>
  )
}

export default Contact