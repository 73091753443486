import { useState } from 'react';
import { useNavigate } from 'react-router';

import { LogisticsTable, TopAnalytics } from '../../components';
import SmallSquare from './../../components/UIs/skeletons/SmallSquare/SmallSquare';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import axios from '../../utils/axios';
import { useQuery } from 'react-query';

import { useTranslation } from 'react-i18next';

import cls from './logistics.module.scss';

const Logistics = () => {
  // COMPONENT HOOKS
  const navigate = useNavigate()
  const { t: translate } = useTranslation('common');

  const analytics = [
    {
      id: 1,
      label: `${translate('topAnalytics.countries')}`,
      var: '-',
      link: `${translate('topAnalytics.ourCountries')}`,
      icon: <i className="fa-light fa-earth-americas"></i>
    },
    {
      id: 2,
      label: `${translate('topAnalytics.cities')}`,
      var: '-',
      link: `${translate('topAnalytics.ourCities')}`,
      icon: <i className="fa-light fa-city"></i>
    }
  ]

  const fetchLogisticsAnalytics = async () => {
    const response = await axios.get('/logistics/analytics');

    if(!response && !response.data.success) return;

    return response.data.success;
  }

  const { data: LogisticsAnalytics, isLoading, isError } = useQuery('LogisticsAnalytics', fetchLogisticsAnalytics, {
    select: (LogisticsAnalytics) => {
      analytics[0].var = LogisticsAnalytics.totalCountries;
      analytics[1].var = LogisticsAnalytics.totalCities;
    }
  });

  return (
    <Box className={cls.logistics}>

      <Box className={cls.logistics__head}>

        <Typography variant='h6' component='h6'>{translate('logistics.title')}</Typography>

        <Typography><span>{translate('logistics.logistics')}</span> &gt; {translate('logistics.townsList')}</Typography>

      </Box>

      {/* TOP ANALYTICS SECTION */}
      {!isLoading ? 

        <TopAnalytics analytics={analytics} />

        :

        <Grid container spacing={2} style={{ marginBottom: '20px' }}>

          <Grid item xs={12} md={6} lg={6}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <SmallSquare />
          </Grid>

        </Grid>

      }

      <Box className={cls.logistics__table} sx={{ bgcolor: 'background.secondary' }}>

        <Box className={cls.logistics__table_head}>

          <Typography variant="h6" component="h6">
            {translate('logistics.townsList')}
          </Typography>

          <button onClick={() => navigate('/logistics/add-country')}><i className="fa-regular fa-plus"></i> {translate('logistics.add')}</button>

        </Box>

        <LogisticsTable />

      </Box>

    </Box>
  )
}

export default Logistics;