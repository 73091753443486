import { useState, useEffect } from 'react';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';

import { NavLinks } from './navLinks';

import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';

import cls from './navList.module.scss';

const NavList = ({ smallBar, setMobSidebar }) => {
  // COMPONENT HOOKS
  const location = useLocation()
  const navigate = useNavigate()
  const [showSub, setShowSub] = useState(location.pathname.split('/')[1]);
  const { t: translate, i18n } = useTranslation('nav')

  useEffect(() => {
    let openedLink = document.getElementById(showSub);
    setShowSub(location.pathname.split('/')[1])
    if (location.pathname.split('/')[1] && openedLink) beOpen(openedLink)
  }, [])

  // COMPONENT HANDLERS
  const beOpen = (element) => { // SO ACCORDION OPEN WHEN PAGE RELOADS
    let subMenu = element.nextElementSibling;

    if (subMenu) {
      let maxHeight = subMenu.scrollHeight;

      if (!subMenu.style.maxHeight) {
        subMenu.style.maxHeight = maxHeight + 'px';
      } else {
        subMenu.style.maxHeight = null;
      }
    }
  }

  const showSubList = (element, list) => {
    if(showSub === list) {
      setShowSub('')
    }
    else {
      setShowSub(list)

      var elements = document.getElementsByClassName('subMenu');

      for(var i = 0; i < elements.length; i++) {
        elements[i].style.maxHeight = null;
      }
    }

    let subMenu = element.nextElementSibling;

    if (subMenu) {
      let maxHeight = subMenu.scrollHeight;

      if (!subMenu.style.maxHeight) {
        subMenu.style.maxHeight = maxHeight + 'px';
      } else {
        subMenu.style.maxHeight = null;
      }
    }
  }

  const navigateToPage = (link) => {
    navigate(link);
    if (setMobSidebar) {
      setMobSidebar(false)
    }
  }

  const navigateToSubPage = () => {
    if (setMobSidebar) {
      setMobSidebar(false)
    }
  }

  return (
    <Box className={`${cls.sidebar__list} ${smallBar ? cls.small : ''}`}>

      {NavLinks(translate).map((link, idx) => (

        <Box key={idx}>
        
          {link.subMenu.length >= 1 ? 
          
            <Box key={idx} className={`${cls.sidebar__list_link} ${showSub === link.condition ? cls.showSub : ''} ${cls[i18n.language]} ${link.condition}`}>

              <Box id={link.condition} className={`${cls.link} ${location.pathname.includes(link.condition) ? cls.selected : ''}`} onClick={(e) => showSubList(e.target, link.condition)}>

                <Box className={cls.icon}>

                  {link.icon}

                </Box>

                <Box className={cls.label}>
                  {link.lable}
                </Box>

                <i className={`${cls[i18n.language]} fa-regular fa-angle-down`}></i>

              </Box>

              <Box className={`${cls.subMenu} ${cls[i18n.language]} subMenu`}>

                <Box className={cls.wrapper}>

                  {showSub === link.condition && <Box className={cls.dropLayer} onClick={(e) => showSubList(e, '')}></Box>}

                  <ul>
                    {
                      link.subMenu.map((item, idx) => (
                        <NavLink key={idx} to={item.link} className={({ isActive }) => isActive ? cls.active : ''} onClick={navigateToSubPage}>
                          <li><i className="fa-light fa-circle-small"></i> {item.name}</li>
                        </NavLink>
                      ))
                    }
                  </ul>

                </Box>

              </Box>

            </Box>

            :

            <Box className={`${cls.sidebar__list_link} ${cls[i18n.language]}`}>

              <Box className={`${cls.link} ${location.pathname === link.link ? cls.selected : ''}`} onClick={() => navigateToPage(link.link)}>

                <Box className={cls.icon}>

                  {link.icon}

                </Box>

                <Box className={cls.label}>
                  {link.lable}
                </Box>

              </Box>

            </Box>
        
          }

        </Box>

      ))}

    </Box>
  )
}

export default NavList