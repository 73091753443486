import { useDispatch } from "react-redux";
import { userActions } from "../../../store/slices/userSlice";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { toast } from "react-toastify";

import axios from "../../../utils/axios";

import Swal from "sweetalert2";

import cls from "./linkSettings.module.scss";

const LinkSettings = ({ user, setLoading, setOpenLinkEmail }) => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation("common");
  const dispatch = useDispatch();

  const unLinkCheck = (external) => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("linkSettings.unLink"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("Unlink Account"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        unLinkAccount(external);
      }
    });
  };

  const unLinkAccount = async (external) => {
    setLoading(true);

    const res = await axios
      .delete(`/user/${user._id}/link`, { data: { link: external } });

    if (!res) return;

    setLoading(false);

    dispatch(userActions.updateUserLinking({ [external]: { linked: false } }));

    successNotify(translate("linkSettings.unlinked"));
  };

  const responseGoogle = async (res) => {
    setLoading(true);

    const data = {
      type: "google",
      google_token: res.tokenId,
    };

    const response = await axios
      .patch(`/user/${user._id}/link`, { link: data });

    if (!response) return;

    setLoading(false);

    dispatch(userActions.updateUserLinking({ google: { linked: true } }));

    successNotify(translate("linkSettings.linkedSuccess"));
  };

  const responseFacebook = async (res) => {
    setLoading(true);

    const data = {
      type: "facebook",
      facebook_token: res.tokenId,
    };

    const response = await axios
      .patch(`/user/${user._id}/link`, { link: data });

    if (!response) return;

    setLoading(false);

    dispatch(userActions.updateUserLinking({ facebook: { linked: true } }));

    successNotify(translate("linkSettings.linkedSuccess"));
  };

  const successNotify = (msg) => toast.success(`${msg}`);
  const errorNotify = (msg) => toast.error(`${msg}`);

  return (
    <Box className={cls.linkSettings} sx={{ bgcolor: "background.secondary" }}>
      <Typography variant="h6" component="h6">
        {translate("linkSettings.link")}
      </Typography>

      <Box className={cls.linkSettings__field}>
        <Box className={cls.linkSettings__field_account}>
          <i className="fa-regular fa-envelope"></i>

          <span>{translate("linkSettings.email")}</span>
        </Box>

        {user.password_linked ? (
          <Box className={cls.linkSettings__field_action}>
            <button disabled>{translate("linkSettings.linked")}</button>
          </Box>
        ) : (
          <Box className={cls.linkSettings__field_action}>
            <button onClick={() => setOpenLinkEmail(true)}>
              {translate("linkSettings.linkAcc")}
            </button>
          </Box>
        )}
      </Box>

      <Box className={cls.linkSettings__field}>
        <Box className={cls.linkSettings__field_account}>
          <i className="fa-brands fa-google"></i>

          <span>{translate("linkSettings.google")}</span>
        </Box>

        <GoogleLogin
          clientId="710445269332-g7l3bhrce7g6913ugk5446cs9vtdo9bg.apps.googleusercontent.com"
          render={(renderProps) => (
            <Box className={cls.linkSettings__field_action}>
              {user.externalAuth.google.linked ? (
                <button onClick={() => unLinkCheck("google")}>
                  {translate("linkSettings.unlinkAcc")}
                </button>
              ) : (
                <button onClick={renderProps.onClick}>
                  {translate("linkSettings.linkAcc")}
                </button>
              )}
            </Box>
          )}
          buttonText="Login"
          onSuccess={responseGoogle}
          // onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
        />

        {/* <Box className={cls.linkSettings__field_action}>
          <button>{translate("linkSettings.linkAcc")}</button>
        </Box> */}
      </Box>

      <Box className={cls.linkSettings__field}>
        <Box className={cls.linkSettings__field_account}>
          <i className="fa-brands fa-facebook-f"></i>

          <span>{translate("linkSettings.facebook")}</span>
        </Box>

        <FacebookLogin
          appId="370200991632471"
          callback={responseFacebook}
          render={(renderProps) => (
            <Box className={cls.linkSettings__field_action}>
              {user.externalAuth.facebook.linked ? (
                <button onClick={() => unLinkCheck("facebook")}>
                  {translate("linkSettings.unlinkAcc")}
                </button>
              ) : (
                <button onClick={renderProps.onClick}>
                  {translate("linkSettings.linkAcc")}
                </button>
              )}
            </Box>
          )}
        />

        {/* <Box className={cls.linkSettings__field_action}>
          <button>{translate("linkSettings.linkAcc")}</button>
        </Box> */}
      </Box>
    </Box>
  );
};

export default LinkSettings;
