import { useState } from "react";
import { useSelector } from "react-redux";

import LinkAccount from "../../components/modals/LinkAccount/LinkAccount";

import Loader from "../../components/UIs/Loading/Loading";
import CropImage from "../../components/modals/CropImage/CropImage";

import {
  ProfileSettings,
  SettingsNav,
  SecuritySettings,
  LinkSettings,
  DeactivateSettings,
  MobSettingsNav,
} from "../../components";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import axios from "../../utils/axios";

import { useQuery } from "react-query";

import cls from "./settings.module.scss";

const Settings = () => {
  // COMPONENT HOOKS
  const { user } = useSelector(({ user }) => user);
  const [currentPage, setCurrentPage] = useState("information");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openImageCropper, setOpenImageCropper] = useState(false);
  const [croppedImage, setCroppedImage] = useState(
    user.profile_picture ? user.profile_picture : "/assets/imgs/user/user.jpg"
  );
  const [oldPhoto, setOldPhoto] = useState(
    user.profile_picture ? user.profile_picture : "/assets/imgs/user/user.jpg"
  );
  const [loading, setLoading] = useState(false);
  const [openLinkEmail, setOpenLinkEmail] = useState(false);
  const { t: translate, i18n } = useTranslation("common");

  // COMPONENT HANDLERS
  const changePage = (page) => {
    setCurrentPage(page);
  };

  const fetchCurrentUserData = async () => {
    const response = await axios
      .get(`/user/${user._id}`);

    if (!response || !response.data) return;

    return response;
  };

  const { data: currentUserData, isLoading: userDataLoading } = useQuery(
    "currentUserData",
    fetchCurrentUserData
  );

  const renderCurrentPage = () => {
    switch (currentPage) {
      case "information":
        return (
          <ProfileSettings
            user={user}
            croppedImage={croppedImage}
            setCroppedImage={setCroppedImage}
            setOpenImageCropper={setOpenImageCropper}
            setLoading={setLoading}
          />
        );

      case "security":
        return <SecuritySettings user={user} setLoading={setLoading} />;

      case "link":
        return (
          <LinkSettings
            user={user}
            setLoading={setLoading}
            setOpenLinkEmail={setOpenLinkEmail}
          />
        );

      case "deactivate":
        return <DeactivateSettings setLoading={setLoading} user={user} />;
    }
  };

  return (
    <Box className={cls.settings}>
      {loading && <Loader />}

      <Box className={cls.settings__head}>
        <Typography variant="h6" component="h6">
          {translate("settings.title")}
        </Typography>

        <Typography>
          <span>{translate("settings.settings")}</span> &gt;{" "}
          {translate("settings.account")}
        </Typography>
      </Box>

      <Box
        className={cls.settings__smallLink}
        sx={{ bgcolor: "background.secondary" }}
      >
        <i
          className="fa-regular fa-bars-sort"
          onClick={() => setOpenSidebar(true)}
        ></i>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={8} className={cls.settings__page}>
          {renderCurrentPage()}
        </Grid>

        <Grid item lg={4} className={cls.settings__settingsNav}>
          <SettingsNav currentPage={currentPage} changePage={changePage} />
        </Grid>
      </Grid>

      <MobSettingsNav
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
        currentPage={currentPage}
        changePage={changePage}
      />

      {openImageCropper && (
        <CropImage
          setOpenCropImage={setOpenImageCropper}
          imageToCrop={croppedImage}
          setCroppedImage={setCroppedImage}
          oldPhoto={oldPhoto}
        />
      )}

      {openLinkEmail && (
        <LinkAccount
          setOpenLinkAccount={setOpenLinkEmail}
          setLoading={setLoading}
        />
      )}
    </Box>
  );
};

export default Settings;
