import { createSlice } from "@reduxjs/toolkit";

var initialState = { websiteData: {} };

const websiteDataSlice = createSlice({
  name: "websiteData",
  initialState,
  reducers: {
    setWebsiteData: (state, action) => {
      state.websiteData = action.payload;
    },
  },
});

export const websiteDataActions = websiteDataSlice.actions;

export default websiteDataSlice.reducer;
