import { useState, useMemo } from "react";
import { Link, Router, useNavigate } from "react-router-dom";

import ChooseList from "../UIs/ChooseList/ChooseList";
import TableCheckBox from "../UIs/TableCheckBox/TableCheckBox";
import Selectbox from "../UIs/SelectBox/Selectbox";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";

import { format } from "date-fns";

import { toast } from "react-toastify";
import Swal from "sweetalert2";

import axios from "../../utils/axios";
import { useQuery } from "react-query";

import { useTranslation } from "react-i18next";

import cls from "./contactsTable.module.scss";
import LargeSquare from "../UIs/skeletons/LargeSquare/LargeSquare";

const ContactsTable = () => {
  // COMPONENT HOOKS
  const [contactsList, setContactsList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();
  const { t: translate, i18n } = useTranslation("common");

  const actionList = (id) => [
    {
      item: `${translate("category.view")}`,
      icon: <i className="fa-light fa-eye"></i>,
      method: () => navigate(`/contact/${id}`),
    },
    {
      item: `${translate("category.delete")}`,
      icon: <i className="fa-light fa-trash-can"></i>,
      method: () => checkDeleteContact(id),
    },
  ];

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        header: `${translate("contact.name")}`,
        accessor: "name",
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("contact.email")}`,
        accessor: "email",
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("contact.phone")}`,
        id: "phone",
        accessor: "phone",
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("contact.message")}`,
        id: "message",
        accessor: "message",
        Cell: ({ value }) => (
          <span>
            {value.substring(0, 100)} {value.length > 100 ? "..." : ""}
          </span>
        ),
        minWidth: "20%",
        width: "20%",
      },
      {
        header: `${translate("contact.action")}`,
        id: "action",
        Cell: ({ row }) => (
          <ChooseList
            list={actionList(row.original.id)}
            text={<i className="fa-thin fa-ellipsis-stroke"></i>}
            withArrow={false}
          />
        ),
        disableSortBy: true,
        minWidth: "2%",
        width: "2%",
      },
    ],
    [i18n.language]
  );

  const tableInstatce = useTable(
    {
      columns,
      data: contactsList || [],
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <TableCheckBox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <TableCheckBox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableSortBy: true,
          minWidth: "2%",
          width: "2%",
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    pageCount,
    pageOptions,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, selectedRowIds },
  } = tableInstatce;

  // COMPONENT HANDLERS
  const changePage = (e, pageNum) => {
    setPageNumber(pageNum);
    gotoPage(pageNum - 1);
  };

  const fetchContacts = async () => {
    const response = await axios
      .get("/contact")
      .catch((err) => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    return response.data.success;
  };

  const {
    data: allContacts,
    isFetching: contactsLoading,
    refetch: refetchContacts,
  } = useQuery("allContacts", fetchContacts, {
    refetchOnWindowFocus: false,
    onSuccess: (allContacts) => {
      const updatedContacts = allContacts.map((contact) => ({
        id: contact._id,
        name: contact.name,
        email: contact.email_address,
        phone: contact.phone_number,
        message: contact.message,
      }));

      setContactsList([...updatedContacts]);
    },
  });

  const checkDeleteContact = (id) => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteContact"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteContact(id);
      }
    });
  };

  const deleteContact = async (id) => {
    const response = await axios.delete(`/contact/${id}`).catch((err) => {
      errorNotify(err.response.data.err);
    });

    if (!response || !response.data) return;

    refetchContacts();

    successNotify(translate("toast.contactDeleted"));
  };

  const checkDeleteMultipleContacts = () => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteContacts"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMultipleContacts();
      }
    });
  };

  const deleteMultipleContacts = async () => {
    const ids = selectedFlatRows.map((id) => id.original.id);
    const response = await axios
      .delete(`/user`, { data: { IDs: ids } })
      .catch((err) => {
        errorNotify(err.response.data.err);
      });

    if (!response || !response.data) return;

    refetchContacts();

    successNotify(translate("toast.contactsDeleted"));
  };

  const gotToContactDetails = (e, id) => {
    if (
      e.target.tagName !== "INPUT" &&
      e.target.tagName !== "BUTTON" &&
      e.target.tagName !== "LI"
    ) {
      navigate(`/contact/${id}`);
    }
  };

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box className={cls.contactsTable} sx={{ bgcolor: "background.secondary" }}>
      {selectedFlatRows.length >= 1 && (
        <Box className={cls.deleteSelected}>
          {translate("product.select")} {selectedFlatRows.length}{" "}
          {translate("product.result")}{" "}
          <span onClick={checkDeleteMultipleContacts}>
            {translate("product.remove")}
          </span>
        </Box>
      )}

      <Box className={cls.contactsTable__table}>
        <Box className={`${cls.search} ${cls[i18n.language]}`}>
          <i className="fa-light fa-magnifying-glass"></i>

          <input
            type="search"
            placeholder={translate("contact.search")}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </Box>
        {contactsLoading ? (
          <LargeSquare />
        ) : (
          <>
            {contactsList.length && rows.length >= 1 ? (
              <>

                <Table {...getTableProps()}>
                  <TableHead>
                    {headerGroups.map((headerGroup, idx) => (
                      <TableRow
                        key={idx}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column, idx) => (
                          <TableCell
                            key={idx}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={{
                              minWidth: column.minWidth,
                              width: column.width,
                            }}
                          >
                            <span>
                              {column.render("header")}

                              {!column.disableSortBy && (
                                <>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <i className="fa-light fa-sort-down"></i>
                                    ) : (
                                      <i className="fa-light fa-sort-up"></i>
                                    )
                                  ) : (
                                    <i className="fa-light fa-sort"></i>
                                  )}
                                </>
                              )}
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>

                  <TableBody {...getTableBodyProps()}>
                    {page.map((row, idx) => {
                      prepareRow(row);

                      return (
                        <TableRow
                          key={idx}
                          {...row.getRowProps()}
                          onClick={(e) =>
                            gotToContactDetails(e, row.original.id)
                          }
                        >
                          {row.cells.map((cell, idx) => (
                            <TableCell
                              key={idx}
                              {...cell.getCellProps()}
                              style={{
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                              }}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </>
            ) : (
              <Box className="empty">
                <img src="/assets/imgs/contact/message.png" alt="no contacts" />
                <p>{translate("contact.empty")}</p>
              </Box>
            )}
          </>
        )}
      </Box>

      {contactsList.length >= 1 && (
        <Box className={cls.contactsTable__footer}>
          <Typography>
            {translate("product.show")} {pageIndex + 1}{" "}
            {translate("product.of")} {pageOptions.length}{" "}
            {translate("product.results")}
          </Typography>

          <Box className={`${cls.pagination} pagination`}>
            <Pagination
              count={pageCount}
              page={pageNumber}
              onChange={changePage}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ContactsTable;
