import { useState, useRef } from 'react';

import Selectbox from '../../UIs/SelectBox/Selectbox';
import Loader from '../../UIs/Loading/Loading';

import Box from '@mui/material/Box';

import axios from '../../../utils/axios';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';

import cls from './editReturn.module.scss';

const EditReturn = ({ setShowReturnOrder, returnDetails, refetchReturns }) => {
  // COMPONENT HOOKS
  const overlay = useRef();
  const [orderStatus, setOrderStatus] = useState({name: returnDetails.status});
  const { t: translate, i18n } = useTranslation('common');

  const statusList = [
    {
      name: `${translate('orders.underProgress')}`,
      query: 'Under Progress'
    },
    {
      name: `${translate('orders.returned')}`,
      query: 'Returned'
    },
    {
      name: `${translate('orders.cancelled')}`,
      query: 'Cancelled'
    }
  ]

  // COMPONENT HANDLERS
  const closeModal = (e) => {
    if(overlay.current === e.target) setShowReturnOrder(false);
  }

  const close = () => {
    setShowReturnOrder(false)
  }

  const editReturn = async () => {
    const data = {
      status: orderStatus.query,
    };

    const response = await axios.patch(`/return/${returnDetails.id}`, data).catch(err => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    refetchReturns();

    successNotify(translate('returns.updated'));

    close();
  }

  const { mutate: editReturnStatus, isLoading: editReturnLoading } = useMutation('editOrderStatus', editReturn)

  const successNotify = (message) => toast.success(message)
  const errorNotify = (message) => toast.error(message)

  return (
    <Box className={cls.overlay} ref={overlay} onClick={(e) => closeModal(e)}>

      {editReturnLoading && <Loader />}

      <Box className={cls.area}>

        <Box className={cls.area__wrapper}>
          <Box className={`${cls.close} ${cls[i18n.language]}`} onClick={close}>
            <i className="fa-solid fa-xmark"></i>
          </Box>

          <Box container className={cls.area__content} spacing={3}>

            <h3>{translate('returns.edit')}</h3>

            <Box className={cls.fields}>

              <Box className={cls.field}>

                <label htmlFor="">{translate(('returns.status'))}</label>

                <Selectbox placeholder={translate(('returns.status'))} list={statusList} displayProp='name' value={orderStatus.name || ''} select={setOrderStatus} />

              </Box>

            </Box>

            <Box className={cls.actions}>
              
              <button onClick={close}>{translate('orders.close')}</button>

              <button onClick={editReturnStatus}>{translate('returns.edit')}</button>
            
            </Box>

          </Box>

        </Box>
      </Box>
    </Box>
  )
}

export default EditReturn;