import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { format } from 'date-fns';

import cls from './returnedItem.module.scss';

const ReturnedItem = ({ singleReturn }) => {
  // COMMPONENT HOOKS 
  const { t: translate, i18n } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <Box className={cls.returnedItem}>
      <Box className={cls.head}>

      <Box className={cls.head__part}>

        <Box className={cls.side}>
          <Box>
            <Typography className={cls.top}>
              {translate('userProfile.requestedDate')}
            </Typography>
            <Typography>
              {format(new Date(singleReturn.createdAt).getTime(), 'dd/MM/yyyy')}
            </Typography>
          </Box>
        </Box>

      </Box>

      <Box className={`${cls.head__part} ${cls.includeView}`}>

        <Box className={cls.side}>
          <Box>
            <Typography className={cls.top}>
              {translate('userProfile.id')}
            </Typography>
            <Typography>
              {singleReturn._id.substring(0,10) + '...'}
            </Typography>
          </Box>
        </Box>

      </Box>

      </Box>

      <Box className={cls.body}>

        {singleReturn?.items?.map(product => (
          
          <Box className={cls.body__order} key={product._id}>

            <Box className={cls.body__order_img}>

              <img className={cls[i18n.language]} src={product.item.item.images[0]} alt="orderImage" loading="lazy" />

              <Box>

                <h6 onClick={() => navigate(`/products/${product.item.item._id}`)}>{product.item.item.name.substring(0, 80) + '...'}</h6>

                <button className={cls.return} onClick={() => navigate(`/products/${product.item.item._id}`)}>

                  {translate('userProfile.details')}
                  
                </button>

              </Box>

            </Box>

          </Box>

        ))}

      </Box>
    </Box>
  )
}

export default ReturnedItem;