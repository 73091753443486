import { ReturnDetails as ReturnDetailsComponent } from '../../components';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import cls from './returnDetails.module.scss';

const ReturnDetails = () => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation('common');

  return (
    <Box className={cls.returnDetails}>

      <Box className={cls.returnDetails__head}>

        <Typography variant='h6' component='h6'>{translate('returnDetails.title')}</Typography>

        <Typography><span>{translate('returnDetails.returns')}</span> &gt; {translate('returnDetails.details')}</Typography>

      </Box>

      <Box className={cls.returnDetails__returnArea} sx={{ bgcolor: 'background.secondary' }}>

        <ReturnDetailsComponent />

      </Box>

    </Box>
  )
}

export default ReturnDetails