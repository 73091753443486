import { useState, useRef } from "react";

import Selectbox from "./../../UIs/SelectBox/Selectbox";
import CropImage from "../CropImage/CropImage";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import Loader from "./../../UIs/Loading/Loading";

import axios from "../../../utils/axios";

import { useMutation } from "react-query";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";

import cls from "./userPersonalTab.module.scss";

const userPersonalTab = ({
  setOpenPersonalEdit,
  userData,
  refetchUserData,
}) => {
  // COMPONENT HOOKS
  const overlay = useRef();
  const [openImageCropper, setOpenImageCropper] = useState(false);
  const [croppedImage, setCroppedImage] = useState(
    userData.profile_picture ? userData.profile_picture : ""
  );
  const [choosedImage, setChoosedImage] = useState(
    userData.profile_picture ? userData.profile_picture : ""
  );
  const inputRef = useRef();
  const [date, setDate] = useState(new Date());
  const { t: translate, i18n } = useTranslation("common");
  const [fields, setFields] = useState({
    username: userData.username,
    email: userData.email,
    phone: userData.phone,
  });

  // COMPONENT HANDLERS
  const closeModal = (e) => {
    if (overlay.current === e.target) setOpenPersonalEdit(false);
  };

  const close = () => {
    setOpenPersonalEdit(false);
  };

  const inputClick = () => {
    inputRef.current.click();
  };

  const chooseCategoryImage = (e) => {
    setOpenImageCropper(true);
    setChoosedImage(e.target.files[0]);
  };

  const deleteImage = () => {
    setCroppedImage("");
    setChoosedImage("");
    inputRef.current.value = "";
  };

  const handleFields = (e) => {
    setFields((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const updateCustomerData = async () => {
    const response = await axios
      .patch(`/user/${userData._id}`, fields)
      .catch((err) => errorNotify(err.response.data.err));

    if (!response || !response.data) return;

    if (typeof croppedImage === "object") {
      let profilePic = new FormData();

      profilePic.append("profile-picture", croppedImage);
      profilePic.append("change", 1);

      const imageRes = await axios
        .patch(`/user/${userData._id}/profile-picture`, profilePic);

      if (!imageRes || !imageRes.data) return;
    }

    refetchUserData();

    close();

    successNotify(translate("toast.customerUpdated"));
  };

  const { mutate: mutateUpdateCustomer, isLoading: updateLoading } =
    useMutation("updateCustomer", updateCustomerData);

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box className={cls.overlay} ref={overlay} onClick={(e) => closeModal(e)}>
      {updateLoading && <Loader />}

      <Box className={cls.area}>
        <Box className={cls.area__wrapper}>
          <Box className={`${cls.close} ${cls[i18n.language]}`} onClick={close}>
            <i className="fa-solid fa-xmark"></i>
          </Box>

          <Box container className={cls.area__content} spacing={3}>
            <h3>{translate("userProfile.edit")}</h3>

            <Box className={cls.fields}>
              <Box className={cls.field}>
                <label htmlFor="">{translate("userProfile.username")}</label>

                <input
                  type="text"
                  placeholder={translate("userProfile.username")}
                  name="username"
                  value={fields.username || ""}
                  onChange={(e) => handleFields(e)}
                />
              </Box>

              <Box className={cls.field}>
                <Box>
                  <label htmlFor="">{translate("userProfile.email")}</label>

                  <input
                    type="text"
                    placeholder={translate("userProfile.email")}
                    name="email"
                    value={fields.email || ""}
                    onChange={(e) => handleFields(e)}
                  />
                </Box>
              </Box>

              <Box className={cls.field}>
                <label htmlFor="">{translate("userProfile.phoneNum")}</label>

                <input
                  type="number"
                  placeholder={translate("userProfile.phoneNum")}
                  name="phone"
                  value={fields.phone || ""}
                  onChange={(e) => handleFields(e)}
                />
              </Box>

              <Box className={cls.field}>
                <label>{translate("userProfile.picture")}</label>

                <Box className={cls.tumbBox}>
                  {croppedImage ? (
                    <>
                      <Box className={cls.images}>
                        <Box className={cls.image}>
                          <img
                            src={
                              typeof croppedImage === "object"
                                ? URL.createObjectURL(croppedImage)
                                : croppedImage
                            }
                            alt="uploadedImage"
                          />

                          <i
                            className="fa-regular fa-xmark"
                            onClick={deleteImage}
                          ></i>
                        </Box>

                        {typeof choosedImage === "object" &&
                          choosedImage.name.substring(0, 20) + "..."}
                      </Box>
                    </>
                  ) : (
                    <Box onClick={inputClick}>
                      <i className="fa-solid fa-cloud-arrow-up"></i>

                      <Typography>{translate("userProfile.upload")}</Typography>
                    </Box>
                  )}

                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={inputRef}
                    onChange={chooseCategoryImage}
                  />
                </Box>
              </Box>
            </Box>

            <Box className={cls.actions}>
              <button onClick={close}>{translate("userProfile.close")}</button>

              <button onClick={mutateUpdateCustomer}>
                {translate("userProfile.save")}
              </button>
            </Box>
          </Box>
        </Box>
      </Box>

      {openImageCropper && (
        <CropImage
          setOpenCropImage={setOpenImageCropper}
          imageToCrop={URL.createObjectURL(choosedImage)}
          setCroppedImage={setCroppedImage}
        />
      )}
    </Box>
  );
};

export default userPersonalTab;
