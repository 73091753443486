import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useSelector } from "react-redux";

import Accordion from "../../UIs/Accordion/Accordion";

import FilterAccordion from "../../UIs/FilterAccordion/FilterAccordion";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";

import { useTranslation } from "react-i18next";

import cls from "./allProductsFilter.module.scss";

const AllProductsFilter = ({
  filterUrl,
  setFilterUrl,
  allCategories,
  choosedCategory,
  setChoosedCategory,
  choosedCategoryData,
  setChoosedCategoryData,
  selectedColors,
  setSelectedColors,
  categoriesLoading,
}) => {
  // COMPONENT HOOKS
  const [priceRange, setPriceRange] = useState([0, 100]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const { t: translate, i18n } = useTranslation("common");

  useEffect(() => {
    let queryParams = [
      "category",
      "subcategory",
      "price",
      "brands",
      "variants",
      "colors",
    ];

    queryParams.forEach((param) => {
      if (searchParams.get(param)) {
        setFilterUrl((prev) => ({
          ...prev,
          [param]: [...searchParams.get(param).split(",")],
        }));
        if (param !== "colors" && param !== "price" && param !== "category") {
          let allInside = searchParams
            .get(param)
            .split(",")
            .map((keyword) => {
              return {
                type: param,
                name:
                  param === "subcategory"
                    ? choosedCategory.sub_categories.find(
                        (sub) => sub._id === keyword
                      ).name
                    : keyword,
              };
            });
          setFilteredResults((prev) => [...prev, ...allInside]);
        }
      }
    });
  }, []);

  useEffect(() => {
    setPriceRange(
      searchParams.get("price")
        ? [
            +searchParams.get("price").split("~")[0],
            +searchParams.get("price").split("~")[1],
          ]
        : priceRange
    );
  }, []);

  useEffect(() => {
    if (filterUrl?.price) {
      setPriceRange([
        +filterUrl?.price[0]?.split("~")[0] || 0,
        +filterUrl?.price[0]?.split("~")[1] || 100,
      ]);
    }
  }, [choosedCategoryData]);

  // COMPONENT HANDLERS
  const handleChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const handlePriceChangeCommitted = () => {
    setFilterUrl((prev) => ({
      ...prev,
      price: [priceRange[0], priceRange[1]],
    }));
    searchParams.set("price", `${priceRange[0]}~${priceRange[1]}`);
    setSearchParams(searchParams);
  };

  const selectColor = (color) => {
    var colorFound = selectedColors.findIndex((col) => col === color);

    if (colorFound <= -1) {
      if (searchParams.get("colors")) {
        let allColors = [...searchParams.get("colors").split(",")];

        allColors.push(color);

        setFilterUrl((prev) => ({ ...prev, colors: allColors }));

        searchParams.set("colors", allColors.toString());

        setSearchParams(searchParams);

        setSelectedColors([...selectedColors, color]);
      } else {
        setFilterUrl((prev) => ({ ...prev, colors: [color] }));

        searchParams.set("colors", color);

        setSearchParams(searchParams);

        setSelectedColors([...selectedColors, color]);
      }
    } else {
      setSelectedColors([...selectedColors.filter((col) => col !== color)]);

      if (filterUrl.colors.length === 1) {
        delete filterUrl.colors;

        setFilterUrl({ ...filterUrl });

        searchParams.delete("colors");

        setSearchParams(searchParams);
      } else {
        let allColors = filterUrl.colors.filter((col) => col !== color);

        setFilterUrl((prev) => ({ ...prev, colors: allColors }));

        searchParams.set("colors", allColors.toString());

        setSearchParams(searchParams);
      }
    }
  };

  const addToFilters = (type, keyword) => {
    let word = keyword;

    if (type === "subcategory") {
      word = keyword._id;
    }

    if (searchParams.get(type)) {
      let allVariants = [searchParams.get(type)];

      let variantsArray = allVariants[0].split(",");

      const filterFound = variantsArray.findIndex((query) => query === word);

      if (filterFound != -1) {
        variantsArray.splice(filterFound, 1);

        if (filterUrl[type].length === 1) {
          delete filterUrl[type];

          setFilterUrl({ ...filterUrl });

          searchParams.delete(type);

          setSearchParams(searchParams);
        } else {
          setFilterUrl((prev) => ({ ...prev, [type]: variantsArray }));

          let allVars = variantsArray.toString();

          searchParams.set(type, allVars);

          setSearchParams(searchParams);
        }
      } else {
        setFilterUrl((prev) => ({ ...prev, [type]: allVariants }));

        filterUrl[type].push(word);

        setFilterUrl({ ...filterUrl });

        let allVars = filterUrl[type].toString();

        searchParams.set(type, allVars);

        setSearchParams(searchParams);
      }
    } else {
      setFilterUrl((prev) => ({ ...prev, [type]: [word] }));

      searchParams.set(type, word);

      setSearchParams(searchParams);
    }

    const found = filteredResults.find((item) => item === keyword);

    if (!found) {
      if (type === "subcategory") {
        setFilteredResults((prev) => [
          ...prev,
          { name: keyword.name, type: type, _id: keyword._id },
        ]);
      } else {
        setFilteredResults((prev) => [
          ...prev,
          { name: keyword, type: type, _id: keyword._id },
        ]);
      }
    }
  };

  const removeFromFilters = (type, keyword, id) => {
    const results = [...filteredResults];

    const found = filteredResults.findIndex((item) => item.name === keyword);

    results.splice(found, 1);

    setFilteredResults(results);

    if (filterUrl[type].length === 1) {
      delete filterUrl[type];

      setFilterUrl({ ...filterUrl });

      searchParams.delete(type);

      setSearchParams(searchParams);
    } else {
      let allVars = filterUrl[type].filter(
        (one) => one !== (type === "subcategory" ? id : keyword)
      );

      setFilterUrl((prev) => ({ ...prev, [type]: allVars }));

      searchParams.set(type, allVars.toString());

      setSearchParams(searchParams);
    }
  };

  const clearFilter = () => {
    setFilteredResults([]);
    setSearchParams({});
    setFilterUrl({});
  };

  return (
    <Box
      className={cls.allProductsFilter}
      sx={{ bgcolor: "background.secondary" }}
    >
      <Box className={cls.allProductsFilter__filter}>
        <Box className={cls.allProductsFilter__filter_head}>
          <Typography>{translate("products.filters")}</Typography>

          <Typography className={cls.clear} onClick={clearFilter}>
            {translate("products.clear")}
          </Typography>
        </Box>

        <Box className={cls.allProductsFilter__filter_box}>
          {filteredResults.length ? (
            <>
              {filteredResults.map((result, idx) => (
                <Box
                  key={idx}
                  className={`${cls.filtered} ${cls[i18n.language]}`}
                >
                  <span>{result.name}</span>

                  <i
                    className="fa-light fa-xmark"
                    onClick={() =>
                      removeFromFilters(result.type, result.name, result._id)
                    }
                  ></i>
                </Box>
              ))}
            </>
          ) : (
            <Typography>{translate("products.noFilters")}</Typography>
          )}
        </Box>
      </Box>

      <Box className={cls.allProductsFilter__products}>
        <Box className={cls.allProductsFilter__filter_head}>
          <Typography>{translate("products.categories")}</Typography>
        </Box>

        {allCategories &&
          allCategories.map((category, idx) => (
            <Box key={idx}>
              <FilterAccordion
                list={category.sub_categories}
                category={category}
                size="small"
                active={choosedCategory._id === category._id ? true : false}
                addToFilters={addToFilters}
                removeFromFilters={removeFromFilters}
                choosedCategory={choosedCategory}
                setChoosedCategory={setChoosedCategory}
                setChoosedCategoryData={setChoosedCategoryData}
                filterUrl={filterUrl}
                setFilterUrl={setFilterUrl}
                setSearchParams={setSearchParams}
                searchParams={searchParams}
                setFilteredResults={setFilteredResults}
              />
            </Box>
          ))}
      </Box>

      <Box className={cls.allProductsFilter__price}>
        <Box className={cls.allProductsFilter__filter_head}>
          <Typography>
            {translate("products.price")} ({websiteData.currency})
          </Typography>
        </Box>

        <Box className={cls.slider}>
          <Box className={cls.slider__range}>
            <Typography>${priceRange[0]}</Typography>

            <span>{translate("products.to")}</span>

            <Typography>${priceRange[1]}</Typography>
          </Box>

          <Slider
            getAriaLabel={() => "Price range"}
            value={priceRange}
            onChange={handleChange}
            onChangeCommitted={handlePriceChangeCommitted}
            max={
              choosedCategoryData
                ? choosedCategoryData.maxPrice === 0
                  ? 100
                  : choosedCategoryData.maxPrice
                : 100
            }
            min={choosedCategoryData ? choosedCategoryData.minPrice : 0}
            valueLabelDisplay="auto"
            disableSwap
          />
        </Box>
      </Box>

      <Box className={cls.allProductsFilter__brands}>
        <Accordion
          type="brands"
          list={choosedCategoryData ? choosedCategoryData.brands : []}
          label={translate("products.brands")}
          size="big"
          withCheckbox={true}
          removeFromFilters={removeFromFilters}
          addToFilters={addToFilters}
          filterUrl={filterUrl}
          categoriesLoading={categoriesLoading}
        />
      </Box>

      <Box className={cls.allProductsFilter__discount}>
        <Accordion
          type="variants"
          list={choosedCategoryData ? choosedCategoryData.variants : []}
          label={translate("products.variants")}
          size="big"
          withCheckbox={true}
          removeFromFilters={removeFromFilters}
          addToFilters={addToFilters}
          filterUrl={filterUrl}
          categoriesLoading={categoriesLoading}
        />
      </Box>

      <Box className={cls.allProductsFilter__colors}>
        <Box className={cls.allProductsFilter__colors_head}>
          <Typography>{translate("products.colors")}</Typography>
        </Box>

        <Box className={cls.colorsBox}>
          {choosedCategoryData && choosedCategoryData.colors.length ? (
            <>
              {choosedCategoryData.colors.map((color, idx) => (
                <Box
                  key={idx}
                  className={`${cls.color} ${
                    selectedColors.find((col) => col === color)
                      ? cls.selected
                      : ""
                  }`}
                  style={{ backgroundColor: color }}
                  onClick={() => selectColor(color)}
                ></Box>
              ))}
            </>
          ) : (
            <p className={cls.emptyColors}>{translate("products.noColors")}</p>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AllProductsFilter;
