const lightVars = () => {

  document.querySelector(":root").style.setProperty("--bg-color", "#ffffff");

  document.querySelector(":root").style.setProperty("--bg-alt-color", "#f8f8f8");

  document.querySelector(":root").style.setProperty("--dark-main-color", "#2b1cc9");

  /* MY COLORS */

  document.querySelector(":root").style.setProperty("--default-background", "#F8F9FA");

  document.querySelector(":root").style.setProperty("--secondary-background", "#fff");

  document.querySelector(":root").style.setProperty("--special-background", "#fafafa");

  document.querySelector(":root").style.setProperty("--text-color", "#383e50");

  document.querySelector(":root").style.setProperty("--border-color", "#eee");

  document.querySelector(":root").style.setProperty("--background-light", "#FAFAFA");

  document.querySelector(":root").style.setProperty("--hover-color", "#f3f3f3");

  document.querySelector(":root").style.setProperty("--main-color-light", "#def0ff");

  document.querySelector(":root").style.setProperty("--main-color-very-light", "#f2f9ff");

  document.querySelector(":root").style.setProperty("--error-alert", "#ee6a6a");

  document.querySelector(":root").style.setProperty("--error-alert-light", "#ffe8e8");

  document.querySelector(":root").style.setProperty("--success-light", "#e0ffee");

  document.querySelector(":root").style.setProperty("--alert-light", "#fff6e8");

  document.querySelector(":root").style.setProperty("--scroll-color", "#95b6d1");

  /* MY COLORS */

  document.querySelector(":root").style.setProperty("--light-border", "#E3E1FC");

  document.querySelector(":root").style.setProperty("--bg-element", "#fff");

  document.querySelector(":root").style.setProperty("--bg-default", "#f5f7fa");

  document.querySelector(":root").style.setProperty("--custom", "#bdc3c7");

};

export default lightVars;
