import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import EditAddress from '../../../modals/EditAddress/EditAddress';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { format } from "date-fns";

import { useTranslation } from "react-i18next";

import cls from "./orderSpecs.module.scss";

const OrderSpecs = ({ order, refetchOrder }) => {
  // COMPONENT HOOKS
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const [openEditAddress, setOpenEditAddress] = useState(false)
  const { t: translate, i18n } = useTranslation("common");
  const navigate = useNavigate();

  return (
    <Box className={cls.orderSpecs}>
      <Box
        className={cls.orderSpecs__status}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Box className={cls.head}>
          <Typography>{translate("orderDetails.stat")}</Typography>
        </Box>

        <Box className={cls.content}>
          <p>
            {translate("orderDetails.stat")}: {order.status}
          </p>
        </Box>
      </Box>

      <Box
        className={cls.orderSpecs__logistics}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Box className={cls.head}>
          <Typography>
            <i className="fa-light fa-truck"></i>{" "}
            {translate("orderDetails.logistics")}
          </Typography>
        </Box>

        <Box className={cls.content}>
          <img src="/assets/imgs/orders/shipped.png" alt="truck" />

          <Typography>{translate("orderDetails.logisitcs")}</Typography>

          <span>
            {translate("orderDetails.shippingTo")}:{" "}
            {order.delivery_address.city}
          </span>

          <span>
            {translate("orderDetails.shippingPrice")}:{" "}
            {order.shipping.price.toFixed(2)} ({websiteData.currency})
          </span>

          <span>
            {translate("orderDetails.date")}:{" "}
            {format(
              new Date(order.shipping.estimated_delivery).getTime(),
              "dd MMM, yyyy"
            )}
          </span>
        </Box>
      </Box>

      <Box
        className={cls.orderSpecs__customer}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Box className={cls.head}>
          <Typography>{translate("orderDetails.customer")}</Typography>

          <button onClick={() => navigate(`/customers/${order.user._id}`)}>
            {translate("orderDetails.profile")}
          </button>
        </Box>

        <Box className={cls.content}>
          <Box className={cls.customer}>
            <img
              src={order.user.profile_picture || "/assets/imgs/user/user.jpg"}
              alt={order.user.username}
            />

            <Box>
              <Typography>{order.user.username}</Typography>

              <span>{order.user.type}</span>
            </Box>
          </Box>

          <Box>
            <Typography>
              <i className="fa-light fa-envelope"></i>{" "}
              <span>{order.user.email}</span>
            </Typography>

            <Typography>
              <i className="fa-light fa-phone"></i>{" "}
              <span>
                {order.user.phone
                  ? order.user.phone
                  : translate("orderDetails.noPhone")}
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        className={cls.orderSpecs__billing}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Box className={cls.head}>
          <Typography>
            <i className="fa-light fa-location-dot"></i>{" "}
            {translate("orderDetails.address")}
          </Typography>

          <button onClick={() => setOpenEditAddress(true)}>
            {translate("orderDetails.changeAddress")}
          </button>
        </Box>

        <Box className={cls.content}>
          <ul>
            <li>
              {translate("orderDetails.type")}: {order.delivery_address.type}
            </li>
            <li>
              {translate("orderDetails.city")}: {order.delivery_address.city}
            </li>
            <li>
              {translate("orderDetails.country")}:{" "}
              {order.delivery_address.country}
            </li>
            <li>
              {translate("orderDetails.street")}:{" "}
              {order.delivery_address.street}
            </li>
            <li>
              {translate("orderDetails.zip")}: {order.delivery_address.zipCode}
            </li>
          </ul>
        </Box>
      </Box>

      <Box
        className={cls.orderSpecs__payment}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Box className={cls.head}>
          <Typography>
            <i className="fa-regular fa-credit-card"></i>{" "}
            {translate("orderDetails.payment")}
          </Typography>
        </Box>

        <Box className={cls.content}>
          <ul>
            <li>
              {translate("orderDetails.paymentType")}: {order.payment.type}
            </li>
          </ul>
        </Box>
      </Box>
      {openEditAddress && (
        <EditAddress
          setOpenEditAddress={setOpenEditAddress} 
          addressDataToEdit={order.delivery_address}
          refetchAddresses={refetchOrder}
          order={order}
        />
      )}
    </Box>
  );
};

export default OrderSpecs;
