import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import NavList from "../NavList/NavList";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import cls from "./Sidebar.module.scss";
import classes from '../NavList/navList.module.scss';

const Sidebar = ({ smallBar }) => {
  // COMPONENT HOOKS
  const navigate = useNavigate();
  const websiteData = useSelector(({ websiteData }) => websiteData.websiteData);
  const { t: translate, i18n } = useTranslation("nav");

  function handleMouseEnter() {
    if (smallBar) {
      document.querySelector(`.${classes.sidebar__list}`).classList.add(classes.expaneded);
    }
  }

  function handleMouseLeave() {
    if (smallBar) {
      document.querySelector(`.${classes.sidebar__list}`).classList.remove(classes.expaneded);
    }
  }

  return (
    <Box
      className={`${cls.sidebar} ${smallBar ? cls.small : ""} ${cls[i18n.language]}`}
      sx={{ bgcolor: "background.secondary" }}
      style={{ paddingLeft: '7px' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box className={cls.sidebar__head}>
        <Box className={cls.sidebar__head_content}>
          <img
            src={websiteData?.logo}
            alt="logo"
            onClick={() => navigate("/")}
          />

          <Typography variant="h4" component="h4" onClick={() => navigate("/")}>
            {websiteData?.website}
          </Typography>
        </Box>
      </Box>

      <Box className={cls.sidebar__body}>
        <NavList smallBar={smallBar} />
      </Box>
    </Box>
  );
};

export default Sidebar;
