import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { format } from 'date-fns';
import CurrencyFormat from 'react-currency-format';

import cls from './returnedItem.module.scss';

const ReturnedItem = ({ returnData }) => {
  // COMMPONENT HOOKS 
  const navigate = useNavigate();
  const { t: translate, i18n } = useTranslation('common');

  return (
    <Box className={cls.returnedItem}>
      <Box className={cls.head}>

      <Box className={cls.head__part}>

        <Box className={cls.side}>
          <Box>
            <Typography className={cls.top}>
              {translate('userProfile.requestedDate')}
            </Typography>
            <Typography>
              {format(new Date(returnData.createdAt).getTime(), 'dd/MM/yyyy')}
            </Typography>
          </Box>
        </Box>

      </Box>

      <Box className={`${cls.head__part} ${cls.includeView}`}>

        <Box className={cls.side}>
          <Box>
            <Typography className={cls.top}>
              {translate('userProfile.id')}
            </Typography>
            <Typography>
              {returnData.order._id.substring(0, 10) + '...'}
            </Typography>
          </Box>
        </Box>

      </Box>

      </Box>

      <Box className={cls.body}>

        {returnData.order.items.map((product, idx) => (
          
          <Box key={idx} className={cls.body__order}>

            {returnData.items.map(item => (

              <Box key={item._id} className={cls.body__order_img}>

                <img className={cls[i18n.language]} src={item.item.item.images[0]} alt="orderImage" loading="lazy" />

                <Box>

                  <h6 onClick={() => navigate(`/products/${item._id}`)}>{item.item.item.name.substring(0, 100) + '...'}</h6>

                  <Typography>
                    {translate('returnDetails.total')}: <CurrencyFormat value={item.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
                  </Typography>

                  <button className={cls.return} onClick={() => navigate(`/products/${item.item.item._id}`)}>

                    {translate('userProfile.details')}
                    
                  </button>

                </Box>

              </Box>

            ))}

          </Box>

        ))}

      </Box>
    </Box>
  )
}

export default ReturnedItem;