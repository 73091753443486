import { useState, useMemo } from "react";
import { useNavigate } from "react-router";

import { useSelector } from "react-redux";

import ChooseList from "../../UIs/ChooseList/ChooseList";
import TableCheckBox from "../../UIs/TableCheckBox/TableCheckBox";
import Selectbox from "../../UIs/SelectBox/Selectbox";
import EditOrder from "../../modals/EditOrder/EditOrder";
import LargeSquare from "../../UIs/skeletons/LargeSquare/LargeSquare";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";
import Snackbar from "@mui/material/Snackbar";

import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useRowSelect,
} from "react-table";

import { format } from "date-fns";
import CurrencyFormat from "react-currency-format";

import Swal from "sweetalert2";

import { toast } from "react-toastify";

import { CopyToClipboard } from "react-copy-to-clipboard";

import axios from "../../../utils/axios";

import { useTranslation } from "react-i18next";

import cls from "./allOrdersTable.module.scss";

const OrdersTable = ({
  ordersLoading,
  ordersCount,
  ordersData,
  ordersLimit,
  currentPage,
  setCurrentPage,
  refetchOrders,
  filterQuery,
  setFilterQuery,
  orderStatus,
  setOrderStatus,
  orderPayment,
  setOrderPayment,
  filter,
  setFilter,
}) => {
  // COMPONENT HOOKS
  const [pageNumber, setPageNumber] = useState(1);
  const [showEditOrder, setShowEditOrder] = useState(false);
  const [orderUpdate, setOrderUpdate] = useState();
  const [copied, setCopied] = useState(false);
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const { t: translate, i18n } = useTranslation("common");
  const navigate = useNavigate();

  const actionList = (id, order) => [
    {
      item: `${translate("category.view")}`,
      icon: <i className="fa-light fa-eye"></i>,
      method: () => navigate(`/orders/${id}`),
    },
    {
      item: `${translate("category.editStatus")}`,
      icon: <i className="fa-light fa-pen-nib"></i>,
      method: () => {
        setShowEditOrder(true);
        setOrderUpdate(order);
      },
    },
    {
      item: `${translate("category.delete")}`,
      icon: <i className="fa-light fa-trash-can"></i>,
      method: () => checkDeleteOrder(id),
    },
  ];

  const statusList = [
    {
      label: `${translate("orders.refunded")}`,
      name: "Refunded",
    },
    {
      label: `${translate("orders.completed")}`,
      name: "Completed",
    },
    {
      label: `${translate("orders.processing")}`,
      name: "Processing",
    },
    {
      label: `${translate("orders.cancelled")}`,
      name: "Cancelled",
    },
    {
      label: `${translate("orders.return")}`,
      name: "Return",
    },
  ];

  const paymentList = [
    {
      label: `${translate("orders.master")}`,
      name: "master card",
    },
    {
      label: `${translate("orders.deivery")}`,
      name: "delivery",
    },
    {
      label: `${translate("orders.paypal")}`,
      name: "paypal",
    },
    {
      label: `${translate("orders.visa")}`,
      name: "visa",
    },
  ];

  // TABLE COLUMNS
  const columns = useMemo(
    () => [
      {
        header: `${translate("orders.id")}`,
        accessor: "id",
        Cell: ({ value }) => (
          <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
            <span className="idString">{value.substring(0, 10) + "..."}</span>
          </CopyToClipboard>
        ),
        minWidth: "3%",
        width: "3%",
      },
      {
        header: `${translate("orders.customer")}`,
        id: "customer",
        accessor: "customer.name",
        Cell: ({ value, row }) => (
          <Box className={cls.customer}>
            <img
              src={row.original.customer.img}
              alt="productImage"
              loading="lazy"
            />

            <Box className={cls.info}>
              <p>{value}</p>
            </Box>
          </Box>
        ),
        minWidth: "15%",
        width: "15%",
      },
      {
        header: `${translate("orders.date")}`,
        accessor: "date",
        Cell: ({ value }) => <span>{format(value, "dd/MM/yyyy")}</span>,
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("orders.productsNum")}`,
        accessor: "products",
        Cell: ({ value }) => <span>{value}</span>,
        minWidth: "7%",
        width: "7%",
      },
      {
        header: `${translate("orders.amount")} (${websiteData.currency})`,
        accessor: "amount",
        Cell: ({ value }) => (
          <CurrencyFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
        minWidth: "12%",
        width: "12%",
      },
      {
        header: `${translate("orders.method")}`,
        accessor: "method",
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("orderDetails.stat")}`,
        id: "status",
        accessor: "status",
        Cell: ({ value }) => (
          <span className={`${cls.status} ${cls[value]}`}>{value}</span>
        ),
        minWidth: "10%",
        width: "10%",
      },
      {
        header: `${translate("orders.shippingStatus")}`,
        accessor: "shippingStatus",
        Cell: ({ value }) => (
          <span className={`${cls.status} ${cls[value]}`}>{value}</span>
        ),
        minWidth: "7%",
        width: "7%",
      },
      {
        header: `${translate("orders.action")}`,
        id: "action",
        Cell: ({ row }) => (
          <ChooseList
            list={actionList(row.original.id, row.original)}
            text={<i className="fa-thin fa-ellipsis-stroke"></i>}
            withArrow={false}
          />
        ),
        disableSortBy: true,
        minWidth: "5%",
        width: "5%",
      },
    ],
    [i18n.language]
  );

  const tableInstatce = useTable(
    {
      columns,
      data: ordersData,
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <TableCheckBox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <TableCheckBox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableSortBy: true,
          minWidth: "2%",
          width: "2%",
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    pageCount,
    pageOptions,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, selectedRowIds },
  } = tableInstatce;

  // COMPONENT HANDLERS
  const changePage = (e, pageNum) => {
    setCurrentPage(pageNum);
    gotoPage(pageNum - 1);
  };

  const checkDeleteOrder = (id) => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteOrder"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteOrder(id);
      }
    });
  };

  const deleteOrder = async (id) => {
    const response = await axios.delete(`/order/${id}`).catch((err) => {
      errorNotify(err.response.data.err);
    });

    if (!response || !response.data) return;

    refetchOrders();

    successNotify(translate("toast.orderDeleted"));
  };

  const checkDeleteMultipleOrders = () => {
    Swal.fire({
      title: translate("toast.sure"),
      text: translate("toast.deleteOrders"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: translate("toast.delete"),
      cancelButtonText: translate("toast.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMultipleOrders();
      }
    });
  };

  const deleteMultipleOrders = async () => {
    const ids = selectedFlatRows.map((id) => id.original.id);
    const response = await axios
      .delete(`/order`, { data: { IDs: ids } })
      .catch((err) => {
        errorNotify(err.response.data.err);
      });

    if (!response || !response.data) return;

    refetchOrders();

    successNotify(translate("toast.ordersDeleted"));
  };

  const handleOrderStatus = (filter) => {
    setFilter(filter);

    if (filter !== "all") {
      setFilterQuery((prev) => ({ ...prev, shippingStatus: filter }));
    } else {
      delete filterQuery.shippingStatus;

      setFilterQuery({ ...filterQuery });
    }
  };

  const handleStatusMethod = (filter) => {
    setOrderStatus({ ...filter });
    setFilterQuery((prev) => ({ ...prev, status: filter.name }));
  };

  const handlePaymentMethod = (filter) => {
    setOrderPayment({ ...filter });
    setFilterQuery((prev) => ({ ...prev, payment: filter.name }));
  };

  const gotToOrderDetails = (e, id) => {
    if (
      e.target.tagName !== "INPUT" &&
      e.target.tagName !== "BUTTON" &&
      e.target.tagName !== "LI" &&
      e.target.tagName !== "SPAN"
    ) {
      navigate(`/orders/${id}`);
    }
  };

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <Box className={cls.ordersTable}>
      <Box className={cls.orders__table_options}>
        <Box className={`${cls.search} ${cls[i18n.language]}`}>
          <i className="fa-light fa-magnifying-glass"></i>

          <input
            type="search"
            placeholder={translate("orders.search")}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </Box>

        <Selectbox
          placeholder={translate("orders.stat")}
          value={orderStatus.name || ""}
          select={handleStatusMethod}
          list={statusList}
          displayProp="name"
        />

        <Selectbox
          placeholder={translate("orders.pay")}
          value={orderPayment.name || ""}
          select={handlePaymentMethod}
          list={paymentList}
          displayProp="name"
        />
      </Box>

      <Box className={cls.orders__table_filters}>
        <Typography
          className={filter === "all" ? cls.selected : ""}
          onClick={() => handleOrderStatus("all")}
        >
          <i className="fa-light fa-bags-shopping"></i>{" "}
          <span>{translate("orders.all")}</span>
        </Typography>

        <Typography
          className={filter === "Processing" ? cls.selected : ""}
          onClick={() => handleOrderStatus("Processing")}
        >
          <i className="fa-light fa-clock"></i>{" "}
          <span>{translate("orders.processing")}</span>
        </Typography>

        <Typography
          className={filter === "Ready To Shipping" ? cls.selected : ""}
          onClick={() => handleOrderStatus("Ready To Shipping")}
        >
          <i className="fa-light fa-bookmark"></i>{" "}
          <span>{translate("orders.ready")}</span>
        </Typography>

        <Typography
          className={filter === "Shipped" ? cls.selected : ""}
          onClick={() => handleOrderStatus("Shipped")}
        >
          <i className="fa-light fa-box-open"></i>{" "}
          <span>{translate("orders.shipped")}</span>
        </Typography>

        <Typography
          className={filter === "On The Way" ? cls.selected : ""}
          onClick={() => handleOrderStatus("On The Way")}
        >
          <i className="fa-light fa-truck"></i>{" "}
          <span>{translate("orders.onWay")}</span>
        </Typography>

        <Typography
          className={filter === "Delivered" ? cls.selected : ""}
          onClick={() => handleOrderStatus("Delivered")}
        >
          <i className="fa-light fa-badge-check"></i>{" "}
          <span>{translate("orders.delivered")}</span>
        </Typography>
      </Box>

      {selectedFlatRows.length >= 1 && (
        <Box className={cls.deleteSelected}>
          {translate("product.select")} {selectedFlatRows.length}{" "}
          {translate("product.result")}{" "}
          <span onClick={checkDeleteMultipleOrders}>
            {translate("product.remove")}
          </span>
        </Box>
      )}

      <Box className={cls.ordersTable__table}>
        {!ordersLoading ? (
          <>
            {ordersData.length && rows.length >= 1 ? (
              <Table {...getTableProps()}>
                <TableHead>
                  {headerGroups.map((headerGroup, idx) => (
                    <TableRow key={idx} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, idx) => (
                        <TableCell
                          key={idx}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                          }}
                        >
                          <span>
                            {column.render("header")}

                            {!column.disableSortBy && (
                              <>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <i className="fa-light fa-sort-down"></i>
                                  ) : (
                                    <i className="fa-light fa-sort-up"></i>
                                  )
                                ) : (
                                  <i className="fa-light fa-sort"></i>
                                )}
                              </>
                            )}
                          </span>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>

                <TableBody {...getTableBodyProps()}>
                  {page.map((row, idx) => {
                    prepareRow(row);

                    return (
                      <TableRow
                        key={idx}
                        {...row.getRowProps()}
                        onClick={(e) => gotToOrderDetails(e, row.original.id)}
                      >
                        {row.cells.map((cell, idx) => (
                          <TableCell
                            key={idx}
                            {...cell.getCellProps()}
                            style={{
                              minWidth: cell.column.minWidth,
                              width: cell.column.width,
                            }}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Box className="empty">
                <img src="/assets/imgs/common/empty.png" alt="no orders" />
                <p>{translate("analytics.noOrdersFound")}</p>
              </Box>
            )}
          </>
        ) : (
          <LargeSquare />
        )}
      </Box>

      <Box className={cls.ordersTable__footer}>
        <Typography>
          {translate("product.show")} {currentPage} {translate("product.of")}{" "}
          {Math.ceil(ordersCount / ordersLimit)} {translate("product.results")}
        </Typography>

        <Box className={`${cls.pagination} pagination`}>
          <Pagination
            count={Math.ceil(ordersCount / ordersLimit)}
            page={currentPage}
            onChange={changePage}
          />
        </Box>
      </Box>

      {showEditOrder && (
        <EditOrder
          setShowEditOrder={setShowEditOrder}
          order={orderUpdate}
          orderIdUpdate={orderUpdate.id}
          refetchOrders={refetchOrders}
        />
      )}

      {/* SNACKBAR COMPONENT */}
      <Snackbar
        open={copied}
        autoHideDuration={2000}
        onClose={() => setCopied(false)}
        message="Copied to Clipboard!"
      />
    </Box>
  );
};

export default OrdersTable;
