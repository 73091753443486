export const NavLinks = (translate) => {
  return (
    [
      {
        lable: `${translate('nav.analytics')}`,
        link: '/',
        condition: 'analytics',
        icon: <i className="fa-light fa-house"></i>,
        subMenu: []
      },
    
      {
        lable: `${translate('nav.general')}`,
        link: '/general',
        condition: 'general',
        icon: <i className="fa-light fa-earth-americas"></i>,
        subMenu: []
      },
    
      {
        lable: `${translate('nav.ads')}`,
        link: '/application-ads',
        condition: 'ads',
        icon: <i className="fa-light fa-books-medical"></i>,
        subMenu: [
          {
            name: `${translate('nav.allAds')}`,
            link: '/ads/all'
          }
        ]
      },
    
      {
        lable: `${translate('nav.categories')}`,
        link: '/categories',
        condition: 'categories',
        icon: <i className="fa-light fa-server"></i>,
        subMenu: [
          {
            name: `${translate('nav.cat')}`,
            link: '/categories/all'
          },
          {
            name: `${translate('nav.createCat')}`,
            link: '/categories/create-category'
          }
        ]
      },
    
      {
        lable: `${translate('nav.products')}`,
        link: '/products',
        condition: 'products',
        icon: <i className="fa-light fa-bag-shopping"></i>,
        subMenu: [
          {
            name: `${translate('nav.prods')}`,
            link: '/products/all'
          },
          {
            name: `${translate('nav.createProds')}`,
            link: '/products/create-product'
          }
        ]
      },
    
      {
        lable: `${translate('nav.coupons')}`,
        link: '/coupons',
        condition: 'coupons',
        icon: <i className="fa-light fa-circle-star"></i>,
        subMenu: [
          {
            name: `${translate('nav.allCoupons')}`,
            link: '/coupons/all'
          },
          {
            name: `${translate('nav.createCoupon')}`,
            link: '/coupons/create-coupon'
          }
        ]
      },
    
      {
        lable: `${translate('nav.orders')}`,
        link: '/orders',
        condition: 'orders',
        icon: <i className="fa-light fa-cart-plus" style={{ marginLeft: '-3px', marginRight: '3px' }}></i>,
        subMenu: []
      },
    
      {
        lable: `${translate('nav.returns')}`,
        link: '/returns',
        condition: 'returns',
        icon: <i className="fa-light fa-arrow-rotate-left" style={{ marginLeft: '-3px', marginRight: '3px' }}></i>,
        subMenu: []
      },
    
      {
        lable: `${translate('nav.logistics')}`,
        link: '/logistics',
        condition: 'logistics',
        icon: <i className="fa-light fa-truck"></i>,
        subMenu: []
      },
    
      {
        lable: `${translate('nav.customers')}`,
        link: '/customers',
        condition: 'customers',
        icon: <i className="fa-light fa-users"></i>,
        subMenu: []
      },
    
      {
        lable: `${translate('nav.contact')}`,
        link: '/contact',
        condition: 'contact',
        icon: <i className="fa-light fa-users"></i>,
        subMenu: []
      },
    
      {
        lable: `${translate('nav.support')}`,
        link: '/customer-support',
        condition: 'support',
        icon: <i className="fa-light fa-headset"></i>,
        subMenu: []
      },
    
      {
        lable: `${translate('nav.logs')}`,
        link: '/logs',
        condition: 'logs',
        icon: <i className="fa-light fa-headset"></i>,
        subMenu: []
      },
    
      {
        lable: `${translate('nav.account')}`,
        link: '/account',
        condition: 'account',
        icon: <i className="fa-light fa-user-gear"></i>,
        subMenu: []
      },
    ]
  )
}