import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux/es/exports';
import store from './store/store';
import './i18n/config'

import './styles/globals.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <Provider store={store}>

    <BrowserRouter>

      <App />
      
    </BrowserRouter>

  </Provider>

);