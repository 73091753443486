import Skeleton from '@mui/material/Skeleton';

const LargeSquare = () => {
  return (
    <Skeleton variant="rectangular" width="100%" height="100%" style={{ borderRadius: '5px' }}>
      <div style={{ paddingTop: '57%' }} />
    </Skeleton>
  )
}

export default LargeSquare