import { useState, useRef, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { websiteDataActions } from "./../../../store/slices/websiteDataSlice";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Selectbox from "../../UIs/SelectBox/Selectbox";
import InputFieldComponent from "../../UIs/InputField/InputField";
import UploadField from "./../../UIs/UploadField/UploadField";
import Loader from "../../UIs/Loading/Loading";

import { useQuery, useMutation } from "react-query";
import axios from "../../../utils/axios";

import { useTranslation } from "react-i18next";

import currencies from "../../../data/currencies.json";

import { toast } from "react-toastify";

import cls from "./applicationTab.module.scss";
import LargeSquare from "./../../UIs/skeletons/LargeSquare/LargeSquare";

const ApplicationTab = () => {
  // COMPONENT HOOKS
  const [allCurrencies, setAllCurrencies] = useState([]);
  const websiteData = useSelector(({ websiteData }) => websiteData.websiteData);
  const [websiteName, setWebsiteName] = useState(websiteData.website || "");
  const [currency, setCurrency] = useState(websiteData.currency || "");
  const [choosedImage, setChoosedImage] = useState(websiteData.logo || "");
  const [emptyFields, setEmptyFields] = useState(false);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const { t: translate, i18n } = useTranslation("common");

  useEffect(() => {
    if (i18n.language === "en") {
      let allcurrens = currencies.map((one) => {
        return one.en;
      });
      setAllCurrencies(allcurrens);
    } else {
      let allcurrens = currencies.map((one) => {
        return one.ar;
      });
      setAllCurrencies(allcurrens);
    }
  }, [currencies, i18n.language]);

  const updateMainInfo = async () => {
    const data = {
      currency,
      website: websiteName,
      logo: choosedImage,
    };

    const empties = Object.values(data).filter(
      (one) => one === undefined || one === ""
    );

    if (empties.length) {
      setEmptyFields(true);
    } else {
      setEmptyFields(false);

      let mainData = new FormData();

      Object.keys(data).forEach((key) => {
        mainData.append(key, data[key]);
      });

      const response = await axios
        .patch("/info", mainData)
        .catch((err) => errorNotify(err.response.data.err));

      if (!response || !response.data) return;

      dispatch(websiteDataActions.setWebsiteData(response.data.success));

      successNotify(translate("toast.generalUpdated"));
    }
  };

  const { mutate: updateGeneralInfo, isLoading: updateLoading } = useMutation(
    "updateGeneralInfo",
    updateMainInfo
  );

  const successNotify = (message) => toast.success(message);
  const errorNotify = (message) => toast.error(message);

  return (
    <>
      {updateLoading && <Loader />}
      <Box
        className={cls.applicationTab}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Typography variant="h6" component="h6">
          {translate("general.prepernces")}
        </Typography>

        <Box className={cls.applicationTab__fields}>
          <Grid container columnSpacing={3}>
            <Grid item xs={12} md={6}>
              <Box className={cls.applicationTab__fields_field}>
                <Box>
                  <label>{translate("general.name")}</label>

                  <InputFieldComponent
                    type="text"
                    placeholder={translate("general.name")}
                    value={websiteName}
                    setFunc={(e) => setWebsiteName(e.target.value)}
                    error={emptyFields && !websiteName ? true : false}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box className={cls.applicationTab__fields_field}>
                <Box className={cls.single}>
                  <label>{translate("general.currency")}</label>

                  <Selectbox
                    placeholder={translate("general.currency")}
                    list={allCurrencies}
                    value={currency}
                    select={setCurrency}
                    error={emptyFields && !currency ? true : false}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <label>{translate("general.appLogo")}</label>

          <UploadField
            attach={choosedImage}
            updateAttachment={setChoosedImage}
            error={emptyFields && !choosedImage ? true : false}
          />
        </Box>
      </Box>

      <Box className={cls.btn}>
        <button onClick={updateGeneralInfo}>{translate("general.save")}</button>
      </Box>
    </>
  );
};

export default ApplicationTab;
