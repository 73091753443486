import { useNavigate } from 'react-router';

import { CouponsList, TopAnalytics } from '../../../components';
import SmallSquare from '../../../components/UIs/skeletons/SmallSquare/SmallSquare';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import axios from '../../../utils/axios';

import cls from './allcoupons.module.scss';

const AllCoupon = () => {
  // COMPONENT HOOKS
  const { t: translate, i18n } = useTranslation('common');
  const navigate = useNavigate();

  const analytics = [
    {
      id: 1,
      label: `${translate('topAnalytics.allCoupons')}`,
      var: '-',
      link: `${translate('topAnalytics.availableCoupons')}`,
      icon: <i className="fa-light fa-circle-star"></i>
    },
    {
      id: 2,
      label: `${translate('topAnalytics.percent')}`,
      var: '-',
      link: `${translate('topAnalytics.percentCoupons')}`,
      icon: <i className="fa-light fa-percent"></i>
    },
    {
      id: 3,
      label: `${translate('topAnalytics.fixed')}`,
      var: '-',
      link: `${translate('topAnalytics.fixedCoupons')}`,
      icon: <i className="fa-regular fa-dollar-sign"></i>
    },
    {
      id: 4,
      label: `${translate('topAnalytics.alive')}`,
      var: '-',
      link: `${translate('topAnalytics.aliveCoupons')}`,
      icon: <i className="fa-light fa-stopwatch"></i>
    }
  ]

  const fetchCouponsAnalytics = async () => {
    const response = await axios.get('/coupon/analytics');

    if(!response || !response.data.success) return;

    return response.data.success;
  }

  const { data: couponsAnalytics, isLoading } = useQuery('coupons', fetchCouponsAnalytics, {
    select: (couponsAnalytics) => {
      analytics[0].var = couponsAnalytics.totalCoupons
      analytics[1].var = couponsAnalytics.totalCouponsPercentaged
      analytics[2].var = couponsAnalytics.totalCouponsAmounted
      analytics[3].var = couponsAnalytics.totalAliveCoupons

      return analytics
    }
  });

  return (
    <Box className={cls.allCoupons}>

      <Box className={cls.allCoupons__head}>

        <Typography variant='h6' component='h6'>{translate('allCoupons.title')}</Typography>

        <Typography><span>{translate('allCoupons.coupons')}</span> &gt; {translate('allCoupons.all')}</Typography>

      </Box>

      {isLoading ? 

        <Grid container spacing={2} style={{ marginBottom: '20px' }}>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

        </Grid>

        :

        <TopAnalytics analytics={analytics} />
    
      }

      <Box sx={{bgcolor: 'background.secondary' }} className={cls.allCoupons__tabel}>

        <Box className={cls.allCoupons__table_head}>

          <Typography variant="h6" component="h6">
            {translate('allCoupons.list')}
          </Typography>

          <button onClick={() => navigate('/coupons/create-coupon')}><i className="fa-regular fa-plus"></i> {translate('allCoupons.add')}</button>

        </Box>

        <CouponsList />

      </Box>

    </Box>
  )
}

export default AllCoupon