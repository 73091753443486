import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router";
import { useSelector } from "react-redux";
// IMPORT PAGES
import {
  Analytics,
  Login,
  Reset,
  Forget,
  Orders,
  Returns,
  AllProducts,
  CreateProduct,
  AllCategories,
  CreateCategory,
  ProductDetails,
  OrderDetails,
  Customers,
  Settings,
  Logistics,
  AddCountry,
  EditCountry,
  AllCoupons,
  CreateCoupon,
  UserProfile,
  General,
  AllAds,
  CreateAd,
  Contact,
  ContactDetails,
  CustomerSupport,
  Chat,
  CreateReturn,
  ReturnDetails,
  Logs,
  Notifications,
  Error,
} from "../pages";

const RequireAuth = ({ Component }) => {
  const user = useSelector((state) => state.user.user);
  return !user ? <Navigate to="/login" /> : <Component />;
};

const RequireNoAuth = ({ Component }) => {
  const user = useSelector((state) => state.user.user);
  return user ? <Navigate to="/" /> : <Component />;
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<RequireNoAuth Component={Login} />} />

      <Route path="/reset" element={<RequireNoAuth Component={Reset} />} />

      <Route path="/forget" element={<RequireNoAuth Component={Forget} />} />

      <Route path="/" element={<RequireAuth Component={Analytics} />} />

      <Route
        path="/products/all"
        element={<RequireAuth Component={AllProducts} />}
      />

      <Route
        path="/products/create-product"
        element={<RequireAuth Component={CreateProduct} />}
      />

      <Route
        path="/categories/all"
        element={<RequireAuth Component={AllCategories} />}
      />

      <Route
        path="/categories/create-category"
        element={<RequireAuth Component={CreateCategory} />}
      />

      <Route
        path="/products/:id"
        element={<RequireAuth Component={ProductDetails} />}
      />

      <Route path="/orders" element={<RequireAuth Component={Orders} />} />

      <Route path="/returns" element={<RequireAuth Component={Returns} />} />

      <Route
        path="/orders/:orderId"
        element={<RequireAuth Component={OrderDetails} />}
      />

      <Route
        path="/customers"
        element={<RequireAuth Component={Customers} />}
      />

      <Route path="/account" element={<RequireAuth Component={Settings} />} />

      <Route
        path="/logistics"
        element={<RequireAuth Component={Logistics} />}
      />

      <Route
        path="/logistics/add-country"
        element={<RequireAuth Component={AddCountry} />}
      />

      <Route
        path="/logistics/edit-country"
        element={<RequireAuth Component={EditCountry} />}
      />

      <Route
        path="/coupons/all"
        element={<RequireAuth Component={AllCoupons} />}
      />

      <Route
        path="/coupons/create-coupon"
        element={<RequireAuth Component={CreateCoupon} />}
      />

      <Route
        path="/customers/:customerId"
        element={<RequireAuth Component={UserProfile} />}
      />

      <Route path="/general" element={<RequireAuth Component={General} />} />

      <Route path="/ads/all" element={<RequireAuth Component={AllAds} />} />

      <Route
        path="/ads/edit-ad/:adId"
        element={<RequireAuth Component={CreateAd} />}
      />

      <Route path="/contact" element={<RequireAuth Component={Contact} />} />

      <Route
        path="/contact/:id"
        element={<RequireAuth Component={ContactDetails} />}
      />

      <Route
        path="/customer-support"
        element={<RequireAuth Component={CustomerSupport} />}
      />

      <Route path="/logs" element={<RequireAuth Component={Logs} />} />

      <Route
        path="/notifications"
        element={<RequireAuth Component={Notifications} />}
      />

      <Route
        path="/create-return"
        element={<RequireAuth Component={CreateReturn} />}
      />

      <Route
        path="/ticket/:ticketId/chat"
        element={<RequireAuth Component={Chat} />}
      />

      <Route
        path="/returns/:returnId"
        element={<RequireAuth Component={ReturnDetails} />}
      />

      <Route path="/*" element={<Error />} />
    </Routes>
  );
};

export default AppRoutes;
