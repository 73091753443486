import { useState } from 'react';

import { ItemsForReturn } from '../../components';
import { Selectbox } from '../../components';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';

import cls from './createReturn.module.scss';

const returns = [
  {
    _id: '1',
    tracking_number: '934589348',
    status: 'Delivered',
    order: {
      items: [
        {
          item: {
            name: 'Iphone 13 pro max',
            images: [
              '/assets/imgs/products/tshirt.png'
            ],
            quantity: 1,
          },
          variant: '32 GB'
        },
        {
          item: {
            name: 'Iphone 13 pro max',
            images: [
              '/assets/imgs/products/tshirt.png'
            ],
            quantity: 2,
          },
          variant: '32 GB'
        },
        {
          item: {
            name: 'Iphone 13 pro max',
            images: [
              '/assets/imgs/products/tshirt.png'
            ],
            quantity: 3,
          },
          variant: '32 GB'
        },
        {
          item: {
            name: 'Iphone 13 pro max',
            images: [
              '/assets/imgs/products/tshirt.png'
            ],
            quantity: 4,
          },
          variant: '32 GB'
        }
      ],
    }
  }
]

const CreateReturn = () => {
  // COMPONENT HOOKS
  const [userFound, setUserFound] = useState(false);
  const [ticketFound, setTicketFound] = useState(false);
  const [userId, setUserId] = useState('');
  const [ticket, setTicket] = useState('');
  const [returnStatus, setReturnStatus] = useState()
  const { t: translate, i18n } = useTranslation('common');

  const statusList = [
    `${translate('orders.allFilt')}`,
    `${translate('orders.cancelled')}`,
    `${translate('orders.delivered')}`,
    `${translate('orders.pending')}`,
    `${translate('orders.returns')}`
  ]

  // COMPONENT HANDLERS
  const submitUserid = () => {
    setUserFound(true)
  }

  const submitTicket= () => {
    setTicketFound(true)
  }

  return (
    <div className={cls.createReturn}>

      <Box className={cls.createReturn__head}>

        <Typography variant='h6' component='h6'>{translate('createReturn.title')}</Typography>

        <Typography><span>{translate('createReturn.Returns')}</span> &gt; {translate('createReturn.returns')}</Typography>

      </Box>

      <Grid container spacing={3}>

        <Grid item xs={12} md={4}>

          <Box className={cls.createReturn__inputs} sx={{ bgcolor: 'background.secondary' }}>

            <Box className={cls.createReturn__inputs_field}>

              <label>{translate('createReturn.user')}</label>

              <Box className={cls.actions}>

                <input type="text" placeholder={translate('createReturn.user')} onChange={(e) => setUserId(e.targte.value)} />

                <button onClick={submitUserid}>{translate('createReturn.submit')}</button>

              </Box>

            </Box>

            <Box className={`${cls.createReturn__inputs_field} ${!userFound ? cls.disabled : ''}`}>

              <label>{translate('createReturn.ticket')}</label>

              <Box className={cls.actions}>

                <input type="text" value={ticket} onChange={(e) => setTicket(e.target.value)} placeholder={translate('createReturn.ticket')} disabled={!userFound} />

                <button onClick={submitTicket}>{translate('createReturn.submit')}</button>

              </Box>

            </Box>

            <Box className={`${cls.createReturn__inputs_field} ${!ticketFound ? cls.disabled : ''}`}>

              <label>{translate('createReturn.status')}</label>

              <Box className={cls.actions}>

                <Selectbox placeholder={translate('createReturn.status')} list={statusList} value={returnStatus} select={setReturnStatus} disabled={!ticketFound} />

              </Box>

            </Box>

          </Box>

        </Grid>

        <Grid item xs={12} md={8}>

          <Box className={cls.createReturn__returns} sx={{ bgcolor: 'background.secondary' }}>

            {returns.map((retrn, idx) => (

              <ItemsForReturn key={idx} retrn={retrn} />

            ))}

            {!ticket && returns.length <= 0 && (

              <p className={cls.warning}>{translate('createReturn.warning')}</p>

            )}

          </Box>

          <Box className={cls.btn}>

            <button>{translate('createReturn.create')}</button>

          </Box>

        </Grid>

      </Grid>

    </div>
  )
}

export default CreateReturn;