import { useState, useContext, useEffect } from 'react'
import { ThemeContext } from '../../../theme/theme'

import Box from '@mui/material/Box'

import Cookies from 'universal-cookie'

import cls from './modeSwitch.module.scss'

const cookie = new Cookies()

const ModeSwitch = () => {
  // COMPONENT HOOKS
  const [mode, setMode] = useState(cookie.get('marketyMode') || 'light');
  const { switchMode } = useContext(ThemeContext)

  useEffect(() => {
    setMode(cookie.get('marketyMode'))
  }, [cookie.get('marketyMode')])

  return (
    <Box className={cls.modeSwitch} sx={{ bgcolor: 'background.default' }} onClick={switchMode}>
      {mode === 'light' ? 
      
        <i className="fa-light fa-moon"></i>

        :

        <i className="fa-light fa-sun"></i>
    
      }
      
    </Box>
  )
}

export default ModeSwitch