import { useState } from "react";

import { useSelector } from "react-redux";

import { AllOrdersTable, CreateOrder, TopAnalytics } from "../../../components";
import SmallSquare from "../../../components/UIs/skeletons/SmallSquare/SmallSquare";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { useQuery } from "react-query";
import axios from "../../../utils/axios";

import CurrencyFormat from "react-currency-format";

import { useTranslation } from "react-i18next";

import cls from "./orders.module.scss";

const Orders = () => {
  // COMPONENT HOOKS
  const [ordersCount, setOrdersCount] = useState(0);
  const [ordersData, setOrdersData] = useState([]);
  const [ordersLimit, setOrdersLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterQuery, setFilterQuery] = useState({});
  const [orderStatus, setOrderStatus] = useState("");
  const [orderPayment, setOrderPayment] = useState({});
  const [filter, setFilter] = useState("all");
  const [openCreateOrder, setOpenCreateOrder] = useState(false);
  const { websiteData } = useSelector(({ websiteData }) => websiteData);
  const { t: translate } = useTranslation("common");

  const analytics = [
    {
      id: 1,
      label: `${translate("topAnalytics.allOrders")}`,
      var: "-",
      link: `${translate("topAnalytics.allOrder")}`,
      icon: <i className="fa-light fa-bag-shopping"></i>,
    },
    {
      id: 2,
      label: `${translate("topAnalytics.totalEarnings")} (${
        websiteData.currency
      })`,
      var: "-",
      link: `${translate("topAnalytics.viewEarnings")}`,
      icon: <i className="fa-regular fa-dollar-sign"></i>,
    },
    {
      id: 3,
      label: `${translate("topAnalytics.paypal")}`,
      var: "-",
      link: `${translate("topAnalytics.paypalOrders")}`,
      icon: <i className="fa-brands fa-paypal"></i>,
    },
    {
      id: 4,
      label: `${translate("topAnalytics.delivery")}`,
      var: "-",
      link: `${translate("topAnalytics.deliveryOrders")}`,
      icon: <i className="fa-light fa-truck"></i>,
    },
    {
      id: 5,
      label: `${translate("topAnalytics.processing")}`,
      var: "-",
      link: `${translate("topAnalytics.processingOrders")}`,
      icon: <i className="fa-light fa-stopwatch"></i>,
    },
    {
      id: 6,
      label: `${translate("topAnalytics.completed")}`,
      var: "-",
      link: `${translate("topAnalytics.completedOrders")}`,
      icon: <i className="fa-light fa-badge-check"></i>,
    },
    {
      id: 7,
      label: `${translate("topAnalytics.refuneded")}`,
      var: "-",
      link: `${translate("topAnalytics.refundedOrders")}`,
      icon: <i className="fa-regular fa-dollar-sign"></i>,
    },
    {
      id: 8,
      label: `${translate("topAnalytics.cancelled")}`,
      var: "-",
      link: `${translate("topAnalytics.cancelledOrders")}`,
      icon: <i className="fa-light fa-xmark"></i>,
    },
  ];

  const fetchOrdersAnalytics = async () => {
    const response = await axios.get("/order/analytics");

    if (!response || !response.data) return;

    return response.data.success;
  };

  const { data: ordersAnalytics, isFetching: analyticsLoading } = useQuery(
    "ordersAnalytics",
    fetchOrdersAnalytics,
    {
      refetchOnWindowFocus: false,
      select: (ordersAnalytics) => {
        analytics[0].var = ordersAnalytics.totalOrders;
        analytics[1].var = (
          <CurrencyFormat
            value={ordersAnalytics.totalEarnings.toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
          />
        );
        analytics[2].var = ordersAnalytics.totalOrdersByPayPal;
        analytics[3].var = ordersAnalytics.totalOrdersByDelivery;
        analytics[4].var = ordersAnalytics.totalOrdersProcessing;
        analytics[5].var = ordersAnalytics.totalOrdersCompleted;
        analytics[6].var = ordersAnalytics.totalOrdersRefunded;
        analytics[7].var = ordersAnalytics.totalOrdersCancelled;

        return analytics;
      },
    }
  );

  const fetchAllOrders = async () => {
    let queryString = ``;

    Object.keys(filterQuery).forEach((key) => {
      queryString += `&${key}=${filterQuery[key]}`;
    });

    const response = await axios.get(
      `/order?pu=1&pi=1&skip=${
        (currentPage - 1) * ordersLimit
      }&limit=${ordersLimit}${queryString}`
    );

    if (!response || !response.data) return;

    setOrdersCount(response.data.count);

    return response.data.success;
  };

  const {
    data: allOrders,
    isFetching: ordersLoading,
    refetch: refetchOrders,
  } = useQuery(["fetchOrders", currentPage, filterQuery], fetchAllOrders, {
    refetchOnWindowFocus: false,
    onSuccess: (allOrders) => {
      const updatedOrders = allOrders.map((order) => {
        return {
          id: order._id,
          product: {
            img: order?.items[0]?.item?.images[0],
            name: order?.items[0]?.item?.name,
            brand: order?.items[0]?.item?.brand,
          },
          products: order.items.length,
          customer: {
            img: order?.user?.profile_picture || "/assets/imgs/user/user.jpg",
            name: order?.user?.username,
          },
          date: new Date(order.updatedAt).getTime(),
          amount: order.items
            .reduce((total, order) => (total += order.price), 0)
            .toFixed(2),
          method: order.payment.type,
          shippingStatus: order.shipping.status,
          status: order.status,
        };
      });

      setOrdersData(updatedOrders);
    },
  });

  const resetFilterQuery = () => {
    setFilterQuery({});
    setOrderStatus({});
    setOrderPayment({});
    setFilter("all");
  };

  const productTableProps = {
    ordersLoading,
    ordersCount,
    ordersData,
    ordersLimit,
    currentPage,
    setCurrentPage,
    refetchOrders,
    filterQuery,
    setFilterQuery,
    orderStatus,
    setOrderStatus,
    orderPayment,
    setOrderPayment,
    filter,
    setFilter,
  };

  return (
    <Box className={cls.orders}>
      <Box className={cls.orders__head}>
        <Typography variant="h6" component="h6">
          {translate("orders.title")}
        </Typography>

        <Typography>
          <span>{translate("orders.orders")}</span> &gt;{" "}
          {translate("orders.all")}
        </Typography>
      </Box>

      {!analyticsLoading ? (
        <TopAnalytics analytics={analytics} special={true} />
      ) : (
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>

          <Grid item xs={12} sm={6} xl={3}>
            <SmallSquare />
          </Grid>
        </Grid>
      )}

      <Box
        className={cls.orders__table}
        sx={{ bgcolor: "background.secondary" }}
      >
        <Box className={cls.orders__table_head}>
          <Typography variant="h6" component="h6">
            {translate("orders.history")}
          </Typography>

          {Object.keys(filterQuery).length ? (
            <span onClick={resetFilterQuery}>
              <i className="fa-light fa-arrow-rotate-left"></i>{" "}
              {translate("general.resetFilter")}
            </span>
          ) : (
            ""
          )}
        </Box>

        <AllOrdersTable {...productTableProps} />
      </Box>

      {openCreateOrder && (
        <CreateOrder setOpenCreateOrder={setOpenCreateOrder} />
      )}
    </Box>
  );
};

export default Orders;
